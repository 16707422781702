import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import { BOOLEANS } from "../../../Utils/calorieHelper";

function CRSCalc() {
  const [maritalStatus, setMaritalStatus] = useState("Single");
  const [age, setAge] = useState(17);
  const [levelEducation, setLevelEducation] = useState("NA");
  const [englishListening, setEnglishListening] = useState("5");
  const [englishSpeaking, setEnglishSpeaking] = useState("5");
  const [englishReading, setEnglishReading] = useState("5");
  const [englishWriting, setEnglishWriting] = useState("5");
  const [frenchListening, setFrenchListening] = useState("0");
  const [frenchSpeaking, setFrenchSpeaking] = useState("0");
  const [frenchReading, setFrenchReading] = useState("0");
  const [frenchWriting, setFrenchWriting] = useState("0");
  const [workExperience, setWorkExperience] = useState("None");
  const [experienceAndIELTS, setExperienceAndIELTS] = useState("NA");
  const [brotherSisterLiving, setBrotherSisterLiving] = useState({
    label: "No",
    value: "no",
  });
  const [strongFrench, setStrongFrench] = useState({
    label: "No",
    value: "no",
  });
  const [postSecondaryEducation, setPostSecondaryEducation] = useState({
    label: "No",
    value: "no",
  });
  const [arrangedEmployment, setArrangedEmployment] = useState({
    label: "No",
    value: "no",
  });
  const [PNPNomination, setPNPNomination] = useState({
    label: "No",
    value: "no",
  });
  const [yearEducation, setYearEducation] = useState("NA");
  const [spouseAccompanying, setSpouseAccompanying] = useState({
    label: "No",
    value: "no",
  });
  const [spouseEducation, setSpouseEducation] = useState("NA");
  const [spouseLanguageSkills, setSpouseLanguageSkills] = useState("None");
  const [spouseWorkExperience, setSpouseWorkExperience] = useState("None");
  const [totalScore, setTotalScore] = useState();

  const calculateScore = () => {
    let score = 0;

    // Calculate age points
    if (spouseAccompanying.value === "yes") {
      if (age === "17") score += 0;
      else if (age === "18") score += 90;
      else if (age === "19") score += 95;
      else if (age >= 20 && age <= 29) score += 100;
      else if (age === "30") score += 95;
      else if (age === "31") score += 90;
      else if (age === "32") score += 85;
      else if (age === "33") score += 80;
      else if (age === "34") score += 75;
      else if (age === "35") score += 70;
      else if (age === "36") score += 65;
      else if (age === "37") score += 60;
      else if (age === "38") score += 55;
      else if (age === "39") score += 50;
      else if (age === "40") score += 45;
      else if (age === "41") score += 35;
      else if (age === "42") score += 25;
      else if (age === "43") score += 15;
      else if (age === "44") score += 5;
      else if (age === "45") score += 0;
      else score += 0;
    } else {
      if (age === "17") score += 0;
      else if (age === "18") score += 99;
      else if (age === "19") score += 105;
      else if (age >= 20 && age <= 29) score += 110;
      else if (age === "30") score += 105;
      else if (age === "31") score += 99;
      else if (age === "32") score += 94;
      else if (age === "33") score += 88;
      else if (age === "34") score += 83;
      else if (age === "35") score += 77;
      else if (age === "36") score += 72;
      else if (age === "37") score += 66;
      else if (age === "38") score += 61;
      else if (age === "39") score += 55;
      else if (age === "40") score += 50;
      else if (age === "41") score += 39;
      else if (age === "42") score += 28;
      else if (age === "43") score += 17;
      else if (age === "44") score += 6;
      else if (age === "45") score += 0;
      else score += 0;
    }

    // Education level points
    if (spouseAccompanying.value === "yes") {
      switch (levelEducation) {
        case "High School":
          score += 0;
          break;
        case "Secondary Diploma":
          score += 28;
          break;
        case "ITI":
          score += 84;
          break;
        case "Diploma":
          score += 91;
          break;
        case "Bachelor's":
          score += 112;
          break;
        case "Double Bachelor's":
          score += 119;
          break;
        case "Master":
          score += 126;
          break;
        case "Phd":
          score += 140;
          break;
        default:
          score += 0;
      }
    } else {
      switch (levelEducation) {
        case "High School":
          score += 0;
          break;
        case "Secondary Diploma":
          score += 30;
          break;
        case "ITI":
          score += 90;
          break;
        case "Diploma":
          score += 98;
          break;
        case "Bachelor's":
          score += 120;
          break;
        case "Double Bachelor's":
          score += 128;
          break;
        case "Master":
          score += 135;
          break;
        case "Phd":
          score += 150;
          break;
        default:
          score += 0;
      }
    }

    // First Official Language (English) points
    if (spouseAccompanying.value === "yes") {
      switch (englishListening) {
        case "5":
          score += 0;
          break;
        case "5.5":
          score += 8;
          break;
        case "6":
          score += 16;
          break;
        case "6.5":
          score += 16;
          break;
        case "7":
          score += 16;
          break;
        case "7.5":
          score += 22;
          break;
        case "8":
          score += 29;
          break;
        case "8.5":
          score += 32;
          break;
        case "9":
          score += 32;
          break;
        default:
          score += 0;
      }
      switch (englishSpeaking) {
        case "5":
          score += 0;
          break;
        case "5.5":
          score += 8;
          break;
        case "6":
          score += 16;
          break;
        case "6.5":
          score += 22;
          break;
        case "7":
          score += 29;
          break;
        case "7.5":
          score += 32;
          break;
        case "8":
          score += 32;
          break;
        case "8.5":
          score += 32;
          break;
        case "9":
          score += 32;
          break;
        default:
          score += 0;
      }
      switch (englishReading) {
        case "5":
          score += 8;
          break;
        case "5.5":
          score += 8;
          break;
        case "6":
          score += 16;
          break;
        case "6.5":
          score += 22;
          break;
        case "7":
          score += 29;
          break;
        case "7.5":
          score += 32;
          break;
        case "8":
          score += 32;
          break;
        case "8.5":
          score += 32;
          break;
        case "9":
          score += 32;
          break;
        default:
          score += 0;
      }
      switch (englishWriting) {
        case "5":
          score += 0;
          break;
        case "5.5":
          score += 8;
          break;
        case "6":
          score += 16;
          break;
        case "6.5":
          score += 22;
          break;
        case "7":
          score += 29;
          break;
        case "7.5":
          score += 32;
          break;
        case "8":
          score += 32;
          break;
        case "8.5":
          score += 32;
          break;
        case "9":
          score += 32;
          break;
        default:
          score += 0;
      }
    } else {
      switch (englishListening) {
        case "5":
          score += 0;
          break;
        case "5.5":
          score += 9;
          break;
        case "6":
          score += 17;
          break;
        case "6.5":
          score += 17;
          break;
        case "7":
          score += 17;
          break;
        case "7.5":
          score += 23;
          break;
        case "8":
          score += 31;
          break;
        case "8.5":
          score += 34;
          break;
        case "9":
          score += 34;
          break;
        default:
          score += 0;
      }
      switch (englishSpeaking) {
        case "5":
          score += 0;
          break;
        case "5.5":
          score += 9;
          break;
        case "6":
          score += 17;
          break;
        case "6.5":
          score += 23;
          break;
        case "7":
          score += 31;
          break;
        case "7.5":
          score += 34;
          break;
        case "8":
          score += 34;
          break;
        case "8.5":
          score += 34;
          break;
        case "9":
          score += 34;
          break;
        default:
          score += 0;
      }
      switch (englishReading) {
        case "5":
          score += 9;
          break;
        case "5.5":
          score += 9;
          break;
        case "6":
          score += 17;
          break;
        case "6.5":
          score += 23;
          break;
        case "7":
          score += 31;
          break;
        case "7.5":
          score += 34;
          break;
        case "8":
          score += 34;
          break;
        case "8.5":
          score += 34;
          break;
        case "9":
          score += 34;
          break;
        default:
          score += 0;
      }
      switch (englishWriting) {
        case "5":
          score += 0;
          break;
        case "5.5":
          score += 9;
          break;
        case "6":
          score += 17;
          break;
        case "6.5":
          score += 23;
          break;
        case "7":
          score += 31;
          break;
        case "7.5":
          score += 34;
          break;
        case "8":
          score += 34;
          break;
        case "8.5":
          score += 34;
          break;
        case "9":
          score += 34;
          break;
        default:
          score += 0;
      }
    }

    // Second Official Language (French) points
    switch (frenchListening) {
      case "181":
        score += 1;
        break;
      case "217":
        score += 1;
        break;
      case "249":
        score += 3;
        break;
      case "280":
        score += 3;
        break;
      case "298":
        score += 6;
        break;
      case "316":
        score += 6;
        break;
      default:
        score += 0;
    }
    switch (frenchSpeaking) {
      case "226":
        score += 1;
        break;
      case "271":
        score += 1;
        break;
      case "310":
        score += 3;
        break;
      case "349":
        score += 3;
        break;
      case "371":
        score += 6;
        break;
      case "393":
        score += 6;
        break;
      default:
        score += 0;
    }
    switch (frenchReading) {
      case "151":
        score += 1;
        break;
      case "181":
        score += 1;
        break;
      case "207":
        score += 3;
        break;
      case "233":
        score += 3;
        break;
      case "248":
        score += 6;
        break;
      case "263":
        score += 6;
        break;
      default:
        score += 0;
    }
    switch (frenchWriting) {
      case "226":
        score += 1;
        break;
      case "271":
        score += 1;
        break;
      case "310":
        score += 3;
        break;
      case "349":
        score += 3;
        break;
      case "371":
        score += 6;
        break;
      case "393":
        score += 6;
        break;
      default:
        score += 0;
    }

    // Canadian Work Experience points
    if (spouseAccompanying.value === "yes") {
      switch (workExperience) {
        case "1 year":
          score += 35;
          break;
        case "2 year":
          score += 46;
          break;
        case "3 year":
          score += 56;
          break;
        case "4 year":
          score += 63;
          break;
        case "5 year":
          score += 70;
          break;
        default:
          score += 0;
      }
    } else {
      switch (workExperience) {
        case "1 year":
          score += 40;
          break;
        case "2 year":
          score += 53;
          break;
        case "3 year":
          score += 64;
          break;
        case "4 year":
          score += 72;
          break;
        case "5 year":
          score += 80;
          break;
        default:
          score += 0;
      }
    }

    // Additional factors
    if (brotherSisterLiving.value === "yes") score += 15;
    if (strongFrench.value === "yes") score += 30;
    if (postSecondaryEducation.value === "yes") {
      switch (yearEducation) {
        case "1 or 2 years":
          score += 15;
          break;
        case "3 or above years":
          score += 30;
          break;
        default:
          break;
      }
    }
    if (arrangedEmployment.value === "yes") score += 200;
    if (PNPNomination.value === "yes") score += 600;

    //spouse detail
    if (spouseAccompanying.value === "yes") {
      switch (spouseEducation) {
        case "High School":
          score += 0;
          break;
        case "Secondary Diploma":
          score += 2;
          break;
        case "ITI":
          score += 6;
          break;
        case "Diploma":
          score += 7;
          break;
        case "Bachelor's":
          score += 8;
          break;
        case "Double Bachelor's":
          score += 9;
          break;
        case "Master":
          score += 10;
          break;
        case "Phd":
          score += 10;
          break;
        default:
          score += 0;
      }

      switch (spouseLanguageSkills) {
        case "Basic":
          score += 0;
          break;
        case "Competent":
          score += 4;
          break;
        case "Proficient":
          score += 12;
          break;
        case "Superior":
          score += 12;
          break;
        default:
          score += 0;
      }

      switch (spouseWorkExperience) {
        case "1 year":
          score += 5;
          break;
        case "2 year":
          score += 7;
          break;
        case "3 year":
          score += 8;
          break;
        case "4 year":
          score += 9;
          break;
        case "5 year":
          score += 10;
          break;
        default:
          score += 0;
      }
    }

    if (experienceAndIELTS === "1+ years") {
      if (
        englishListening >= "7" &&
        englishSpeaking >= "7" &&
        englishReading >= "7" &&
        englishWriting >= "7"
      ) {
        score += 25;
      } else if (
        (englishListening === "9" ||
          englishSpeaking === "9" ||
          englishReading === "9" ||
          englishWriting === "9") &&
        (englishListening >= "7.5" ||
          englishSpeaking >= "7.5" ||
          englishReading >= "7.5" ||
          englishWriting >= "7.5")
      ) {
        score += 13;
      } else {
        score += 13;
      }
    } else if (experienceAndIELTS === "3+ years") {
      if (
        englishListening >= "7" &&
        englishSpeaking >= "7" &&
        englishReading >= "7" &&
        englishWriting >= "7"
      ) {
        score += 50;
      } else if (
        (englishListening === "9" ||
          englishSpeaking === "9" ||
          englishReading === "9" ||
          englishWriting === "9") &&
        (englishListening >= "7.5" ||
          englishSpeaking >= "7.5" ||
          englishReading >= "7.5" ||
          englishWriting >= "7.5")
      ) {
        score += 25;
      } else {
        score += 25;
      }
    } else {
      score += 0;
    }

    if (["Bachelor's", "Diploma"].includes(levelEducation)) {
      if (
        englishListening >= "7" &&
        englishSpeaking >= "7" &&
        englishReading >= "7" &&
        englishWriting >= "7"
      ) {
        score += 25;
      } else if (
        (englishListening === "9" ||
          englishSpeaking === "9" ||
          englishReading === "9" ||
          englishWriting === "9") &&
        (englishListening >= "7.5" ||
          englishSpeaking >= "7.5" ||
          englishReading >= "7.5" ||
          englishWriting >= "7.5")
      ) {
        score += 13;
      } else {
        score += 13;
      }
    } else if (["Double Bachelor's", "Phd"].includes(levelEducation)) {
      if (
        englishListening >= "7" &&
        englishSpeaking >= "7" &&
        englishReading >= "7" &&
        englishWriting >= "7"
      ) {
        score += 50;
      } else if (
        (englishListening === "9" ||
          englishSpeaking === "9" ||
          englishReading === "9" ||
          englishWriting === "9") &&
        (englishListening >= "7.5" ||
          englishSpeaking >= "7.5" ||
          englishReading >= "7.5" ||
          englishWriting >= "7.5")
      ) {
        score += 25;
      } else {
        score += 25;
      }
    } else {
      score += 0;
    }

    score = Math.min(score, 1200);
    return score;
  };

  useEffect(() => {
    const updatedScore = calculateScore();
    setTotalScore(updatedScore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    maritalStatus,
    age,
    levelEducation,
    englishListening,
    englishSpeaking,
    englishReading,
    englishWriting,
    frenchListening,
    frenchReading,
    frenchSpeaking,
    frenchWriting,
    workExperience,
    experienceAndIELTS,
    brotherSisterLiving,
    strongFrench,
    postSecondaryEducation,
    arrangedEmployment,
    PNPNomination,
    yearEducation,
    spouseAccompanying,
    spouseEducation,
    spouseLanguageSkills,
    spouseWorkExperience,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <SelectBoxComponent
                label={"Marital Status"}
                value={maritalStatus}
                setValue={setMaritalStatus}
                data={[
                  "Single",
                  "Married",
                  "Common Law",
                  "Divorced",
                  "Widowed",
                  "Annual Marriage",
                ]}
                placeholder="Select marital status"
              />
              {(maritalStatus === "Married" ||
                maritalStatus === "Common Law") && (
                <RadioButtonComponent
                  label={
                    "Your spouse or common-law partner Accompanying you to Canada?"
                  }
                  data={BOOLEANS}
                  defaultValue={"no"}
                  valueSetter={setSpouseAccompanying}
                  row={true}
                />
              )}
              {spouseAccompanying.value === "yes" && (
                <>
                  <SelectBoxComponent
                    label={"Spouse’s level of education"}
                    value={spouseEducation}
                    setValue={setSpouseEducation}
                    data={[
                      "NA",
                      "High School",
                      "Secondary Diploma",
                      "ITI",
                      "Diploma",
                      "Bachelor's",
                      "Double Bachelor's",
                      "Master",
                      "Phd",
                    ]}
                    placeholder="Select Spouse’s level of education"
                  />
                  <SelectBoxComponent
                    label={"Spouse's English/French Language Skills"}
                    value={spouseLanguageSkills}
                    setValue={setSpouseLanguageSkills}
                    data={[
                      "None",
                      "Basic",
                      "Competent",
                      "Proficient",
                      "Superior",
                    ]}
                    placeholder="Select Spouse’s Language Skills"
                  />
                  <SelectBoxComponent
                    label={"Spouse's Canadian Work Experience"}
                    value={spouseWorkExperience}
                    setValue={setSpouseWorkExperience}
                    data={[
                      "None",
                      "Less than a year",
                      "1 year",
                      "2 year",
                      "3 year",
                      "4 year",
                      "5 year",
                    ]}
                    placeholder="Select Spouse's Canadian work experience"
                  />
                </>
              )}
              <InputComponent
                isInput={true}
                label="Your Age"
                value={age}
                setState={setAge}
                min={17}
                max={45}
              />
              <SelectBoxComponent
                label={"Level of Education"}
                value={levelEducation}
                setValue={setLevelEducation}
                data={[
                  "NA",
                  "High School",
                  "Secondary Diploma",
                  "ITI",
                  "Diploma",
                  "Bachelor's",
                  "Double Bachelor's",
                  "Master",
                  "Phd",
                ]}
                placeholder="Select level of education"
              />
              <Typography className="common-sub-heading-calc">
                First Official Language (English)
              </Typography>
              <SelectBoxComponent
                label={"Listening"}
                value={englishListening}
                setValue={setEnglishListening}
                data={["5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9"]}
                placeholder="Select listening score"
              />
              <SelectBoxComponent
                label={"Speaking"}
                value={englishSpeaking}
                setValue={setEnglishSpeaking}
                data={["5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9"]}
                placeholder="Select speaking score"
              />
              <SelectBoxComponent
                label={"Reading"}
                value={englishReading}
                setValue={setEnglishReading}
                data={["5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9"]}
                placeholder="Select reading score"
              />
              <SelectBoxComponent
                label={"Writing"}
                value={englishWriting}
                setValue={setEnglishWriting}
                data={["5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9"]}
                placeholder="Select writing score"
              />
              <Typography className="common-sub-heading-calc">
                Second Official Language (French)
              </Typography>
              <SelectBoxComponent
                label={"Listening"}
                value={frenchListening}
                setValue={setFrenchListening}
                data={["0", "181", "217", "249", "280", "298", "316"]}
                placeholder="Select listening score"
              />
              <SelectBoxComponent
                label={"Speaking"}
                value={frenchSpeaking}
                setValue={setFrenchSpeaking}
                data={["0", "226", "271", "310", "349", "371", "393"]}
                placeholder="Select speaking score"
              />
              <SelectBoxComponent
                label={"Reading"}
                value={frenchReading}
                setValue={setFrenchReading}
                data={["0", "151", "181", "207", "233", "248", "263"]}
                placeholder="Select reading score"
              />
              <SelectBoxComponent
                label={"Writing"}
                value={frenchWriting}
                setValue={setFrenchWriting}
                data={["0", "226", "271", "310", "349", "371", "393"]}
                placeholder="Select writing score"
              />
              <SelectBoxComponent
                label={"Canadian Work Experience"}
                value={workExperience}
                setValue={setWorkExperience}
                data={[
                  "None",
                  "Less than a year",
                  "1 year",
                  "2 year",
                  "3 year",
                  "4 year",
                  "5 year",
                ]}
                placeholder="Select canadian work experience"
              />
              <SelectBoxComponent
                label={"Combination of Experience and IELTS"}
                value={experienceAndIELTS}
                setValue={setExperienceAndIELTS}
                data={["NA", "1+ years", "3+ years"]}
                placeholder="How Many Years Experience"
              />
              <RadioButtonComponent
                label={"Brother or Sister living in Canada"}
                data={BOOLEANS}
                defaultValue={"no"}
                valueSetter={setBrotherSisterLiving}
                row={true}
              />
              <RadioButtonComponent
                label={"Strong French"}
                data={BOOLEANS}
                defaultValue={"no"}
                valueSetter={setStrongFrench}
                row={true}
              />
              <RadioButtonComponent
                label={"Post Secondary Education in Canada"}
                data={BOOLEANS}
                defaultValue={"no"}
                valueSetter={setPostSecondaryEducation}
                row={true}
              />
              {postSecondaryEducation.value === "yes" && (
                <SelectBoxComponent
                  label={"How many years"}
                  value={yearEducation}
                  setValue={setYearEducation}
                  data={["NA", "1 or 2 years", "3 or above years"]}
                  placeholder="How Many Years"
                />
              )}
              <RadioButtonComponent
                label={"Arranged Employement"}
                data={BOOLEANS}
                defaultValue={"no"}
                valueSetter={setArrangedEmployment}
                row={true}
              />
              <RadioButtonComponent
                label={"PNP Nomination"}
                data={BOOLEANS}
                defaultValue={"no"}
                valueSetter={setPNPNomination}
                row={true}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                label="Your Final Total Score"
                isInput={false}
                value={totalScore}
                date={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default CRSCalc;
