import React from "react";
import { Grid, Typography } from "@mui/material";
import "./SimilarCalc.scss";
import { useNavigate } from "react-router";
import { COMPONENTDATA } from "../../../Utils/Constant";

const SimilarCalc = () => {
  const activeSlug = window.location.pathname.replace(/\//g, '');
  const navigate = useNavigate();

  const handleClickSimilar = (slug) => {
    navigate(`/${slug}`);
    window.scrollTo(0, 0);
  };

  return (
    <Grid container sx={{ mb: 2, mt: 4 }}>
      <Grid item xs={12} className="calculation-part related-calc-div">
        <Typography className="related-heading">Related</Typography>
        <Grid
          container
          sx={{
            justifyContent: "space-between",
            mt: 4,
            maxWidth: { xs: "100%", sm: "70%" },
            marginLeft: { xs: "auto", sm: "12px" },
          }}
        >
          {COMPONENTDATA?.filter((x) => x.slug === activeSlug)[0]?.similar?.map(
            (item, index) => (
              <Grid
                item
                xs={12}
                md={6}
                className="similar-calc-div"
                onClick={() => handleClickSimilar(item?.slug)}
                key={index}
              >
                <Typography className="similar">{item?.title}</Typography>
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SimilarCalc;
