import React, { useEffect, useState } from "react";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import ComponentHeader from "../Header/ComponentHeader";
import { Box, Grid, Typography } from "@mui/material";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import InputComponent from "../../Common/InputComponent/InputComponent";

const dataList = [
  "2 Administration-Bond",
  "3 Adoption-Deed",
  "4 Affidavit",
  "5(a) Agreement (if relating to Sale of Bill of Exchange)",
  "5((b) to (g)) Agreement (relating to purchase of Securities, Cotton, Oil etc)",
  "5(gb) Agreement (Relating to Advertisement)",
  "5(h) Agreement (if not otherwise provided for)",
  "5(g(a)) Agreement-Construction Developments/Transfer Immovable Property",
  "6(a) Deposit of Title deeds",
  "6(b) Pledge, Loan or Hypo.-Movable Property",
  "7 Alteration Articles Of Association Of Company (Remaining Amount paid under Art. 12)",
  "8(a) Alteration Memorandum Of Association Of Company (Companies Under 26 of the Companies Act)",
  "9 Appointment In Execution Of Power",
  "10 Appraisement Or Valuation",
  "11 Apprenticeship Deed",
  "12 Articles Of Association Of Company",
  "13 Award",
  "14 Bond",
  "15 Bottomry Bond",
  "16 Cancellation",
  "17 Certificate of Sale",
  "18 Certificate Or Other Document (Relating to Share)",
  "19 Composition Deed",
  "20(c) Conveyance-Property of N.T.C, G.H.B, G.O.F.A",
  "20(aa) Conveyance (Movable Property)",
  "20(a) Conveyance (Immovable Property)",
  "20(b) Conveyance-Property of Co-Op Soc.",
  "21 Copy Or Extract",
  "22 Counterpart Or Duplicate",
  "23 Customs Or Excise Bond (Movable Property)",
  "24 Delivery Order In Respect of Goods",
  "25 Divorce",
  "26 Exchange of Property",
  "27(a) Further Charge (Original Mortgage)",
  "27((b)(ii)(b)) Further Charge without possession (Exceed 10 crore)",
  "27((b)(i)) Further Charge with possession",
  "27((b)(ii)(a)) Further Charge without possession (up to 10 crore)",
  "28 Gift",
  "29 Indemnity Bond",
  "30(a) Lease -(ImmovableProperty)-Rent",
  "30(b) Lease -(ImmovableProperty)-Premium or Money Adv.",
  "30(c) Lease -(ImmovableProperty)-Rent+Premium or Money Adv.",
  "30(A) LEAVE & LICENSE AGREEMENT (Other than Residential property)",
  "30(d) LEASE - Relating to movable Property",
  "31 Letter of Allotment (Shares)",
  "32 Letter Of Guarantee",
  "33 Letter Of Licence",
  "34 Marriage Registration",
  "35(a) Memorandum of Association (for companies of under sec. 26 of the Companies Act)",
  "35(b) Memorandum of Association",
  "36(b) Mortgage deed without possession",
  "36(c) Collateral or auxiliary or additional or substituted security",
  "36(a) Mortgage Deed-With Possession",
  "37 Mortgage Of A Crop",
  "38 Notarial Act",
  "39(e) Note or Memorandum (any other goods)",
  "39(g) Note (Related to Government Sec.)",
  "39(a) to (d), (f) Note or Memorandum (Related purchase or sale of Cotton, Bullion, Oil seeds, Yarn etc)",
  "40(b) Note Or Entry(for Pledge, Loan or Hypo.-Movable Property)",
  "40(a) Note Or Entry(for Deposit of Title deeds)",
  "41 Note Of Protest By Master Of Ship",
  "42(a) Order For Payment Of Money (Payable After 1 year)",
  "42(b) Order For The Payment Of Money (Payable Within 1 yr)",
  "43 Partitioned",
  "44((1)(b)) Partnership (by immovable Property)",
  "44(3a) Dissolution (immovable property taken by Partners)",
  "44((1)(c)) Partnership (by Cash & immovable Property)",
  "44((1)(a)) Partnership-(by Cash)",
  "44((2)(a)) Alteration of Constitution of Partnership-Remaining Amt.",
  "44((2)(b)) Alteration of Constitution of Partnership - (other than Clause a)",
  "44((3)(b)) Dissolution (In any other case)",
  "45(h) Power Of Attorney (In Any Other Case)",
  "45(a to e) Power of Attorney",
  "45(g) Power Of Attorney (for Development of any immovable property)",
  "45(f) Power Of Attorney (Sale Of Immovable Property for consideration)",
  "45(f(ii)(a)) Power of Attorney (in case of blood relation)",
  "45(f(ii)(b)) Power of Attorney (other than blood relation)",
  "46 Protest Of Bill or Note",
  "47 Protest By The Master Of A Ship",
  "48 Reconveyance Of Mortgaged Property",
  "48(A) Record Of Transaction",
  "49(a) Release (ancestral property in favor of legal heirs)",
  "49(b) Release (without ancestral property in favor of legal heirs)",
  "50 Respondentia Bond",
  "51 Security Bond-Mortgage",
  "52(A(i)) Settlement (Religious/Charitable)",
  "52(B(i)) Revocation Of Settlement (Religious/Charitable)",
  "52(A(ii)) Settlement - Other Case",
  "52(B(ii)) Settlement - Revocation Other Case",
  "53 Share Warrants ",
  "55 Surrender Of Lease ",
  "56(a) Transfer of Debenture ",
  "56(b) Transfer-Interest Secured by Bond",
  "56(c) Transform-Under the Administrators General Act 1963, Sec.22",
  "56(d) Transfer-Trustees or Beneficiary Without-Consideration",
  "57 Transfer Of Lease ",
  "58(a) Trust-Declaration ",
  "58(b) Trust-Revocation",
];

function StampDutyCalc() {
  const [selectArticle, setSelectArticle] = useState("2 Administration-Bond");
  const [result, setResult] = useState(0);
  const [showInputField, setShowInputField] = useState(false);
  const [totalAmount, setTotalAmount] = useState(10000);
  const [duration, setDuration] = useState("select duration");
  const [advanceAmount, setAdvanceAmount] = useState(10000);
  const [considerationValue, setConsiderationValue] = useState(15000);
  const [marketValue, setMarketValue] = useState(10000);
  const [calculateOnMarketValue, setCalculateOnMarketValue] = useState(12000);

  function calculateStampDuty(totalAmount, percentage) {
    if (totalAmount < 0) return 0;
    let result = (totalAmount * percentage) / 100;
    return result % 1 === 0 ? result : Math.ceil(result);
  }

  function calculateMV(calculateOnMarketValue) {
    let result = (calculateOnMarketValue * 4.9) / 100;
    return result;
  }

  function calculateStampDutyOnMarketValueAndTA(totalAmount, marketValue) {
    const TA = Math.min(calculateStampDuty(totalAmount, 1), 10000);
    const MA = calculateMV(marketValue);
    let result = TA + MA;
    return result;
  }

  function stampDutyOnMarketValueBy2550per(totalAmount) {
    if (totalAmount <= 100000000) {
      return calculateStampDuty(totalAmount, 0.25);
    } else {
      return calculateStampDuty(totalAmount, 0.5);
    }
  }

  function calculate01perStampDuty(totalAmount) {
    return calculateStampDuty(totalAmount, 0.01);
  }

  function calculate1perStampDuty(totalAmount) {
    return calculateStampDuty(totalAmount, 1);
  }

  function calculate2perStampDuty(totalAmount) {
    return calculateStampDuty(totalAmount, 2);
  }

  function calculate50perStampDuty(totalAmount) {
    let result = calculateStampDuty(totalAmount, 0.5);
    return Math.min(result, 500000);
  }

  function calculate10perStampDuty(totalAmount) {
    return calculateStampDuty(totalAmount, 0.1);
  }

  function calculate70perStampDuty(totalAmount) {
    return calculateStampDuty(totalAmount, 0.7);
  }

  function calculateStampDutyOnConBase2550(totalAmount) {
    if (totalAmount <= 100000000) {
      return calculateStampDuty(totalAmount, 0.25);
    } else {
      return Math.min(calculateStampDuty(totalAmount, 0.5), 800000);
    }
  }

  function calculateStampDutyOnConBase3570(totalAmount) {
    if (totalAmount <= 100000000) {
      return (totalAmount * 0.35) / 100;
    } else {
      return Math.min((totalAmount * 0.7) / 100, 1120000);
    }
  }

  function calculateStampDutyByDuration(totalAmount, duration, advanceAmount) {
    const advancePremium = (advanceAmount * 4.9) / 100;
    const averageYearlyRent = totalAmount;

    const durationCalculations = {
      "Up to 1 year": () =>
        calculateStampDuty(totalAmount, 1.4) + advancePremium,
      "above 1 Years - up to 3 Years": () =>
        calculateStampDuty(averageYearlyRent, 2.8) + advancePremium,
      "above 3years - up to 10 Years": () =>
        calculateStampDuty(averageYearlyRent, 4.9) + advancePremium,
      "above 10 years - up to 30 Years": () =>
        calculateStampDuty(averageYearlyRent, 4.9) + advancePremium,
      "above 30 years - up to 98 Years": () =>
        calculateStampDuty(averageYearlyRent, 4.9) + advancePremium,
      "98 Years": () =>
        calculateStampDuty(averageYearlyRent, 4.9) + advancePremium,
      Infinity: () => calculateStampDuty(averageYearlyRent, 4.9),
      "Without time limit": () =>
        calculateStampDuty(averageYearlyRent, 4.9) + advancePremium,
    };

    return durationCalculations[duration]
      ? durationCalculations[duration]()
      : 0;
  }

  function calculateAdvancePremium(advanceAmount) {
    return (advanceAmount * 4.9) / 100;
  }

  function calculateStampDutyOnRent(totalAmount, duration) {
    const averageYearlyRent = totalAmount;

    const durationCalculations = {
      "Up to 1 year": () => calculateStampDuty(totalAmount, 1.4),
      "above 1 Years - up to 3 Years": () =>
        calculateStampDuty(averageYearlyRent, 2.8),
      "above 3years - up to 10 Years": () =>
        calculateStampDuty(averageYearlyRent, 4.9),
      "above 10 years - up to 30 Years": () =>
        calculateStampDuty(averageYearlyRent, 4.9),
      "above 30 years - up to 98 Years": () =>
        calculateStampDuty(averageYearlyRent, 4.9),
      "98 Years": () => calculateStampDuty(averageYearlyRent, 4.9),
      Infinity: () => calculateStampDuty(averageYearlyRent, 4.9),
      "Without time limit": () => calculateStampDuty(averageYearlyRent, 4.9),
    };

    return durationCalculations[duration]
      ? durationCalculations[duration]()
      : 0;
  }

  function calculateMarketValueOrConsiderationValue(
    marketValue,
    considerationValue,
    percentage
  ) {
    const maxValue = Math.max(marketValue, considerationValue);
    return (maxValue * percentage) / 100;
  }

  function calculateOnOnlyMarketValue49(calculateOnMarketValue) {
    return (calculateOnMarketValue * 4.9) / 100;
  }
  function calculateOnOnlyMarketValue35(calculateOnMarketValue) {
    return (calculateOnMarketValue * 3.5) / 100;
  }

  function calculateMarketValueOrConsiderationValueOn49per(
    marketValue,
    considerationValue
  ) {
    return calculateMarketValueOrConsiderationValue(
      marketValue,
      considerationValue,
      4.9
    );
  }
  function calculateMarketValueOrConsiderationValueOn35per(
    marketValue,
    considerationValue
  ) {
    return calculateMarketValueOrConsiderationValue(
      marketValue,
      considerationValue,
      3.5
    );
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resultMap = {
    "2 Administration-Bond": 300,
    "3 Adoption-Deed": 200,
    "4 Affidavit": 50,
    "5(a) Agreement (if relating to Sale of Bill of Exchange)": 0,
    "5((b) to (g)) Agreement (relating to purchase of Securities, Cotton, Oil etc)":
      calculate01perStampDuty,
    "5(gb) Agreement (Relating to Advertisement)": calculate50perStampDuty,
    "5(h) Agreement (if not otherwise provided for)": 300,
    "5(g(a)) Agreement-Construction Developments/Transfer Immovable Property":
      calculateMarketValueOrConsiderationValueOn35per,
    "6(a) Deposit of Title deeds": calculateStampDutyOnConBase2550,
    "6(b) Pledge, Loan or Hypo.-Movable Property":
      calculateStampDutyOnConBase2550,
    "7 Alteration Articles Of Association Of Company (Remaining Amount paid under Art. 12)":
      calculate50perStampDuty,
    "8(a) Alteration Memorandum Of Association Of Company (Companies Under 26 of the Companies Act)": 0,
    "9 Appointment In Execution Of Power": 300,
    "10 Appraisement Or Valuation": 300,
    "11 Apprenticeship Deed": 300,
    "12 Articles Of Association Of Company": calculate50perStampDuty,
    "13 Award": 300,
    "14 Bond": calculateStampDutyOnConBase2550,
    "15 Bottomry Bond": calculateStampDutyOnConBase2550,
    "16 Cancellation": 300,
    "17 Certificate of Sale": calculateMarketValueOrConsiderationValueOn49per,
    "18 Certificate Or Other Document (Relating to Share)":
      calculate10perStampDuty,
    "19 Composition Deed": 300,
    "20(c) Conveyance-Property of N.T.C, G.H.B, G.O.F.A":
      calculateMarketValueOrConsiderationValueOn49per,
    // "20(aa) Conveyance (Movable Property)",//---
    "20(a) Conveyance (Immovable Property)":
      calculateMarketValueOrConsiderationValueOn49per,
    "20(b) Conveyance-Property of Co-Op Soc.":
      calculateMarketValueOrConsiderationValueOn49per,
    "21 Copy Or Extract": 300,
    "22 Counterpart Or Duplicate": 300,
    "23 Customs Or Excise Bond (Movable Property)": 300,
    "24 Delivery Order In Respect of Goods": calculate10perStampDuty,
    "25 Divorce": 300,
    "26 Exchange of Property": calculateMarketValueOrConsiderationValueOn49per,
    "27(a) Further Charge (Original Mortgage)": 0,
    // "27((b)(ii)(b)) Further Charge without possession (Exceed 10 crore)",//---
    // "27((b)(i)) Further Charge with possession",//---
    // "27((b)(ii)(a)) Further Charge without possession (up to 10 crore)",//---
    "28 Gift": calculateMarketValueOrConsiderationValueOn49per,
    "29 Indemnity Bond": 300,
    "30(a) Lease -(ImmovableProperty)-Rent": calculateStampDutyOnRent,
    "30(b) Lease -(ImmovableProperty)-Premium or Money Adv.":
      calculateAdvancePremium,
    "30(c) Lease -(ImmovableProperty)-Rent+Premium or Money Adv.":
      calculateStampDutyByDuration,
    "30(A) LEAVE & LICENSE AGREEMENT (Other than Residential property)":
      calculate50perStampDuty,
    "30(d) LEASE - Relating to movable Property": calculate2perStampDuty,
    "31 Letter of Allotment (Shares)": calculate10perStampDuty,
    "32 Letter Of Guarantee": 300,
    "33 Letter Of Licence": 300,
    "34 Marriage Registration": 200,
    "35(a) Memorandum of Association (for companies of under sec. 26 of the Companies Act)": 300,
    "35(b) Memorandum of Association": calculate50perStampDuty,
    "36(b) Mortgage deed without possession": calculateStampDutyOnConBase3570,
    "36(c) Collateral or auxiliary or additional or substituted security":
      calculate70perStampDuty,
    // "36(a) Mortgage Deed-With Possession",//---
    "37 Mortgage Of A Crop": (totalAmount, duration) => {
      if (duration === "3 Months Below") {
        return (totalAmount * 0.5) / 100;
      } else if (duration === "3 Months Above") {
        return (totalAmount * 1) / 100;
      } else {
        return 0;
      }
    },
    "38 Notarial Act": 50,
    "39(e) Note or Memorandum (any other goods)": 0,
    "39(g) Note (Related to Government Sec.)": 300,
    "39(a) to (d), (f) Note or Memorandum (Related purchase or sale of Cotton, Bullion, Oil seeds, Yarn etc)":
      calculate01perStampDuty,
    "40(b) Note Or Entry(for Pledge, Loan or Hypo.-Movable Property)":
      calculateStampDutyOnConBase2550,
    "40(a) Note Or Entry(for Deposit of Title deeds)":
      calculateStampDutyOnConBase2550,
    "41 Note Of Protest By Master Of Ship": 300,
    "42(a) Order For Payment Of Money (Payable After 1 year)":
      calculate50perStampDuty,
    "42(b) Order For The Payment Of Money (Payable Within 1 yr)":
      calculateStampDutyOnConBase2550,
    "43 Partitioned": stampDutyOnMarketValueBy2550per,
    "44((1)(b)) Partnership (by immovable Property)":
      calculateOnOnlyMarketValue49,
    "44(3a) Dissolution (immovable property taken by Partners)":
      calculateOnOnlyMarketValue49,
    "44((1)(c)) Partnership (by Cash & immovable Property)":
      calculateStampDutyOnMarketValueAndTA,
    "44((1)(a)) Partnership-(by Cash)": (totalAmount) => {
      return Math.min(calculate1perStampDuty(totalAmount), 10000);
    },
    "44((2)(a)) Alteration of Constitution of Partnership-Remaining Amt.":
      calculateStampDutyOnMarketValueAndTA,
    "44((2)(b)) Alteration of Constitution of Partnership - (other than Clause a)": 200,
    "44((3)(b)) Dissolution (In any other case)": 200,
    "45(h) Power Of Attorney (In Any Other Case)": 300,
    "45(a to e) Power of Attorney": 300,
    "45(g) Power Of Attorney (for Development of any immovable property)":
      calculateOnOnlyMarketValue35,
    "45(f) Power Of Attorney (Sale Of Immovable Property for consideration)":
      calculateMarketValueOrConsiderationValueOn49per,
    "45(f(ii)(a)) Power of Attorney (in case of blood relation)": 300,
    "45(f(ii)(b)) Power of Attorney (other than blood relation)":
      calculateMarketValueOrConsiderationValueOn49per,
    "46 Protest Of Bill or Note": 300,
    "47 Protest By The Master Of A Ship": 300,
    "48 Reconveyance Of Mortgaged Property": 300,
    "48(A) Record Of Transaction": 300,
    "49(a) Release (ancestral property in favor of legal heirs)": 200,
    "49(b) Release (without ancestral property in favor of legal heirs)":
      calculateOnOnlyMarketValue49,
    "50 Respondentia Bond": calculateStampDutyOnConBase2550,
    "51 Security Bond-Mortgage": 300,
    "52(A(i)) Settlement (Religious/Charitable)":
      calculateStampDutyOnConBase3570,
    "52(B(i)) Revocation Of Settlement (Religious/Charitable)":
      calculateStampDutyOnConBase3570,
    "52(A(ii)) Settlement - Other Case":
      calculateMarketValueOrConsiderationValueOn49per,
    "52(B(ii)) Settlement - Revocation Other Case":
      calculateMarketValueOrConsiderationValueOn49per,
    "53 Share Warrants ": calculateStampDutyOnConBase2550,
    "55 Surrender Of Lease ": 300,
    "56(a) Transfer of Debenture ": 300,
    "56(b) Transfer-Interest Secured by Bond": 300,
    "56(c) Transform-Under the Administrators General Act 1963, Sec.22": 300,
    "56(d) Transfer-Trustees or Beneficiary Without-Consideration": 300,
    "57 Transfer Of Lease ": calculateMarketValueOrConsiderationValueOn49per,
    "58(a) Trust-Declaration ": 300,
    "58(b) Trust-Revocation": 300,
  };

  const handleSelect = (data) => {
    setSelectArticle(data);
    setShowInputField(
      data ===
        "5((b) to (g)) Agreement (relating to purchase of Securities, Cotton, Oil etc)" ||
        data === "5(gb) Agreement (Relating to Advertisement)" ||
        data === "12 Articles Of Association Of Company" ||
        data ===
          "7 Alteration Articles Of Association Of Company (Remaining Amount paid under Art. 12)" ||
        data === "14 Bond" ||
        data === "15 Bottomry Bond" ||
        data === "18 Certificate Or Other Document (Relating to Share)" ||
        data === "24 Delivery Order In Respect of Goods" ||
        data === "30(d) LEASE - Relating to movable Property" ||
        data ===
          "30(A) LEAVE & LICENSE AGREEMENT (Other than Residential property)" ||
        data === "31 Letter of Allotment (Shares)" ||
        data === "35(b) Memorandum of Association" ||
        data === "36(b) Mortgage deed without possession" ||
        data ===
          "36(c) Collateral or auxiliary or additional or substituted security" ||
        data ===
          "39(a) to (d), (f) Note or Memorandum (Related purchase or sale of Cotton, Bullion, Oil seeds, Yarn etc)" ||
        data === "42(a) Order For Payment Of Money (Payable After 1 year)" ||
        data === "42(b) Order For The Payment Of Money (Payable Within 1 yr)" ||
        data === "43 Partitioned" ||
        data === "44((1)(a)) Partnership-(by Cash)" ||
        data === "44((1)(c)) Partnership (by Cash & immovable Property)" ||
        data ===
          "44((2)(a)) Alteration of Constitution of Partnership-Remaining Amt." ||
        data ===
          "44(2)(a) Alteration of Constitution of Partnership- Remaining Amt. after Cl.1" ||
        data === "50 Respondentia Bond" ||
        data === "52(A(i)) Settlement (Religious/Charitable)" ||
        data === "52(B(i)) Revocation Of Settlement (Religious/Charitable)" ||
        data === "53 Share Warrants " ||
        data === "30(a) Lease -(ImmovableProperty)-Rent" ||
        data === "30(b) Lease -(ImmovableProperty)-Premium or Money Adv."
    );

    if (data === "30(b) Lease -(ImmovableProperty)-Premium or Money Adv.") {
      setTotalAmount((totalAmount) => (totalAmount * 4.9) / 100);
    }

    if (data !== "30(b) Lease -(ImmovableProperty)-Premium or Money Adv.") {
      setAdvanceAmount(0);
    }
  };

  useEffect(() => {
    const calculateResult = resultMap[selectArticle];
    if (typeof calculateResult === "function") {
      if (
        selectArticle === "17 Certificate of Sale" ||
        selectArticle === "57 Transfer Of Lease " ||
        selectArticle === "52(A(ii)) Settlement - Other Case" ||
        selectArticle === "52(B(ii)) Settlement - Revocation Other Case" ||
        selectArticle ===
          "45(f) Power Of Attorney (Sale Of Immovable Property for consideration)" ||
        selectArticle ===
          "45(f(ii)(b)) Power of Attorney (other than blood relation)" ||
        selectArticle === "20(a) Conveyance (Immovable Property)" ||
        selectArticle === "20(b) Conveyance-Property of Co-Op Soc." ||
        selectArticle ===
          "20(c) Conveyance-Property of N.T.C, G.H.B, G.O.F.A" ||
        selectArticle === "26 Exchange of Property" ||
        selectArticle === "28 Gift" ||
        selectArticle ===
          "5(g(a)) Agreement-Construction Developments/Transfer Immovable Property"
      ) {
        const calculateRes = calculateResult(marketValue, considerationValue);
        setResult(calculateRes);
      } else if (
        selectArticle === "44((1)(b)) Partnership (by immovable Property)" ||
        selectArticle ===
          "44(3a) Dissolution (immovable property taken by Partners)" ||
        selectArticle ===
          "49(b) Release (without ancestral property in favor of legal heirs)" ||
        selectArticle ===
          "45(g) Power Of Attorney (for Development of any immovable property)"
      ) {
        const calculateMarketValue = calculateResult(calculateOnMarketValue);
        setResult(calculateMarketValue);
      } else if (
        selectArticle ===
        "30(c) Lease -(ImmovableProperty)-Rent+Premium or Money Adv."
      ) {
        const calculateStampDutyByDuration = calculateResult(
          totalAmount,
          duration,
          advanceAmount
        );
        setResult(calculateStampDutyByDuration);
      } else if (
        selectArticle ===
          "44((1)(c)) Partnership (by Cash & immovable Property)" ||
        selectArticle ===
          "44((2)(a)) Alteration of Constitution of Partnership-Remaining Amt."
      ) {
        const calculateSDonTAandMV = calculateResult(
          totalAmount,
          calculateOnMarketValue
        );
        setResult(calculateSDonTAandMV);
      } else if (
        selectArticle ===
        "30(b) Lease -(ImmovableProperty)-Premium or Money Adv."
      ) {
        const calculateAdvancePremium = calculateResult(advanceAmount);
        setResult(calculateAdvancePremium);
      } else {
        const calculatedResult = calculateResult(
          totalAmount,
          duration,
          advanceAmount
        );
        setResult(calculatedResult);
      }
    } else {
      setResult(calculateResult);
    }
  }, [
    resultMap,
    totalAmount,
    selectArticle,
    showInputField,
    duration,
    advanceAmount,
    marketValue,
    considerationValue,
    calculateOnMarketValue,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid item xs={12} md={7} className="calculation-part ">
          <Grid>
            <SelectBoxComponent
              data={dataList}
              label="Select Article"
              value={selectArticle}
              setValue={handleSelect}
              placeholder="Select Your State"
            />
            {showInputField &&
              selectArticle !== "30(a) Lease -(ImmovableProperty)-Rent" &&
              selectArticle !==
                "30(b) Lease -(ImmovableProperty)-Premium or Money Adv." && (
                <InputComponent
                  label="Enter Total Amount"
                  isInput={true}
                  value={totalAmount}
                  setState={setTotalAmount}
                />
              )}
            {(selectArticle === "30(a) Lease -(ImmovableProperty)-Rent" ||
              selectArticle ===
                "30(b) Lease -(ImmovableProperty)-Premium or Money Adv." ||
              selectArticle ===
                "30(c) Lease -(ImmovableProperty)-Rent+Premium or Money Adv.") && (
              <>
                <SelectBoxComponent
                  data={[
                    "Up to 1 year",
                    "above 1 Years - up to 3 Years",
                    "above 3years - up to 10 Years",
                    "above 10 years - up to 30 Years",
                    "above 30 years - up to 98 Years",
                    "98 Years",
                    "Infinity",
                    "Without time limit",
                  ]}
                  label="Lease Period"
                  value={duration}
                  setValue={setDuration}
                  placeholder="Lease Period"
                />
                {(selectArticle ===
                  "30(b) Lease -(ImmovableProperty)-Premium or Money Adv." ||
                  selectArticle ===
                    "30(c) Lease -(ImmovableProperty)-Rent+Premium or Money Adv.") && (
                  <InputComponent
                    label="Money/Advance Premium/fine"
                    isInput={true}
                    value={advanceAmount}
                    setState={(value) => {
                      const numericValue = parseFloat(value) || 0;
                      setAdvanceAmount(numericValue);
                      setResult(numericValue * 0.049);
                    }}
                  />
                )}
                {(selectArticle === "30(a) Lease -(ImmovableProperty)-Rent" ||
                  selectArticle ===
                    "30(c) Lease -(ImmovableProperty)-Rent+Premium or Money Adv.") && (
                  <InputComponent
                    label="Enter Rent"
                    isInput={true}
                    value={totalAmount}
                    setState={setTotalAmount}
                  />
                )}
              </>
            )}
            {(selectArticle === "6(a) Deposit of Title deeds" ||
              selectArticle === "6(b) Pledge, Loan or Hypo.-Movable Property" ||
              selectArticle === "37 Mortgage Of A Crop" ||
              selectArticle ===
                "40(a) Note Or Entry(for Deposit of Title deeds)" ||
              selectArticle ===
                "40(b) Note Or Entry(for Pledge, Loan or Hypo.-Movable Property)") && (
              <>
                <InputComponent
                  label="Enter Total Amount"
                  isInput={true}
                  value={totalAmount}
                  setState={setTotalAmount}
                />
                <SelectBoxComponent
                  data={["", "3 Months Above", "3 Months Below"]}
                  label="Select Duration"
                  value={duration}
                  setValue={setDuration}
                  placeholder="Select Duration"
                />
              </>
            )}
            {(selectArticle === "17 Certificate of Sale" ||
              selectArticle ===
                "52(B(ii)) Settlement - Revocation Other Case" ||
              selectArticle === "52(A(ii)) Settlement - Other Case" ||
              selectArticle ===
                "45(f) Power Of Attorney (Sale Of Immovable Property for consideration)" ||
              selectArticle ===
                "45(f(ii)(b)) Power of Attorney (other than blood relation)" ||
              selectArticle === "20(a) Conveyance (Immovable Property)" ||
              selectArticle === "20(b) Conveyance-Property of Co-Op Soc." ||
              selectArticle ===
                "20(c) Conveyance-Property of N.T.C, G.H.B, G.O.F.A" ||
              selectArticle === "26 Exchange of Property" ||
              selectArticle === "57 Transfer Of Lease " ||
              selectArticle ===
                "5(g(a)) Agreement-Construction Developments/Transfer Immovable Property" ||
              selectArticle === "28 Gift") && (
              <>
                <InputComponent
                  label="Market Value"
                  isInput={true}
                  value={marketValue}
                  setState={setMarketValue}
                />
                <InputComponent
                  label="Consideration Value"
                  isInput={true}
                  value={considerationValue}
                  setState={setConsiderationValue}
                />
              </>
            )}
            {(selectArticle ===
              "44((1)(c)) Partnership (by Cash & immovable Property)" ||
              selectArticle ===
                "44((2)(a)) Alteration of Constitution of Partnership-Remaining Amt." ||
              selectArticle ===
                "44((1)(b)) Partnership (by immovable Property)" ||
              selectArticle ===
                "44(3a) Dissolution (immovable property taken by Partners)" ||
              selectArticle ===
                "49(b) Release (without ancestral property in favor of legal heirs)" ||
              selectArticle ===
                "45(g) Power Of Attorney (for Development of any immovable property)") && (
              <InputComponent
                label="Market Value"
                isInput={true}
                value={calculateOnMarketValue}
                setState={setCalculateOnMarketValue}
              />
            )}
          </Grid>
          <Grid className="result-label">
            <Typography>Result</Typography>
          </Grid>
          <Grid className="result-common-div">
            <InputComponent
              label="Calculate StampDuty"
              isInput={false}
              value={result}
              setState={setResult}
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default StampDutyCalc;
