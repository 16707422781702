export const NETWORK_CLASS = [
  { label: "any", value: "any" },
  { label: "A", value: "a" },
  { label: "B", value: "b" },
  { label: "C", value: "c" },
];

export const SUBNET_OPTIONS = {
  any: [
    "255.255.255.255 /32",
    "255.255.255.254 /31",
    "255.255.255.252 /30",
    "255.255.255.248 /29",
    "255.255.255.240 /28",
    "255.255.255.224 /27",
    "255.255.255.192 /26",
    "255.255.255.128 /25",
    "255.255.255.0 /24",
    "255.255.254.0 /23",
    "255.255.252.0 /22",
    "255.255.248.0 /21",
    "255.255.240.0 /20",
    "255.255.224.0 /19",
    "255.255.192.0 /18",
    "255.255.128.0 /17",
    "255.255.0.0 /16",
    "255.254.0.0 /15",
    "255.252.0.0 /14",
    "255.248.0.0 /13",
    "255.240.0.0 /12",
    "255.224.0.0 /11",
    "255.192.0.0 /10",
    "255.128.0.0 /9",
    "255.0.0.0 /8",
    "254.0.0.0 /7",
    "252.0.0.0 /6",
    "248.0.0.0 /5",
    "240.0.0.0 /4",
    "224.0.0.0 /3",
    "192.0.0.0 /2",
    "128.0.0.0 /1",
  ],
  a: [
    "255.255.255.255 /32",
    "255.255.255.254 /31",
    "255.255.255.252 /30",
    "255.255.255.248 /29",
    "255.255.255.240 /28",
    "255.255.255.224 /27",
    "255.255.255.192 /26",
    "255.255.255.128 /25",
    "255.255.255.0 /24",
    "255.255.254.0 /23",
    "255.255.252.0 /22",
    "255.255.248.0 /21",
    "255.255.240.0 /20",
    "255.255.224.0 /19",
    "255.255.192.0 /18",
    "255.255.128.0 /17",
    "255.255.0.0 /16",
    "255.254.0.0 /15",
    "255.252.0.0 /14",
    "255.248.0.0 /13",
    "255.240.0.0 /12",
    "255.224.0.0 /11",
    "255.192.0.0 /10",
    "255.128.0.0 /9",
    "255.0.0.0 /8",
  ],
  b: [
    "255.255.255.255 /32",
    "255.255.255.254 /31",
    "255.255.255.252 /30",
    "255.255.255.248 /29",
    "255.255.255.240 /28",
    "255.255.255.224 /27",
    "255.255.255.192 /26",
    "255.255.255.128 /25",
    "255.255.255.0 /24",
    "255.255.254.0 /23",
    "255.255.252.0 /22",
    "255.255.248.0 /21",
    "255.255.240.0 /20",
    "255.255.224.0 /19",
    "255.255.192.0 /18",
    "255.255.128.0 /17",
    "255.255.0.0 /16",
  ],
  c: [
    "255.255.255.255 /32",
    "255.255.255.254 /31",
    "255.255.255.252 /30",
    "255.255.255.248 /29",
    "255.255.255.240 /28",
    "255.255.255.224 /27",
    "255.255.255.192 /26",
    "255.255.255.128 /25",
    "255.255.255.0 /24",
  ],
};

export function calculateSubnet(ipAddress, subnetMask) {
  const ipParts = ipAddress.split(".").map(Number);
  const maskParts = subnetMask.split(".").map(Number);

  // Convert IP and Mask to binary
  const ipBinary = ipParts
    .map((part) => part.toString(2).padStart(8, "0"))
    .join("");
  const maskBinary = maskParts
    .map((part) => part.toString(2).padStart(8, "0"))
    .join("");

  // Calculate Network Address
  const networkBinary = ipBinary
    .split("")
    .map((bit, index) => (maskBinary[index] === "1" ? bit : "0"))
    .join("");
  const networkParts = networkBinary
    .match(/.{8}/g)
    .map((bin) => parseInt(bin, 2));
  const networkAddress = networkParts.join(".");

  // Calculate Broadcast Address
  const broadcastBinary = ipBinary
    .split("")
    .map((bit, index) => (maskBinary[index] === "0" ? "1" : bit))
    .join("");
  const broadcastParts = broadcastBinary
    .match(/.{8}/g)
    .map((bin) => parseInt(bin, 2));
  const broadcastAddress = broadcastParts.join(".");

  // Calculate Usable Host IP Range
  const firstHostBinary =
    networkBinary.substring(0, networkBinary.length - 1) + "1";
  const lastHostBinary =
    broadcastBinary.substring(0, broadcastBinary.length - 1) + "0";
  const firstHostParts = firstHostBinary
    .match(/.{8}/g)
    .map((bin) => parseInt(bin, 2));
  const lastHostParts = lastHostBinary
    .match(/.{8}/g)
    .map((bin) => parseInt(bin, 2));
  const firstHost = firstHostParts.join(".");
  const lastHost = lastHostParts.join(".");

  // Number of Hosts
  const numHosts = Math.pow(2, 32 - maskBinary.replace(/0/g, "").length);

  // Wildcard Mask
  const wildcardMask = maskParts.map((part) => 255 - part).join(".");

  // Binary Subnet Mask
  const binarySubnetMask = maskParts
    .map((part) => part.toString(2).padStart(8, "0"))
    .join(".");

  // IP Class
  const ipClass =
    maskBinary.replace(/0/g, "").length <= 7
      ? ""
      : maskBinary.replace(/0/g, "").length <= 15
      ? "A"
      : maskBinary.replace(/0/g, "").length <= 23
      ? "B"
      : maskBinary.replace(/0/g, "").length <= 32
      ? "C"
      : "D/E";

  // CIDR Notation
  const cidrNotation = maskBinary.replace(/0/g, "").length;

  // Short Notation
  const shortNotation = `${ipAddress} /${cidrNotation}`;

  // Binary ID
  const binaryID = ipParts
    .map((part) => part.toString(2).padStart(8, "0"))
    .join("");

  // Integer ID
  const integerID = ipParts.reduce(
    (acc, part, index) => acc + part * Math.pow(256, 3 - index),
    0
  );

  // Hex ID
  const hexID = `0x${integerID.toString(16).toUpperCase().padStart(8, "0")}`;

  // in-addr.arpa
  const inAddrArpa = `${ipParts.reverse().join(".")}.in-addr.arpa`;

  // IPv4 Mapped Address
  const ipv4MappedAddress = `::ffff:${ipParts
    .map((part) => part.toString(16).padStart(2, "0"))
    .join("")}`;

  // 6to4 Prefix
  const sixTo4Prefix = `2002:${ipParts
    .slice(0, 2)
    .map((part) => part.toString(16).padStart(2, "0"))
    .join("")}:${ipParts
    .slice(2)
    .map((part) => part.toString(16).padStart(2, "0"))
    .join("")}::/48`;

  // Determine IP Type
  const isPrivate =
    ipParts[0] === 10 ||
    (ipParts[0] === 172 && ipParts[1] >= 16 && ipParts[1] <= 31) ||
    (ipParts[0] === 192 && ipParts[1] === 168);
  const ipType = isPrivate ? "Private" : "Public";

  return {
    ipAddress,
    networkAddress,
    broadcastAddress,
    firstHost,
    lastHost,
    numHosts: numHosts, // Including network and broadcast addresses
    usableHosts: numHosts,
    subnetMask: subnetMask,
    wildcardMask,
    binarySubnetMask,
    ipClass,
    cidrNotation: `/${cidrNotation}`,
    shortNotation,
    binaryID,
    integerID,
    hexID,
    inAddrArpa,
    ipv4MappedAddress,
    sixTo4Prefix,
    ipType,
  };
}

export const PREFIX_LENGTH = Array.from({ length: 128 }, (_, i) => `/${i + 1}`);
