import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import PieChartComponent from "../../Common/PieChart/PieChart";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import TabbingComponent from "../../Common/TabbingRadio/TabbingComponent";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function GstCalc() {
  const [totalAmount, setTotalAmount] = useState(1000);
  const [taxSlab, setTaxSlab] = useState(18);
  const [totalGst, setTotalGst] = useState(0);
  const [postGstAmount, setPostGstAmount] = useState(0);
  const [type, setType] = useState("GST Exclusive");


  useEffect(() => {
    let gstAmount;
    if (totalAmount > 0) {
      if (type === "GST Exclusive") {
        gstAmount = (totalAmount * taxSlab) / 100;
        setPostGstAmount((+totalAmount + +gstAmount).toFixed(2));
        setTotalGst(gstAmount.toFixed(2));
      } else if (type === "GST Inclusive") {
        gstAmount = totalAmount - [totalAmount * (100 / (100 + taxSlab))];
        setPostGstAmount((totalAmount - gstAmount).toFixed(2));
        setTotalGst(gstAmount.toFixed(2));
      }
    }
  }, [totalAmount, taxSlab, type]);
  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid sx={{ margin: "10px 4px" }}>
          <TabbingComponent
            data={["GST Exclusive", "GST Inclusive"]}
            value={type}
            setValue={setType}
          />
        </Grid>
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <InputComponent
                isInput={true}
                flag="rupee"
                label="Total Amount"
                value={totalAmount}
                setState={setTotalAmount}
                min={1}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Tax slab"
                setState={setTaxSlab}
                value={taxSlab}
                min={0}
                max={999}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div">
              <InputComponent
                label={
                  type === "GST Exclusive"
                    ? "Actual Amount"
                    : "Inclusive Gst Amount"
                }
                isInput={false}
                value={totalAmount}
              />
              <InputComponent label="IGST" isInput={false} value={totalGst} />
              <InputComponent
                label="CGST"
                isInput={false}
                value={+totalGst / 2}
              />
              <InputComponent
                label="SGST / UTGST"
                isInput={false}
                value={+totalGst / 2}
              />
              <InputComponent
                label={
                  type === "GST Exclusive"
                    ? "Post-GST Amount"
                    : "Pre-GST Amount"
                }
                isInput={false}
                value={postGstAmount}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +totalGst, label: "Total GST" },
                {
                  id: 1,
                  value: type === "GST Exclusive" ? totalAmount : postGstAmount,
                  label:
                    type === "GST Exclusive"
                      ? "Actual Amount"
                      : "Pre-GST Amount",
                },
              ]}
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default GstCalc;
