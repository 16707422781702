import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import "./RentCalc.scss";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import InputComponent from "../../Common/InputComponent/InputComponent";
import TabbingComponent from "../../Common/TabbingRadio/TabbingComponent";
import RangeChart from "../../Common/RangeChart/RangeChart";

function RentCalc() {
  const [preTaxIncome, setPreTaxIncome] = useState(1000);
  const [preTaxIncomeType, setPreTaxIncomeType] = useState("Yearly");
  const [payback, setPayback] = useState(0);
  const [affordRentalPayment, setAffordRentalPayment] = useState(0);
  const [recommendRentalPayment, setRecommendRentalPayment] = useState(0);

  useEffect(() => {
    const monthlyAmount =
      preTaxIncomeType === "Monthly" ? preTaxIncome : preTaxIncome / 12;
    const affordValue = ((monthlyAmount * 28) / 100 - payback).toFixed(2);
    const recommendedValue = ((monthlyAmount * 36) / 100 - payback).toFixed(2);
    setAffordRentalPayment(isNaN(affordValue) ? 0 : affordValue);
    setRecommendRentalPayment(isNaN(recommendedValue) ? 0 : recommendedValue);
  }, [preTaxIncome, preTaxIncomeType, payback]);

  return (
    <>
      {returnHelmetData()}

      <Box
        sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}
        className="rent-calc"
      >
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={6} className="calculation-part">
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Your pre-tax income"
                value={preTaxIncome}
                min={1}
                setState={setPreTaxIncome}
                max={10000000000}
              />
              <TabbingComponent
                data={["Yearly", "Monthly"]}
                value={preTaxIncomeType}
                setValue={setPreTaxIncomeType}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Your monthly debt payback "
                value={payback}
                min={0}
                setState={setPayback}
                max={10000000}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              <InputComponent
                isInput={false}
                value={`You can afford up to <strong>$${recommendRentalPayment}</strong> per month on a rental payment. It is recommended to keep your rental payment below <strong>$${affordRentalPayment}</strong> per month.`}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <RangeChart
              value1={+affordRentalPayment}
              value2={+recommendRentalPayment}
              totalRange={+affordRentalPayment + +recommendRentalPayment}
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default RentCalc;
