import React from "react";
import "./PrivacyPolicy.scss";
import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import ComponentHeader from "../Header/ComponentHeader";
import { Helmet } from "react-helmet";
import { CONSTANT, SEO } from "../../../Utils/Constant";

function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>{SEO.PRIVACY_POLICY_TITLE}</title>
        <meta name="description" content={SEO.PRIVACY_POLICY_DESCRIPTION} />
        <meta name="keywords" content={SEO.PRIVACY_POLICY_KEYWORDS} />

        <meta property="og:title" content={SEO.PRIVACY_POLICY_TITLE} />
        <meta
          property="og:description"
          content={SEO.PRIVACY_POLICY_DESCRIPTION}
        /><meta name="robots" content="index" />
        <meta name="googlebot" content="index" /><link rel="canonical" href={window.location.href} />
        <meta name="googlebot-news" content="index" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.CALCULATOR_TIMES_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.CALCULATOR_TIMES} />

        <meta name="twitter:title" content={SEO.PRIVACY_POLICY_TITLE} />
        <meta
          name="twitter:description"
          content={SEO.PRIVACY_POLICY_DESCRIPTION}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Grid sx={{ px: 2, py: 1 }}>
        <ComponentHeader name="Privacy Policy" />
        <Grid className="privacy-policy">
          <Typography variant="h2">Introduction</Typography>
          <Typography>
            Thank you for visiting{" "}
            <a href="/">
              <b>Calculator Times</b>
            </a>
            . We are committed to protecting your privacy and ensuring that your
            personal information remains confidential. This Privacy Policy
            outlines how we handle any information collected from visitors to
            our website.
          </Typography>
          <Typography variant="h2">Information We Collect</Typography>
          <Typography>
            We do not collect any personal information from visitors to our
            website. Our calculator tools are designed to function without the
            need for user input or data collection.
          </Typography>
          <Typography variant="h2">Use of Cookies</Typography>
          <Typography>
            We do not use cookies or any other tracking technologies on our
            website.
          </Typography>
          <Typography variant="h2">Third-Party Links</Typography>
          <Typography>
            Our website may contain links to third-party websites for additional
            resources or information. Please note that these websites have their
            own privacy policies, and we do not accept any responsibility or
            liability for their policies or practices.
          </Typography>
          <Typography variant="h2">Web Forms on Our Site</Typography>
          <Typography>
            The information you fill and submit by the web forms on our site
            enables the calculators to function.
          </Typography>
          <Typography variant="h2">Changes to This Privacy Policy</Typography>
          <Typography>
            We reserve the right to update or change our Privacy Policy at any
            time. Any changes will be posted on this page.
          </Typography>
          <Typography variant="h2">Security</Typography>
          <Typography>
            We value your trust in providing us your Personal Information. Thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the Internet,
            or method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
          </Typography>
          <Typography variant="h2">Links to Other Sites</Typography>
          <Typography>
            Our Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over, and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </Typography>
          <Typography variant="h2">Children's Privacy</Typography>
          <Typography>
            Our Services do not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from children
            under 13. In the case we discover that a child under 13 has provided
            us with personal information, we immediately delete this from our
            servers. If you are a parent or guardian and you are aware that your
            child has provided us with personal information, please contact us
            so that we will be able to take the necessary actions.
          </Typography>
          <Typography variant="h2">Contact Us</Typography>
          <Typography>
            If you have any questions or concerns regarding our Privacy Policy,
            please contact us at{" "}
            <b>
              <u>{process.env.REACT_APP_CONTACT_EMAIL}</u>
            </b>
            .
          </Typography>
          <Typography>
            Last Updated:{" "}
            <b>{dayjs().startOf("month").format("DD MMMM,YYYY")}</b>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default PrivacyPolicy;
