import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import weekday from "dayjs/plugin/weekday";
import isBusinessDay from "dayjs-business-days";
import "dayjs-business-days";

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(weekday);
dayjs.extend(isBusinessDay);

export const dateDifference = (date1, date2) => {
  /*
   * calcDate() : Calculates the difference between two dates
   * @date1 : "First Date in the format MM-DD-YYYY"
   * @date2 : "Second Date in the format MM-DD-YYYY"
   * return : Array
   */

  //dayjs object to Date object
  const dt_date1 = new Date(
    `${date1.month() + 1}-${date1.date()}-${date1.year()}`
  );
  const dt_date2 = new Date(
    `${date2.month() + 1}-${date2.date()}-${date2.year()}`
  );

  //Get the Timestamp
  const date1_time_stamp = dt_date1.getTime();
  const date2_time_stamp = dt_date2.getTime();

  let calc;

  //Check which timestamp is greater
  if (date1_time_stamp > date2_time_stamp) {
    calc = new Date(date1_time_stamp - date2_time_stamp);
  } else {
    calc = new Date(date2_time_stamp - date1_time_stamp);
  }
  //Retrieve the date, month and year
  const calcFormatTmp =
    calc.getDate() + "-" + (calc.getMonth() + 1) + "-" + calc.getFullYear();
  //Convert to an array and store
  const calcFormat = calcFormatTmp.split("-");
  //Subtract each member of our array from the default date
  const days_passed = Number(Math.abs(calcFormat[0]) - 1);
  const months_passed = Number(Math.abs(calcFormat[1]) - 1);
  const years_passed = Number(Math.abs(calcFormat[2]) - 1970);

  //Set up custom text
  const yrsTxt = ["year", "years"];
  const mnthsTxt = ["month", "months"];
  const daysTxt = ["day", "days"];

  //Convert to days and sum together
  const total_days = years_passed * 365 + months_passed * 30.417 + days_passed;
  const total_secs = total_days * 24 * 60 * 60;
  const total_mins = total_days * 24 * 60;
  const total_hours = total_days * 24;
  const total_weeks = total_days >= 7 ? total_days / 7 : 0;

  //display result with custom text
  const result =
    (years_passed === 1
      ? years_passed + " " + yrsTxt[0] + " "
      : years_passed > 1
      ? years_passed + " " + yrsTxt[1] + " "
      : "") +
    (months_passed === 1
      ? months_passed + " " + mnthsTxt[0]
      : months_passed > 1
      ? months_passed + " " + mnthsTxt[1] + " "
      : "") +
    (days_passed === 1
      ? days_passed + " " + daysTxt[0]
      : days_passed > 1
      ? days_passed + " " + daysTxt[1]
      : "");

  //return the result
  return {
    total_days: Math.round(total_days),
    total_weeks: Math.round(total_weeks),
    total_hours: Math.round(total_hours),
    total_minutes: Math.round(total_mins),
    total_seconds: Math.round(total_secs),
    result: result === "" ? "0 days" : result.trim(),
  };
};

export const workingDaysHelper = (date1, date2) => {
  if (date1.isSame(date2)) {
    return { workingDays: 0, holidays: 0 };
  }

  // If end date is not weekend, then subtract 1 to not the end day.
  if (date2.day() !== 0 || date2.day() !== 6) {
    date2 = date2.subtract(1, "day");
  }

  // dayjs object to Date object
  let startDate = date1?.toDate();
  let endDate = date2?.toDate();

  // Hanlde startdate greater than end date
  if (endDate <= startDate) {
    [startDate, endDate] = [endDate, startDate];
  }

  let allDays = Math.floor(
    (endDate.getTime() - startDate.getTime()) / 86400000
  );
  let holidays = [
    { name: "New Year's Day", date: [1, 0] },
    { name: "Martin Luther King Day", date: [15, 0] },
    { name: "Presidents' Day", date: [19, 1] },
    { name: "Memorial Day", date: [27, 4] },
    { name: "Juneteenth Day", date: [19, 5] },
    { name: "Independence Day", date: [4, 6] },
    { name: "Labor Day", date: [2, 8] },
    { name: "Columbus Day", date: [14, 9] },
    { name: "Veteran's Day", date: [11, 10] },
    { name: "Thanksgiving", date: [28, 10] },
    { name: "Christmas", date: [25, 11] },
  ];

  let holidayList = [];

  for (let i = 0; i < allDays; i++) {
    let tmpDate = new Date();
    tmpDate.setTime(startDate.getTime() + i * 24 * 60 * 60 * 1000);

    holidays.forEach((holiday) => {
      if (
        tmpDate.getDate() === holiday.date[0] &&
        tmpDate.getMonth() === holiday.date[1]
      ) {
        holidayList.push({
          name: holiday.name,
          date: tmpDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
        });
      }
    });
  }

  // Calculate days between dates
  let millisecondsPerDay = 86400 * 1000; // Day in milliseconds
  startDate.setHours(0, 0, 0, 1); // Start just after midnight
  endDate.setHours(23, 59, 59, 999); // End just before midnight
  let diff = endDate - startDate; // Milliseconds between datetime objects
  let days = Math.ceil(diff / millisecondsPerDay);

  // Subtract two weekend days for every week in between
  let weeks = Math.floor(days / 7);
  days -= weeks * 2;

  // Handle special cases
  let startDay = startDate.getDay();
  let endDay = endDate.getDay();

  // Remove weekend not previously removed.
  if (startDay - endDay > 1) {
    days -= 2;
  }
  // Remove start day if span starts on Sunday but ends before Saturday
  if (startDay === 0 && endDay !== 6) {
    days--;
  }
  // Remove end day if span ends on Saturday but starts after Sunday
  if (endDay === 6 && startDay !== 0) {
    days--;
  }

  return { workingDays: days - holidayList.length, holidayList };
};

export const DateHelper = (date1, date2) => {
  return {
    dateString: dateDifference(date1, date2).result,
    ...workingDaysHelper(date1, date2),
  };
};
export const dateDifferenceForAge = (date1, date2) => {
  // Ensure the first date is earlier than the second
  if (date1 > date2) {
    [date1, date2] = [date2, date1];
  }

  const msInDay = 24 * 60 * 60 * 1000;
  const msInWeek = 7 * msInDay;
  const msInMonth = 30.44 * msInDay; // Average month length
  const msInYear = 365.25 * msInDay; // Average year length

  const diffInMs = date2 - date1;

  // Calculate years, months, and days
  const years = Math.floor(diffInMs / msInYear);
  const remainingAfterYears = diffInMs % msInYear;
  const months = Math.floor(remainingAfterYears / msInMonth);
  const remainingAfterMonths = remainingAfterYears % msInMonth;
  const days = Math.floor(remainingAfterMonths / msInDay);

  // Calculate total months and days
  const totalMonths = Math.floor(diffInMs / msInMonth);
  const remainingDaysForMonths = Math.floor((diffInMs % msInMonth) / msInDay);

  // Calculate total weeks and days
  const totalWeeks = Math.floor(diffInMs / msInWeek);
  const remainingDaysForWeeks = Math.floor((diffInMs % msInWeek) / msInDay);

  let str1Parts = [];
  if (years || months || days) str1Parts.push(`${years} years`);
  if (months || days) str1Parts.push(`${months} months`);
  if (days) str1Parts.push(`${days} days`);
  const str1 = str1Parts.join(" ").trim();

  let str2Parts = [];
  if (totalMonths || remainingDaysForMonths)
    str2Parts.push(`${totalMonths} months`);
  if (remainingDaysForMonths) str2Parts.push(`${remainingDaysForMonths} days`);
  const str2 = str2Parts.join(" ").trim();

  let str3Parts = [];
  if (totalWeeks || remainingDaysForWeeks)
    str3Parts.push(`${totalWeeks} weeks`);
  if (remainingDaysForWeeks) str3Parts.push(`${remainingDaysForWeeks} days`);
  const str3 = str3Parts.join(" ").trim();

  return { str1, str2, str3 };
};

export const formatDate = (date) => {
  //date: dayjs object
  return date?.toDate().toLocaleDateString("en-us", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const modifyDateForSubtractCalc = (
  operation,
  selectedDate,
  timeUnits,
  businessDays,
  holidays
) => {
  let date = dayjs(selectedDate);

  if (businessDays !== null) {
    let count = 0;
    while (count < businessDays) {
      date = operation === "+" ? date.add(1, "day") : date.subtract(1, "day");
      if (date.isBusinessDay() && !isHoliday(date, holidays)) {
        count++;
      }
    }
  } else {
    for (const [unit, value] of Object.entries(timeUnits)) {
      if (operation === "+") {
        date = date.add(value, unit);
      } else if (operation === "-") {
        date = date.subtract(value, unit);
      } else {
        throw new Error("Invalid operation. Use '+' or '-'.");
      }
    }
  }

  const formattedDate = date.format("MMM D, YYYY, dddd");
  const originalDate = dayjs(selectedDate).format("MMM D, YYYY");
  const action = operation === "+" ? "after" : "before";

  let details = "";
  let totalHolidays = 0;
  let totalWeekendDays = 0;
  if (businessDays !== null) {
    let tempDate = dayjs(selectedDate);
    for (let i = 0; i < businessDays; i++) {
      tempDate =
        operation === "+"
          ? tempDate.add(1, "day")
          : tempDate.subtract(1, "day");
      if (!tempDate.isBusinessDay() || isHoliday(tempDate, holidays)) {
        if (!tempDate.isBusinessDay()) {
          totalWeekendDays++;
        }
        if (isHoliday(tempDate, holidays)) {
          totalHolidays++;
        }
        businessDays++;
      }
    }

    details = `${businessDays} business day${businessDays > 1 ? "s" : ""}`;
  } else {
    if (timeUnits.years)
      details += `${timeUnits.years} year${timeUnits.years > 1 ? "s" : ""}`;
    if (timeUnits.months)
      details += `${details ? ", " : ""}${timeUnits.months} month${
        timeUnits.months > 1 ? "s" : ""
      }`;
    if (timeUnits.weeks)
      details += `${details ? ", " : ""}${timeUnits.weeks} week${
        timeUnits.weeks > 1 ? "s" : ""
      }`;
    if (timeUnits.days)
      details += `${details ? ", " : ""}${timeUnits.days} day${
        timeUnits.days > 1 ? "s" : ""
      }`;
  }

  const resultString = `${formattedDate} is the date ${details} ${
    details ? action : "today"
  } ${originalDate}`;

  return {
    result: resultString,
    holidays: totalHolidays,
    weekoffDays: totalWeekendDays,
  };
};
const isHoliday = (date, holidays) => {
  const dateObj = {
    month: date.month(),
    date: date.date(),
  };
  return holidays.some(
    (holiday) =>
      holiday.date[0] === dateObj.date && holiday.date[1] === dateObj.month
  );
};
