import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import {
  calculateSubnet,
  NETWORK_CLASS,
  PREFIX_LENGTH,
  SUBNET_OPTIONS,
} from "../../../Utils/SubnetHelper";
import TableComponent from "../../Common/TableComponent/TableComponent";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import BigNumber from "bignumber.js";

export default function Subnet() {
  const defaultSubnet = "255.255.255.252 /30";

  const [networkClass, setNetworkClass] = useState({
    label: "any",
    value: "any",
  });
  const [subnetOptions, setSubnetOptions] = useState(SUBNET_OPTIONS.any);
  const [selectedSubnet, setSelectedSubnet] = useState(
    SUBNET_OPTIONS.any.find((option) => option === defaultSubnet) ||
      SUBNET_OPTIONS.any[0]
  );
  const [ipAddress, setIpAddress] = useState("103.251.19.80");
  const [subnetResults, setSubnetResults] = useState(null);
  const [tableView, setTableView] = useState(false);
  const [subnetTable, setSubnetTable] = useState([]);

  const [prefixLength, setPrefixLength] = useState("/64");
  const [ipAddress1, setIpAddress1] = useState(
    "2001:0db8:85a3::8a2e:0370:7332"
  );
  const [subnetResultsv6, setSubnetResultsv6] = useState({});

  useEffect(() => {
    const tempVar = networkClass.value;
    const newOptions = SUBNET_OPTIONS[tempVar] || [];
    setSubnetOptions(newOptions);
    if (!newOptions.some((option) => option.value === selectedSubnet.value)) {
      setSelectedSubnet(newOptions[0] || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkClass]);

  useEffect(() => {
    const subnetMask = selectedSubnet.split(" ")[0];
    const results = calculateSubnet(ipAddress, subnetMask);
    setSubnetResults(results);
  }, [ipAddress, selectedSubnet]);

  useEffect(() => {
    const calculateSubnets = (ip, subnetMask) => {
      const [firstOctet, secondOctet, thirdOctet] = ip.split(".").map(Number);
      const subnetParts = subnetMask.split(".").map(Number);
      const subnets = [];
      const parts = selectedSubnet.split(" ");
      const lastNumber = parts[1].substring(1);

      if (lastNumber > 24) {
        for (let i = 0; i < 256; i += 256 - subnetParts[3]) {
          const networkAddress = `${firstOctet}.${secondOctet}.${thirdOctet}.${i}`;
          const firstHost = `${firstOctet}.${secondOctet}.${thirdOctet}.${
            i + 1
          }`;
          const lastHost = `${firstOctet}.${secondOctet}.${thirdOctet}.${
            i + (256 - subnetParts[3]) - 2
          }`;
          const broadcastAddress = `${firstOctet}.${secondOctet}.${thirdOctet}.${
            i + (256 - subnetParts[3]) - 1
          }`;

          subnets.push({
            networkAddress,
            usableHostRange: `${firstHost} - ${lastHost}`,
            broadcastAddress,
          });
        }
      } else {
        for (let i = 0; i < 256; i += 256 - subnetParts[2]) {
          const networkAddress = `${firstOctet}.${secondOctet}.${Math.floor(
            i
          )}.0`;
          const firstHost = `${firstOctet}.${secondOctet}.${Math.floor(i)}.1`;
          const lastHost = `${firstOctet}.${secondOctet}.${Math.floor(
            i + 256 - subnetParts[2] - 1
          )}.254`;
          const broadcastAddress = `${firstOctet}.${secondOctet}.${Math.floor(
            i + 256 - subnetParts[2] - 1
          )}.255`;

          subnets.push({
            networkAddress,
            usableHostRange: `${firstHost} - ${lastHost}`,
            broadcastAddress,
          });
        }
      }

      return subnets;
    };
    if (ipAddress && selectedSubnet) {
      const subnetMask = selectedSubnet.split(" ")[0];
      setSubnetTable(calculateSubnets(ipAddress, subnetMask));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ipAddress, selectedSubnet]);

  const calculateSubnetResults = (ip, prefix) => {
    const fullIpAddress = ip.replace(/::/, ":0000:0000:");
    const totalIpAddresses = new BigNumber(2).pow(
      128 - parseInt(prefix.replace("/", ""))
    );

    const networkAddress =
      fullIpAddress.split(":").slice(0, 4).join(":") + "::";
    const ipRange = `${networkAddress.replace(
      "::",
      ":"
    )}0000:0000:0000:0000 - <br/> ${networkAddress.replace(
      "::",
      ":"
    )}ffff:ffff:ffff:ffff`;

    return {
      ipAddress: `${ip}${prefix}`,
      fullIpAddress: fullIpAddress,
      totalIpAddresses: totalIpAddresses.toFormat(),
      networkAddress: networkAddress,
      ipRange: ipRange,
    };
  };

  useEffect(() => {
    const results = calculateSubnetResults(ipAddress1, prefixLength);
    setSubnetResultsv6(results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ipAddress1, prefixLength]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={10} className="calculation-part ">
            <Typography className="common-sub-heading-calc">
              IPv4 Subnet Calculator
            </Typography>

            <Grid>
              <RadioButtonComponent
                label={"Network Class"}
                data={NETWORK_CLASS}
                defaultValue={"any"}
                valueSetter={setNetworkClass}
                row={false}
              />
              <Grid>
                <SelectBoxComponent
                  label={"Subnet"}
                  value={selectedSubnet}
                  setValue={setSelectedSubnet}
                  data={subnetOptions}
                />
              </Grid>
              <InputComponent
                type="text"
                label="IP Address"
                isInput={true}
                value={ipAddress}
                setState={setIpAddress}
              />
            </Grid>

            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            {subnetResults && (
              <Grid>
                <InputComponent
                  label="IP Address"
                  isInput={false}
                  value={subnetResults?.ipAddress}
                  date={true}
                />
                <InputComponent
                  label="Network Address"
                  isInput={false}
                  value={subnetResults?.networkAddress}
                  date={true}
                />
                <InputComponent
                  label="Usable Host IP Range"
                  isInput={false}
                  value={
                    subnetResults?.firstHost > subnetResults?.lastHost
                      ? "NA"
                      : `${subnetResults?.firstHost} - ${subnetResults?.lastHost}`
                  }
                  date={true}
                />
                <InputComponent
                  label="Broadcast Address"
                  isInput={false}
                  value={subnetResults?.broadcastAddress}
                  date={true}
                />
                <InputComponent
                  label="Total Number of Hosts"
                  isInput={false}
                  value={subnetResults?.numHosts}
                  date={true}
                />
                <InputComponent
                  label="Number of Usable Hosts"
                  isInput={false}
                  value={
                    subnetResults?.numHosts - 2 < 0
                      ? 0
                      : subnetResults?.numHosts - 2
                  }
                  date={true}
                />
                <InputComponent
                  label="Subnet Mask"
                  isInput={false}
                  value={selectedSubnet.split(" ")[0]}
                  date={true}
                />
                <InputComponent
                  label="Wildcard Mask"
                  isInput={false}
                  value={subnetResults?.wildcardMask}
                  date={true}
                />
                <InputComponent
                  label="Binary Subnet Mask"
                  isInput={false}
                  value={subnetResults?.binarySubnetMask}
                  date={true}
                />
                {subnetResults?.cidrNotation.replace("/", "") > 7 && (
                  <InputComponent
                    label="IP Class"
                    isInput={false}
                    value={subnetResults?.ipClass}
                    date={true}
                  />
                )}
                <InputComponent
                  label="CIDR Notation"
                  isInput={false}
                  value={subnetResults?.cidrNotation}
                  date={true}
                />
                <InputComponent
                  label="IP Type"
                  isInput={false}
                  value={subnetResults?.ipType}
                  date={true}
                />
                <InputComponent
                  label="Short"
                  isInput={false}
                  value={subnetResults?.shortNotation}
                  date={true}
                />
                <InputComponent
                  label="Binary ID"
                  isInput={false}
                  value={subnetResults?.binaryID}
                  date={true}
                />
                <InputComponent
                  label="Integer ID"
                  isInput={false}
                  value={subnetResults?.integerID}
                  date={true}
                />
                <InputComponent
                  label="Hex ID"
                  isInput={false}
                  value={subnetResults?.hexID}
                  date={true}
                />
                <InputComponent
                  label="in-addr.arpa"
                  isInput={false}
                  value={subnetResults?.inAddrArpa}
                  date={true}
                />
                <InputComponent
                  label="IPv4 Mapped Address"
                  isInput={false}
                  value={subnetResults?.ipv4MappedAddress}
                  date={true}
                />
                <InputComponent
                  label="6to4 Prefix"
                  isInput={false}
                  value={subnetResults?.sixTo4Prefix}
                  date={true}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid className="data-table-container">
          <Grid className="table-toggle-div">
            <Typography variant="h5">
              All {subnetTable.length} of the Possible{" "}
              {subnetResults?.cidrNotation} Networks for{" "}
              {subnetResults?.cidrNotation.replace("/", "") > 24
                ? ipAddress.split(".").slice(0, 3).join(".")
                : ipAddress.split(".").slice(0, 2).join(".") + ".*"}
              .*{" "}
            </Typography>
            <Tooltip
              title={tableView ? "click to close table" : "click to open table"}
              placement="top"
              arrow
            >
              <span>
                <img
                  src={plusIcon}
                  alt="plusIcon"
                  onClick={() => setTableView(!tableView)}
                  style={{
                    transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                  className="add-icon-table"
                />
              </span>
            </Tooltip>
          </Grid>
          {tableView && (
            <Grid>
              <Grid>
                <TableComponent
                  rows={subnetTable}
                  headerData={[
                    "Network Address",
                    "Usable Host Range",
                    "Broadcast Address",
                  ]}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" }, mt: 2 }}>
          <Grid item xs={12} md={10} className="calculation-part ">
            <Typography className="common-sub-heading-calc">
              IPv6 Subnet Calculator
            </Typography>

            <Grid>
              <Grid>
                <SelectBoxComponent
                  label={"Prefix Length"}
                  value={prefixLength}
                  setValue={setPrefixLength}
                  data={PREFIX_LENGTH}
                />
              </Grid>
              <InputComponent
                type="text"
                label="IP Address"
                isInput={true}
                value={ipAddress1}
                setState={setIpAddress1}
              />
            </Grid>

            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                label="IP Address"
                isInput={false}
                value={subnetResultsv6?.ipAddress}
                date={true}
              />
              <InputComponent
                label="Full IP Address"
                isInput={false}
                value={subnetResultsv6?.fullIpAddress}
                date={true}
              />
              <InputComponent
                label="Total IP Addresses"
                isInput={false}
                value={subnetResultsv6?.totalIpAddresses}
                date={true}
              />
              <InputComponent
                label="Network"
                isInput={false}
                value={subnetResultsv6?.networkAddress}
                date={true}
              />
              <InputComponent
                label="IP Range"
                isInput={false}
                value={subnetResultsv6?.ipRange}
                date={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
