/* eslint-disable array-callback-return */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import "./Table.scss";

export default function DenseTable({ rows, headerData }) {
  const allKeys = ["string", "date", "periodStart", "OVWindow", "dueDate"];

  const rowHeaderCellStyling = {
    backgroundColor: "#295fa6",
    fontWeight: "bold",
    color: "white",
    paddingRight: "5px",
  };

  const cellStyling = {
    paddingX: "10px",
  };

  return (
    <TableContainer className="tableBorder" sx={{}} component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow sx={{ border: "2px solid #295fa6" }}>
            {headerData?.map((item, index) => (
              <TableCell
                align="center"
                sx={{ ...rowHeaderCellStyling }}
                key={index}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? (
            rows.map((row) => (
              <TableRow key={row[0]}>
                {allKeys.map((key, index) => {
                  if (typeof row[key] === "string") {
                    return (
                      <>
                        <TableCell
                          align={index === 0 ? "left" : "right"}
                          sx={{
                            ...cellStyling,
                            backgroundColor: row?.cellColor,
                          }}
                          key={row[key]}
                        >
                          {row[key]}
                        </TableCell>
                      </>
                    );
                  } else if (dayjs.isDayjs(row[key])) {
                    return (
                      <>
                        <TableCell
                          align={index === 0 ? "left" : "right"}
                          sx={{
                            ...cellStyling,
                            backgroundColor: row[key].cellColor,
                          }}
                          key={row[key].name}
                          rowSpan={row[key].rowSpan}
                        >
                          {row[key].format("ll")}
                        </TableCell>
                      </>
                    );
                  } else if (
                    Array.isArray(row[key]) &&
                    dayjs.isDayjs(row[key][0])
                  ) {
                    return (
                      <>
                        <TableCell
                          align={index === 0 ? "left" : "right"}
                          sx={{
                            ...cellStyling,
                            backgroundColor: row.cellColor,
                          }}
                          key={row[key]}
                        >
                          {`${row[key][0].format("ll")} - ${row[key][1].format(
                            "ll"
                          )}`}
                        </TableCell>
                      </>
                    );
                  }
                })}
              </TableRow>
            ))
          ) : (
            <>
              <TableCell colSpan={headerData.length}>
                No data found for your input value
              </TableCell>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
