import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import InputComponent from "../../Common/InputComponent/InputComponent";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";

const PaceCalc = () => {
  const [hour, setHour] = useState(1);
  const [minute, setMinute] = useState(55);
  const [second, setSecond] = useState(25);
  const [distance, setDistance] = useState(10);
  const [units, setUnits] = useState("Kilometers");
  const [event, setEvent] = useState("-- Or pick an event --");
  const [pace, setPace] = useState("");

  const eventDistances = {
    Marathon: { distance: 42.195, unit: "Kilometers" },
    "Half-Marathon": { distance: 21.0975, unit: "Kilometers" },
    "1K": { distance: 1, unit: "Kilometers" },
    "5K": { distance: 5, unit: "Kilometers" },
    "10K": { distance: 10, unit: "Kilometers" },
    "1 Miles": { distance: 1, unit: "Miles" },
    "5 Miles": { distance: 5, unit: "Miles" },
    "10 Miles": { distance: 10, unit: "Miles" },
    "800 meters": { distance: 800, unit: "Meters" },
    "1500 meters": { distance: 1500, unit: "Meters" },
  };

  useEffect(() => {
    if (event !== "-- Or pick an event --") {
      setDistance(eventDistances[event].distance);
      setUnits(eventDistances[event].unit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const calculatePace = () => {
    const totalSeconds = +hour * 3600 + +minute * 60 + +second;
    let distanceInMiles, distanceInKilometers;

    switch (units) {
      case "Kilometers":
        distanceInMiles = +distance / 1.60934;
        distanceInKilometers = +distance;
        break;
      case "Meters":
        distanceInMiles = +distance / 1609.34;
        distanceInKilometers = +distance / 1000;
        break;
      case "Yards":
        distanceInMiles = +distance / 1760;
        distanceInKilometers = +distance / 1093.61;
        break;
      case "Miles":
      default:
        distanceInMiles = +distance;
        distanceInKilometers = +distance * 1.60934;
        break;
    }

    const pacePerMile = +totalSeconds / +distanceInMiles;
    const pacePerKilometer = +totalSeconds / +distanceInKilometers;

    const formatTime = (totalSeconds) => {
      const hours = Math.floor(+totalSeconds / 3600);
      const minutes = Math.floor((+totalSeconds % 3600) / 60);
      const seconds = (+totalSeconds % 60).toFixed(2);
      if (hours) {
        return `${+hours === Infinity ? 0 : +hours} hours ${
          +minutes || 0
        } minutes ${+seconds || 0} seconds`;
      } else {
        return `${+minutes || 0} minutes ${+seconds || 0} seconds`;
      }
    };

    setPace({
      pacePerMile: `${formatTime(+pacePerMile)} per mile`,
      pacePerKilometer: `${formatTime(+pacePerKilometer)} per kilometer`,
      milesPerHour: (+distanceInMiles / (+totalSeconds / 3600)).toFixed(2),
      kilometersPerHour: (
        +distanceInKilometers /
        (+totalSeconds / 3600)
      ).toFixed(2),
      metersPerMinute: (
        (+distanceInKilometers * 1000) /
        (+totalSeconds / 60)
      ).toFixed(2),
      metersPerSecond: ((+distanceInKilometers * 1000) / +totalSeconds).toFixed(
        2
      ),
    });
  };

  useEffect(() => {
    calculatePace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hour, minute, second, distance, units]);

  return (
    <>
      <Grid>
        <InputComponent
          isInput={true}
          label="Hour"
          max={100}
          value={hour}
          setState={setHour}
        />
        <InputComponent
          isInput={true}
          label="Minute"
          max={60}
          value={minute}
          setState={setMinute}
        />
        <InputComponent
          isInput={true}
          label="Second"
          max={60}
          value={second}
          setState={setSecond}
        />
        <Grid>
          <Grid className="concrete-calc-input pace">
            <Typography className="input-label">Distance</Typography>
            <Grid sx={{ display: "flex" }}>
              <input
                type="number"
                value={distance}
                onChange={(e) =>
                  setDistance(() =>
                    e.target.value < 0
                      ? 0
                      : e.target.value > 1000000000000
                      ? 1000000000000
                      : e.target.value
                  )
                }
                className="input-field"
              />

              <Grid className="select-box-wrap">
                <SelectBoxComponent
                  value={units}
                  setValue={setUnits}
                  data={["Miles", "Kilometers", "Meters", "Yards"]}
                  placeholder="Select Units"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SelectBoxComponent
          value={event}
          setValue={setEvent}
          data={[
            "-- Or pick an event --",
            "Marathon",
            "Half-Marathon",
            "1K",
            "5K",
            "10K",
            "1 Miles",
            "5 Miles",
            "10 Miles",
            "800 meters",
            "1500 meters",
          ]}
        />
      </Grid>
      <Grid className="result-label">
        <Typography>Result</Typography>
      </Grid>
      <Grid>
        <InputComponent
          isInput={false}
          label="Pace in different units"
          value={`
                  ${pace.pacePerMile}<br>
                  ${pace.pacePerKilometer}<br>
                  ${
                    pace.milesPerHour === "Infinity" ? 0 : pace.milesPerHour
                  } miles/hour<br>
                  ${
                    pace.kilometersPerHour === "Infinity"
                      ? 0
                      : pace.kilometersPerHour
                  } kilometers/hour<br>
                  ${
                    pace.metersPerMinute === "Infinity"
                      ? 0
                      : pace.metersPerMinute
                  } meters/minute<br>
                  ${
                    pace.metersPerSecond === "Infinity"
                      ? 0
                      : pace.metersPerSecond
                  } meters/second
                `}
          date={true}
        />
      </Grid>
    </>
  );
};

export default PaceCalc;
