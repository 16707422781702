import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(LocalizedFormat);
dayjs.extend(customParseFormat);

export const generateUsingDueDate = (dueDate) => {
  let tableData = [];

  let week40start = dueDate.subtract(6, "days");
  let week40end = dueDate;

  //Add  week1-week39
  for (let i = 1; i < 40; i++) {
    let startDay = week40start.subtract(40 - i, "week");
    let endDay = week40end.subtract(40 - i, "week");
    tableData.push({
      week: `Week ${i}`,
      date: [startDay, endDay],
    });
  }

  // Add week 40
  tableData.push({
    week: "Week 40",
    date: [week40start, week40end],
  });

  // Add week41-42
  for (let i = 1; i <= 2; i++) {
    let startDay = week40start.add(i, "week");
    let endDay = week40end.add(i, "week");
    tableData.push({
      week: `Week 4${i}`,
      date: [startDay, endDay],
    });
  }

  tableData = addTrimestersAndIMs(tableData);

  let currentWeekIndex = findCurrentWeekIndex(tableData);
  let progressPercentage = calculateProgress(tableData);
  let currentTrimester = getTrimester(currentWeekIndex);
  let conceivedDate = getConceivedDate(tableData);

  let trimesterStr =
    currentTrimester !== false
      ? `You are in the ${currentTrimester}.`
      : "You are in none of the trimesters.";
  let conceivedStr = `Your baby would've been likely conceived on ${conceivedDate}.`;
  let progressStr = `You are ${progressPercentage}% of the way through your pregnancy.`;

  if (currentWeekIndex) {
    tableData[currentWeekIndex]["cellColor"] = "#fabee2";
  }

  return {
    tableData,
    paragraphStrings: { trimesterStr, conceivedStr, progressStr },
  };
};

export const generateUsingLastPeriod = (lastPeriod, averageCycleLength) => {
  let tableData = [];

  let week0StartDate = lastPeriod.add(1 + (averageCycleLength - 28), "day");

  for (let i = 0; i < 42; i++) {
    let startDay = week0StartDate.add(i, "week");
    let endDay = startDay.add(6, "day");
    tableData.push({
      week: `Week ${i + 1}`,
      date: [startDay, endDay],
    });
  }

  tableData = addTrimestersAndIMs(tableData);

  let dueDate = `The estimated due date is ${getDueDate(tableData)}.`;
  let conceivedDate = `Your baby would've been likely conceived on ${getConceivedDate(
    tableData
  )}.`;

  return { tableData, paragraphStrings: { dueDate, conceivedDate } };
};

export const generateConceptionDate = (conceptionDate) => {
  let tableData = [];

  // Create first two weeks
  let week2startDate = conceptionDate.subtract(6, "day");
  let week2endDate = conceptionDate;
  let week1startDate = week2startDate.subtract(1, "week");
  let week1endDate = week2endDate.subtract(1, "week");

  // Push first two weeks
  tableData.push(
    {
      week: "Week 1",
      date: [week1startDate, week1endDate],
    },
    {
      week: "Week 2",
      date: [week2startDate, week2endDate],
    }
  );

  // Create and push next 40 weeks
  for (let i = 3; i <= 42; i++) {
    let startDay = week2startDate.add(i - 2, "week");
    let endDay = week2endDate.add(i - 2, "week");
    tableData.push({
      week: `Week ${i}`,
      date: [startDay, endDay],
    });
  }

  tableData = addTrimestersAndIMs(tableData);

  let currentWeekIndex = findCurrentWeekIndex(tableData);
  let currentTrimester = getTrimester(currentWeekIndex);
  let progressPercentage = calculateProgress(tableData);

  let dueDateStr = `The estimated due date is ${getDueDate(tableData)}.`;
  let trimesterStr =
    currentTrimester !== false
      ? `You are in the ${currentTrimester}.`
      : "You are in none of the trimesters.";
  let progressStr = `You are ${progressPercentage}% of the way through your pregnancy.`;

  return {
    tableData,
    paragraphStrings: { dueDateStr, trimesterStr, progressStr },
  };
};

export const generateIVF = (IVFDate, embryoAge) => {
  let tableData = [];

  let startDate = IVFDate.subtract(embryoAge, "day");

  for (let i = 0; i < 42; i++) {
    let endDay = startDate.add(i - 1, "week");
    let startDay = endDay.subtract(6, "day");
    tableData.push({
      week: `Week ${i + 1}`,
      date: [startDay, endDay],
    });
  }

  tableData = addTrimestersAndIMs(tableData);

  let currentWeekIndex = findCurrentWeekIndex(tableData);
  let currentTrimester = getTrimester(currentWeekIndex);
  let progressPercentage = calculateProgress(tableData);

  let dueDateStr = `The estimated due date is ${getDueDate(tableData)}.`;
  let trimesterStr =
    currentTrimester !== false
      ? `You are in the ${currentTrimester}.`
      : "You are in none of the trimesters.";
  let progressStr = `You are ${progressPercentage}% of the way through your pregnancy.`;

  return {
    tableData,
    paragraphStrings: { dueDateStr, trimesterStr, progressStr },
  };
};

export const generateUltrasound = (ultrasoundDate, LOPWeek, LOPDay) => {
  console.clear();
  let tableData = [];

  let conceptionDate = ultrasoundDate.subtract(LOPDay, "day");
  let startDate = conceptionDate.subtract((LOPWeek - 1) * 7, "day");

  for (let i = 0; i < 42; i++) {
    let endDay = startDate.add(i, "week");
    let startDay = endDay.subtract(6, "day");
    tableData.push({
      week: `Week ${i + 1}`,
      date: [startDay, endDay],
    });
  }

  tableData = addTrimestersAndIMs(tableData);

  let currentWeekIndex = findCurrentWeekIndex(tableData);
  let currentTrimester = getTrimester(currentWeekIndex);
  let progressPercentage = calculateProgress(tableData);
  let conceivedDate = getConceivedDate(tableData);

  let dueDateStr = `The estimated due date is ${getDueDate(tableData)}.`;
  let trimesterStr =
    currentTrimester !== false
      ? `You are in the ${currentTrimester}.`
      : "You are in none of the trimesters.";
  let progressStr = `You are ${progressPercentage}% of the way through your pregnancy.`;
  let conceivedStr = `Your baby would've been likely conceived on ${conceivedDate}.`;

  return {
    tableData,
    paragraphStrings: { dueDateStr, trimesterStr, progressStr, conceivedStr },
  };
};

export const addTrimestersAndIMs = (tableData) => {
  tableData[0]["trimester"] = {
    name: "First trimester",
    rowSpan: 12,
    cellColor: "#dddddd",
  };
  tableData[12]["trimester"] = {
    name: "Second trimester",
    rowSpan: 15,
    cellColor: "#dddddd",
  };
  tableData[27]["trimester"] = {
    name: "Second trimester",
    rowSpan: 15,
    cellColor: "#dddddd",
  };

  tableData[0]["IM"] = { name: "", rowSpan: 2 };
  tableData[2]["IM"] = {
    name: "Baby conceived",
    rowSpan: 1,
    cellColor: "#cae1fc",
  };
  tableData[3]["IM"] = {
    name: "Pregnancy test positive",
    rowSpan: 1,
    cellColor: "#cae1fc",
  };
  tableData[4]["IM"] = { name: "", rowSpan: 1 };
  tableData[5]["IM"] = {
    name: "Heartbeat detectable by ultrasound",
    rowSpan: 2,
    cellColor: "#cae1fc",
  };
  tableData[7]["IM"] = { name: "", rowSpan: 5 };
  tableData[12]["IM"] = {
    name: "Miscarriage risk decreases",
    rowSpan: 1,
    cellColor: "#cae1fc",
  };
  tableData[13]["IM"] = { name: "", rowSpan: 4 };
  tableData[17]["IM"] = {
    name: "Baby begins making noticeable movements, can hear sounds, and gender can be found out.",
    rowSpan: 4,
    cellColor: "#cae1fc",
  };
  tableData[21]["IM"] = { name: "", rowSpan: 1 };
  tableData[22]["IM"] = {
    name: "Premature baby may survive",
    rowSpan: 1,
    cellColor: "#cae1fc",
  };
  tableData[23]["IM"] = { name: "", rowSpan: 4 };
  tableData[27]["IM"] = {
    name: "Baby can breathe",
    rowSpan: 1,
    cellColor: "#cae1fc",
  };
  tableData[28]["IM"] = { name: "", rowSpan: 9 };
  tableData[37]["IM"] = { name: "Full Term", rowSpan: 5, cellColor: "#cae1fc" };

  return tableData;
};

export const findCurrentWeekIndex = (tableData) => {
  let today = dayjs();
  let weekIndex;

  if (!(today >= tableData[0].date[0] && today <= tableData[41].date[1])) {
    return false;
  }

  tableData.forEach((item, index) => {
    if (
      (today >= item.date[0] && today <= item.date[1]) ||
      today.format("DD/MM/YYYY") === item.date[0].format("DD/MM/YYYY") ||
      today.format("DD/MM/YYYY") === item.date[1].format("DD/MM/YYYY")
    ) {
      weekIndex = index;
      return;
    }
  });

  return weekIndex;
};

export const calculateProgress = (tableData) => {
  let totalDuration = 280;
  let elapsedDuration = dayjs().diff(tableData[0].date[0], "day");
  let percentage = Math.max(
    ((elapsedDuration / totalDuration) * 100).toFixed(0),
    0
  );

  return percentage;
};

export const getTrimester = (weekIndex) => {
  if (weekIndex !== false) {
    switch (true) {
      case weekIndex >= 0 && weekIndex <= 12:
        return "First Trimester";

      case weekIndex >= 3 && weekIndex <= 26:
        return "Second Trimester";

      case weekIndex >= 27 && weekIndex <= 36:
        return "Third Trimester";

      case weekIndex >= 36 && weekIndex <= 41:
        return "Third Trimester and full term";

      default:
        return false;
    }
  } else {
    return false;
  }
};

export const getDueDate = (tableData) => tableData[39].date[1].format("ll");

export const getConceivedDate = (tableData) =>
  tableData[1].date[1].format("ll");
