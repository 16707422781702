import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";

import { returnHelmetData } from "../../../Utils/CommonFunction";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import PieChartComponent from "../../Common/PieChart/PieChart";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

export default function PayCheckCalc() {
  const [payFrequency, setPayFrequency] = useState("Bi-weekly");
  const [payAmount, setPayAmount] = useState(80000);
  const [fileStatus, setFileStatus] = useState("Single");
  const [numberOfChildrenUnder17, setNumberOfChildrenUnder17] = useState(0);
  const [numberOfOtherDependents, setNumberOfOtherDependents] = useState(0);
  const [otherIncome, setOtherIncome] = useState(0);
  const [stateIncomeRate, setStateIncomeRate] = useState(0);
  const [cityIncomeRate, setCityIncomeRate] = useState(0);
  const [pretaxDeductionsWithheld, setPretaxDeductionsWithheld] =
    useState(6000);
  const [deductionsNotWithheld, setDeductionsNotWithheld] = useState(0);
  const [itemizedDeductions, setItemizedDeductions] = useState(0);
  const [secondJobIncome, setSecondJobIncome] = useState(0);
  const [thirdJobIncome, setThirdJobIncome] = useState(0);
  const [isSpouseIncome, setIsSpouseIncome] = useState({
    name: "No",
    value: false,
  });
  const [isSelfEmployOrIndependent, setIsSelfEmployOrIndependent] = useState({
    name: "No",
    value: false,
  });

  const ConformationRadio = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const [grossPay, setGrossPay] = useState(0);
  const [federalIncomeTax, setFederalIncomeTax] = useState(0);
  const [socialSecurityTax, setSocialSecurityTax] = useState(0);
  const [medicareTax, setMedicareTax] = useState(0);
  const [stateIncomeTax, setStateIncomeTax] = useState(0);
  const [cityIncomeTax, setCityIncomeTax] = useState(0);
  const [deductionsWithheld, setDeductionsWithheld] = useState(0);
  const [finalPayCheck, setFinalPayCheck] = useState(0);

  useEffect(() => {
    const payPeriodsPerYear = {
      Daily: 260,
      Weekly: 52,
      "Bi-weekly": 26,
      "Semi-monthly": 24,
      Monthly: 12,
      Quarterly: 4,
      "Semi-Annually": 2,
      Annually: 1,
    }[payFrequency];

    function calculateSocialSecurityTax() {
      const socialSecurityRateEmployee = 0.062;
      const socialSecurityRateEmployer = 0.062;
      const socialSecurityMaxWage = 168600;

      // Determine taxable income for Social Security tax
      let taxableIncome = Math.min(
        parseFloat(payAmount) / +payPeriodsPerYear,
        +socialSecurityMaxWage / +payPeriodsPerYear
      );

      // Calculate Social Security tax (employee portion)
      let socialSecurityTaxEmployee =
        +taxableIncome * +socialSecurityRateEmployee;

      // Calculate Social Security tax (employer portion, if needed)
      let socialSecurityTaxEmployer =
        +taxableIncome * +socialSecurityRateEmployer;

      // Total Social Security tax (employee + employer portions)
      let totalSocialSecurityTax =
        +socialSecurityTaxEmployee + +socialSecurityTaxEmployer;

      // Calculate Social Security tax per pay period
      let socialSecurityTaxPerPeriod = +totalSocialSecurityTax;

      return isSelfEmployOrIndependent?.value
        ? +socialSecurityTaxPerPeriod
        : +socialSecurityTaxPerPeriod / 2;
    }
    function calculateMedicareTax() {
      // Constants
      const medicareRate = 0.0145;
      const additionalMedicareRate = 0.009;

      // Additional Medicare thresholds based on filing status
      const additionalMedicareThresholds = {
        Single: 200000,
        "Filling Jointly or Qualified Widow": 250000,
        "Married Filing Separately": 125000,
        "Head of Household": 200000,
      };

      // Determine the threshold based on filing status
      const additionalMedicareThreshold =
        additionalMedicareThresholds[fileStatus] || 200000;
      // Calculate the standard Medicare tax
      let medicareTax = +payAmount * medicareRate;

      // Calculate the additional Medicare tax if applicable
      if (+payAmount > additionalMedicareThreshold) {
        let additionalIncome = +payAmount - additionalMedicareThreshold;
        let additionalMedicareTax = additionalIncome * additionalMedicareRate;
        medicareTax += additionalMedicareTax;
      }

      return +medicareTax / +payPeriodsPerYear;
    }

    function getStandardDeduction(fileStatus) {
      switch (fileStatus) {
        case "Single":
        case "Married Filling Separately":
          return 14600;
        case "Filling Jointly or Qualified Widow":
          return 29200;
        case "Head of Household":
          return 21900;
        default:
          return 14600;
      }
    }

    function calculateFederalIncomeTax(taxableIncome, fileStatus) {
      if (
        fileStatus === "Single" ||
        fileStatus === "Married Filling Separately"
      ) {
        return calculateTaxForSingle(taxableIncome);
      } else if (fileStatus === "Filling Jointly or Qualified Widow") {
        return calculateTaxForMarriedJoint(taxableIncome);
      } else if (fileStatus === "Head of Household") {
        return calculateTaxForHeadOfHousehold(taxableIncome);
      }
      return calculateTaxForSingle(taxableIncome); // Default to single if status is unknown
    }

    function calculateTaxForSingle(taxableIncome) {
      if (taxableIncome <= 11600) return taxableIncome * 0.1;
      if (taxableIncome <= 47150) return 1160 + (taxableIncome - 11600) * 0.12;
      if (taxableIncome <= 100525) return 5426 + (taxableIncome - 47150) * 0.22;
      if (taxableIncome <= 191950)
        return 17168.5 + (taxableIncome - 100525) * 0.24;
      if (taxableIncome <= 243725)
        return 39110.5 + (taxableIncome - 191950) * 0.32;
      if (taxableIncome <= 609350)
        return 55678.5 + (taxableIncome - 243725) * 0.35;
      return 183647.25 + (taxableIncome - 609350) * 0.37;
    }

    function calculateTaxForMarriedJoint(taxableIncome) {
      if (taxableIncome <= 23200) return taxableIncome * 0.1;
      if (taxableIncome <= 94300) return 2320 + (taxableIncome - 23200) * 0.12;
      if (taxableIncome <= 201050)
        return 10852 + (taxableIncome - 94300) * 0.22;
      if (taxableIncome <= 383900)
        return 34337 + (taxableIncome - 201050) * 0.24;
      if (taxableIncome <= 487450)
        return 78221 + (taxableIncome - 383900) * 0.32;
      if (taxableIncome <= 731200)
        return 111357 + (taxableIncome - 487450) * 0.35;
      return 196669.5 + (taxableIncome - 731200) * 0.37;
    }

    function calculateTaxForHeadOfHousehold(taxableIncome) {
      if (taxableIncome <= 16550) return taxableIncome * 0.1;
      if (taxableIncome <= 63100) return 1655 + (taxableIncome - 16550) * 0.12;
      if (taxableIncome <= 100500) return 7241 + (taxableIncome - 63100) * 0.22;
      if (taxableIncome <= 191950)
        return 15469 + (taxableIncome - 100500) * 0.24;
      if (taxableIncome <= 243700)
        return 37417 + (taxableIncome - 191950) * 0.32;
      if (taxableIncome <= 609350)
        return 53977 + (taxableIncome - 243700) * 0.35;
      return 181954.5 + (taxableIncome - 609350) * 0.37;
    }

    function calculatePaycheckFederalTax() {
      // Calculate annual gross income
      const annualGrossIncome =
        +payAmount + +otherIncome + +secondJobIncome + +thirdJobIncome;

      // Calculate annual taxable income
      const adjustedGrossIncome =
        +annualGrossIncome -
        +pretaxDeductionsWithheld -
        +deductionsNotWithheld -
        +itemizedDeductions;
      const standardDeduction = getStandardDeduction(fileStatus);
      const taxableIncome =
        +adjustedGrossIncome -
        +standardDeduction -
        +numberOfChildrenUnder17 * 2000 -
        +numberOfOtherDependents * 500;

      // Calculate federal income tax
      const federalIncomeTax = calculateFederalIncomeTax(
        +taxableIncome,
        fileStatus
      );

      // Calculate self-employment tax if applicable
      let selfEmploymentTax = 0;
      if (isSelfEmployOrIndependent?.value) {
        selfEmploymentTax = +annualGrossIncome * 0.153;
      }

      // Calculate total federal tax including self-employment tax if applicable
      const totalFederalTax =
        +federalIncomeTax + +selfEmploymentTax / +payPeriodsPerYear;

      // Calculate federal tax per paycheck
      const federalTaxPerPaycheck = +totalFederalTax / +payPeriodsPerYear;

      return federalTaxPerPaycheck;
    }

    function calculateTakeHomeSalary() {
      // Convert pay frequency to number of pay periods per year

      let grossSalaryPerTimePeriod = +payAmount / +payPeriodsPerYear;
      let federalTaxAmount = calculatePaycheckFederalTax();
      let deductionsWithheldPerPeriod =
        pretaxDeductionsWithheld / +payPeriodsPerYear;
      let cityIncomeTaxAmount =
        +grossSalaryPerTimePeriod * (+cityIncomeRate / 100);
      let stateIncomeTaxAmount =
        +grossSalaryPerTimePeriod * (+stateIncomeRate / 100);
      let socialTaxAmount = calculateSocialSecurityTax();
      let medicareTaxAmount = calculateMedicareTax();

      // Update states
      setGrossPay(+grossSalaryPerTimePeriod);
      setFederalIncomeTax(+federalTaxAmount);
      setSocialSecurityTax(+socialTaxAmount);
      setMedicareTax(+medicareTaxAmount);
      setStateIncomeTax(+stateIncomeTaxAmount);
      setCityIncomeTax(+cityIncomeTaxAmount);
      setDeductionsWithheld(+deductionsWithheldPerPeriod);
      setFinalPayCheck(
        +grossSalaryPerTimePeriod -
          (+federalTaxAmount +
            +socialTaxAmount +
            +medicareTaxAmount +
            +stateIncomeTaxAmount +
            +cityIncomeTaxAmount +
            +deductionsWithheldPerPeriod)
      );
    }
    calculateTakeHomeSalary();
  }, [
    payFrequency,
    payAmount,
    fileStatus,
    numberOfChildrenUnder17,
    numberOfOtherDependents,
    otherIncome,
    stateIncomeRate,
    cityIncomeRate,
    pretaxDeductionsWithheld,
    deductionsNotWithheld,
    itemizedDeductions,
    isSpouseIncome,
    isSelfEmployOrIndependent,
    secondJobIncome,
    thirdJobIncome,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <InputComponent
                label="Your job income (salary)"
                isInput={true}
                flag="dollar"
                value={payAmount}
                setState={setPayAmount}
                max={10000000000}
              />

              <SelectBoxComponent
                value={payFrequency}
                setValue={setPayFrequency}
                data={[
                  "Daily",
                  "Weekly",
                  "Bi-weekly",
                  "Semi-monthly",
                  "Monthly",
                  "Quarterly",
                  "Semi-Annually",
                  "Annually",
                ]}
                placeholder="Select pay frequency"
                label="Pay frequency"
              />
              <SelectBoxComponent
                value={fileStatus}
                setValue={setFileStatus}
                data={[
                  "Single",
                  "Filling Jointly or Qualified Widow",
                  "Married Filling Separately",
                  "Head of Household",
                ]}
                placeholder="Select file status"
                label="File status"
              />
              <InputComponent
                label="Number of children under age 17"
                isInput={true}
                value={numberOfChildrenUnder17}
                setState={setNumberOfChildrenUnder17}
                max={100}
              />
              <InputComponent
                label="Number of other dependents	"
                isInput={true}
                value={numberOfOtherDependents}
                setState={setNumberOfOtherDependents}
                max={100}
              />
              <InputComponent
                label="Other income (not from jobs)"
                isInput={true}
                flag="dollar"
                value={otherIncome}
                setState={setOtherIncome}
                max={10000000000}
              />
              <InputComponent
                label="Pretax deductions withheld"
                isInput={true}
                flag="dollar"
                value={pretaxDeductionsWithheld}
                setState={setPretaxDeductionsWithheld}
                max={10000000000}
              />
              <InputComponent
                label="Deductions not withheld"
                isInput={true}
                flag="dollar"
                value={deductionsNotWithheld}
                setState={setDeductionsNotWithheld}
                max={10000000000}
              />
              <InputComponent
                label="Itemized deductions"
                isInput={true}
                flag="dollar"
                value={itemizedDeductions}
                setState={setItemizedDeductions}
                max={10000000000}
              />
              <RadioButtonComponent
                label={"Has 2nd, 3rd job income or spouse has income?"}
                data={ConformationRadio}
                defaultValue={false}
                valueSetter={setIsSpouseIncome}
                row={true}
                type="yes-no"
              />
              {isSpouseIncome?.value && (
                <Grid className="extra-input-for-old-job calculation-part">
                  <InputComponent
                    label="2nd job income"
                    isInput={true}
                    flag="dollar"
                    value={secondJobIncome}
                    setState={setSecondJobIncome}
                    max={10000000000}
                  />
                  <InputComponent
                    label="3rd job income"
                    isInput={true}
                    flag="dollar"
                    value={thirdJobIncome}
                    setState={setThirdJobIncome}
                    max={10000000000}
                  />
                </Grid>
              )}
              <InputComponent
                isInput={true}
                flag="percentage"
                label="State income tax rate"
                setState={setStateIncomeRate}
                value={stateIncomeRate}
                min={0}
                max={100}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="City income tax rate"
                setState={setCityIncomeRate}
                value={cityIncomeRate}
                min={0}
                max={100}
              />
              <RadioButtonComponent
                label={"Are you self-employed or an independent contractor?"}
                data={ConformationRadio}
                defaultValue={false}
                valueSetter={setIsSelfEmployOrIndependent}
                row={true}
                type="yes-no"
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div">
              <InputComponent
                label="Gross Pay"
                isInput={false}
                flag="dollar"
                value={grossPay}
              />
              <InputComponent
                Taxes
                label="Federal Income Tax"
                flag="dollar"
                isInput={false}
                value={federalIncomeTax}
              />
              <InputComponent
                label="Social Security Tax"
                isInput={false}
                flag="dollar"
                value={socialSecurityTax}
              />
              <InputComponent
                label="Medicare Tax"
                isInput={false}
                flag="dollar"
                value={medicareTax}
              />
              <InputComponent
                label="State Income Tax"
                isInput={false}
                flag="dollar"
                value={stateIncomeTax}
              />
              <InputComponent
                Taxes
                label="City Income Tax"
                flag="dollar"
                isInput={false}
                value={cityIncomeTax}
              />
              <InputComponent
                label="Deductions withheld"
                isInput={false}
                flag="dollar"
                value={deductionsWithheld}
              />

              <InputComponent
                label="Final Pay Check"
                isInput={false}
                flag="dollar"
                value={finalPayCheck}
                color="green"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +grossPay, label: "Gross Pay" },
                {
                  id: 1,
                  value: +federalIncomeTax,
                  label: "Federal Income Tax",
                },
                {
                  id: 2,
                  value: +socialSecurityTax,
                  label: "Social Security Tax",
                },
                { id: 3, value: +medicareTax, label: "Medicare Tax" },
                { id: 4, value: +stateIncomeTax, label: "State Income Tax" },
                { id: 5, value: +cityIncomeTax, label: "City Income Tax" },
                {
                  id: 6,
                  value: +deductionsWithheld,
                  label: "Deductions withheld",
                },
                { id: 7, value: +finalPayCheck, label: "Final Pay Check" },
              ]}
              flag="usa-income"
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
