import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import InputComponent from "../../Common/InputComponent/InputComponent";
import PieChartComponent from "../../Common/PieChart/PieChart";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";

function HRACalc() {
  const [basicSalaryReceivedPerAnnum, setBasicSalaryReceivedPerAnnum] =
    useState(500000);
  const [dearnessReceivedPerAnnum, setDearnessReceivedPerAnnum] =
    useState(90000);
  const [HraReceivedPerAnnum, setHraReceivedPerAnnum] = useState(100000);
  const [totalRentPaidPerAnnum, setTotalRentPaidPerAnnum] = useState(120000);
  const [isMetroCity, setIsMetroCity] = useState("Yes");
  const [taxableHRA, setTaxableHRA] = useState(0);
  const [exemptedHRA, setExemptedHRA] = useState(0);

  useEffect(() => {
    const calculateResult = (
      basicSalary,
      da,
      hraReceived,
      totalRentPaid,
      metro
    ) => {
      const metroFactor = metro === "Yes" ? 0.5 : 0.4;

      const maxHRA = +hraReceived;

      const actualRentPaid = +totalRentPaid - (+basicSalary + +da) * 0.1;

      const option1 = +maxHRA;
      const option2 = (+basicSalary + +da) * +metroFactor;
      const option3 = +actualRentPaid;

      const taxableHRA = Math.min(option1, option2, option3);
      const exemptedHRA = +hraReceived - +taxableHRA;

      return {
        exemptedHRA: exemptedHRA,
        taxableHRA: taxableHRA,
      };
    };

    const { exemptedHRA, taxableHRA } = calculateResult(
      +basicSalaryReceivedPerAnnum,
      +dearnessReceivedPerAnnum,
      +HraReceivedPerAnnum,
      +totalRentPaidPerAnnum,
      isMetroCity
    );
    setExemptedHRA(+taxableHRA.toFixed(2));
    setTaxableHRA(+exemptedHRA.toFixed(2));
  }, [
    dearnessReceivedPerAnnum,
    HraReceivedPerAnnum,
    totalRentPaidPerAnnum,
    isMetroCity,
    basicSalaryReceivedPerAnnum,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid>
          <Grid container>
            <Grid item xs={12} md={7} className="calculation-part">
              <Grid>
                <InputComponent
                  isInput={true}
                  flag="rupee"
                  label="Basic salary received per annum"
                  value={basicSalaryReceivedPerAnnum}
                  setState={setBasicSalaryReceivedPerAnnum}
                  max={10000000000}
                />
                <InputComponent
                  isInput={true}
                  flag="rupee"
                  label="Dearness allowance (DA) received per annum"
                  value={dearnessReceivedPerAnnum}
                  setState={setDearnessReceivedPerAnnum}
                  max={10000000000}
                />
                <InputComponent
                  isInput={true}
                  flag="rupee"
                  label="HRA received per annum"
                  value={HraReceivedPerAnnum}
                  setState={setHraReceivedPerAnnum}
                  max={10000000000}
                />
                <InputComponent
                  isInput={true}
                  flag="rupee"
                  label="Total rent paid per annum"
                  value={totalRentPaidPerAnnum}
                  setState={setTotalRentPaidPerAnnum}
                  max={10000000000}
                />
                <SelectBoxComponent
                  value={isMetroCity}
                  label="Do you live in Delhi, Mumbai, Chennai or Kolkata?"
                  setValue={setIsMetroCity}
                  data={["Yes", "No"]}
                  placeholder="Do you live in a metro city?"
                />
              </Grid>
              <Grid className="result-label">
                <Typography>Result</Typography>
              </Grid>
              <Grid className="result-common-div">
                <InputComponent
                  label="Exempted HRA"
                  isInput={false}
                  value={exemptedHRA}
                />
                <InputComponent
                  label="Taxable HRA"
                  isInput={false}
                  value={taxableHRA}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={5} sx={{ display: "flex" }}>
              <PieChartComponent
                data={[
                  { id: 0, value: +exemptedHRA, label: "Exempted HRA" },
                  { id: 1, value: +taxableHRA, label: "Taxable HRA" },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default HRACalc;
