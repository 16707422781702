import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import InputComponent from "../../Common/InputComponent/InputComponent";

function DownPaymentCalc() {
  const [upfrontCash, setUpfrontCash] = useState(100000);
  const [dp1, setDp1] = useState(20);
  const [icc1, setIcc1] = useState(3);
  const [interestRate1, setInterestRate1] = useState(6.414);
  const [loanTerm1, setLoanTerm1] = useState(30);
  const [result1, setResult1] = useState({
    HomePrice: 0,
    DownPayment: 0,
    ClosingCosts: 0,
    LoanAmount: 0,
    MonthlyPayment: 0,
  });

  const [homePrice, setHomePrice] = useState(500000);
  const [dp2, setDp2] = useState(20);
  const [icc2, setIcc2] = useState(3);
  const [interestRate2, setInterestRate2] = useState(6.414);
  const [loanTerm2, setLoanTerm2] = useState(30);
  const [result2, setResult2] = useState({
    totalValue: 0,
    DownPayment: 0,
    ClosingCosts: 0,
    LoanAmount: 0,
    MonthlyPayment: 0,
  });

  const [upfrontCash3, setUpfrontCash3] = useState(100000);
  const [homePrice3, setHomePrice3] = useState(500000);
  const [icc3, setIcc3] = useState(3);
  const [interestRate3, setInterestRate3] = useState(6.414);
  const [loanTerm3, setLoanTerm3] = useState(30);
  const [result3, setResult3] = useState({
    DownPaymentPercentage: 0,
    DownPayment: 0,
    ClosingCosts: 0,
    LoanAmount: 0,
    MonthlyPayment: 0,
  });

  useEffect(() => {
    const calculateFirst = () => {
      const downPaymentRate = +dp1 / 100;
      const closingCostRate = +icc1 / 100;
      const annualInterestRate = +interestRate1 / 100;

      const homePrice = +upfrontCash / (downPaymentRate + closingCostRate);

      const downPayment = homePrice * downPaymentRate;

      const closingCosts = homePrice * closingCostRate;

      const loanAmount = homePrice - downPayment;

      const monthlyInterestRate = annualInterestRate / 12;

      const numberOfPayments = +loanTerm1 * 12;

      const monthlyPayment =
        (loanAmount * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

      setResult1({
        HomePrice: homePrice.toFixed(2),
        DownPayment: downPayment.toFixed(2),
        ClosingCosts: closingCosts.toFixed(2),
        LoanAmount: loanAmount.toFixed(2),
        MonthlyPayment: monthlyPayment.toFixed(2),
      });
    };
    const calculateSecond = () => {
      const downPaymentRate = +dp2 / 100;
      const closingCostRate = +icc2 / 100;
      const interestRateMonthly = +interestRate2 / 100 / 12;
      const loanTermMonths = +loanTerm2 * 12;

      const downPayment = homePrice * downPaymentRate;

      const closingCosts = homePrice * closingCostRate;

      const loanAmount = homePrice - downPayment;

      const monthlyPayment =
        (loanAmount * interestRateMonthly) /
        (1 - Math.pow(1 + interestRateMonthly, -loanTermMonths));

      const totalCost = downPayment + closingCosts;
      setResult2({
        totalValue: totalCost,
        DownPayment: downPayment,
        ClosingCosts: closingCosts,
        LoanAmount: loanAmount,
        MonthlyPayment: monthlyPayment,
      });
    };

    const calculateThird = () => {
      const closingCostRate = +icc3 / 100;
      const interestRateMonthly = +interestRate3 / 100 / 12;
      const loanTermMonths = +loanTerm3 * 12;

      // Calculate Closing Costs
      const closingCosts = +homePrice3 * closingCostRate;

      // Calculate Down Payment
      const downPayment = Math.min(+upfrontCash3, +homePrice3) - closingCosts;

      // Calculate Loan Amount
      const loanAmount = +homePrice3 - downPayment;

      // Calculate Monthly Payment using the formula for an amortizing loan
      const monthlyPayment =
        (loanAmount * interestRateMonthly) /
        (1 - Math.pow(1 + interestRateMonthly, -loanTermMonths));

      // Calculate Down Payment Percentage
      const downPaymentPercentage = (+downPayment / +homePrice3) * 100;
      setResult3({
        DownPaymentPercentage: downPaymentPercentage.toFixed(2),
        DownPayment: downPayment.toFixed(2),
        ClosingCosts: closingCosts.toFixed(2),
        LoanAmount: loanAmount.toFixed(2),
        MonthlyPayment: monthlyPayment.toFixed(2),
      });
    };
    calculateFirst();
    calculateSecond();
    calculateThird();
  }, [
    upfrontCash,
    dp1,
    icc1,
    interestRate1,
    loanTerm1,
    homePrice,
    dp2,
    icc2,
    interestRate2,
    loanTerm2,
    upfrontCash3,
    homePrice3,
    icc3,
    interestRate3,
    loanTerm3,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container gap={2.5}>
          <Grid item xs={12} md={5.5} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              1. Use the Upfront Cash Available
            </Typography>
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Upfront Cash Available"
                value={upfrontCash}
                min={1}
                setState={setUpfrontCash}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Down Payment"
                value={dp1}
                min={0}
                setState={setDp1}
                max={100}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Including Closing Cost"
                value={icc1}
                min={0}
                setState={setIcc1}
                max={100}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Interest Rate"
                value={interestRate1}
                min={0}
                setState={setInterestRate1}
                max={100}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Loan Term"
                value={loanTerm1}
                min={0}
                setState={setLoanTerm1}
                max={100}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Home Price"
                isInput={false}
                value={result1?.HomePrice}
              />

              <InputComponent
                label="Down Payment"
                isInput={false}
                value={result1?.DownPayment}
              />
              <InputComponent
                label="Closing Costs"
                isInput={false}
                value={result1?.ClosingCosts}
              />
              <InputComponent
                label="Loan Amount"
                isInput={false}
                value={result1?.LoanAmount}
              />
              <InputComponent
                label="Monthly Payment"
                isInput={false}
                value={result1?.MonthlyPayment}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={5.5} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              2. Use the Home Price
            </Typography>
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Home Price"
                value={homePrice}
                min={1}
                setState={setHomePrice}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Down Payment"
                value={dp2}
                min={0}
                setState={setDp2}
                max={100}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Including Closing Cost"
                value={icc2}
                min={0}
                setState={setIcc2}
                max={100}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Interest Rate"
                value={interestRate2}
                min={0}
                setState={setInterestRate2}
                max={100}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Loan Term"
                value={loanTerm2}
                min={0}
                setState={setLoanTerm2}
                max={100}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Down Payment"
                isInput={false}
                value={result2?.DownPayment}
              />
              <InputComponent
                label="Closing Costs"
                isInput={false}
                value={result2?.ClosingCosts}
              />
              <InputComponent
                label="Down Payment + Closing Costs"
                isInput={false}
                value={result2?.totalValue}
              />
              <InputComponent
                label="Loan Amount"
                isInput={false}
                value={result2?.LoanAmount}
              />
              <InputComponent
                label="Monthly Payment"
                isInput={false}
                value={result2?.MonthlyPayment}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={5.5} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              3. Use the Home Price and Upfront Cash Available
            </Typography>
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Home Price"
                value={homePrice3}
                min={1}
                setState={setHomePrice3}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Upfront Cash Available"
                value={upfrontCash3}
                min={1}
                setState={setUpfrontCash3}
                max={10000000000}
              />

              <InputComponent
                isInput={true}
                flag="percentage"
                label="Including Closing Cost"
                value={icc3}
                min={0}
                setState={setIcc3}
                max={100}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Interest Rate"
                value={interestRate3}
                min={0}
                setState={setInterestRate3}
                max={100}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Loan Term"
                value={loanTerm3}
                min={0}
                setState={setLoanTerm3}
                max={100}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Down Payment"
                isInput={false}
                value={result3?.DownPayment}
              />

              <InputComponent
                label="Down Payment Percentage"
                isInput={false}
                flag="percentage"
                value={result3?.DownPaymentPercentage}
              />
              <InputComponent
                label="Closing Costs"
                isInput={false}
                value={result3?.ClosingCosts}
              />
              <InputComponent
                label="Loan Amount"
                isInput={false}
                value={result3?.LoanAmount}
              />
              <InputComponent
                label="Monthly Payment"
                isInput={false}
                value={result3?.MonthlyPayment}
              />
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default DownPaymentCalc;
