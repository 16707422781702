import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import "../AgeCalc/AgeCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import DatePickerCommon from "../../Common/Datepicker/DatePickerCommon";
import dayjs from "dayjs";
import dateOfBirthCalcImg from "../../../Assets/ComponentImage/dateOfBirthCalc.jpg";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function DateOfBirthCalc() {
  const [dob, setDob] = useState();
  const [fromAgeCount, setFromAgeCount] = useState(dayjs(new Date()));
  const [resultData, setResultData] = useState([]);
  

  useEffect(() => {
    if (dob && fromAgeCount && dob <= fromAgeCount) {
      const ageInMonths = fromAgeCount.diff(dob, "month");
      const ageInDays = fromAgeCount.diff(dob, "day");
      const ageInYears = fromAgeCount.diff(dob, "year");

      let result = {
        ageInMonths: `${ageInMonths} Months`,
        ageInYears: `${ageInYears} Years`,
        ageInDays: `${ageInDays} Days`,
      };
      setResultData(result);
    } else {
      setResultData({
        ageInMonths: "0 Months",
        ageInYears: "0 Years",
      });
    }
  }, [dob, fromAgeCount]);
  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid className="dob-date-picker">
              <DatePickerCommon
                label="Date Of Birth"
                value={dob}
                setValue={setDob}
                fromAgeCount={fromAgeCount}
              />
              <DatePickerCommon
                label="Count from this current date"
                value={fromAgeCount}
                setValue={setFromAgeCount}
                disabled={true}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div">
              <InputComponent
                label="Age In Years"
                isInput={false}
                value={resultData?.ageInYears || "0 Years"}
                date={true}
              />
              <InputComponent
                label="Age In Months"
                isInput={false}
                value={resultData?.ageInMonths || "0 Months"}
                date={true}
              />
              <InputComponent
                label="Age In Days"
                isInput={false}
                value={resultData?.ageInDays || "0 Days"}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={0} md={1} />

          <Grid item xs={0} md={4} sx={{ py: 2 }}>
            <img
              src={dateOfBirthCalcImg}
              alt="date-of-birth-calculator"
              className="age-calc-assets"
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default DateOfBirthCalc;
