import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DatePickerCommon from "../../Common/Datepicker/DatePickerCommon";
import dayjs from "dayjs";
import ComponentHeader from "../Header/ComponentHeader";
import DateView from "../../Common/CalenderView/CalenderView";
import {
  DateHelper,
  formatDate,
  modifyDateForSubtractCalc,
} from "../../../Utils/dateHelper";
import TableComponent from "../../Common/TableComponent/TableComponent";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import {
  returnHelmetData
} from "../../../Utils/CommonFunction";
import InputComponent from "../../Common/InputComponent/InputComponent";
import "./DateCalc.scss";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

export default function DateCalc() {
  const [date1, setDate1] = useState(dayjs());
  const [date2, setDate2] = useState(dayjs());
  const [date3, setDate3] = useState(dayjs());
  const [operation, setOperation] = useState("+");

  const [tableView, setTableView] = useState(false);
  const [result, setResult] = useState(false);
  const [result2, setResult2] = useState(false);
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [week, setWeek] = useState(0);
  const [day, setDay] = useState(0);
  const [businessDay, setBusinessDay] = useState(null);
  const [radioValue, setRadioValue] = useState(0);

  const [holidays, setHolidays] = useState([
    { name: "New Year's Day", date: [1, 0], checked: true },
    { name: "Martin Luther King Day", date: [15, 0], checked: true },
    { name: "Presidents' Day", date: [19, 1], checked: true },
    { name: "Memorial Day", date: [27, 4], checked: true },
    { name: "Juneteenth Day", date: [19, 5], checked: true },
    { name: "Independence Day", date: [4, 6], checked: true },
    { name: "Labor Day", date: [2, 8], checked: true },
    { name: "Columbus Day", date: [14, 9], checked: true },
    { name: "Veteran's Day", date: [11, 10], checked: true },
    { name: "Thanksgiving", date: [28, 10], checked: true },
    { name: "Christmas", date: [25, 11], checked: true },
    { name: "Christmas Eve", date: [24, 11], checked: true },
    { name: "New Year's Eve", date: [30, 11], checked: true },
  ]);


  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);

    if (!event.target.checked) {
      setBusinessDay(null);
    } else {
      setBusinessDay(0);
    }
  };

  useEffect(() => {
    setResult(() => DateHelper(date1, date2));
  }, [date1, date2]);

  useEffect(() => {
    setResult2(() =>
      modifyDateForSubtractCalc(
        operation,
        date3,
        {
          years: year,
          months: month,
          weeks: week,
          days: day,
        },
        businessDay,
        radioValue === "1" ? holidays.filter((holiday) => holiday.checked) : []
      )
    );
  }, [
    date3,
    checked,
    businessDay,
    year,
    radioValue,
    week,
    month,
    day,
    operation,
    holidays,
  ]);

  const handleChangeRadio = (event) => {
    setRadioValue(event.target.value);
  };

  const handleHolidayCheckbox = (e, index) => {
    setHolidays((prev) =>
      prev.map((holiday, i) =>
        i === index ? { ...holiday, checked: e.target.checked } : holiday
      )
    );
  };
  return (
    <>
      {returnHelmetData()}

      <Box
        sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}
        className="date-calc-container"
      >
        <ComponentHeader />

        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12} md={6.9} className="calculation-part ">
            <Grid>
              <Typography variant="h6" className="common-sub-heading-calc">
                {" "}
                1. Days Between Two Dates
              </Typography>
              <Typography className="date-calc-sub-title">
                {" "}
                Find the number of years, months, weeks, and days between dates.
              </Typography>

              <Grid item xs={12} className="date-calc-open-date-picker">
                <DateView date={date1} valueSetter={setDate1} />
                <DateView date={date2} valueSetter={setDate2} />
              </Grid>

              <DatePickerCommon
                label="Start Date"
                value={date1}
                setValue={setDate1}
                enableFutureDate
              />
              <DatePickerCommon
                label="End Date"
                value={date2}
                setValue={setDate2}
                enableFutureDate
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              {" "}
              <Typography className="gpa-final-result">
                Difference between {formatDate(date1)} and {formatDate(date2)}{" "}
                is <b>{result.dateString || 0}</b>
              </Typography>
              <InputComponent
                label="Business days"
                isInput={false}
                value={result?.workingDays || 0}
                date={true}
              />
              <InputComponent
                label="Holidays"
                isInput={false}
                value={result.holidayList?.length || 0}
                date={true}
              />
            </Grid>
            <Divider sx={{ my: 2 }} />

            <Grid className="data-table-container">
              <Grid className="table-toggle-div">
                <Typography variant="h6" className="common-sub-heading-calc">
                  Holiday Details
                </Typography>
                <Tooltip
                  title={
                    tableView ? "click to close table" : "click to open table"
                  }
                  placement="top"
                  arrow
                >
                  <span>
                    <img
                      src={plusIcon}
                      alt="plusIcon"
                      onClick={() => setTableView(!tableView)}
                      style={{
                        transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                      }}
                      className="add-icon-table"
                    />
                  </span>
                </Tooltip>
              </Grid>
              {tableView && result.holidayList && (
                <Grid>
                  <Grid>
                    <TableComponent
                      rows={result.holidayList}
                      headerData={["Holiday", "Date"]}
                      isMinWidth={300}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4.9} className="calculation-part ">
            <Grid>
              <Typography variant="h6" className="common-sub-heading-calc">
                {" "}
                2. Add to or Subtract from a Date
              </Typography>

              <DatePickerCommon
                label="Start Date"
                value={date3}
                setValue={setDate3}
                enableFutureDate
              />
              <SelectBoxComponent
                value={operation}
                setValue={setOperation}
                data={["+", "-"]}
                placeholder="Select add or subtract"
              />
              {checked ? (
                <>
                  <InputComponent
                    isInput={true}
                    label="Business Days"
                    value={businessDay}
                    setState={setBusinessDay}
                    max={999999}
                  />
                </>
              ) : (
                <>
                  {" "}
                  <InputComponent
                    isInput={true}
                    label="Years"
                    value={year}
                    setState={setYear}
                    max={9999}
                  />
                  <InputComponent
                    isInput={true}
                    label="Months"
                    value={month}
                    setState={setMonth}
                    max={1000000}
                  />
                  <InputComponent
                    isInput={true}
                    label="Weeks"
                    value={week}
                    setState={setWeek}
                    max={200}
                  />
                  <InputComponent
                    isInput={true}
                    label="Days"
                    value={day}
                    setState={setDay}
                    max={9999}
                  />
                </>
              )}

              <Grid className="radio-btn-grp">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    checked={checked}
                    onClick={handleChange}
                    label="Calculate in business days"
                  />
                </FormGroup>
                {checked && (
                  <>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={radioValue}
                        onChange={handleChangeRadio}
                      >
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="Exclude weekend only"
                        />
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="Exclude weekend and holidays below"
                        />
                      </RadioGroup>
                    </FormControl>{" "}
                    {radioValue === "1" && (
                      <FormGroup className="holiday-list-div">
                        {holidays?.map((value, index) => (
                          <FormControlLabel
                            control={<Checkbox />}
                            checked={value?.checked}
                            label={value?.name}
                            onClick={(e) => handleHolidayCheckbox(e, index)}
                            key={index}
                          />
                        ))}
                      </FormGroup>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              <Typography
                sx={{ mt: "24px !important" }}
                className="gpa-final-result"
              >
                {result2?.result}
              </Typography>

              {checked && (
                <>
                  {radioValue === "1" && (
                    <InputComponent
                      label="Holidays"
                      isInput={false}
                      value={result2?.holidays || "0"}
                      date={true}
                    />
                  )}
                  <InputComponent
                    label="Weekend days"
                    isInput={false}
                    value={result2?.weekoffDays || "0"}
                    date={true}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
