import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import "./AutoLeaseCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import { InterestTypeIntoLease } from "../../../Utils/calorieHelper";
import PieChartComponent from "../../Common/PieChart/PieChart";
import TabbingComponent from "../../Common/TabbingRadio/TabbingComponent";

function AutoLeaseCalc() {
  const [autoPrice, setAutoPrice] = useState(50000);
  const [monthlyPay, setMonthlyPay] = useState(617.99);
  const [initialPayType, setInitialPayType] = useState("Total Price");
  const [leaseTerm, setLeaseTerm] = useState(3);
  const [downPayment, setDownPayment] = useState(10000);
  const [interestRate, setInterestRate] = useState(4.992);
  const [tradeInValue, setTradeInValue] = useState(0);
  const [saleTax, setSaleTax] = useState(7);
  const [residualValue, setResidualValue] = useState(24000);
  const [interestRateType, setInterestRateType] = useState({
    label: "Interest Rate ( % )",
    value: "InterestR",
  });
  const [monthlyLeasePayment, setMonthlyLeasePayment] = useState(0);
  const [monthlyDepreciation, setMonthlyDepreciation] = useState(0);
  const [monthlyInterest, setMonthlyInterest] = useState(0);
  const [monthlyTax, setMonthlyTax] = useState(0);
  const [upfrontPayment, setUpfrontPayment] = useState(0);
  const [moneyFactorValue, setMoneyFactorValue] = useState(0.00208);
  const [moneyFactorEquivalentAPR, setMoneyFactorEquivalentAPR] = useState(0);
  const [total36LeasePayments, setTotal36LeasePayments] = useState(0);
  const [totalCostToOwnAfterLeaseEnds, setTotalCostToOwnAfterLeaseEnds] =
    useState(0);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [saleTaxValue, setSaleTaxValue] = useState(0);

  const [totalLoanAmount, setTotalLoanAmount] = useState(0);
  const [totalOf36LoanPayments, setTotalOf36LoanPayments] = useState(0);
  const [totalLoanInterest, setTotalLoanInterest] = useState(0);
  const [totalCostToOwn, setTotalCostToOwn] = useState(0);
  const [vehicleCost, setVehicleCost] = useState(0);

  useEffect(() => {
    if (initialPayType === "Monthly Payment") {
      let moneyFactor;
      if (interestRateType.value === "InterestR") {
        // Convert the annual interest rate to a money factor
        moneyFactor = +interestRate / 2400;
      } else if (interestRateType.value === "moneyF") {
        // Use the provided money factor directly
        moneyFactor = +moneyFactorValue;
      }

      // Calculate the total monthly lease payment excluding tax
      const preTaxMonthlyPayment = monthlyPay / (1 + saleTax / 100);

      // Calculate the monthly depreciation part
      const monthlyDepreciation =
        preTaxMonthlyPayment - residualValue * moneyFactor;

      // Calculate the capitalized cost (AutoPrice) from the monthly depreciation
      const capitalizedCost =
        (monthlyDepreciation * leaseTerm * 12 + residualValue) /
        (1 + moneyFactor * leaseTerm * 12);

      // Now calculate the AutoPrice
      const autoPrice = capitalizedCost + downPayment + tradeInValue;

      setVehicleCost(autoPrice.toFixed(2));
    }
    // Calculate the capitalized cost
    const capitalizedCost = +autoPrice - +downPayment - +tradeInValue;

    // Calculate the monthly depreciation
    const depreciation =
      (+capitalizedCost - +residualValue) / (+leaseTerm * 12);

    // Determine the money factor based on the selected interest rate type
    let moneyFactor;
    if (interestRateType.value === "InterestR") {
      // Convert the annual interest rate to a money factor
      moneyFactor = +interestRate / 2400;
      setMoneyFactorEquivalentAPR(moneyFactor.toFixed(5));
    } else if (interestRateType.value === "moneyF") {
      // Use the provided money factor directly
      moneyFactor = +moneyFactorValue;
      setMoneyFactorEquivalentAPR((moneyFactor * 2400).toFixed(2)); // Convert back to equivalent APR for display
    }

    // Calculate the monthly interest charge
    const interest = (+capitalizedCost + +residualValue) * +moneyFactor;

    // Calculate the monthly tax amount
    const monthlyTax = (+depreciation + +interest) * (+saleTax / 100);

    // Calculate the total monthly lease payment
    const monthlyLeasePayment = +depreciation + +interest + +monthlyTax;

    // Calculate the total lease payments over the term
    const totalLeasePayments = +monthlyLeasePayment * (+leaseTerm * 12);

    // Calculate the total cost to own after lease ends
    const totalCostToOwnAfterLeaseEnds =
      +downPayment + +totalLeasePayments + +residualValue;

    // If purchased under the same conditions
    const totalLoanAmount = +autoPrice - +downPayment - +tradeInValue;
    const monthlyLoanPayment =
      (+totalLoanAmount * (+interestRate / 100 / 12)) /
      (1 - Math.pow(1 + +interestRate / 100 / 12, -(+leaseTerm * 12)));
    const totalLoanPayments = +monthlyLoanPayment * (+leaseTerm * 12);
    const totalLoanInterest = +totalLoanPayments - +totalLoanAmount;
    const totalCostToOwn =
      +downPayment + (+saleTax / 100) * +autoPrice + +totalLoanPayments;

    // Setting state values
    setSaleTaxValue(((saleTax / 100) * autoPrice).toFixed(2));
    setMonthlyLeasePayment(monthlyLeasePayment.toFixed(2));
    setMonthlyDepreciation(depreciation.toFixed(2));
    setMonthlyInterest(interest.toFixed(2));
    setMonthlyTax(monthlyTax.toFixed(2));
    setUpfrontPayment(parseInt(downPayment).toFixed(2));
    setTotal36LeasePayments(totalLeasePayments.toFixed(2));
    setTotalCostToOwnAfterLeaseEnds(
      parseInt(totalCostToOwnAfterLeaseEnds).toFixed(2)
    );
    setMonthlyPayment(monthlyLoanPayment.toFixed(2));

    setTotalLoanAmount(totalLoanAmount.toFixed(2));
    setTotalOf36LoanPayments(totalLoanPayments.toFixed(2));
    setTotalLoanInterest(totalLoanInterest.toFixed(2));
    setTotalCostToOwn(parseInt(totalCostToOwn).toFixed(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    autoPrice,
    leaseTerm,
    downPayment,
    interestRate,
    tradeInValue,
    saleTax,
    residualValue,
    interestRateType,
    monthlyPay,
    initialPayType,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <TabbingComponent
                data={["Total Price", "Monthly Payment"]}
                value={initialPayType}
                setValue={setInitialPayType}
              />
              {initialPayType === "Total Price" ? (
                <InputComponent
                  isInput={true}
                  flag="dollar"
                  label="Auto Price"
                  value={autoPrice}
                  min={1}
                  setState={setAutoPrice}
                  max={10000000000}
                />
              ) : (
                <InputComponent
                  isInput={true}
                  flag="dollar"
                  label="Monthly Payment"
                  value={monthlyPay}
                  min={1}
                  setState={setMonthlyPay}
                  max={10000000000}
                />
              )}
              <InputComponent
                isInput={true}
                flag="year"
                label="Lease Term	"
                setState={setLeaseTerm}
                value={leaseTerm}
                min={0}
                max={199}
              />
              <Grid>
                <RadioButtonComponent
                  label={"Interest Type & Value"}
                  data={InterestTypeIntoLease}
                  defaultValue={"InterestR"}
                  valueSetter={setInterestRateType}
                  row={false}
                />
                {interestRateType.value === "InterestR" ? (
                  <InputComponent
                    isInput={true}
                    flag="percentage"
                    label=""
                    value={interestRate}
                    min={0}
                    setState={setInterestRate}
                    max={99}
                  />
                ) : (
                  <InputComponent
                    isInput={true}
                    value={moneyFactorValue}
                    min={0}
                    setState={setMoneyFactorValue}
                    max={10000000000}
                  />
                )}
              </Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Down Payment	"
                value={downPayment}
                min={0}
                setState={setDownPayment}
                max={10000000000}
              />{" "}
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Trade-in Value "
                value={tradeInValue}
                min={0}
                setState={setTradeInValue}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Sales Tax "
                setState={setSaleTax}
                value={saleTax}
                min={0}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Residual Value "
                value={residualValue}
                min={0}
                setState={setResidualValue}
                max={10000000000}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              {initialPayType === "Total Price" ? (
                <InputComponent
                  label="Monthly Lease Payment"
                  flag="dollar"
                  isInput={false}
                  value={monthlyLeasePayment}
                />
              ) : (
                <InputComponent
                  label="Vehicle Cost"
                  flag="dollar"
                  isInput={false}
                  value={vehicleCost}
                />
              )}
              <InputComponent
                label="Monthly Depreciation"
                flag="dollar"
                isInput={false}
                value={monthlyDepreciation}
              />
              <InputComponent
                label="Monthly Interest"
                flag="dollar"
                isInput={false}
                value={monthlyInterest}
              />
              <InputComponent
                label="Monthly Tax"
                flag="dollar"
                isInput={false}
                value={monthlyTax}
              />
              {interestRateType.value === "moneyF" && (
                <InputComponent
                  label="Money Factor Equivalent APR"
                  flag="percentage"
                  isInput={false}
                  value={moneyFactorEquivalentAPR}
                />
              )}
              <InputComponent
                label="Upfront Payment"
                flag="dollar"
                isInput={false}
                value={upfrontPayment}
              />
              <InputComponent
                label={`Total ${leaseTerm * 12} Lease Payments`}
                flag="dollar"
                isInput={false}
                value={total36LeasePayments}
              />
              <InputComponent
                label="Total Cost to Own After Lease Ends"
                flag="dollar"
                isInput={false}
                value={totalCostToOwnAfterLeaseEnds}
              />
              <Grid className="result-label">
                <Typography>If purchased under the same conditions</Typography>
              </Grid>
              <InputComponent
                label="Monthly Payment"
                flag="dollar"
                isInput={false}
                value={monthlyPayment}
              />
              <InputComponent
                label="Sale Tax"
                flag="dollar"
                isInput={false}
                value={saleTaxValue}
              />
              <InputComponent
                label="Upfront Payment"
                flag="dollar"
                isInput={false}
                value={+upfrontPayment + +saleTaxValue}
              />
              <InputComponent
                label="Total Loan Amount"
                flag="dollar"
                isInput={false}
                value={totalLoanAmount}
              />
              <InputComponent
                label={`Total of ${leaseTerm * 12} Loan Payments`}
                flag="dollar"
                isInput={false}
                value={totalOf36LoanPayments}
              />
              <InputComponent
                label="Total Loan Interest"
                flag="dollar"
                isInput={false}
                value={totalLoanInterest}
              />
              <InputComponent
                label="Total Cost to Own"
                flag="dollar"
                isInput={false}
                value={totalCostToOwn}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                {
                  id: 0,
                  value: +monthlyLeasePayment,
                  label: "Monthly Lease Payment",
                },
                {
                  id: 1,
                  value: +monthlyDepreciation,
                  label: "Monthly Depreciation",
                },
                { id: 2, value: +monthlyInterest, label: "Monthly Interest" },
                { id: 3, value: +monthlyTax, label: "Monthly Tax" },
              ]}
              flag="invest"
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default AutoLeaseCalc;
