import React, { createContext, useState } from "react";

export const DataContext = createContext();

export const DataContextProvider = ({ children }) => {
  const [tabIndex, setTabIndex] = React.useState("Home Loan Calculator");
  const [blogData, setBlogData] = useState({});
  const [isCalcShow, setIsCalcShow] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);

  return (
    <DataContext.Provider
      value={{
        tabIndex,
        setTabIndex,
        blogData,
        setBlogData,
        isCalcShow,
        setIsCalcShow,
        selectedCategory,
        setSelectedCategory,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
