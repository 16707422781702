import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../Utils/CommonFunction";
import { Container, Divider, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { COMPONENTDATA } from "../../../Utils/Constant";
import Fuse from "fuse.js";
import headerLogo from "../../../Assets/Icon/CalculatorTimes.png";
import { useRef } from "react";

import "./Header.scss";

function Header() {
  const navigate = useNavigate();
  const returnHomeHandler = () => {
    navigate("/");
  };
  const [filteredResults, setFilteredResults] = useState(COMPONENTDATA);
  const [modalSearch, setModalSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const catMenu = useRef(null);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const closeOpenMenus = (e) => {
    if (modalSearch && !catMenu.current?.contains(e.target)) {
      setModalSearch(false);
      setSearchQuery("");
      setFilteredResults(COMPONENTDATA);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  const fuse = new Fuse(COMPONENTDATA, {
    keys: ["title", "description"],
    threshold: 0.4, // Adjust the threshold according to your preference
  });
  const handleSearch = (query) => {
    if (!modalSearch) {
      setModalSearch(true);
    }
    if (query.length > 0) {
      setFilteredResults([]);
      const results = fuse.search(query);
      const itemArray = results.map((item) => item.item);
      setFilteredResults(itemArray);
      setSearchQuery(query);
    } else {
      setSearchQuery("");
      setFilteredResults(COMPONENTDATA);
    }
  };
  const handleSearchResultClick = (e) => {
    setModalSearch(false);
    navigate(`./${e.target.getAttribute("value")}`);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="header-class">
        <Container className="container-class">
          <Toolbar className="header-mainbar">
            <img
              src={headerLogo}
              alt="logo"
              className="header-logo"
              onClick={() => returnHomeHandler()}
            />

            <Grid sx={{ position: "relative" }}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon sx={{ color: "#61b0c1" }} />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => handleSearch(e.target.value)}
                  className="header-search"
                  onFocus={() => setModalSearch(true)}
                  value={searchQuery}
                />
              </Search>
              {modalSearch && (
                <Grid
                  ref={catMenu}
                  className="slide-up search-result"
                  sx={{ left: { xs: 0, sm: 10 } }}
                >
                  {filteredResults.length > 0 ? (
                    filteredResults?.map((value, i) => (
                      <>
                        <Typography
                          key={i}
                          className="slide-up"
                          onClick={(e) => handleSearchResultClick(e)}
                          value={value?.slug}
                        >
                          {value?.title}
                        </Typography>
                        <Divider />
                      </>
                    ))
                  ) : (
                    <>
                      <Typography
                        className="slide-up"
                        sx={{ textAlign: "center" }}
                      >
                        <span>{searchQuery}</span> calculator not found.
                      </Typography>
                      <Divider />
                    </>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid id="google_translate_element" className="switch-btn"></Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}

export default Header;
