import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import InputSlider from "../../Common/Slider/Slider";
import InputComponent from "../../Common/InputComponent/InputComponent";
import {
  formatAmountIndian,
  returnHelmetData
} from "../../../Utils/CommonFunction";
import PieChart from "../../Common/PieChart/PieChart";
import "./GratuityCalc.scss";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

export default function GratuityCalc() {
  const [years, seYears] = useState(15);
  const [salary, setSalary] = useState(20000);
  const [gratuity, setGratuity] = useState(173077);
  const [totalSalary, setTotalSalary] = useState(3600000);


  useEffect(() => {
    setGratuity(() => (15 * salary * years) / 26);
    setTotalSalary(() => 12 * years * salary);
  }, [years, salary]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid
            sx={{ marginBottom: 2 }}
            item
            xs={12}
            md={7}
            className="calculation-part"
          >
            <InputSlider
              label="Number of years served"
              value={years}
              valueSetter={seYears}
              min={5}
              max={50}
            />

            <Grid item sx={{ marginTop: "1rem" }}>
              <InputComponent
                label="Monthly Salary (Basic Pay + D.A.)"
                isInput={true}
                flag="rupee"
                value={salary}
                setState={setSalary}
                max={1000000}
              />

              <Grid className="result-label">
                <Typography>Result</Typography>
              </Grid>

              <Grid>
                <InputComponent
                  label="Total Gratuity Payable To You"
                  isInput={false}
                  value={`${formatAmountIndian(gratuity).slice(0, -3) || "0"}`}
                  date={true}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid className="piechart-div" item xs={12} md={5}>
            <PieChart
              data={[
                {
                  id: 0,
                  value: totalSalary.toFixed(0),
                  label: "Total Salary Earned",
                },
                {
                  id: 1,
                  value: gratuity.toFixed(0),
                  label: "Gratuity Amount ",
                },
              ]}
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
