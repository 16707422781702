/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import PieChartComponent from "../../Common/PieChart/PieChart";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
function PaydayLoanCalc() {
  const [loanAmountInput, setLoanAmountInput] = useState(100);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [repaymentAmount, setRepaymentAmount] = useState(0);
  const [timeValue, setTimeValue] = useState("10"); // Input value for time
  const [selectedUnit, setSelectedUnit] = useState("Days"); // Default to "Days"
  const [numberOfPayments, setNumberOfPayments] = useState(0);
  const [establishmentFee, setEstablishmentFee] = useState(
    loanAmountInput * 0.2
  );

  const maxValues = {
    Days: 365,
    Weeks: 52,
    Fortnights: 26,
    Months: 12,
  };

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const convertToFullMonths = (value, unit) => {
    const currentYear = new Date().getFullYear();
    const daysInYear = isLeapYear(currentYear) ? 366 : 365;
    const weeksInYear = isLeapYear(currentYear) ? 52.2857 : 52.1775;

    let totalMonths = 0;

    switch (unit) {
      case "Days":
        totalMonths = Math.ceil(value / (daysInYear / 12));
        break;
      case "Weeks":
        totalMonths = Math.ceil(value / (weeksInYear / 12));
        break;
      case "Fortnights":
        totalMonths = Math.ceil((value * 2) / (weeksInYear / 12));
        break;
      case "Months":
        totalMonths = Math.ceil(value);
        break;
      default:
        totalMonths = 0;
    }

    return Math.min(totalMonths, 12);
  };
  const calculateLoanDetails = () => {
    const loanAmount = Number(loanAmountInput);

    const establishmentFee = loanAmount * 0.2;

    const monthlyFeeRate = 0.04;
    const totalFullMonths = convertToFullMonths(
      Number(timeValue),
      selectedUnit
    );
    let paymentCount = 0;
    switch (selectedUnit) {
      case "Days":
        paymentCount = Math.ceil(Number(timeValue) / (365 / 26));
        break;
      case "Weeks":
        paymentCount = Math.ceil(Number(timeValue) / (52 / 26));
        break;
      case "Fortnights":
        paymentCount = Number(timeValue);
        break;
      case "Months":
        paymentCount = Math.ceil(Number(timeValue) / (12 / 26));
        break;
      default:
        paymentCount = 0;
    }

    const monthlyFee = loanAmount * monthlyFeeRate * totalFullMonths;

    const totalRepayAmount = loanAmount + establishmentFee + monthlyFee;

    const repaymentPerPayment = totalRepayAmount / paymentCount;

    setTotalInterest(monthlyFee);
    setTotalAmount(totalRepayAmount);
    setRepaymentAmount(repaymentPerPayment);
    setNumberOfPayments(paymentCount);
    setEstablishmentFee(establishmentFee);
  };

  useEffect(() => {
    if (selectedUnit && timeValue) {
      const maxValue = maxValues[selectedUnit];
      if (Number(timeValue) > maxValue) {
        setTimeValue(maxValue);
      }
    }
  }, [selectedUnit, timeValue]);

  useEffect(() => {
    if (loanAmountInput && selectedUnit && timeValue) {
      calculateLoanDetails();
    }
  }, [loanAmountInput, selectedUnit, timeValue]);

  // Handle input change for loan time value
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    // Only allow numeric values
    if (/^\d*$/.test(newValue)) {
      setTimeValue(newValue);
    }
  };

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar" // Set to dollars
                label="Amount borrowed:"
                value={loanAmountInput}
                setState={setLoanAmountInput}
                min={1}
                max={2000}
              />
              <Grid className="concrete-calc-input pace">
                <Typography className="input-label">Length of loan:</Typography>
                <Grid sx={{ display: "flex" }}>
                  <input
                    type="number"
                    value={timeValue}
                    onChange={handleInputChange}
                    className="input-field"
                    placeholder=""
                  />

                  <Grid className="select-box-wrap">
                    <SelectBoxComponent
                      value={selectedUnit}
                      setValue={setSelectedUnit}
                      data={["Days", "Weeks", "Fortnights", "Months"]}
                      placeholder="Select time unit"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                label="Amount borrowed:"
                isInput={false}
                flag="dollar"
                value={loanAmountInput}
              />
              <InputComponent
                label="Establishment fee: (20% of amount borrowed)"
                isInput={false}
                flag="dollar"
                value={establishmentFee}
              />
              <InputComponent
                label="Monthly fees: (4% each month)"
                isInput={false}
                flag="dollar"
                value={totalInterest}
              />
              <InputComponent
                label="Total to be repaid:"
                isInput={false}
                flag="dollar"
                value={totalAmount}
              />
              <InputComponent
                label={`Fortnightly Repayments: (${numberOfPayments} payments)`}
                isInput={false}
                flag="dollar"
                value={repaymentAmount}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: loanAmountInput, label: "Amount Borrowed" },
                { id: 1, value: establishmentFee, label: "Establishment fee" },
                { id: 2, value: totalInterest, label: "Monthly fees" },
              ]}
              isTax={true}
            />
          </Grid>
        </Grid>

        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default PaydayLoanCalc;
