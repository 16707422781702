import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import GaugeChartComponent from "../../Common/GaugeChart/GaugeChart";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import {
  returnHelmetData
} from "../../../Utils/CommonFunction";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import { GENDERS } from "../../../Utils/calorieHelper";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import UnitConversion from "../../Common/UnitConversion/UnitConversion";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import "./BMICalc.scss";

export default function BMICalc() {
  const [weight, setWeight] = useState({ unit: "kg", value: 70 });
  const [height, setHeight] = useState({ unit: "cm", value: 170 });
  const [BMI, setBMI] = useState(24.2);
  const [gender, setGender] = useState({ name: "Male", value: "male" });
  const [interpretation, setInterpretation] = useState("");
  const [unitConvert, setUnitConvert] = useState(false);
  const [age, setAge] = useState(25);

  const weightUnitSetter = (value) =>
    setWeight((prev) => ({ ...prev, unit: value }));
  const weightValueSetter = (event) => {
    const value = parseFloat(event.target.value);
    setWeight((prev) => ({ ...prev, value: Math.min(value, 500) }));
  };

  const heightUnitSetter = (value) => {
    if (value === "ft") {
      setHeight({ unit: value, value: { ft: 5, in: 8 } });
    } else if (value === "cm") {
      setHeight({ unit: value, value: 170 });
    }
  };

  const heightCMValueSetter = (value) =>
    setHeight((prev) => ({ ...prev, value: Math.min(value, 260) }));
  const heightFTValueSetter = (value) =>
    setHeight((prev) => ({
      ...prev,
      value: { ...prev.value, ft: Math.min(value, 8) },
    }));
  const heightINValueSetter = (value) =>
    setHeight((prev) => ({
      ...prev,
      value: { ...prev.value, in: Math.min(value, 12) },
    }));

  useEffect(() => {
    let weightInKG =
      weight.unit === "kg" ? weight.value : weight.value / 2.20462;
    let heigthInCM =
      height.unit === "cm"
        ? height.value
        : height.value.ft * 30.48 + height.value.in * 2.54;

    const tempBmi = (weightInKG / (heigthInCM * heigthInCM)) * 10000;

    function getBMIPercentile(bmi, age, gender) {
      if (gender === "male") {
        return Math.floor((bmi - 14) * 5);
      } else {
        return Math.floor((bmi - 13) * 5);
      }
    }

    const interpretBMI = (bmi, age, gender) => {
      if (age >= 18) {
        if (bmi < 18.5) {
          setInterpretation("Underweight");
        } else if (bmi >= 18.5 && bmi < 24.9) {
          setInterpretation("Normal weight");
        } else if (bmi >= 25 && bmi < 29.9) {
          setInterpretation("Overweight");
        } else {
          setInterpretation("Obesity");
        }
      } else {
        let percentile = getBMIPercentile(bmi, age, gender);

        if (percentile < 5) {
          setInterpretation("Underweight");
        } else if (percentile >= 5 && percentile < 85) {
          setInterpretation("Healthy weight");
        } else if (percentile >= 85 && percentile < 95) {
          setInterpretation("Overweight");
        } else {
          setInterpretation("Obesity");
        }
      }
    };

    setBMI(isNaN(tempBmi) ? 0 : tempBmi);
    interpretBMI(tempBmi, age, gender);
  }, [weight, height, gender, age]);
  return (
    <>
      
             {returnHelmetData()}

     
      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <InputComponent
                isInput={true}
                flag="year"
                label="Age in Years"
                value={age}
                min={2}
                max={140}
                setState={setAge}
              />
              <RadioButtonComponent
                label={"Gender"}
                data={GENDERS}
                defaultValue={"male"}
                valueSetter={setGender}
                row={false}
              />
              <Grid>
                <Grid className="concrete-calc-input pace">
                  <Typography className="input-label">Weight</Typography>
                  <Grid sx={{ display: "flex" }}>
                    <input
                      type="number"
                      value={weight.value}
                      onChange={weightValueSetter}
                      className="input-field"
                    />

                    <Grid className="select-box-wrap">
                      <SelectBoxComponent
                        value={weight.unit}
                        setValue={weightUnitSetter}
                        data={["kg", "lb"]}
                        placeholder="Select weight unit"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {height.unit === "cm" && (
              <InputComponent
                label="Height (cm)"
                isInput={true}
                min={0}
                max={200}
                value={height.value}
                setState={heightCMValueSetter}
              />
            )}

            {height.unit === "ft" && (
              <>
                <InputComponent
                  label="Height (ft)"
                  isInput={true}
                  min={1}
                  max={10}
                  value={height.value.ft}
                  setState={heightFTValueSetter}
                />

                <InputComponent
                  label="Height (in)"
                  isInput={true}
                  min={1}
                  max={20}
                  value={height.value.in}
                  setState={heightINValueSetter}
                />
              </>
            )}
            <SelectBoxComponent
              label={"Height Unit"}
              value={height.unit}
              setValue={heightUnitSetter}
              data={["cm", "ft"]}
              placeholder="Select height unit"
            />
            <Divider sx={{ my: 2 }} />

            {!unitConvert ? (
              <Grid sx={{ textAlign: "center" }}>
                <Button
                  onClick={(e) => setUnitConvert(!unitConvert)}
                  variant="contained"
                >
                  Unit Conversion
                </Button>
              </Grid>
            ) : (
              <UnitConversion setUnitConvert={setUnitConvert} />
            )}
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div">
              <InputComponent
                label="Your BMI is"
                isInput={false}
                value={BMI.toFixed(1)}
                date={true}
              />
              <InputComponent
                label="interpretation"
                isInput={false}
                value={interpretation}
                date={true}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={5} className="gaugechart-div" sx={{ py: 2 }}>
            <GaugeChartComponent value={BMI.toFixed(1)} />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
