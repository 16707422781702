import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import TabbingComponent from "../../Common/TabbingRadio/TabbingComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import PaceCalc from "./PaceCalc";
import TimePaceCalc from "./TimePaceCalc";
import DistancePaceCalc from "./DistancePaceCalc";
import InputComponent from "../../Common/InputComponent/InputComponent";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import Table from "./Table";
import TableComponent from "../../Common/TableComponent/TableComponent";
import plusIcon from "../../../Assets/Icon/plusIcon.png";

function PaceCalculator() {
  const [type, setType] = useState("Pace");
  const [paceHour, setPaceHour] = useState(5);
  const [paceMinute, setPaceMinute] = useState(30);
  const [paceSecond, setPaceSecond] = useState(0);
  const [paceUnit1, setPaceUnit1] = useState("Per Miles");
  const [paceUnit2, setPaceUnit2] = useState("Per Kilometers");
  const [paceValue, setPaceValue] = useState();

  const [elapsedHours, setElapsedHours] = useState(6);
  const [elapsedMinutes, setElapsedMinutes] = useState(15);
  const [elapsedSeconds, setElapsedSeconds] = useState(0);
  const [currentDistance, setCurrentDistance] = useState(1);
  const [fullDistance, setFullDistance] = useState(5);
  const [currentDistanceUnit, setCurrentDistanceUnit] = useState("Kilometers");
  const [fullDistanceUnit, setFullDistanceUnit] = useState("Kilometers");
  const [paceTime, setPaceTime] = useState({});

  const [distances, setDistances] = useState({
    d0: 1,
    d1: 2,
    d2: 3,
  });
  const [distanceUnits, setDistanceUnits] = useState({
    du0: "Kilometers",
    du1: "Kilometers",
    du2: "Kilometers",
  });
  const [times, setTimes] = useState({
    t0: "00:03:25",
    t1: "00:06:55",
    t2: "00:10:25",
  });
  const [paceUnit, setPaceUnit] = useState("Per Miles");
  const [tableView, setTableView] = useState(false);
  const [averagePace, setAveragePace] = useState("");
  const [paceTable, setPaceTable] = useState([]);

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = (totalSeconds % 60).toFixed(2);
    if (hours) {
      return `${hours} hours ${minutes} minutes ${seconds} seconds`;
    } else {
      return `${minutes} minutes ${seconds} seconds`;
    }
  };

  const convertPace = () => {
    const totalPaceInSeconds =
      +paceHour * 3600 + +paceMinute * 60 + parseFloat(+paceSecond);
    let paceInNewUnit = 0;

    switch (paceUnit1) {
      case "Per Miles":
        switch (paceUnit2) {
          case "Per Kilometers":
            paceInNewUnit = totalPaceInSeconds / 1.60934;
            break;
          case "Miles Per Hour":
            paceInNewUnit = 3600 / totalPaceInSeconds;
            break;
          case "Kilometers Per Hour":
            paceInNewUnit = 3600 / (totalPaceInSeconds / 1.60934);
            break;
          case "Meters Per Minute":
            paceInNewUnit = 1609.34 / (totalPaceInSeconds / 60);
            break;
          case "Meters Per Second":
            paceInNewUnit = 1609.34 / totalPaceInSeconds;
            break;
          case "Yards Per Minute":
            paceInNewUnit = 1760 / (totalPaceInSeconds / 60);
            break;
          case "Yards Per Second":
            paceInNewUnit = 1760 / totalPaceInSeconds;
            break;
          default:
            paceInNewUnit = totalPaceInSeconds;
        }
        break;
      case "Per Kilometers":
        switch (paceUnit2) {
          case "Per Miles":
            paceInNewUnit = totalPaceInSeconds * 1.60934;
            break;
          case "Miles Per Hour":
            paceInNewUnit = 3600 / (totalPaceInSeconds * 1.60934);
            break;
          case "Kilometers Per Hour":
            paceInNewUnit = 3600 / totalPaceInSeconds;
            break;
          case "Meters Per Minute":
            paceInNewUnit = 1000 / (totalPaceInSeconds / 60);
            break;
          case "Meters Per Second":
            paceInNewUnit = 1000 / totalPaceInSeconds;
            break;
          case "Yards Per Minute":
            paceInNewUnit = 1093.61 / (totalPaceInSeconds / 60);
            break;
          case "Yards Per Second":
            paceInNewUnit = 1093.61 / totalPaceInSeconds;
            break;
          default:
            paceInNewUnit = totalPaceInSeconds;
        }
        break;
      case "Miles Per Hour":
        switch (paceUnit2) {
          case "Per Miles":
            paceInNewUnit = 3600 / totalPaceInSeconds;
            break;
          case "Per Kilometers":
            paceInNewUnit = 3600 / (totalPaceInSeconds * 1.60934);
            break;
          case "Kilometers Per Hour":
            paceInNewUnit = totalPaceInSeconds * 1.60934;
            break;
          case "Meters Per Minute":
            paceInNewUnit = (totalPaceInSeconds * 1609.34) / 60;
            break;
          case "Meters Per Second":
            paceInNewUnit = (totalPaceInSeconds * 1609.34) / 3600;
            break;
          case "Yards Per Minute":
            paceInNewUnit = (totalPaceInSeconds * 1760) / 60;
            break;
          case "Yards Per Second":
            paceInNewUnit = (totalPaceInSeconds * 1760) / 3600;
            break;
          default:
            paceInNewUnit = totalPaceInSeconds;
        }
        break;
      case "Kilometers Per Hour":
        switch (paceUnit2) {
          case "Per Miles":
            paceInNewUnit = 3600 / (totalPaceInSeconds / 1.60934);
            break;
          case "Per Kilometers":
            paceInNewUnit = 3600 / totalPaceInSeconds;
            break;
          case "Miles Per Hour":
            paceInNewUnit = totalPaceInSeconds / 1.60934;
            break;
          case "Meters Per Minute":
            paceInNewUnit = (totalPaceInSeconds * 1000) / 60;
            break;
          case "Meters Per Second":
            paceInNewUnit = (totalPaceInSeconds * 1000) / 3600;
            break;
          case "Yards Per Minute":
            paceInNewUnit = (totalPaceInSeconds * 1093.61) / 60;
            break;
          case "Yards Per Second":
            paceInNewUnit = (totalPaceInSeconds * 1093.61) / 3600;
            break;
          default:
            paceInNewUnit = totalPaceInSeconds;
        }
        break;
      case "Meters Per Minute":
        switch (paceUnit2) {
          case "Per Miles":
            paceInNewUnit = 1609.34 / (totalPaceInSeconds / 60);
            break;
          case "Per Kilometers":
            paceInNewUnit = 1000 / (totalPaceInSeconds / 60);
            break;
          case "Miles Per Hour":
            paceInNewUnit = (totalPaceInSeconds * 60) / 1609.34;
            break;
          case "Kilometers Per Hour":
            paceInNewUnit = (totalPaceInSeconds * 60) / 1000;
            break;
          case "Meters Per Second":
            paceInNewUnit = totalPaceInSeconds / 60;
            break;
          case "Yards Per Minute":
            paceInNewUnit = totalPaceInSeconds * 1.09361;
            break;
          case "Yards Per Second":
            paceInNewUnit = (totalPaceInSeconds / 60) * 1.09361;
            break;
          default:
            paceInNewUnit = totalPaceInSeconds;
        }
        break;
      case "Meters Per Second":
        switch (paceUnit2) {
          case "Per Miles":
            paceInNewUnit = 1609.34 / totalPaceInSeconds;
            break;
          case "Per Kilometers":
            paceInNewUnit = 1000 / totalPaceInSeconds;
            break;
          case "Miles Per Hour":
            paceInNewUnit = (totalPaceInSeconds * 3600) / 1609.34;
            break;
          case "Kilometers Per Hour":
            paceInNewUnit = (totalPaceInSeconds * 3600) / 1000;
            break;
          case "Meters Per Minute":
            paceInNewUnit = totalPaceInSeconds * 60;
            break;
          case "Yards Per Minute":
            paceInNewUnit = totalPaceInSeconds * 1.09361 * 60;
            break;
          case "Yards Per Second":
            paceInNewUnit = totalPaceInSeconds * 1.09361;
            break;
          default:
            paceInNewUnit = totalPaceInSeconds;
        }
        break;
      case "Yards Per Minute":
        switch (paceUnit2) {
          case "Per Miles":
            paceInNewUnit = 1760 / (totalPaceInSeconds / 60);
            break;
          case "Per Kilometers":
            paceInNewUnit = 1093.61 / (totalPaceInSeconds / 60);
            break;
          case "Miles Per Hour":
            paceInNewUnit = (totalPaceInSeconds * 60) / 1760;
            break;
          case "Kilometers Per Hour":
            paceInNewUnit = (totalPaceInSeconds * 60) / 1093.61;
            break;
          case "Meters Per Minute":
            paceInNewUnit = totalPaceInSeconds / 1.09361;
            break;
          case "Meters Per Second":
            paceInNewUnit = totalPaceInSeconds / 60 / 1.09361;
            break;
          case "Yards Per Second":
            paceInNewUnit = totalPaceInSeconds / 60;
            break;
          default:
            paceInNewUnit = totalPaceInSeconds;
        }
        break;
      case "Yards Per Second":
        switch (paceUnit2) {
          case "Per Miles":
            paceInNewUnit = 1760 / totalPaceInSeconds;
            break;
          case "Per Kilometers":
            paceInNewUnit = 1093.61 / totalPaceInSeconds;
            break;
          case "Miles Per Hour":
            paceInNewUnit = (totalPaceInSeconds * 3600) / 1760;
            break;
          case "Kilometers Per Hour":
            paceInNewUnit = (totalPaceInSeconds * 3600) / 1093.61;
            break;
          case "Meters Per Minute":
            paceInNewUnit = totalPaceInSeconds / 60 / 1.09361;
            break;
          case "Meters Per Second":
            paceInNewUnit = totalPaceInSeconds / 1.09361;
            break;
          case "Yards Per Minute":
            paceInNewUnit = totalPaceInSeconds * 60;
            break;
          default:
            paceInNewUnit = totalPaceInSeconds;
        }
        break;
      default:
        paceInNewUnit = totalPaceInSeconds;
    }

    // For pace results that are in time format
    if (["Per Miles", "Per Kilometers"].includes(paceUnit2)) {
      setPaceValue(formatTime(paceInNewUnit));
    } else {
      setPaceValue(paceInNewUnit.toFixed(3));
    }
  };

  const calculatePace = () => {
    const totalElapsedTimeInSeconds =
      +elapsedHours * 3600 + +elapsedMinutes * 60 + +elapsedSeconds;

    let currentDistanceInKm;
    switch (currentDistanceUnit) {
      case "Miles":
        currentDistanceInKm = +currentDistance * 1.60934;
        break;
      case "Kilometers":
        currentDistanceInKm = +currentDistance;
        break;
      case "Meters":
        currentDistanceInKm = +currentDistance / 1000;
        break;
      case "Yards":
        currentDistanceInKm = +currentDistance / 1093.61;
        break;
      default:
        currentDistanceInKm = +currentDistance;
    }

    let fullDistanceInKm;
    switch (fullDistanceUnit) {
      case "Miles":
        fullDistanceInKm = +fullDistance * 1.60934;
        break;
      case "Kilometers":
        fullDistanceInKm = +fullDistance;
        break;
      case "Meters":
        fullDistanceInKm = +fullDistance / 1000;
        break;
      case "Yards":
        fullDistanceInKm = +fullDistance / 1093.61;
        break;
      default:
        fullDistanceInKm = +fullDistance;
    }

    const paceTimePerKm = +totalElapsedTimeInSeconds / +currentDistanceInKm;
    const totalTimeToFinish = +paceTimePerKm * +fullDistanceInKm;

    const paceTimePerMileInSeconds = +paceTimePerKm * 1.60934;

    const speedInKmh =
      (+currentDistanceInKm / +totalElapsedTimeInSeconds) * 3600;
    const speedInMph = +speedInKmh / 1.60934;
    const speedInMetersPerMinute =
      (+currentDistanceInKm * 1000) / (+totalElapsedTimeInSeconds / 60);
    const speedInMetersPerSecond = +speedInMetersPerMinute / 60;
    const speedInYardsPerMinute = +speedInMetersPerMinute * 1.09361;
    const speedInYardsPerSecond = +speedInYardsPerMinute / 60;

    setPaceTime({
      finishTime: formatTime(+totalTimeToFinish),
      pacePerMile: formatTime(+paceTimePerMileInSeconds),
      pacePerKm: formatTime(+paceTimePerKm),
      speedInKmh: `${
        +speedInKmh.toFixed(2) === Infinity ? 0 : +speedInKmh.toFixed(2)
      } Kilometers Per Hour`,
      speedInMph: `${
        +speedInMph.toFixed(2) === Infinity ? 0 : +speedInMph.toFixed(2)
      } Miles Per Hour`,
      speedInMetersPerMinute: `${
        +speedInMetersPerMinute.toFixed(2) === Infinity
          ? 0
          : +speedInMetersPerMinute.toFixed(2)
      } Meters Per Minute`,
      speedInMetersPerSecond: `${
        +speedInMetersPerSecond.toFixed(2) === Infinity
          ? 0
          : +speedInMetersPerSecond.toFixed(2)
      } Meters Per Second`,
      speedInYardsPerMinute: `${
        +speedInYardsPerMinute.toFixed(2) === Infinity
          ? 0
          : +speedInYardsPerMinute.toFixed(2)
      } Yards Per Minute`,
      speedInYardsPerSecond: `${
        +speedInYardsPerSecond.toFixed(2) === Infinity
          ? 0
          : +speedInYardsPerSecond.toFixed(2)
      } Yards Per Second`,
    });
  };

  useEffect(() => {
    convertPace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paceHour, paceMinute, paceSecond, paceUnit1, paceUnit2]);

  useEffect(() => {
    calculatePace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    elapsedHours,
    elapsedMinutes,
    elapsedSeconds,
    currentDistance,
    fullDistance,
    currentDistanceUnit,
    fullDistanceUnit,
  ]);

  const hmsToSeconds = (hms) => {
    const [h, m, s] = hms.split(":").map(parseFloat);
    return h * 3600 + m * 60 + s;
  };

  const secondsToHms = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = (seconds % 60).toFixed(2);
    return `${String(h).padStart(2, "0")}:${String(m).padStart(
      2,
      "0"
    )}:${String(s).padStart(5, "0")}`;
  };

  const calculatePaceTable = () => {
    const paces = [];
    let accumulatedSeconds = 0;
    let accumulatedDistance = 0;

    const unitConversionFactor = {
      "Per Miles": 1.60934,
      "Per Kilometers": 1,
      "Miles Per Hour": 1.60934 / 3600,
      "Kilometers Per Hour": 1 / 3600,
      "Meters Per Minute": 1 / 60,
      "Meters Per Second": 1,
      "Yards Per Minute": 0.0009144 / 60,
      "Yards Per Second": 0.0009144,
    };

    const unitToKilometers = {
      Kilometers: 1,
      Miles: 1.60934,
      Meters: 0.001,
      Yards: 0.0009144,
    };

    for (let i = 0; i < Object.keys(distances).length; i++) {
      const currentDistance =
        distances[`d${i}`] * unitToKilometers[distanceUnits[`du${i}`]];
      if (currentDistance === 0) continue;
      const previousDistance =
        i === 0
          ? 0
          : distances[`d${i - 1}`] *
            unitToKilometers[distanceUnits[`du${i - 1}`]];
      const sectionDistance = currentDistance - previousDistance;
      const currentTimeSeconds = hmsToSeconds(times[`t${i}`]);
      if (currentTimeSeconds === 0) continue;
      const previousTimeSeconds =
        i === 0 ? 0 : hmsToSeconds(times[`t${i - 1}`]);
      const sectionTimeSeconds = currentTimeSeconds - previousTimeSeconds;

      const paceSeconds =
        sectionTimeSeconds / (sectionDistance / unitConversionFactor[paceUnit]);

      accumulatedSeconds += sectionTimeSeconds;
      accumulatedDistance += sectionDistance;

      paces.push({
        no: i + 1,
        distance: sectionDistance.toFixed(2),
        time: secondsToHms(sectionTimeSeconds),
        pace: secondsToHms(paceSeconds),
        accumulatedPace: secondsToHms(
          accumulatedSeconds /
            (accumulatedDistance / unitConversionFactor[paceUnit])
        ),
      });
    }

    setPaceTable(paces);

    // Calculate the average pace as the accumulated pace for the total distance and time
    const totalPaceSeconds =
      accumulatedSeconds /
      (accumulatedDistance / unitConversionFactor[paceUnit]);
    setAveragePace(formatTime(totalPaceSeconds));
  };

  useEffect(() => {
    calculatePaceTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distances, distanceUnits, times, paceUnit]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container gap={2.5} sx={{ mt: 2 }}>
          <Grid item xs={12} md={5.9} className="calculation-part">
            <Grid sx={{ margin: "20px 4px" }}>
              <TabbingComponent
                data={["Pace", "Time", "Distance"]}
                value={type}
                setValue={setType}
              />
            </Grid>
            <Grid>
              {type === "Pace" && <PaceCalc />}
              {type === "Time" && <TimePaceCalc />}
              {type === "Distance" && <DistancePaceCalc />}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.9} className="calculation-part">
            <Typography variant="h6" className="common-sub-heading-calc">
              Pace Converter
            </Typography>

            <InputComponent
              isInput={true}
              label="Pace Hour"
              max={100}
              value={paceHour}
              setState={setPaceHour}
            />
            <InputComponent
              isInput={true}
              label="Pace Minute"
              max={60}
              value={paceMinute}
              setState={setPaceMinute}
            />
            <InputComponent
              isInput={true}
              label="Pace Second"
              max={60}
              value={paceSecond}
              setState={setPaceSecond}
            />

            <SelectBoxComponent
              value={paceUnit1}
              setValue={setPaceUnit1}
              data={[
                "Per Miles",
                "Per Kilometers",
                "Miles Per Hour",
                "Kilometers Per Hour",
                "Meters Per Minute",
                "Meters Per Second",
                "Yards Per Minute",
                "Yards Per Second",
              ]}
              placeholder="Select Units"
            />
            <SelectBoxComponent
              value={paceUnit2}
              setValue={setPaceUnit2}
              data={[
                "Per Miles",
                "Per Kilometers",
                "Miles Per Hour",
                "Kilometers Per Hour",
                "Meters Per Minute",
                "Meters Per Second",
                "Yards Per Minute",
                "Yards Per Second",
              ]}
              placeholder="Select Units"
              label="Output Unit"
            />

            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <InputComponent
              label="Pace Value"
              isInput={false}
              value={`${paceValue} ${paceUnit2}`}
              date={true}
            />
          </Grid>
          <Grid item xs={12} md={5.9} className="calculation-part">
            <Typography variant="h6" className="common-sub-heading-calc">
              Finish Time Calculator
            </Typography>

            <Grid>
              <Grid className="concrete-calc-input pace">
                <Typography className="input-label">
                  Current Distance Traveled
                </Typography>
                <Grid sx={{ display: "flex" }}>
                  <input
                    type="number"
                    value={currentDistance}
                    onChange={(e) =>
                      setCurrentDistance(() =>
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value
                      )
                    }
                    className="input-field"
                  />

                  <Grid className="select-box-wrap">
                    <SelectBoxComponent
                      value={currentDistanceUnit}
                      setValue={setCurrentDistanceUnit}
                      data={["Miles", "Kilometers", "Meters", "Yards"]}
                      placeholder="Select Units"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <InputComponent
              isInput={true}
              label="Elapsed Pace Hour"
              max={100}
              value={elapsedHours}
              setState={setElapsedHours}
            />
            <InputComponent
              isInput={true}
              label="Elapsed Pace Minute"
              max={60}
              value={elapsedMinutes}
              setState={setElapsedMinutes}
            />
            <InputComponent
              isInput={true}
              label="Elapsed Pace Second"
              max={60}
              value={elapsedSeconds}
              setState={setElapsedSeconds}
            />
            <Grid>
              <Grid className="concrete-calc-input pace">
                <Typography className="input-label">Full Distance</Typography>
                <Grid sx={{ display: "flex" }}>
                  <input
                    type="number"
                    value={fullDistance}
                    onChange={(e) =>
                      setFullDistance(() =>
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value
                      )
                    }
                    className="input-field"
                  />

                  <Grid className="select-box-wrap">
                    <SelectBoxComponent
                      value={fullDistanceUnit}
                      setValue={setFullDistanceUnit}
                      data={["Miles", "Kilometers", "Meters", "Yards"]}
                      placeholder="Select Units"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Typography className="gpa-final-result">
              At current pace, it will take{" "}
              <strong>{paceTime.finishTime}</strong> to finish {fullDistance}{" "}
              Kilometers
            </Typography>
            <InputComponent
              label="Pace Value"
              isInput={false}
              value={`
                  ${paceTime.pacePerMile} Per Miles<br>
                  or ${paceTime.pacePerKm} Per Kilometers <br>
                  or ${paceTime.speedInMph} <br>
                  or ${paceTime.speedInKmh} <br>
                  or ${paceTime.speedInMetersPerMinute} <br>
                  or ${paceTime.speedInMetersPerSecond} <br>
                  or ${paceTime.speedInYardsPerMinute} <br>
                  or ${paceTime.speedInYardsPerSecond} <br>

                `}
              date={true}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid item className="calculation-part ">
              <Grid item>
                <Typography className="common-sub-heading-calc">
                  Multipoint Pace Calculator
                </Typography>
              </Grid>
              <Grid item>
                <Table
                  numOfRows={Object.keys(distances).length}
                  headerData={["Distance", "Unit", "Time"]}
                  distances={{ data: distances, setter: setDistances }}
                  distanceUnits={{
                    data: distanceUnits,
                    setter: setDistanceUnits,
                  }}
                  times={{ data: times, setter: setTimes }}
                />
                <Button
                  sx={{ my: 2 }}
                  variant="contained"
                  onClick={() => {
                    const newIndex = Object.keys(distances).length;
                    setDistances((prev) => ({
                      ...prev,
                      [`d${[newIndex]}`]: 0,
                    }));
                    setDistanceUnits((prev) => ({
                      ...prev,
                      [`du${[newIndex]}`]: "Kilometers",
                    }));
                    setTimes((prev) => ({
                      ...prev,
                      [`t${[newIndex]}`]: "00:00:00",
                    }));
                  }}
                >
                  Add Distance
                </Button>
                <SelectBoxComponent
                  label={"Your Preferred Pace Unit"}
                  value={paceUnit}
                  setValue={setPaceUnit}
                  data={["Per Miles", "Per Kilometers"]}
                  placeholder="Select Units"
                />
              </Grid>
              <Grid className="result-label">
                <Typography>Result</Typography>
              </Grid>{" "}
              <InputComponent
                label="Average Pace"
                isInput={false}
                value={`${averagePace} ${paceUnit}`}
                date={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid className="data-table-container">
          <Grid className="table-toggle-div">
            <Typography variant="h5">Pace Distance Table</Typography>
            <Tooltip
              title={tableView ? "click to close table" : "click to open table"}
              placement="top"
              arrow
            >
              <span>
                <img
                  src={plusIcon}
                  alt="plusIcon"
                  onClick={() => setTableView(!tableView)}
                  style={{
                    transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                  className="add-icon-table"
                />
              </span>
            </Tooltip>
          </Grid>
          {tableView && (
            <Grid>
              <Grid>
                <TableComponent
                  rows={paceTable}
                  headerData={[
                    "No.",
                    "Distance",
                    "Time(H:M:S)",
                    "Pace",
                    "Accumulated Pace",
                  ]}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default PaceCalculator;
