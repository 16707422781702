import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

export default function PoundsToDollarsCalc() {
  const [pounds, setPounds] = useState(1);
  const [dollars, setDollars] = useState(1.3);
  const [conversionRate, setConversionRate] = useState();


  useEffect(() => {
    const fetchConversionRate = async () => {
      try {
        const response = await fetch(
          `https://api.freecurrencyapi.com/v1/latest?apikey=${process.env.REACT_APP_CURRENCY_CONVERSION_API_KEY}&currencies=USD&base_currency=GBP`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setConversionRate(data.data.USD.toFixed(2) || 1.3);
      } catch (error) {
        console.error("Error fetching conversion rate:", error);
      }
    };

    fetchConversionRate();
  }, []);

  useEffect(() => {
    const PoundToDollar = Number(pounds) * conversionRate;

    setDollars(PoundToDollar ? Number(PoundToDollar.toFixed(2)) : 0);
  }, [pounds, conversionRate]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={8} className="calculation-part ">
            <Typography className="common-sub-heading-desc">
              1 Pound sterling = {conversionRate} United States Dollar
            </Typography>

            <Grid>
              <InputComponent
                label="Pound sterling"
                isInput={true}
                value={pounds}
                min={1}
                max={100000000000000000000}
                setState={setPounds}
                flag="pound"
              />
            </Grid>

            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <InputComponent
              label="United States Dollar"
              isInput={false}
              value={dollars}
              flag="dollar"
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
