import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import InputComponent from "../../Common/InputComponent/InputComponent";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";

const DistancePaceCalc = () => {
  const [hour, setHour] = useState(1);
  const [minute, setMinute] = useState(55);
  const [second, setSecond] = useState(25);
  const [paceHour, setPaceHour] = useState(1);
  const [paceMinute, setPaceMinute] = useState(0);
  const [paceSecond, setPaceSecond] = useState(25);
  const [paceUnit, setPaceUnit] = useState("Per Kilometers");
  const [distance, setDistance] = useState();

  const calculateDistance = () => {
    const totalTimeInSeconds = +hour * 3600 + +minute * 60 + +second;
    const totalPaceInSeconds =
      +paceHour * 3600 + +paceMinute * 60 + +paceSecond;

    let distanceInMiles,
      distanceInKilometers,
      distanceInMeters,
      distanceInYards;

    switch (paceUnit) {
      case "Per Miles":
        distanceInMiles = +totalTimeInSeconds / +totalPaceInSeconds;
        distanceInKilometers = +distanceInMiles * 1.60934;
        distanceInMeters = +distanceInKilometers * 1000;
        distanceInYards = +distanceInMiles * 1760;
        break;
      case "Per Kilometers":
        distanceInKilometers = +totalTimeInSeconds / +totalPaceInSeconds;
        distanceInMiles = +distanceInKilometers / 1.60934;
        distanceInMeters = +distanceInKilometers * 1000;
        distanceInYards = +distanceInMiles * 1760;
        break;
      case "Miles Per Hour":
        distanceInMiles = (+totalTimeInSeconds / 3600) * +paceHour;
        distanceInKilometers = +distanceInMiles * 1.60934;
        distanceInMeters = +distanceInKilometers * 1000;
        distanceInYards = +distanceInMiles * 1760;
        break;
      case "Kilometers Per Hour":
        distanceInKilometers = (+totalTimeInSeconds / 3600) * +paceHour;
        distanceInMiles = +distanceInKilometers / 1.60934;
        distanceInMeters = +distanceInKilometers * 1000;
        distanceInYards = +distanceInMiles * 1760;
        break;
      case "Meters Per Minute":
        distanceInMeters = (+totalTimeInSeconds / 60) * +paceMinute;
        distanceInKilometers = +distanceInMeters / 1000;
        distanceInMiles = +distanceInKilometers / 1.60934;
        distanceInYards = +distanceInMiles * 1760;
        break;
      case "Meters Per Second":
        distanceInMeters = +totalTimeInSeconds * +paceSecond;
        distanceInKilometers = +distanceInMeters / 1000;
        distanceInMiles = +distanceInKilometers / 1.60934;
        distanceInYards = +distanceInMiles * 1760;
        break;
      case "Yards Per Minute":
        distanceInYards = (+totalTimeInSeconds / 60) * +paceMinute;
        distanceInMeters = +distanceInYards / 1.09361;
        distanceInKilometers = +distanceInMeters / 1000;
        distanceInMiles = +distanceInKilometers / 1.60934;
        break;
      case "Yards Per Second":
        distanceInYards = +totalTimeInSeconds * +paceSecond;
        distanceInMeters = +distanceInYards / 1.09361;
        distanceInKilometers = +distanceInMeters / 1000;
        distanceInMiles = +distanceInKilometers / 1.60934;
        break;
      default:
        break;
    }

    setDistance({
      miles: +distanceInMiles.toFixed(3),
      kilometers: +distanceInKilometers.toFixed(3),
      meters: +distanceInMeters.toFixed(1),
      yards: +distanceInYards.toFixed(1),
    });
  };

  useEffect(() => {
    calculateDistance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hour, minute, second, paceHour, paceMinute, paceSecond, paceUnit]);

  return (
    <>
      <Grid>
        <InputComponent
          isInput={true}
          label="Hour"
          max={100}
          value={hour}
          setState={setHour}
        />
        <InputComponent
          isInput={true}
          label="Minute"
          max={60}
          value={minute}
          setState={setMinute}
        />
        <InputComponent
          isInput={true}
          label="Second"
          max={60}
          value={second}
          setState={setSecond}
        />
        <InputComponent
          isInput={true}
          label="Pace Hour"
          max={100}
          value={paceHour}
          setState={setPaceHour}
        />
        <InputComponent
          isInput={true}
          label="Pace Minute"
          max={60}
          value={paceMinute}
          setState={setPaceMinute}
        />
        <InputComponent
          isInput={true}
          label="Pace Second"
          max={60}
          value={paceSecond}
          setState={setPaceSecond}
        />
        <SelectBoxComponent
          value={paceUnit}
          setValue={setPaceUnit}
          data={[
            "Per Miles",
            "Per Kilometers",
            "Miles Per Hour",
            "Kilometers Per Hour",
            "Meters Per Minute",
            "Meters Per Second",
            "Yards Per Minute",
            "Yards Per Second",
          ]}
          placeholder="Select Units"
        />
      </Grid>
      <Grid className="result-label">
        <Typography>Result</Typography>
      </Grid>
      <Grid>
        <InputComponent
          isInput={false}
          label="Pace in different units"
          value={`${
            distance
              ? `${distance.miles} Miles<br/> or ${distance.kilometers} Kilometers<br/> or ${distance.meters} Meters<br/> or ${distance.yards} Yards`
              : ""
          }`}
          date={true}
        />
      </Grid>
    </>
  );
};

export default DistancePaceCalc;
