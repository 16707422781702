import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import "./ConcreteCalc.scss";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import shape1 from "../../../Assets/Icon/concreteShape1.png";
import shape2 from "../../../Assets/Icon/concreteShape2.png";
import shape3 from "../../../Assets/Icon/concreteShape3.png";
import shape4 from "../../../Assets/Icon/concreteShape4.png";
import shape5 from "../../../Assets/Icon/concreteShape5.png";

function ConcreteCalc() {
  const [slab, setSlab] = useState({
    lengthValue: 5,
    widthValue: 2.5,
    heightValue: 5,
    quantity: 1,
  });
  const [lengthUnit1, setLengthUnit1] = useState("meters");
  const [widthUnit1, setWidthUnit1] = useState("meters");
  const [heightUnit1, setHeightUnit1] = useState("meters");
  const [Volume, setVolume] = useState(0);
  const [volumeInYard, setVolumeInYard] = useState(0);
  const [volumeInMeter, setVolumeInMeter] = useState(0);
  const [weight, setWeight] = useState(0);
  const [bags1, setBags1] = useState(0);
  const [bags2, setBags2] = useState(0);

  const [hole, setHole] = useState({
    diameterValue: 5,
    heightValue: 2.5,
    quantity: 1,
  });
  const [diameterUnit1, setDiameterUnit1] = useState("meters");
  const [heightUnit2, setHeightUnit2] = useState("meters");
  const [Volume1, setVolume1] = useState(0);
  const [volumeInYard1, setVolumeInYard1] = useState(0);
  const [volumeInMeter1, setVolumeInMeter1] = useState(0);
  const [weight1, setWeight1] = useState(0);
  const [bags3, setBags3] = useState(0);
  const [bags4, setBags4] = useState(0);

  const [circular, setCircular] = useState({
    outerDiameter: 5,
    innerDiameter: 4,
    heightValue: 6,
    quantity: 1,
  });
  const [outerDiameterUnit, setOuterDiameterUnit] = useState("meters");
  const [innerDiameterUnit, setInnerDiameterUnit] = useState("meters");
  const [heightUnit3, setHeightUnit3] = useState("meters");
  const [Volume2, setVolume2] = useState(0);
  const [volumeInYard2, setVolumeInYard2] = useState(0);
  const [volumeInMeter2, setVolumeInMeter2] = useState(0);
  const [weight2, setWeight2] = useState(0);
  const [bags5, setBags5] = useState(0);
  const [bags6, setBags6] = useState(0);

  const [curb, setCurb] = useState({
    depthValue: 4,
    widthValue: 10,
    heightValue: 4,
    thicknessValue: 5,
    lengthValue: 10,
    quantity: 1,
  });
  const [curbDepthUnit, setCurbDepthUnit] = useState("meters");
  const [gutterWidthUnit, setGutterWidthUnit] = useState("meters");
  const [curbHeightUnit, setCurbHeightUnit] = useState("meters");
  const [thicknessUnit, setThicknessUnit] = useState("meters");
  const [lengthUnit2, setLengthUnit2] = useState("meters");
  const [Volume3, setVolume3] = useState(0);
  const [volumeInYard3, setVolumeInYard3] = useState(0);
  const [volumeInMeter3, setVolumeInMeter3] = useState(0);
  const [weight3, setWeight3] = useState(0);
  const [bags7, setBags7] = useState(0);
  const [bags8, setBags8] = useState(0);

  const [stairs, setStairs] = useState({
    runValue: 12,
    riseValue: 6,
    widthValue: 50,
    platformDepth: 5,
    numberOfSteps: 10,
  });
  const [runUnit, setRunUnit] = useState("meters");
  const [riseUnit, setRiseUnit] = useState("meters");
  const [stairsWidthUnit, setStairsWidthUnit] = useState("meters");
  const [platformDepthUnit, setPlatformDepthUnit] = useState("meters");
  const [Volume4, setVolume4] = useState(0);
  const [volumeInYard4, setVolumeInYard4] = useState(0);
  const [volumeInMeter4, setVolumeInMeter4] = useState(0);
  const [weight4, setWeight4] = useState(0);
  const [bags9, setBags9] = useState(0);
  const [bags10, setBags10] = useState(0);

  useEffect(() => {
    let lengthInMeters = slab.lengthValue;
    let widthInMeters = slab.widthValue;
    let heightInMeters = slab.heightValue;

    switch (lengthUnit1) {
      case "feet":
        lengthInMeters *= 0.3048;
        break;
      case "inches":
        lengthInMeters *= 0.0254;
        break;
      case "yards":
        lengthInMeters *= 0.9144;
        break;
      case "centimeters":
        lengthInMeters *= 0.01;
        break;
      default:
        break;
    }

    switch (widthUnit1) {
      case "feet":
        widthInMeters *= 0.3048;
        break;
      case "inches":
        widthInMeters *= 0.0254;
        break;
      case "yards":
        widthInMeters *= 0.9144;
        break;
      case "centimeters":
        widthInMeters *= 0.01;
        break;
      default:
        break;
    }

    switch (heightUnit1) {
      case "feet":
        heightInMeters *= 0.3048;
        break;
      case "inches":
        heightInMeters *= 0.0254;
        break;
      case "yards":
        heightInMeters *= 0.9144;
        break;
      case "centimeters":
        heightInMeters *= 0.01;
        break;
      default:
        break;
    }

    const volume =
      lengthInMeters * widthInMeters * heightInMeters * slab.quantity * 35.3147;
    setVolume(volume.toFixed(2));
    setVolumeInYard((volume / 27).toFixed(2));
    setVolumeInMeter((volume / 35.3147).toFixed(2));

    const weightNeeded = volume * 133;
    setWeight(weightNeeded.toFixed(2));

    const bags60 = weightNeeded / 60;
    const bags80 = weightNeeded / 80;
    setBags1(bags60.toFixed(2));
    setBags2(bags80.toFixed(2));
  }, [slab, lengthUnit1, widthUnit1, heightUnit1]);

  useEffect(() => {
    let diameterInMeters = hole.diameterValue;
    let heightInMeters = hole.heightValue;

    switch (diameterUnit1) {
      case "feet":
        diameterInMeters *= 0.3048;
        break;
      case "inches":
        diameterInMeters *= 0.0254;
        break;
      case "yards":
        diameterInMeters *= 0.9144;
        break;
      case "centimeters":
        diameterInMeters *= 0.01;
        break;
      default:
        break;
    }

    switch (heightUnit2) {
      case "feet":
        heightInMeters *= 0.3048;
        break;
      case "inches":
        heightInMeters *= 0.0254;
        break;
      case "yards":
        heightInMeters *= 0.9144;
        break;
      case "centimeters":
        heightInMeters *= 0.01;
        break;
      default:
        break;
    }

    const volume =
      Math.PI *
      Math.pow(diameterInMeters / 2, 2) *
      heightInMeters *
      hole.quantity *
      35.3147;
    setVolume1(volume.toFixed(2));
    setVolumeInYard1((volume / 27).toFixed(2));
    setVolumeInMeter1((volume / 35.3147).toFixed(2));

    const weight = volume * 133;
    setWeight1(weight.toFixed(2));

    const bagsUsing60lb = weight / 60;
    setBags3(bagsUsing60lb.toFixed(2));

    const bagsUsing80lb = weight / 80;
    setBags4(bagsUsing80lb.toFixed(2));
  }, [hole, diameterUnit1, heightUnit2]);

  useEffect(() => {
    let outerDiameterInMeters = circular.outerDiameter;
    let innerDiameterInMeters = circular.innerDiameter;
    let heightInMeters = circular.heightValue;

    switch (outerDiameterUnit) {
      case "feet":
        outerDiameterInMeters *= 0.3048;
        break;
      case "inches":
        outerDiameterInMeters *= 0.0254;
        break;
      case "yards":
        outerDiameterInMeters *= 0.9144;
        break;
      case "centimeters":
        outerDiameterInMeters *= 0.01;
        break;
      default:
        break;
    }

    switch (innerDiameterUnit) {
      case "feet":
        innerDiameterInMeters *= 0.3048;
        break;
      case "inches":
        innerDiameterInMeters *= 0.0254;
        break;
      case "yards":
        innerDiameterInMeters *= 0.9144;
        break;
      case "centimeters":
        innerDiameterInMeters *= 0.01;
        break;
      default:
        break;
    }

    switch (heightUnit3) {
      case "feet":
        heightInMeters *= 0.3048;
        break;
      case "inches":
        heightInMeters *= 0.0254;
        break;
      case "yards":
        heightInMeters *= 0.9144;
        break;
      case "centimeters":
        heightInMeters *= 0.01;
        break;
      default:
        break;
    }

    const volume =
      Math.PI *
      heightInMeters *
      ((outerDiameterInMeters / 2) ** 2 - (innerDiameterInMeters / 2) ** 2) *
      circular.quantity *
      35.3147;
    setVolume2(volume.toFixed(2));
    setVolumeInYard2((volume / 27).toFixed(2));
    setVolumeInMeter2((volume / 35.3147).toFixed(2));

    const weight = volume * 133;
    setWeight2(weight.toFixed(2));

    const bagsUsing60lb = weight / 60;
    setBags5(bagsUsing60lb.toFixed(2));

    const bagsUsing80lb = weight / 80;
    setBags6(bagsUsing80lb.toFixed(2));
  }, [circular, outerDiameterUnit, innerDiameterUnit, heightUnit3]);

  useEffect(() => {
    let curbDepthInMeters = +curb.depthValue;
    let gutterWidthInMeters = +curb.widthValue;
    let curbHeightInMeters = +curb.heightValue;
    let thicknessInMeters = +curb.thicknessValue;
    let lengthInMeters = +curb.lengthValue;

    switch (curbDepthUnit) {
      case "feet":
        curbDepthInMeters *= 0.3048;
        break;
      case "inches":
        curbDepthInMeters *= 0.0254;
        break;
      case "yards":
        curbDepthInMeters *= 0.9144;
        break;
      case "centimeters":
        curbDepthInMeters *= 0.01;
        break;
      default:
        break;
    }

    switch (gutterWidthUnit) {
      case "feet":
        gutterWidthInMeters *= 0.3048;
        break;
      case "inches":
        gutterWidthInMeters *= 0.0254;
        break;
      case "yards":
        gutterWidthInMeters *= 0.9144;
        break;
      case "centimeters":
        gutterWidthInMeters *= 0.01;
        break;
      default:
        break;
    }

    switch (curbHeightUnit) {
      case "feet":
        curbHeightInMeters *= 0.3048;
        break;
      case "inches":
        curbHeightInMeters *= 0.0254;
        break;
      case "yards":
        curbHeightInMeters *= 0.9144;
        break;
      case "centimeters":
        curbHeightInMeters *= 0.01;
        break;
      default:
        break;
    }

    switch (thicknessUnit) {
      case "feet":
        thicknessInMeters *= 0.3048;
        break;
      case "inches":
        thicknessInMeters *= 0.0254;
        break;
      case "yards":
        thicknessInMeters *= 0.9144;
        break;
      case "centimeters":
        thicknessInMeters *= 0.01;
        break;
      default:
        break;
    }

    switch (lengthUnit2) {
      case "feet":
        lengthInMeters *= 0.3048;
        break;
      case "inches":
        lengthInMeters *= 0.0254;
        break;
      case "yards":
        lengthInMeters *= 0.9144;
        break;
      case "centimeters":
        lengthInMeters *= 0.01;
        break;
      default:
        break;
    }

    let volumeCurb =
      curbDepthInMeters *
      (curbHeightInMeters + thicknessInMeters) *
      lengthInMeters;

    let volumeGutter = lengthInMeters * gutterWidthInMeters * thicknessInMeters;

    const totalVolume = volumeCurb + volumeGutter;
    const volume = totalVolume * curb.quantity * 35.3147;
    setVolume3(volume.toFixed(2));
    setVolumeInYard3((volume / 27).toFixed(2));
    setVolumeInMeter3((volume / 35.3147).toFixed(2));

    const weight = volume * 133;
    setWeight3(weight.toFixed(2));

    const bagsUsing60lb = weight / 60;
    setBags7(bagsUsing60lb.toFixed(2));

    const bagsUsing80lb = weight / 80;
    setBags8(bagsUsing80lb.toFixed(2));
  }, [
    curb,
    curbDepthUnit,
    gutterWidthUnit,
    curbHeightUnit,
    thicknessUnit,
    lengthUnit2,
  ]);

  useEffect(() => {
    let runValueInMeters = +stairs.runValue;
    let riseValueInMeters = +stairs.riseValue;
    let widthValueInMeters = +stairs.widthValue;
    let platformDepthInMeters = +stairs.platformDepth;

    switch (runUnit) {
      case "feet":
        runValueInMeters *= 0.3048;
        break;
      case "inches":
        runValueInMeters *= 0.0254;
        break;
      case "yards":
        runValueInMeters *= 0.9144;
        break;
      case "centimeters":
        runValueInMeters *= 0.01;
        break;
      default:
        break;
    }

    switch (riseUnit) {
      case "feet":
        riseValueInMeters *= 0.3048;
        break;
      case "inches":
        riseValueInMeters *= 0.0254;
        break;
      case "yards":
        riseValueInMeters *= 0.9144;
        break;
      case "centimeters":
        riseValueInMeters *= 0.01;
        break;
      default:
        break;
    }

    switch (stairsWidthUnit) {
      case "feet":
        widthValueInMeters *= 0.3048;
        break;
      case "inches":
        widthValueInMeters *= 0.0254;
        break;
      case "yards":
        widthValueInMeters *= 0.9144;
        break;
      case "centimeters":
        widthValueInMeters *= 0.01;
        break;
      default:
        break;
    }

    switch (platformDepthUnit) {
      case "feet":
        platformDepthInMeters *= 0.3048;
        break;
      case "inches":
        platformDepthInMeters *= 0.0254;
        break;
      case "yards":
        platformDepthInMeters *= 0.9144;
        break;
      case "centimeters":
        platformDepthInMeters *= 0.01;
        break;
      default:
        break;
    }

    let volumePlatform =
      platformDepthInMeters *
      (riseValueInMeters * stairs.numberOfSteps) *
      widthValueInMeters;

    let volumeStep = 0;
    for (let i = 1; i < stairs.numberOfSteps; i++) {
      volumeStep +=
        runValueInMeters * (riseValueInMeters * i) * widthValueInMeters;
    }
    const totalVolume = volumePlatform + volumeStep;
    const volume = totalVolume * 35.3147;
    setVolume4(volume.toFixed(2));
    setVolumeInYard4((volume / 27).toFixed(2));
    setVolumeInMeter4((volume / 35.3147).toFixed(2));

    const weight = volume * 133;
    setWeight4(weight.toFixed(2));

    const bagsUsing60lb = weight / 60;
    setBags9(bagsUsing60lb.toFixed(2));

    const bagsUsing80lb = weight / 80;
    setBags10(bagsUsing80lb.toFixed(2));
  }, [stairs, runUnit, riseUnit, stairsWidthUnit, platformDepthUnit]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: "10px 24px" }}>
        <ComponentHeader />
        <Grid container gap={2.5} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={5.8} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              1. Slabs, Square Footings, or Walls
            </Typography>
            <Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">Length (l)</Typography>
                <input
                  type="number"
                  value={slab.lengthValue}
                  onChange={(e) =>
                    setSlab((prevSlab) => ({
                      ...prevSlab,
                      lengthValue:
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value,
                    }))
                  }
                  className="input-field"
                />
                <Grid className="select-box-wrap">
                  <SelectBoxComponent
                    value={lengthUnit1}
                    setValue={setLengthUnit1}
                    data={["feet", "inches", "yards", "meters", "centimeters"]}
                  />
                </Grid>
              </Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">Width (w)</Typography>
                <input
                  type="number"
                  value={slab.widthValue}
                  onChange={(e) =>
                    setSlab((prevSlab) => ({
                      ...prevSlab,
                      widthValue:
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value,
                    }))
                  }
                  className="input-field"
                />
                <Grid className="select-box-wrap">
                  <SelectBoxComponent
                    value={widthUnit1}
                    setValue={setWidthUnit1}
                    data={["feet", "inches", "yards", "meters", "centimeters"]}
                  />
                </Grid>
              </Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">Height (h)</Typography>
                <input
                  type="number"
                  value={slab.heightValue}
                  onChange={(e) =>
                    setSlab((prevSlab) => ({
                      ...prevSlab,
                      heightValue:
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value,
                    }))
                  }
                  className="input-field"
                />
                <Grid className="select-box-wrap">
                  <SelectBoxComponent
                    value={heightUnit1}
                    setValue={setHeightUnit1}
                    data={["feet", "inches", "yards", "meters", "centimeters"]}
                  />
                </Grid>
              </Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">Quantity</Typography>
                <input
                  type="number"
                  value={slab.quantity}
                  onChange={(e) =>
                    setSlab((prevSlab) => ({
                      ...prevSlab,
                      quantity: parseInt(
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value
                      ),
                    }))
                  }
                  className="input-field"
                />
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid className="concrete-img">
              <img src={shape1} alt="shape-1" height="100px" width="auto" />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              <InputComponent
                label="Volume"
                isInput={false}
                value={`${Volume} cubic feet`}
                date={true}
              />
              <InputComponent
                isInput={false}
                value={`${volumeInYard} cubic yards`}
                date={true}
              />
              <InputComponent
                isInput={false}
                value={`${volumeInMeter} cubic meters`}
                date={true}
              />
              <InputComponent
                label="Weight needed"
                isInput={false}
                value={`${weight} lbs`}
                date={true}
              />
              <InputComponent
                label="Using 60-lb bags"
                isInput={false}
                value={`${bags1} bags`}
                date={true}
              />
              <InputComponent
                label="Using 80-lb bags"
                isInput={false}
                value={`${bags2} bags`}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5.8} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              2. Hole, Column, or Round Footings
            </Typography>
            <Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">Diameter (d)</Typography>
                <input
                  type="number"
                  value={hole.diameterValue}
                  onChange={(e) =>
                    setHole((prev) => ({
                      ...prev,
                      diameterValue:
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value,
                    }))
                  }
                  className="input-field"
                />
                <Grid className="select-box-wrap">
                  <SelectBoxComponent
                    value={diameterUnit1}
                    setValue={setDiameterUnit1}
                    data={["feet", "inches", "yards", "meters", "centimeters"]}
                  />
                </Grid>
              </Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">
                  Depth or Height (h)
                </Typography>
                <input
                  type="number"
                  value={hole.heightValue}
                  onChange={(e) =>
                    setHole((prev) => ({
                      ...prev,
                      heightValue:
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value,
                    }))
                  }
                  min={0}
                  max={10000000000}
                  className="input-field"
                />
                <Grid className="select-box-wrap">
                  <SelectBoxComponent
                    value={heightUnit2}
                    setValue={setHeightUnit2}
                    data={["feet", "inches", "yards", "meters", "centimeters"]}
                  />
                </Grid>
              </Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">Quantity</Typography>
                <input
                  type="number"
                  value={hole.quantity}
                  onChange={(e) =>
                    setHole((prev) => ({
                      ...prev,
                      quantity: parseInt(
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value
                      ),
                    }))
                  }
                  className="input-field"
                />
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid className="concrete-img">
              <img src={shape2} alt="shape-2" height="150px" width="auto" />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              <InputComponent
                label="Volume"
                isInput={false}
                value={`${Volume1} cubic feet`}
                date={true}
              />
              <InputComponent
                isInput={false}
                value={`${volumeInYard1} cubic yards`}
                date={true}
              />
              <InputComponent
                isInput={false}
                value={`${volumeInMeter1} cubic meters`}
                date={true}
              />
              <InputComponent
                label="Weight needed"
                isInput={false}
                value={`${weight1} lbs`}
                date={true}
              />
              <InputComponent
                label="Using 60-lb bags"
                isInput={false}
                value={`${bags3} bags`}
                date={true}
              />
              <InputComponent
                label="Using 80-lb bags"
                isInput={false}
                value={`${bags4} bags`}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5.8} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              3. Circular Slab or Tube
            </Typography>
            <Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">
                  Outer Diameter (d1)
                </Typography>
                <input
                  type="number"
                  value={circular.outerDiameter}
                  onChange={(e) =>
                    setCircular((prev) => ({
                      ...prev,
                      outerDiameter:
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value,
                    }))
                  }
                  className="input-field"
                />
                <Grid className="select-box-wrap">
                  <SelectBoxComponent
                    value={outerDiameterUnit}
                    setValue={setOuterDiameterUnit}
                    data={["feet", "inches", "yards", "meters", "centimeters"]}
                  />
                </Grid>
              </Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">
                  Inner Diameter (d2)
                </Typography>
                <input
                  type="number"
                  value={circular.innerDiameter}
                  onChange={(e) =>
                    setCircular((prev) => ({
                      ...prev,
                      innerDiameter:
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value,
                    }))
                  }
                  className="input-field"
                />
                <Grid className="select-box-wrap">
                  <SelectBoxComponent
                    value={innerDiameterUnit}
                    setValue={setInnerDiameterUnit}
                    data={["feet", "inches", "yards", "meters", "centimeters"]}
                  />
                </Grid>
              </Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">
                  Length or Height (h)
                </Typography>
                <input
                  type="number"
                  value={circular.heightValue}
                  onChange={(e) =>
                    setCircular((prev) => ({
                      ...prev,
                      heightValue:
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value,
                    }))
                  }
                  className="input-field"
                />
                <Grid className="select-box-wrap">
                  <SelectBoxComponent
                    value={heightUnit3}
                    setValue={setHeightUnit3}
                    data={["feet", "inches", "yards", "meters", "centimeters"]}
                  />
                </Grid>
              </Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">Quantity</Typography>
                <input
                  type="number"
                  value={circular.quantity}
                  onChange={(e) =>
                    setCircular((prev) => ({
                      ...prev,
                      quantity: parseInt(
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value
                      ),
                    }))
                  }
                  className="input-field"
                />
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid className="concrete-img">
              <img src={shape3} alt="shape-3" height="150px" width="auto" />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              <InputComponent
                label="Volume"
                isInput={false}
                value={`${Volume2} cubic feet`}
                date={true}
              />
              <InputComponent
                isInput={false}
                value={`${volumeInYard2} cubic yards`}
                date={true}
              />
              <InputComponent
                isInput={false}
                value={`${volumeInMeter2} cubic meters`}
                date={true}
              />
              <InputComponent
                label="Weight needed"
                isInput={false}
                value={`${weight2} lbs`}
                date={true}
              />
              <InputComponent
                label="Using 60-lb bags"
                isInput={false}
                value={`${bags5} bags`}
                date={true}
              />
              <InputComponent
                label="Using 80-lb bags"
                isInput={false}
                value={`${bags6} bags`}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5.8} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              4. Curb and Gutter Barrier
            </Typography>
            <Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">Curb Depth</Typography>
                <input
                  type="number"
                  value={curb.depthValue}
                  onChange={(e) =>
                    setCurb((prev) => ({
                      ...prev,
                      depthValue:
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value,
                    }))
                  }
                  className="input-field"
                />
                <Grid className="select-box-wrap">
                  <SelectBoxComponent
                    value={curbDepthUnit}
                    setValue={setCurbDepthUnit}
                    data={["feet", "inches", "yards", "meters", "centimeters"]}
                  />
                </Grid>
              </Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">Gutter Width</Typography>
                <input
                  type="number"
                  value={curb.widthValue}
                  onChange={(e) =>
                    setCurb((prev) => ({
                      ...prev,
                      widthValue:
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value,
                    }))
                  }
                  className="input-field"
                />
                <Grid className="select-box-wrap">
                  <SelectBoxComponent
                    value={gutterWidthUnit}
                    setValue={setGutterWidthUnit}
                    data={["feet", "inches", "yards", "meters", "centimeters"]}
                  />
                </Grid>
              </Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">Curb Height</Typography>
                <input
                  type="number"
                  value={curb.heightValue}
                  onChange={(e) =>
                    setCurb((prev) => ({
                      ...prev,
                      heightValue:
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value,
                    }))
                  }
                  className="input-field"
                />
                <Grid className="select-box-wrap">
                  <SelectBoxComponent
                    value={curbHeightUnit}
                    setValue={setCurbHeightUnit}
                    data={["feet", "inches", "yards", "meters", "centimeters"]}
                  />
                </Grid>
              </Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">Flag Thickness</Typography>
                <input
                  type="number"
                  value={curb.thicknessValue}
                  onChange={(e) =>
                    setCurb((prev) => ({
                      ...prev,
                      thicknessValue:
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value,
                    }))
                  }
                  className="input-field"
                />
                <Grid className="select-box-wrap">
                  <SelectBoxComponent
                    value={thicknessUnit}
                    setValue={setThicknessUnit}
                    data={["feet", "inches", "yards", "meters", "centimeters"]}
                  />
                </Grid>
              </Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">Length</Typography>
                <input
                  type="number"
                  value={curb.lengthValue}
                  onChange={(e) =>
                    setCurb((prev) => ({
                      ...prev,
                      lengthValue:
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value,
                    }))
                  }
                  className="input-field"
                />
                <Grid className="select-box-wrap">
                  <SelectBoxComponent
                    value={lengthUnit2}
                    setValue={setLengthUnit2}
                    data={["feet", "inches", "yards", "meters", "centimeters"]}
                  />
                </Grid>
              </Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">Quantity</Typography>
                <input
                  type="number"
                  value={curb.quantity}
                  onChange={(e) =>
                    setCurb((prev) => ({
                      ...prev,
                      quantity: parseInt(
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value
                      ),
                    }))
                  }
                  className="input-field"
                />
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid className="concrete-img">
              <img src={shape4} alt="shape-4" height="150px" width="auto" />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              <InputComponent
                label="Volume"
                isInput={false}
                value={`${Volume3} cubic feet`}
                date={true}
              />
              <InputComponent
                isInput={false}
                value={`${volumeInYard3} cubic yards`}
                date={true}
              />
              <InputComponent
                isInput={false}
                value={`${volumeInMeter3} cubic meters`}
                date={true}
              />
              <InputComponent
                label="Weight needed"
                isInput={false}
                value={`${weight3} lbs`}
                date={true}
              />
              <InputComponent
                label="Using 60-lb bags"
                isInput={false}
                value={`${bags7} bags`}
                date={true}
              />
              <InputComponent
                label="Using 80-lb bags"
                isInput={false}
                value={`${bags8} bags`}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5.8} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              5. Stairs
            </Typography>
            <Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">Run</Typography>
                <input
                  type="number"
                  value={stairs.runValue}
                  onChange={(e) =>
                    setStairs((prev) => ({
                      ...prev,
                      runValue:
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value,
                    }))
                  }
                  className="input-field"
                />
                <Grid className="select-box-wrap">
                  <SelectBoxComponent
                    value={runUnit}
                    setValue={setRunUnit}
                    data={["feet", "inches", "yards", "meters", "centimeters"]}
                  />
                </Grid>
              </Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">Rise</Typography>
                <input
                  type="number"
                  value={stairs.riseValue}
                  onChange={(e) =>
                    setStairs((prev) => ({
                      ...prev,
                      riseValue:
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value,
                    }))
                  }
                  className="input-field"
                />
                <Grid className="select-box-wrap">
                  <SelectBoxComponent
                    value={riseUnit}
                    setValue={setRiseUnit}
                    data={["feet", "inches", "yards", "meters", "centimeters"]}
                  />
                </Grid>
              </Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">Width</Typography>
                <input
                  type="number"
                  value={stairs.widthValue}
                  onChange={(e) =>
                    setStairs((prev) => ({
                      ...prev,
                      widthValue:
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value,
                    }))
                  }
                  className="input-field"
                />
                <Grid className="select-box-wrap">
                  <SelectBoxComponent
                    value={stairsWidthUnit}
                    setValue={setStairsWidthUnit}
                    data={["feet", "inches", "yards", "meters", "centimeters"]}
                  />
                </Grid>
              </Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">Platform Depth</Typography>
                <input
                  type="number"
                  value={stairs.platformDepth}
                  onChange={(e) =>
                    setStairs((prev) => ({
                      ...prev,
                      platformDepth:
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value,
                    }))
                  }
                  className="input-field"
                />
                <Grid className="select-box-wrap">
                  <SelectBoxComponent
                    value={platformDepthUnit}
                    setValue={setPlatformDepthUnit}
                    data={["feet", "inches", "yards", "meters", "centimeters"]}
                  />
                </Grid>
              </Grid>
              <Grid className="concrete-calc-input">
                <Typography className="input-label">Number of Steps</Typography>
                <input
                  type="number"
                  value={stairs.numberOfSteps}
                  onChange={(e) =>
                    setStairs((prev) => ({
                      ...prev,
                      numberOfSteps: parseInt(
                        e.target.value < 0
                          ? 0
                          : e.target.value > 1000000000000
                          ? 1000000000000
                          : e.target.value
                      ),
                    }))
                  }
                  className="input-field"
                />
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid className="concrete-img">
              <img src={shape5} alt="shape-5" height="150px" width="auto" />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              <InputComponent
                label="Volume"
                isInput={false}
                value={`${Volume4} cubic feet`}
                date={true}
              />
              <InputComponent
                isInput={false}
                value={`${volumeInYard4} cubic yards`}
                date={true}
              />
              <InputComponent
                isInput={false}
                value={`${volumeInMeter4} cubic meters`}
                date={true}
              />
              <InputComponent
                label="Weight needed"
                isInput={false}
                value={`${weight4} lbs`}
                date={true}
              />
              <InputComponent
                label="Using 60-lb bags"
                isInput={false}
                value={`${bags9} bags`}
                date={true}
              />
              <InputComponent
                label="Using 80-lb bags"
                isInput={false}
                value={`${bags10} bags`}
                date={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default ConcreteCalc;
