import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import PieChartComponent from "../../Common/PieChart/PieChart";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";

import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import { creditCardCalcRadio } from "../../../Utils/calorieHelper";
function CreditCardCalc() {
  const [loanAmountInput, setLoanAmountInput] = useState(8000);
  const [interestValue, setInterestValue] = useState(18);
  const [payOfYear, setPayOfYear] = useState(2);
  const [payOfAmount, setPayOfAmount] = useState(
    (
      (+loanAmountInput * +interestValue) / 12 / 100 +
      +loanAmountInput * 0.01
    ).toFixed(2)
  );
  const [result, setResult] = useState(false);
  const [payoffInfo, setPayoffInfo] = useState({});
  const [timeFrameResult, setTimeFrameResult] = useState("");

  const [selectedRadio, setSelectedRadio] = useState({
    label: "Pay a certain amount",
    value: "certain",
  });
  useEffect(() => {
    if (selectedRadio.value === "certain" && payOfAmount > 0) {
      const payoffInfo = calculatePayoff(
        loanAmountInput,
        interestValue,
        payOfAmount
      );
      if (payoffInfo.months === Infinity) {
        setResult("err");
        setPayoffInfo(payoffInfo );
      } else {
        setResult(true);
        setPayoffInfo(payoffInfo);
      }
    } else if (selectedRadio.value === "timeframe" && payOfYear > 0) {
      const totalMonths = payOfYear * 12;
      const requiredPayment = calculateRequiredPayment(
        loanAmountInput,
        interestValue,
        totalMonths
      );
      setTimeFrameResult(requiredPayment.toFixed(2));
      setResult(true);
    } else {
      setResult(false);
      setPayoffInfo({});
    }
  }, [
    interestValue,
    loanAmountInput,
    payOfAmount,
    payOfYear,
    selectedRadio.value,
  ]);

  const calculatePayoff = (balance, interestRate, monthlyPayment) => {
    const monthlyInterestRate = interestRate / 100 / 12;
    let months = 0;
    let totalInterest = 0;

    // Check if the monthly payment is less than the interest to avoid infinite loop
    if (monthlyPayment <= balance * monthlyInterestRate) {
      return {
        years: Infinity,
        months: Infinity,
        totalInterest: "Unable to calculate: Monthly payment is too low.",
      };
    }

    while (balance > 0) {
      const interest = balance * monthlyInterestRate;
      totalInterest += interest;
      balance += interest - monthlyPayment; // Update balance with interest and payment
      months++;

      // Preventing negative balance
      if (balance < 0) {
        balance = 0;
      }
    }

    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;

    return {
      years,
      months: remainingMonths,
      totalInterest: totalInterest.toFixed(2),
    };
  };

  const calculateRequiredPayment = (balance, interestRate, totalMonths) => {
    const monthlyInterestRate = interestRate / 100 / 12;

    // Handle zero interest rate separately
    if (monthlyInterestRate === 0) {
      return balance / totalMonths; // Simple division if there is no interest
    }

    return (
      (balance * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -totalMonths))
    );
  };

  const payOffAmountSetter = (percentage) => {
    if (percentage === 1) {
      setPayOfAmount(
        (
          (+loanAmountInput * +interestValue) / 12 / 100 +
          (+loanAmountInput * +percentage) / 100
        ).toFixed(2)
      );
    } else {
      setPayOfAmount(((+loanAmountInput * +percentage) / 100).toFixed(2));
    }
  };
  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Credit card balance"
                value={loanAmountInput}
                setState={setLoanAmountInput}
                min={1}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Interest Rate"
                setState={setInterestValue}
                min={0}
                max={999}
                value={interestValue}
              />
              <Grid>
                <RadioButtonComponent
                  label={"How do you plan to payoff?"}
                  data={creditCardCalcRadio}
                  defaultValue={"certain"}
                  valueSetter={setSelectedRadio}
                  row={true}
                />
              </Grid>
              {selectedRadio.value === "certain" ? (
                <>
                  <InputComponent
                    isInput={true}
                    flag="dollar"
                    label="PayOff Amount"
                    value={payOfAmount}
                    setState={setPayOfAmount}
                    min={0}
                    max={10000000000}
                  />
                  <Grid className="extra-percentage-setter">
                    <Typography onClick={() => payOffAmountSetter(1)}>
                      Interest + 1%,
                    </Typography>
                    <Typography onClick={() => payOffAmountSetter(2)}>
                      2%,
                    </Typography>
                    <Typography onClick={() => payOffAmountSetter(3)}>
                      3%,
                    </Typography>
                    <Typography onClick={() => payOffAmountSetter(4)}>
                      4%,
                    </Typography>
                    <Typography onClick={() => payOffAmountSetter(5)}>
                      5%
                    </Typography>
                  </Grid>
                </>
              ) : (
                <InputComponent
                  isInput={true}
                  flag="year"
                  label="PayOff Year"
                  setState={setPayOfYear}
                  value={payOfYear}
                  min={0}
                  max={199}
                />
              )}
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div" my={3}>
              {result && result !== "err" && (
                <Typography fontSize={20} textAlign={"center"}>
                  {selectedRadio.value === "certain" ? (
                    <>
                      It will take{" "}
                      <span
                        style={{ color: "green" }}
                      >{`${payoffInfo.years} years and ${payoffInfo.months} months`}</span>{" "}
                      to pay off the balance. The total interest is{" "}
                      <span
                        style={{ color: "red" }}
                      >{`$${payoffInfo.totalInterest}`}</span>
                      .
                    </>
                  ) : (
                    <>
                      You need to pay{" "}
                      <span
                        style={{ color: "green" }}
                      >{`$${timeFrameResult} per month`}</span>{" "}
                      to pay off within{" "}
                      <span
                        style={{ color: "green" }}
                      >{`${payOfYear} years`}</span>
                      .
                    </>
                  )}
                </Typography>
              )}
              {result === "err" && (
                <Typography fontSize={20} textAlign={"center"}>
                  <span style={{ color: "red" }}>
                    {payoffInfo.totalInterest}
                  </span>
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +loanAmountInput, label: "Principal" },
                { id: 1, value: +payoffInfo.totalInterest, label: "Interest" },
              ]}
            />
          </Grid>
        </Grid>

        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default CreditCardCalc;
