import React from "react";
import "./DatePickerCommon.scss";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";

const DatePickerCommon = (props) => {
  const {
    label,
    value,
    setValue,
    enableFutureDate,
    fromAgeCount,
    setIsFutureDate,
    disabled = false,
  } = props;
  const isFutureDate = (date) => {
    setIsFutureDate && setIsFutureDate(dayjs(date).isAfter(dayjs(), "day"));
    return dayjs(date).isAfter(dayjs(), "day");
  };

  const shouldDisableFutureDate = (date) => {
    if (enableFutureDate) {
      return false;
    }
    return isFutureDate(date);
  };
  const handleDateChange = (date) => {
    if (enableFutureDate) {
      setValue(date);
    } else if (date > fromAgeCount) {
      setValue(fromAgeCount);
    } else if (!isFutureDate(date)) {
      setValue(date);
    }
  };
  return (
    <>
      <Grid className="common-datepicker-parent">
        <Typography className="input-label">{label}</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label={label}
              value={value}
              onChange={handleDateChange}
              shouldDisableDate={shouldDisableFutureDate}
              disabled={disabled}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Grid>
    </>
  );
};

export default DatePickerCommon;
