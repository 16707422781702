import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectBoxModified = (props) => {
  const { value, valueSetter, data, placeholder } = props;
  const theme = useTheme();

  function getStyles(name, value, theme) {
    return {
      fontWeight:
        value.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleClick = (e) => {
    valueSetter({
      name: e.currentTarget.innerText,
      value: e.currentTarget.dataset.myValue,
    });
  };
  return (
    <div>
      <FormControl sx={{ m: 1, width: "98%", mt: 1 }}>
        <Select
          displayEmpty
          value={value}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
          {data.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              onClick={handleClick}
              data-my-value={item.value}
              style={getStyles(item, value, theme)}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectBoxModified;
