import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import PieChartComponent from "../../Common/PieChart/PieChart";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function VatCalc() {
  const [totalAmount, setTotalAmount] = useState(0);
  const [vatRate, setVatRate] = useState(0);
  const [totalGross, setTotalGross] = useState(0);
  const [totalTax, setTotalTax] = useState(0);

  useEffect(() => {
    let totalTaxValue = (+totalAmount * +vatRate / 100).toFixed(2);
    setTotalGross(+totalAmount + +totalTaxValue);
    setTotalTax(+totalTaxValue);
  }, [totalAmount, vatRate]);
  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <InputComponent
                isInput={true}
                flag="percentage"
                label="VAT rate"
                setState={setVatRate}
                value={vatRate}
                min={0}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Net price"
                value={totalAmount}
                setState={setTotalAmount}
                min={1}
                max={10000000000}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div">
              <InputComponent
                label="Gross Amount"
                isInput={false}
                value={totalGross}
                flag="dollar"
              />
              <InputComponent
                label="Tax Amount"
                isInput={false}
                value={totalTax}
                flag="dollar"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +totalAmount, label: "Net price" },
                {
                  id: 1,
                  value: totalTax,
                  label: "Tax amount",
                },
              ]}
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default VatCalc;
