import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import TableComponent from "../../Common/TableComponent/TableComponent";

function RentVsBuyCalc() {
  const [homePrice, setHomePrice] = useState(500000);
  const [downPayment, setDownPayment] = useState(20);
  const [interestRate, setInterestRate] = useState(6.181);
  const [loanTerm, setLoanTerm] = useState(30);
  const [buyingClosingCosts, setBuyingClosingCosts] = useState(2);
  const [propertyTax, setPropertyTax] = useState(1.5);
  const [propertyTaxIncrease, setPropertyTaxIncrease] = useState(3);
  const [homeInsurance, setHomeInsurance] = useState(2500);
  const [hoaFee, setHoaFee] = useState(0);
  const [maintenanceCost, setMaintenanceCost] = useState(1.5);
  const [homeValueAppreciation, setHomeValueAppreciation] = useState(3);
  const [costIncrease, setCostIncrease] = useState(3);
  const [sellingClosingCosts, setSellingClosingCosts] = useState(7);
  const [monthlyRentalFee, setMonthlyRentalFee] = useState(3000);
  const [rentalFeeIncrease, setRentalFeeIncrease] = useState(3);
  const [renterInsurance, setRenterInsurance] = useState(15);
  const [securityDeposit, setSecurityDeposit] = useState(3000);
  const [upfrontCost, setUpfrontCost] = useState(100);
  const [averageInvestmentReturn, setAverageInvestmentReturn] = useState(5);
  const [marginalFederalTaxRate, setMarginalFederalTaxRate] = useState(25);
  const [marginalStateTaxRate, setMarginalStateTaxRate] = useState(0);
  const [taxFilingStatus, setTaxFilingStatus] = useState(
    "married filing jointly"
  );
  const [tableView, setTableView] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [crossOver, setCrossOver] = useState(0);

  useEffect(() => {
    const downPaymentAmount = +homePrice * (+downPayment / 100);
    const loanAmount = +homePrice - +downPaymentAmount;
    const loanTermMonths = +loanTerm * 12;
    const monthlyInterestRate = +interestRate / 100 / 12;

    const monthlyMortgagePayment =
      (+loanAmount * +monthlyInterestRate) /
      (1 - Math.pow(1 + +monthlyInterestRate, -loanTermMonths));


    function getAnnualBuyingCosts(year) {
      const propertyTaxCost =
        +homePrice *
        (+propertyTax / 100) *
        Math.pow(1 + +propertyTaxIncrease / 100, year - 1);
      const homeInsuranceCost =
        +homeInsurance * Math.pow(1 + +costIncrease / 100, year - 1);
      const maintenanceCostPerYear =
        +homePrice *
        (+maintenanceCost / 100) *
        Math.pow(1 + +homeValueAppreciation / 100, year - 1);
      const hoaFees = +hoaFee * 12;

      return (
        +propertyTaxCost + +homeInsuranceCost + +maintenanceCostPerYear + +hoaFees
      );
    }

    function getAnnualRentingCosts(year) {
      const annualRent =
        +monthlyRentalFee *
        12 *
        Math.pow(1 + +rentalFeeIncrease / 100, year - 1);
      const annualRenterInsurance = +renterInsurance * 12;
      return annualRent + annualRenterInsurance;
    }

    // Tax-related calculations based on tax filing status
    let taxRate = +marginalFederalTaxRate + +marginalStateTaxRate;
    switch (taxFilingStatus) {
      case "single":
        break;
      case "married filing jointly":
        taxRate -= 0.02;
        break;
      case "married filing separately":
        taxRate += 0.01;
        break;
      case "head of household":
        taxRate -= 0.01;
        break;
      case "qualified widow":
        taxRate -= 0.03;
        break;
      default:
        taxRate += 0;
        break;
    }

    const resultTable = [];
    let remainingMortgage = +loanAmount;

    for (let year = 1; year <= 30; year++) {
      // Buying costs
      const yearlyMortgagePayment = +monthlyMortgagePayment * 12;
      const annualBuyingCosts = getAnnualBuyingCosts(year);

      // Calculate interest paid this year
      const interestPaid = +remainingMortgage * (+interestRate / 100);
      const principalPaid = +yearlyMortgagePayment - +interestPaid;
      remainingMortgage -= +principalPaid;

      // Tax deductions (property tax and mortgage interest)
      const taxDeduction =
        ((+propertyTax / 100) * +homePrice + +interestPaid) * (+taxRate / 100);

      const yearlyBuyingCost =
        +yearlyMortgagePayment + +annualBuyingCosts - +taxDeduction;

        // Renting costs
      const yearlyRentingCost = getAnnualRentingCosts(year);     


      resultTable.push({
        year,
        monthlyBuyingCost: (yearlyBuyingCost / 12).toFixed(2),
        yearlyBuyingCost: yearlyBuyingCost.toFixed(2),
        monthlyRentingCost: (yearlyRentingCost / 12).toFixed(2),
        yearlyRentingCost: yearlyRentingCost.toFixed(2),

      });
    }

    // Find crossover point
    const crossoverYear =
      resultTable.find(
        (row) =>
          parseFloat(row.monthlyBuyingCost) <
          parseFloat(row.monthlyRentingCost)
      )?.year || "Never";

    setTableData(resultTable);
    setCrossOver(crossoverYear);
  }, [
    homePrice,
    downPayment,
    interestRate,
    loanTerm,
    buyingClosingCosts,
    propertyTax,
    propertyTaxIncrease,
    homeInsurance,
    hoaFee,
    maintenanceCost,
    homeValueAppreciation,
    costIncrease,
    sellingClosingCosts,
    monthlyRentalFee,
    rentalFeeIncrease,
    renterInsurance,
    securityDeposit,
    upfrontCost,
    averageInvestmentReturn,
    marginalFederalTaxRate,
    marginalStateTaxRate,
    taxFilingStatus,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={8} className="calculation-part">
            <Grid>
              <Typography className="common-sub-heading-calc">
                Home Purchase
              </Typography>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Home price	"
                value={homePrice}
                min={1}
                setState={setHomePrice}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Down payment	"
                value={downPayment}
                min={0}
                setState={setDownPayment}
                max={100}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Interest Rate"
                value={interestRate}
                min={0}
                setState={setInterestRate}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Loan Term"
                value={loanTerm}
                min={0}
                setState={setLoanTerm}
                max={50}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Buying closing costs	"
                value={buyingClosingCosts}
                min={0}
                setState={setBuyingClosingCosts}
                max={999}
              />{" "}
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Property tax	"
                value={propertyTax}
                min={0}
                setState={setPropertyTax}
                max={999}
              />{" "}
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Property tax increase	"
                value={propertyTaxIncrease}
                min={0}
                setState={setPropertyTaxIncrease}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Home insurance		"
                value={homeInsurance}
                min={1}
                setState={setHomeInsurance}
                max={10000000000}
              />{" "}
              <InputComponent
                isInput={true}
                flag="dollar"
                label="HOA fee"
                value={hoaFee}
                min={1}
                setState={setHoaFee}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Maintenance cost	"
                value={maintenanceCost}
                min={0}
                setState={setMaintenanceCost}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Home value appreciation	"
                value={homeValueAppreciation}
                min={0}
                setState={setHomeValueAppreciation}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Cost/insurance increase	"
                value={costIncrease}
                min={0}
                setState={setCostIncrease}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Selling closing costs	"
                value={sellingClosingCosts}
                min={0}
                setState={setSellingClosingCosts}
                max={999}
              />
            </Grid>
            <Grid>
              <Typography className="common-sub-heading-calc">
                Home Rent
              </Typography>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Monthly rental fee		"
                value={monthlyRentalFee}
                min={1}
                setState={setMonthlyRentalFee}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Rental fee increase		"
                value={rentalFeeIncrease}
                min={0}
                setState={setRentalFeeIncrease}
                max={100}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Renter's insurance		"
                value={renterInsurance}
                min={1}
                setState={setRenterInsurance}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Security deposit		"
                value={securityDeposit}
                min={1}
                setState={setSecurityDeposit}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Upfront cost		"
                value={upfrontCost}
                min={1}
                setState={setUpfrontCost}
                max={10000000000}
              />
            </Grid>
            <Grid>
              <Typography className="common-sub-heading-calc">
                Your Information
              </Typography>
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Average investment return	"
                value={averageInvestmentReturn}
                min={0}
                setState={setAverageInvestmentReturn}
                max={100}
              />{" "}
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Marginal federal tax rate	"
                value={marginalFederalTaxRate}
                min={0}
                setState={setMarginalFederalTaxRate}
                max={100}
              />{" "}
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Marginal state tax rate	"
                value={marginalStateTaxRate}
                min={0}
                setState={setMarginalStateTaxRate}
                max={100}
              />
              <SelectBoxComponent
                value={taxFilingStatus}
                setValue={setTaxFilingStatus}
                data={[
                  "single",
                  "married filing jointly",
                  "married filing separately",
                  "head of household",
                  "qualified widow",
                ]}
                label="Tax filing status"
              />{" "}
            </Grid>

            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              <InputComponent
                isInput={false}
                value={`Buying is cheaper if you stay for ${crossOver} years or longer.
                Otherwise, renting is cheaper.`}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid className="data-table-container">
            <Grid className="table-toggle-div">
              <Typography variant="h5">
                Your Amortization Details{" "}
                <span>
                  (<u>Monthly</u>)
                </span>
              </Typography>
              <Tooltip
                title={
                  tableView ? "click to close table" : "click to open table"
                }
                placement="top"
                arrow
              >
                <span>
                  <img
                    src={plusIcon}
                    alt="plusIcon"
                    onClick={() => setTableView(!tableView)}
                    style={{
                      transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                    }}
                    className="add-icon-table"
                  />
                </span>
              </Tooltip>
            </Grid>
            {tableView && (
              <Grid>
                <Grid>
                  <TableComponent
                    rows={tableData}
                    currency="dollar"
                    headerData={[
                      "Year",
                      "Monthly Buying Cost",
                      "Yearly Buying Cost",
                      "Monthly Renting Cost",
                      "Yearly Renting Cost",
                    ]}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default RentVsBuyCalc;
