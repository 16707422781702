import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import PieChartComponent from "../../Common/PieChart/PieChart";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import TableComponent from "../../Common/TableComponent/TableComponent";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import CheckboxGroup from "../../Common/Checkbox/Checkbox";
import { personalLoanCheckbox } from "../../../Utils/Constant";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import { personalLoanRadio } from "../../../Utils/calorieHelper";

function PersonalLoanCalc() {
  const [loanAmountInput, setLoanAmountInput] = useState(20000);
  const [interestRate, setInterestRate] = useState(10);
  const [yearOfLoan, setYearOfLoan] = useState(5);
  const [selectedValues, setSelectedValues] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState({
    label: "deducted from loan",
    value: "intoLoan",
  });
  const [insurance, setInsurance] = useState(0);
  const [monthlyPay, setMonthlyPay] = useState(0);
  const [cashReceived, setCashReceived] = useState(0);
  const [loanPayments, setLoanPayments] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [originationFee, setOriginationFee] = useState(5);
  const [costOfLoan, setCostOfLoan] = useState(0);
  const [APR, setAPR] = useState(0);
  const [payoffDate, setPayoffDate] = useState(0);
  const [tableView, setTableView] = useState(false);
  const [monthlyRecords, setMonthlyRecords] = useState([]);
  const handleCheckboxChange = (newSelectedValues) => {
    if (newSelectedValues.length <= 0) {
      setSelectedValues(false);
    } else {
      setSelectedValues(true);
    }
  };

  useEffect(() => {
    const monthlyInterestRate = +interestRate / 100 / 12;
    const numberOfPayments = yearOfLoan * 12;
    let totalLoanAmount = loanAmountInput;

    // Check if selectedValues is true; if not, set origination fee and insurance to 0
    const originationFeeAmount = selectedValues
      ? +loanAmountInput * (+originationFee / 100)
      : 0;

    // Monthly payment calculation
    let monthlyPayment =
      (totalLoanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

    // Total payments including monthly insurance
    const totalPayments = monthlyPayment * numberOfPayments;
    const totalInterest = +totalPayments - +totalLoanAmount;

    const cashReceived =
      selectedRadio.value === "intoLoan"
        ? +totalLoanAmount - +originationFeeAmount
        : +loanAmountInput;

        
    // Cost of loan including the origination fee
    const costOfLoan = (+totalInterest + +originationFeeAmount + (+insurance * +yearOfLoan * 12)).toFixed(2);
    const APR = ((+costOfLoan / +loanAmountInput) / +yearOfLoan) * 100;
    const payoffDate = new Date();
    payoffDate.setMonth(payoffDate.getMonth() + numberOfPayments);

    // Create monthly records
    const monthlyRecords = [];
    let remainingBalance = totalLoanAmount;

    for (let month = 1; month <= numberOfPayments; month++) {
      const interestPayment = remainingBalance * monthlyInterestRate;
      let principalPayment = monthlyPayment - interestPayment;

      // Ensure the last payment doesn't exceed the remaining balance
      if (month === numberOfPayments) {
        principalPayment = remainingBalance;
        monthlyPayment = interestPayment + principalPayment; // Adjust final payment
      }

      remainingBalance -= principalPayment;

      // Push the record for this month
      monthlyRecords.push({
        month: month,
        interest: interestPayment.toFixed(2),
        principal: principalPayment.toFixed(2),
        endingBalance: remainingBalance.toFixed(2),
      });
    }

    // Set state for the calculated values
    setMonthlyPay(monthlyPayment.toFixed(2));
    setCashReceived(+cashReceived.toFixed(2));
    setLoanPayments(totalPayments.toFixed(2));
    setTotalInterest(totalInterest.toFixed(2));
    setCostOfLoan(+costOfLoan);
    setAPR(APR.toFixed(2));
    setPayoffDate(payoffDate.toLocaleDateString());

    // Set monthly records state
    setMonthlyRecords(monthlyRecords);
  }, [
    interestRate,
    loanAmountInput,
    originationFee,
    selectedRadio.value,
    yearOfLoan,
    insurance,
    selectedValues, // Add selectedValues as a dependency
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Loan amount"
                value={loanAmountInput}
                setState={setLoanAmountInput}
                min={1}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Interest rate"
                setState={setInterestRate}
                min={1}
                max={999}
                value={interestRate}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Loan term"
                setState={setYearOfLoan}
                value={yearOfLoan}
                min={0}
                max={199}
              />

              <CheckboxGroup
                data={personalLoanCheckbox}
                valueSetter={handleCheckboxChange}
                defaultValues={false}
                row={true}
              />

              {selectedValues && (
                <>
                  <InputComponent
                    isInput={true}
                    flag="percentage"
                    label="Origination fee"
                    value={originationFee}
                    setState={setOriginationFee}
                    min={0}
                    max={10000000000}
                  />
                  <Grid>
                    <RadioButtonComponent
                      label={"Paid to be?"}
                      data={personalLoanRadio}
                      defaultValue={"intoLoan"}
                      valueSetter={setSelectedRadio}
                      row={true}
                    />
                  </Grid>
                  <InputComponent
                    isInput={true}
                    flag="dollar"
                    label="Insurance ( per month )"
                    value={insurance}
                    setState={setInsurance}
                    min={0}
                    max={10000000000}
                  />
                </>
              )}
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                label="Monthly pay"
                isInput={false}
                flag="dollar"
                value={monthlyPay}
              />
              {selectedValues && (
                <InputComponent
                  label="Cash received"
                  isInput={false}
                  flag="dollar"
                  value={cashReceived}
                />
              )}
              {insurance > 0 && (
                <InputComponent
                  label="Monthly pay + insurance"
                  isInput={false}
                  flag="dollar"
                  value={(+monthlyPay + +insurance).toFixed(2)}
                />
              )}
              <InputComponent
                label={`Total of ${yearOfLoan * 12} loan payments`}
                isInput={false}
                flag="dollar"
                value={loanPayments}
              />
              <InputComponent
                label="Total interest"
                isInput={false}
                flag="dollar"
                value={totalInterest}
              />
              {selectedValues && (
                <>
                  {insurance > 0 && (
                    <InputComponent
                      label="Total Insurance"
                      isInput={false}
                      flag="dollar"
                      value={insurance * yearOfLoan * 12}
                    />
                  )}
                  <InputComponent
                    label="Origination fee"
                    isInput={false}
                    flag="dollar"
                    value={loanAmountInput * (originationFee / 100)}
                  />

                  <InputComponent
                    label="Cost of loan"
                    isInput={false}
                    flag="dollar"
                    value={
                     costOfLoan
                    }
                  />

                  <InputComponent
                    label="APR"
                    isInput={false}
                    value={APR}
                    flag="percentage"
                  />
                </>
              )}
              <InputComponent
                label="Payoff date"
                isInput={false}
                date={true}
                value={payoffDate}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +loanAmountInput, label: "Principal Amount" },
                { id: 1, value: +totalInterest, label: "Interest Amount" },
              ]}
            />
          </Grid>
        </Grid>
        <Grid className="data-table-container">
          <Grid className="table-toggle-div">
            <Typography variant="h5">
              Your Amortization Details{" "}
              <span>
                (<u>Yearly/Monthly</u>)
              </span>
            </Typography>
            <Tooltip
              title={tableView ? "click to close table" : "click to open table"}
              placement="top"
              arrow
            >
              <span>
                <img
                  src={plusIcon}
                  alt="plusIcon"
                  onClick={() => setTableView(!tableView)}
                  style={{
                    transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                  className="add-icon-table"
                />
              </span>
            </Tooltip>
          </Grid>
          {tableView && (
            <Grid>
              <Grid>
                <TableComponent
                  rows={monthlyRecords}
                  currency="dollar"
                  headerData={[
                    "Month",
                    "Interest",
                    "Principal",
                    "Ending Balance",
                  ]}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default PersonalLoanCalc;
