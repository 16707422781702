import React, { useEffect, useState } from "react";
import "./UnitConversion.scss";
import { Grid, Typography } from "@mui/material";
import { UNITCONVERSIONDATA } from "../../../Utils/Constant";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SelectBoxComponent from "../SelectBox/SelectBoxComponent";
import TabbingComponent from "../TabbingRadio/TabbingComponent";
import { convert, convertTemperature } from "../../../Utils/CommonFunction";

function UnitConversion(props) {
  const { setUnitConvert } = props;
  const [from, setFrom] = useState(0);
  const [type, setType] = useState("Length");
  const [fromUnit, setFromUnit] = useState(
    UNITCONVERSIONDATA.filter((item) => item.category === type)[0].unit[0]
  );
  const [to, setTo] = useState(0);
  const [toUnit, setToUnit] = useState(
    UNITCONVERSIONDATA.filter((item) => item.category === type)[0].unit[0]
  );

  useEffect(() => {
    setFromUnit(
      UNITCONVERSIONDATA.filter((item) => item.category === type)[0].unit[0]
    );
    setToUnit(
      UNITCONVERSIONDATA.filter((item) => item.category === type)[0].unit[0]
    );
  }, [type]);

  useEffect(() => {
    let tempValue = 0;
    if (type === "Temperature") {
      tempValue = convertTemperature(
        from,
        fromUnit.replace(/\s/g, ""),
        toUnit.replace(/\s/g, "")
      );
    } else {
      tempValue = convert(
        from,
        fromUnit.replace(/\s/g, ""),
        toUnit.replace(/\s/g, "")
      );
    }
    setTo(tempValue ? tempValue : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, fromUnit, to, toUnit]);

  return (
    <Grid>
      <Grid className="unit-heading">
        <Typography className="common-sub-heading-calc">
          Unit Conversion
        </Typography>
        <CloseOutlinedIcon
          onClick={(e) => setUnitConvert(false)}
          className="unit-close-icon"
        />
      </Grid>
      <Grid sx={{ margin: "20px 4px" }}>
        <TabbingComponent
          data={[...new Set(UNITCONVERSIONDATA?.map((item) => item?.category))]}
          value={type}
          setValue={setType}
        />
      </Grid>
      <Grid>
        <Grid className="concrete-calc-input pace">
          <Typography className="input-label">From</Typography>
          <Grid sx={{ display: "flex" }}>
            <input
              type="number"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              min={0}
              max={1000000000000000}
              className="input-field"
            />

            <Grid className="select-box-wrap">
              <SelectBoxComponent
                value={fromUnit}
                setValue={setFromUnit}
                data={
                  UNITCONVERSIONDATA.filter((item) => item.category === type)[0]
                    .unit
                }
                placeholder="Select from unit"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Grid className="concrete-calc-input pace">
          <Typography className="input-label">To</Typography>
          <Grid sx={{ display: "flex" }}>
            <input
              type="number"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              disabled={true}
              className="input-field"
            />

            <Grid className="select-box-wrap">
              <SelectBoxComponent
                value={toUnit}
                setValue={setToUnit}
                data={
                  UNITCONVERSIONDATA.filter((item) => item.category === type)[0]
                    .unit
                }
                placeholder="Select to unit"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UnitConversion;
