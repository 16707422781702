import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import "./FractionCalc.scss";
import InputField from "../../Common/InputField/InputField";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import {
  calculateFractions,
  calculateMixedNumbers,
  calculateSimplifyFractions,
  generateFraction,
  generateDecimal,
  generateBigFractions,
} from "../../../Utils/fractionHelper";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function FractionCalc() {

  const [valueX1, setValueX1] = useState(3);
  const [valueX2, setValueX2] = useState(7);
  const [valueX3, setValueX3] = useState("+");
  const [valueX4, setValueX4] = useState(3);
  const [valueX5, setValueX5] = useState(8);
  const [ansX, setAnsX] = useState(0);

  const [valueY1, setValueY1] = useState(-2);
  const [valueY2, setValueY2] = useState(3);
  const [valueY3, setValueY3] = useState(4);
  const [valueY4, setValueY4] = useState("+");
  const [valueY5, setValueY5] = useState(7);
  const [valueY6, setValueY6] = useState(-2);
  const [valueY7, setValueY7] = useState(3);
  const [ansY, setAnsY] = useState(0);

  const [valueZ1, setValueZ1] = useState(21);
  const [valueZ2, setValueZ2] = useState(98);
  const [valueZ3, setValueZ3] = useState(2);
  const [ansZ, setAnsZ] = useState(0);
  const [notValidMessage, setNotValidMessage] = useState("");

  const [valueP1, setValueP1] = useState(0.75);
  const [ansP, setAnsP] = useState({ n: 3, d: 4 });

  const [valueQ1, setValueQ1] = useState(4);
  const [valueQ2, setValueQ2] = useState(5);
  const [ansQ, setAnsQ] = useState(0.8);

  const [valueR1, setValueR1] = useState(316148567456416);
  const [valueR2, setValueR2] = useState(989654165641656);
  const [valueR3, setValueR3] = useState("+");
  const [valueR4, setValueR4] = useState(65485489456544);
  const [valueR5, setValueR5] = useState(16541684556165);
  const [ansR, setAnsR] = useState({ n: 5, d: 7 });

  useEffect(() => {
    setAnsX(
      isNaN(calculateFractions(valueX1, valueX2, valueX3, valueX4, valueX5))
        ? 0
        : calculateFractions(
            valueX1,
            valueX2,
            valueX3,
            valueX4,
            valueX5
          ).toFixed(2)
    );
  }, [valueX1, valueX2, valueX3, valueX4, valueX5]);

  useEffect(() => {
    const result = calculateMixedNumbers(
      valueY1,
      valueY2,
      valueY3,
      valueY4,
      valueY5,
      valueY6,
      valueY7
    );
    setAnsY(isNaN(result) ? 0 : result.toFixed(2));
  }, [valueY1, valueY2, valueY3, valueY4, valueY5, valueY6, valueY7]);

  useEffect(() => {
    if (valueZ2 <= 0 || valueZ3 <= 0) {
      setNotValidMessage("This is not a valid fractions format.");
      setAnsZ(0);
    } else {
      setNotValidMessage("");
      setAnsZ(calculateSimplifyFractions(valueZ1, valueZ2, valueZ3).toFixed(2));
    }
  }, [valueZ1, valueZ2, valueZ3]);

  useEffect(() => {
    setAnsP(generateFraction(valueP1));
  }, [valueP1]);

  useEffect(() => {
    setAnsQ(generateDecimal(valueQ1, valueQ2));
  }, [valueQ1, valueQ2]);

  useEffect(() => {
    setAnsR(generateBigFractions(valueR1, valueR2, valueR3, valueR4, valueR5));
  }, [valueR1, valueR2, valueR3, valueR4, valueR5]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container gap={2.5} sx={{ mt: 2 }}>
          <Grid item xs={12} md={5.8} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              1. Fraction Calculator
            </Typography>
            <Grid className="input-div">
              <Box className="fraction-calc-wrap">
                <Box>
                  <InputField
                    value={valueX1}
                    label={"Insert Value of X1"}
                    type={"number"}
                    setState={(value) => setValueX1(parseInt(value))}
                  />
                  <Divider sx={{ my: 1, borderColor: "#737373" }} />
                  <InputField
                    value={valueX2}
                    label={"Insert Value of X2"}
                    type={"number"}
                    setState={(value) => setValueX2(parseInt(value))}
                  />
                </Box>
                <Box className="select-box-wrap">
                  <SelectBoxComponent
                    value={valueX3}
                    setValue={setValueX3}
                    data={["+", "-", "X", "/"]}
                  />
                </Box>
                <Box>
                  <InputField
                    value={valueX4}
                    label={"Insert Value of X4"}
                    type={"number"}
                    setState={(value) => setValueX4(parseInt(value))}
                  />
                  <Divider sx={{ my: 1, borderColor: "#737373" }} />
                  <Grid>
                    <InputField
                      value={valueX5}
                      label={"Insert Value of X5"}
                      type={"number"}
                      setState={(value) => setValueX5(parseInt(value))}
                    />
                  </Grid>
                </Box>
                <Box className="result-preview">
                  <Box>
                    <Typography>=</Typography>
                  </Box>
                  <Box>
                    <Typography>?</Typography>
                    <Box className="line"></Box>
                    <Typography>?</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Result"
                isInput={false}
                value={ansX}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.8} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              2. Mixed Numbers Calculator
            </Typography>
            <Grid className="input-div">
              <Box className="fraction-calc-wrap">
                <Box>
                  <InputField
                    value={valueY1}
                    label={"Insert Value of Y1"}
                    type={"number"}
                    setState={(value) => setValueY1(parseInt(value))}
                  />
                </Box>
                <Box>
                  <InputField
                    value={valueY2}
                    label={"Insert Value of Y2"}
                    type={"number"}
                    setState={(value) => setValueY2(parseInt(value))}
                  />
                  <Divider sx={{ my: 1, borderColor: "#737373" }} />
                  <InputField
                    value={valueY3}
                    label={"Insert Value of Y3"}
                    type={"number"}
                    setState={(value) => setValueY3(parseInt(value))}
                  />
                </Box>
                <Box className="select-box-wrap">
                  <SelectBoxComponent
                    value={valueY4}
                    setValue={setValueY4}
                    data={["+", "-", "X", "/"]}
                  />
                </Box>
                <Box>
                  <InputField
                    value={valueY5}
                    label={"Insert Value of Y5"}
                    type={"number"}
                    setState={(value) => setValueY5(parseInt(value))}
                  />
                </Box>
                <Box>
                  <InputField
                    value={valueY6}
                    label={"Insert Value of Y6"}
                    type={"number"}
                    setState={(value) => setValueY6(parseInt(value))}
                  />
                  <Divider sx={{ my: 1, borderColor: "#737373" }} />
                  <InputField
                    value={valueY7}
                    label={"Insert Value of Y7"}
                    type={"number"}
                    setState={(value) => setValueY7(parseInt(value))}
                  />
                </Box>
                <Box className="result-preview">
                  <Box>
                    <Typography>=</Typography>
                  </Box>
                  <Box>
                    <Typography>?</Typography>
                    <Box className="line"></Box>
                    <Typography>?</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Result"
                isInput={false}
                value={ansY}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.8} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              3. Simplify Fractions Calculator
            </Typography>
            {notValidMessage !== "" && (
              <Typography className="error-massage">
                {notValidMessage}
              </Typography>
            )}
            <Grid className="input-div">
              <Box className="fraction-calc-wrap">
                <Box>
                  <InputField
                    value={valueZ1}
                    label={"Insert Value of Z1"}
                    type={"number"}
                    setState={(value) => setValueZ1(parseInt(value))}
                  />
                </Box>
                <Box>
                  <InputField
                    value={valueZ2}
                    label={"Insert Value of Z2"}
                    type={"number"}
                    setState={(value) => setValueZ2(parseInt(value))}
                  />
                  <Divider sx={{ my: 1, borderColor: "#737373" }} />
                  <InputField
                    value={valueZ3}
                    label={"Insert Value of Z3"}
                    type={"number"}
                    setState={(value) => setValueZ3(parseInt(value))}
                  />
                </Box>
                <Box className="result-preview">
                  <Box>
                    <Typography>=</Typography>
                  </Box>
                  <Box>
                    <Typography>?</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Result"
                isInput={false}
                value={ansZ}
                date={true}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={5.8} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              4. Decimal to Fraction Calculator
            </Typography>
            <Box className="input-div">
              <Grid className="fraction-calc-wrap" container>
                <Grid item>
                  <InputField
                    value={valueP1}
                    label={"Insert Value of P1"}
                    type={"number"}
                    setState={(value) => setValueP1(parseFloat(value))}
                  />
                </Grid>
                <Grid item style={{ width: "fit-content" }}>
                  <Typography sx={{ my: 2 }} align="center">
                    =
                  </Typography>
                </Grid>

                <Grid item>
                  <InputField value={ansP.n} isDisabled={true} />
                  <Divider sx={{ my: 1, borderColor: "#737373" }} />
                  <InputField value={ansP.d} isDisabled={true} />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} md={5.8} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              5. Fraction to Decimal Calculator
            </Typography>
            <Box className="input-div">
              <Grid className="fraction-calc-wrap" container>
                <Grid item>
                  <Box>
                    <InputField
                      value={valueQ1}
                      label={"Insert Value of Q1"}
                      type={"number"}
                      setState={(value) => setValueQ1(parseInt(value))}
                    />
                    <Divider sx={{ my: 1, borderColor: "#737373" }} />
                    <InputField
                      value={valueQ2}
                      label={"Insert Value of Q2"}
                      type={"number"}
                      setState={(value) => setValueQ2(parseInt(value))}
                      min={1}
                    />
                  </Box>
                </Grid>
                <Grid item style={{ width: "fit-content" }}>
                  <Typography sx={{ my: 2 }} align="center">
                    =
                  </Typography>
                </Grid>
                <Grid item>
                  <Box>
                    <InputField value={ansQ} isDisabled={true} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={5.8} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              6. Big Number Fraction Calculator
            </Typography>
            <Box className="input-div">
              <Grid container sx={{ alignItems: "center" }} spacing={4}>
                <Grid item xs={12} md={6}>
                  <Grid className="big-fraction-calc">
                    <Box>
                      <Box>
                        <InputField
                          value={valueR1}
                          label={"Insert Value of R1"}
                          type={"number"}
                          setState={(value) => setValueR1(parseInt(value))}
                        />
                        <Divider sx={{ my: 1, borderColor: "#737373" }} />
                        <InputField
                          value={valueR2}
                          label={"Insert Value of R2"}
                          type={"number"}
                          setState={(value) => setValueR2(parseInt(value))}
                        />
                      </Box>
                      <Box sx={{ my: 4 }} className="select-box-wrap">
                        <SelectBoxComponent
                          value={valueR3}
                          setValue={setValueR3}
                          data={["+", "-", "X", "/"]}
                        />
                      </Box>
                      <Box>
                        <InputField
                          value={valueR4}
                          label={"Insert Value of R4"}
                          type={"number"}
                          setState={(value) => setValueR4(parseInt(value))}
                        />
                        <Divider sx={{ my: 1, borderColor: "#737373" }} />
                        <InputField
                          value={valueR5}
                          label={"Insert Value of R5"}
                          type={"number"}
                          setState={(value) => setValueR5(parseInt(value))}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography sx={{ my: 2 }} align="center">
                      Result:{" "}
                    </Typography>
                  </Box>
                  <Box>
                    <Box>
                      <InputField value={ansR.n} isDisabled={true} />
                      <Divider sx={{ my: 1, borderColor: "#737373" }} />
                      <InputField value={ansR.d} isDisabled={true} />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default FractionCalc;
