import * as React from "react";
import "./Tabbing.scss";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const TabbingComponent = (props) => {
  const { data, value, setValue } = props;

  const handleAlignment = (event) => {
    setValue(event?.target?.value);
  };

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleAlignment}
      aria-label="text value"
      className="tabbing-div-common notranslate"
      sx={{ width: "100%" }}
    >
      {data.map((item, index) => (
        <ToggleButton
          key={index}
          value={item}
          aria-label={item}
          sx={{
            width: { xs: "50%", sm: 150 },
            padding: { xs: "8px", sm: "11px" },
            fontSize: { xs: "12px", md: "0.875rem" },
          }}
        >
          {item}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
export default TabbingComponent;
