import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import PieChartComponent from "../../Common/PieChart/PieChart";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import "./InvestmentCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import TableComponent from "../../Common/TableComponent/TableComponent";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import plusIcon from "../../../Assets/Icon/plusIcon.png";

function InvestmentCalc() {
  const PERIOD = [
    { label: "Year", value: "year" },
    { label: "Month", value: "month" },
  ];
  const [amountInput, setAmountInput] = useState(20000);
  const [interestValue, setInterestValue] = useState(6);
  const [yearOfInvestment, setyearOfInvestment] = useState(10);
  const [frequency, setFrequency] = useState("annually");
  const [additionalAmount, setAdditionalAmount] = useState(1000);
  const [addAmountPeriod, setAddAmountPeriod] = useState({
    name: "Year",
    value: "year",
  });
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalContribution, setTotalContribution] = useState(0);
  const [investmentTableData, setInvestmentTableData] = useState([]);
  const [tableView, setTableView] = useState(false);

  useEffect(() => {
    const calculateCompoundInterest = () => {
      // Convert annual rate to decimal
      const rate = +interestValue / 100;

      // Calculate the number of times interest is compounded per year
      let n;
      switch (frequency) {
        case "annually":
          n = 1;
          break;
        case "semiannually":
          n = 2;
          break;
        case "quarterly":
          n = 4;
          break;
        case "monthly":
          n = 12;
          break;
        case "semimonthly":
          n = 24; // 12 months * 2 (semimonthly)
          break;
        case "biweekly":
          n = 26; // 52 weeks / 2 (biweekly)
          break;
        case "weekly":
          n = 52;
          break;
        case "daily":
          n = 365;
          break;
        default:
          throw new Error("Invalid compounding frequency");
      }

      let totalContributions;
      if (addAmountPeriod?.value === "year") {
        totalContributions =
          Number(additionalAmount) * Number(+yearOfInvestment);
      } else if (addAmountPeriod?.value === "month") {
        totalContributions =
          Number(additionalAmount) * Number(+yearOfInvestment) * 12;
      } else {
        throw new Error("Invalid additional amount period");
      }

      // Calculate the end balance including additional contribution
      let amount = Number(amountInput);
      let totalInterestAccumulated = 0;
      const investmentData = [];
      if (addAmountPeriod?.value === "year") {
        // Loop for each period (yearly)
        for (let i = 0; i < Number(+yearOfInvestment); i++) {
          let compoundInterestCount = 0;
          for (let index = 0; index < n; index++) {
            const compoundInterestValue = Number(+interestValue) / Number(n);
            const data =
              ((amount + compoundInterestCount) * compoundInterestValue) / 100;
            compoundInterestCount += data;
          }
          // Update total interest accumulated
          totalInterestAccumulated +=
            Number(additionalAmount) *
            Math.pow(1 + rate / n, n * Number(+yearOfInvestment) - i - 1);
          // Add data for the current period to the investment table data
          investmentData?.push({
            period: i + 1,
            deposit:
              i === 0
                ? (Number(amountInput) + Number(additionalAmount)).toFixed(2)
                : Number(additionalAmount).toFixed(2),
            interest: compoundInterestCount.toFixed(2),
            endingBalance: (
              amount +
              compoundInterestCount +
              Number(additionalAmount)
            ).toFixed(2),
          });

          amount += Number(additionalAmount) + compoundInterestCount;
        }
      } else if (addAmountPeriod?.value === "month") {
        // Loop for each period (monthly)
        for (let i = 0; i < Number(+yearOfInvestment) * 12; i++) {
          // Calculate interest for the current month
          const FV = amount * Math.pow(1 + rate / n, n / 12);
          const countedInterest = FV - amount;
          // Add data for the current period to the investment table data
          investmentData?.push({
            period: i + 1,
            deposit:
              i === 0
                ? (Number(amountInput) + Number(additionalAmount)).toFixed(2)
                : Number(additionalAmount).toFixed(2),
            interest: Number(countedInterest).toFixed(2),
            endingBalance: (
              Number(amount) +
              Number(countedInterest) +
              Number(additionalAmount)
            ).toFixed(2),
          });
          // Update amount for the next month
          amount += Number(additionalAmount);
          amount += Number(countedInterest);
        }
      }

      amount += totalInterestAccumulated;

      const endBalance =
        investmentData[investmentData.length - 1]?.endingBalance;
      const totalInterest = endBalance - amountInput - totalContributions;

      setInvestmentTableData(investmentData);
      return {
        endBalance: Number(endBalance).toFixed(2),
        totalContributions: Number(totalContributions).toFixed(2),
        totalInterest: Number(totalInterest).toFixed(2),
      };
    };

    const result = calculateCompoundInterest();
    setTotalAmount(result.endBalance);
    setTotalContribution(result.totalContributions);
    setTotalInterest(result.totalInterest);
  }, [
    amountInput,
    interestValue,
    yearOfInvestment,
    frequency,
    additionalAmount,
    addAmountPeriod,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <InputComponent
                isInput={true}
                flag="rupee"
                label="Starting Amount"
                value={amountInput}
                min={1}
                setState={setAmountInput}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Rate of Interest (p.a.)"
                setState={setInterestValue}
                value={interestValue}
                min={0}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Time Period"
                setState={setyearOfInvestment}
                value={yearOfInvestment}
                min={0}
                max={199}
              />
              <Grid container className="select-box">
                <Grid item sm={5}>
                  <Typography className="select-frequency">
                    Select Your Compound frequency
                  </Typography>
                </Grid>
                <Grid item sm={1}></Grid>
                <Grid item sm={6} className="select-box-wrap">
                  <SelectBoxComponent
                    value={frequency}
                    setValue={setFrequency}
                    data={[
                      "annually",
                      "semiannually",
                      "quarterly",
                      "monthly",
                      "semimonthly",
                      "biweekly",
                      "weekly",
                      "daily",
                    ]}
                    placeholder="Compounding Frequency"
                  />
                </Grid>
              </Grid>
              <InputComponent
                isInput={true}
                flag="rupee"
                label="Additional Contribution"
                setState={setAdditionalAmount}
                value={additionalAmount}
                max={10000000000}
              />
              <RadioButtonComponent
                label="Contribute at"
                data={PERIOD}
                defaultValue={"year"}
                valueSetter={setAddAmountPeriod}
                row={true}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Starting Amount"
                isInput={false}
                value={amountInput}
              />
              <InputComponent
                label="Total Contributions"
                isInput={false}
                value={totalContribution}
              />
              <InputComponent
                label="Total Interest"
                isInput={false}
                value={totalInterest}
              />
              <InputComponent
                label="Total Amount"
                isInput={false}
                value={totalAmount}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              flag="invest"
              data={[
                { id: 0, value: +amountInput, label: "Starting Amount" },
                {
                  id: 1,
                  value: +totalContribution,
                  label: "Total Contribution",
                },
                { id: 2, value: +totalInterest, label: "Interest Amount" },
              ]}
            />
          </Grid>
        </Grid>
        <Grid className="data-table-container">
          <Grid className="table-toggle-div">
            <Typography variant="h5">
              Your Amortization Details{" "}
              <span>
                (<u>Yearly/Monthly</u>)
              </span>
            </Typography>
            <Tooltip
              title={tableView ? "click to close table" : "click to open table"}
              placement="top"
              arrow
            >
              <span>
                <img
                  src={plusIcon}
                  alt="plusIcon"
                  onClick={() => setTableView(!tableView)}
                  style={{
                    transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                  className="add-icon-table"
                />
              </span>
            </Tooltip>
          </Grid>
          {tableView && (
            <Grid>
              <Grid>
                <TableComponent
                  rows={investmentTableData}
                  headerData={[
                    addAmountPeriod.name,
                    "Deposit",
                    "Interest",
                    "Ending balance",
                  ]}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default InvestmentCalc;
