import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import dayjs from "dayjs";
import CheckboxGroup from "../../Common/Checkbox/Checkbox";
import DatePickerCommon from "../../Common/Datepicker/DatePickerCommon";
import PieChartComponent from "../../Common/PieChart/PieChart";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import TableComponent from "../../Common/TableComponent/TableComponent";

function FHALoanCalc() {
  const [homePrice, setHomePrice] = useState(500000);
  const [downPayment, setDownPayment] = useState(3.5);
  const [loanTerm, setLoanTerm] = useState(30);
  const [interestRate, setInterestRate] = useState(6.161);
  const [upfrontFhaMip, setUpfrontFhaMip] = useState(1.75);
  const [annualFhaMip, setAnnualFhaMip] = useState(0.55);
  const [annualFhaMipDuration, setAnnualFhaMipDuration] = useState("Loan Term");
  const [OptionalCheckBox, setOptionalCheckBox] = useState(true);
  const [propertyTaxes, setPropertyTaxes] = useState(1.2);
  const [homeInsurance, setHomeInsurance] = useState(2500);
  const [hoaFee, setHoaFee] = useState(0);
  const [otherCosts, setOtherCosts] = useState(5000);
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [isFewerOptions, setIsFewerOptions] = useState(false);
  const [propertyTaxesIncrease, setPropertyTaxesIncrease] = useState(0);
  const [homeInsuranceIncrease, setHomeInsuranceIncrease] = useState(0);
  const [hoaFeeIncrease, setHoaFeeIncrease] = useState(0);
  const [otherCostsIncrease, setOtherCostsIncrease] = useState(0);
  const [extraMonthlyPay, setExtraMonthlyPay] = useState(0);
  const [extraYearlyPay, setExtraYearlyPay] = useState(0);
  const [extraOnetimePay, setExtraOnetimePay] = useState(0);
  const [biweeklyCheckBox, setBiweeklyCheckBox] = useState(false);
  const [housePriceValue, setHousePriceValue] = useState(0);
  const [loanAmountWithUpfrontMip, setLoanAmountWithUpfrontMip] = useState(0);
  const [downPaymentValue, setDownPaymentValue] = useState(0);
  const [upfrontMip, setUpfrontMip] = useState(0);
  const [totalOfMortgagePayments, setTotalOfMortgagePayments] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [mortgagePayoffDate, setMortgagePayoffDate] = useState(0);
  const [annualMipPayoffDate, setAnnualMipPayoffDate] = useState(0);
  const [biWeeklyPayment, setBiWeeklyPayment] = useState(0);
  const [totalInterestBiWeekly, setTotalInterestBiWeekly] = useState(0);
  const [payoff, setPayoff] = useState(0);
  const [extraPayment, setExtraPayment] = useState(0);
  const [loanPaidOffTime, setLoanPaidOffTime] = useState({
    totalMonths: 0,
    years: 0,
    months: 0,
  });
  const [resultDataMonthly, setResultDataMonthly] = useState({
    mortgagePayment: 0,
    extraPayment: 0,
    propertyTax: 0,
    homeInsurance: 0,
    annualMIP: 0,
    hoaFees: 0,
    otherCosts: 0,
    totalOutOfPocket: 0,
  });
  const [resultDataTotal, setResultDataTotal] = useState({
    mortgagePayment: 0,
    extraPayment: 0,
    propertyTax: 0,
    homeInsurance: 0,
    annualMIP: 0,
    hoaFees: 0,
    otherCosts: 0,
    totalOutOfPocket: 0,
  });
  const [tableView, setTableView] = useState(false);
  const [monthlyRecords, setMonthlyRecords] = useState([]);

  useEffect(() => {
    const calculateLoanDetails = () => {
      // Calculate house price values
      const downPaymentValue = (+homePrice * +downPayment) / 100;
      const loanAmount = +homePrice - +downPaymentValue;
      const upfrontMipValue = (+homePrice * +upfrontFhaMip) / 100;
      const loanAmountWithUpfrontMipValue = +loanAmount + +upfrontMipValue;

      // Determine LTV ratio
      const LTV = (+loanAmount / +homePrice) * 100;

      // Calculate the annual FHA MIP based on loan term, loan amount, and LTV
      let annualFhaMip;

      if (loanTerm > 15) {
        // Loan term is longer than 15 years
        if (loanAmount <= 726200) {
          annualFhaMip = LTV <= 95 ? 0.5 : 0.55;
        } else {
          annualFhaMip = LTV <= 95 ? 0.7 : 0.75;
        }
      } else {
        // Loan term is 15 years or less
        if (loanAmount <= 726200) {
          annualFhaMip = LTV <= 90 ? 0.15 : 0.4;
        } else {
          if (LTV <= 78) {
            annualFhaMip = 0.15;
          } else if (LTV <= 90) {
            annualFhaMip = 0.4;
          } else {
            annualFhaMip = 0.65;
          }
        }
      }

      // Calculate monthly payment
      const monthlyInterestRate = +interestRate / 100 / 12;
      const numberOfPayments = +loanTerm * 12;
      const monthlyPayment =
        (+loanAmountWithUpfrontMipValue *
          +monthlyInterestRate *
          Math.pow(1 + +monthlyInterestRate, +numberOfPayments)) /
        (Math.pow(1 + +monthlyInterestRate, +numberOfPayments) - 1);

      // Calculate monthly costs
      const monthlyPropertyTax = (+homePrice * +propertyTaxes) / 100 / 12;
      const monthlyHomeInsurance = +homeInsurance / 12;
      const monthlyHOA = +hoaFee / 12;
      const monthlyOtherCosts = +otherCosts / 12;
      const monthlyMIP = (+loanAmount * +annualFhaMip) / 100 / 12;

      let totalMonthlyPayment =
        +monthlyPayment +
        +monthlyPropertyTax +
        +monthlyHomeInsurance +
        +monthlyHOA +
        +monthlyOtherCosts +
        +extraMonthlyPay +
        +extraYearlyPay +
        +extraOnetimePay;

      // Calculate bi-weekly payment
      const biweeklyInterestRate = +interestRate / 100 / 26;
      const biWeeklyPaymentValue = +monthlyPayment / 2;
      const numPayments =
        Math.log(
          +biWeeklyPaymentValue /
            (+biWeeklyPaymentValue -
              +loanAmountWithUpfrontMip * +biweeklyInterestRate)
        ) / Math.log(1 + +biweeklyInterestRate);

      const payoffYears = +numPayments / 26;
      const totalPayment = +biWeeklyPaymentValue * +numPayments;
      const biWeeklyTotalInterest = +totalPayment - +loanAmountWithUpfrontMip;

      let objOfPaidOffLoan = {
        years: 0,
        months: 0,
        totalMonths: +loanTerm * 12,
      };
      const mortgagePayoffDateValue = new Date(startDate);
      mortgagePayoffDateValue.setFullYear(
        mortgagePayoffDateValue.getFullYear() + +objOfPaidOffLoan.years
      );
      mortgagePayoffDateValue.setMonth(
        mortgagePayoffDateValue.getMonth() + objOfPaidOffLoan.months
      );

      const options = { year: "numeric", month: "short" };
      const formattedMortgagePayoffDate =
        mortgagePayoffDateValue.toLocaleDateString("en-US", options);

      const annualMipPayoffDateValue = new Date(startDate);
      annualMipPayoffDateValue.setMonth(
        annualMipPayoffDateValue.getMonth() + 147
      );

      const formattedAnnualMipPayoffDate =
        annualMipPayoffDateValue.toLocaleDateString("en-US", options);

      const generateMonthlyRecords = (
        loanAmount,
        monthlyPayment,
        monthlyInterestRate,
        loanStartDate,
        loanTerm, // in years
        extraMonthlyPay = 0, // Extra monthly payment
        extraYearlyPay = 0, // Extra yearly payment
        extraOnetimePay = 0 // One-time extra payment
      ) => {
        setMonthlyRecords([]);

        if (
          loanAmount > 0 &&
          monthlyPayment > 0 &&
          monthlyInterestRate > 0 &&
          loanTerm > 0
        ) {
          const records = [];

          let extraPayment = 0;
          let totalPropertyTax = 0;
          let totalInterestValue = 0;

          // Initialize the amortization table
          let currentLoanBalance = +loanAmountWithUpfrontMipValue.toFixed(2);
          let totalHomeInsurance = 0;
          let totalHoaFees = 0;
          let totalOtherCost = 0;

          let totalResults = {
            propertyTax: 0,
            homeInsurance: 0,
            annualMIP: 0,
            hoaFees: 0,
            otherCosts: 0,
          };

          for (let year = 1; year <= +loanTerm; year++) {
            let yearPrincipal = 0;
            let yearInterest = 0;
            let yearPropertyTax = monthlyPropertyTax;
            let yearHomeInsurance = 0;
            let yearHoaFees = 0;
            let yearOtherCosts = 0;

            // Apply yearly extra payment at the beginning of each year
            if (year === 1) {
              currentLoanBalance -= +extraOnetimePay;
              extraPayment += +extraOnetimePay;
              yearPrincipal += +extraOnetimePay; // Add one-time extra payment to yearPrincipal
            }

            totalResults.propertyTax += yearPropertyTax;
            totalResults.homeInsurance += yearHomeInsurance;
            totalResults.hoaFees += yearHoaFees;
            totalResults.otherCosts += yearOtherCosts;
            // Apply yearly extra payment
            currentLoanBalance -= +extraYearlyPay;
            extraPayment += +extraYearlyPay;
            yearPrincipal += +extraYearlyPay; // Add yearly extra payment to yearPrincipal

            for (let month = 1; month <= 12; month++) {
              // Apply monthly extra payment before interest calculation
              currentLoanBalance -= +extraMonthlyPay;
              extraPayment += +extraMonthlyPay;
              yearPrincipal += +extraMonthlyPay; // Add monthly extra payment to yearPrincipal

              // Ensure balance doesn't go negative
              if (currentLoanBalance < 0) currentLoanBalance = 0;

              let interestPaid =
                +currentLoanBalance.toFixed(6) *
                +monthlyInterestRate.toFixed(6);
              let principalPaid = +monthlyPayment - +interestPaid;

              // Reduce loan balance
              currentLoanBalance -= principalPaid;

              // Ensure balance doesn't go negative
              if (currentLoanBalance < 0 && month !== 12) {
                currentLoanBalance = 0;
                extraPayment += -extraMonthlyPay;

                if (month === 12) {
                  objOfPaidOffLoan = {
                    years: year,
                    months: 0,
                    totalMonths: (year - 1) * 12 + month,
                  };
                } else {
                  objOfPaidOffLoan = {
                    years: year - 1,
                    months: month,
                    totalMonths: (year - 1) * 12 + month,
                  };
                }

                // Accumulate property taxes, insurance, HOA fees, and other costs
                yearPropertyTax =
                  (yearPropertyTax *
                    12 *
                    Math.pow(1 + propertyTaxesIncrease / 100, year - 1)) /
                  (12 / month);
                yearHomeInsurance =
                  (homeInsurance *
                    Math.pow(1 + homeInsuranceIncrease / 100, year - 1)) /
                  (12 / month);
                yearHoaFees =
                  hoaFee * Math.pow(1 + hoaFeeIncrease / 100, year - 1);
                yearOtherCosts =
                  (+otherCosts *
                    Math.pow(1 + otherCostsIncrease / 100, year - 1)) /
                  (12 / month);

                totalPropertyTax += +yearPropertyTax;
                totalHomeInsurance += +yearHomeInsurance;
                totalHoaFees += yearHoaFees;
                totalOtherCost += yearOtherCosts;

                totalInterestValue += +yearInterest.toFixed(2);
                records.push({
                  year: year,
                  interestPaid: +yearInterest.toFixed(2),
                  principalPaid: +yearPrincipal.toFixed(2),
                  propertyTax: +yearPropertyTax.toFixed(2),
                  homeInsurance: +yearHomeInsurance.toFixed(2),
                  hoaFees: +yearHoaFees.toFixed(2),
                  otherCosts: +yearOtherCosts.toFixed(2),
                  endingBalance: 0.0,
                });

                break;
              }

              yearPrincipal += principalPaid;
              yearInterest += interestPaid;
            }

            // Push yearly data if the loan balance is still greater than zero
            if (currentLoanBalance > 0 || year === loanTerm) {
              yearPropertyTax =
                yearPropertyTax *
                12 *
                Math.pow(1 + propertyTaxesIncrease / 100, year - 1);
              yearHomeInsurance =
                homeInsurance *
                Math.pow(1 + homeInsuranceIncrease / 100, year - 1);
              yearHoaFees =
                hoaFee * Math.pow(1 + hoaFeeIncrease / 100, year - 1);
              yearOtherCosts =
                otherCosts * Math.pow(1 + otherCostsIncrease / 100, year - 1);
              totalPropertyTax += +yearPropertyTax;
              totalHomeInsurance += +yearHomeInsurance;
              totalHoaFees += +yearHoaFees;
              totalOtherCost += +yearOtherCosts;

              totalInterestValue += +yearInterest.toFixed(2);
              objOfPaidOffLoan = {
                months: 0,
                years: year,
                totalMonths: year * 12,
              };

              records.push({
                year: year,
                interestPaid: +yearInterest.toFixed(2),
                principalPaid: +yearPrincipal.toFixed(2),
                propertyTax: +yearPropertyTax.toFixed(2),
                homeInsurance: +yearHomeInsurance.toFixed(2),
                hoaFees: +yearHoaFees.toFixed(2),
                otherCosts: +yearOtherCosts.toFixed(2),
                endingBalance: +currentLoanBalance.toFixed(2),
              });
            } else {
              break;
            }
          }
          let mipNumberOfPayments;
          if (annualFhaMipDuration === "11 Years") {
            mipNumberOfPayments = 11 * 12;
          } else if (annualFhaMipDuration === "5 Years") {
            mipNumberOfPayments = 5 * 12;
          } else if (annualFhaMipDuration === "78% LTV") {
            mipNumberOfPayments = 12 * 12;
          } else {
            mipNumberOfPayments = +objOfPaidOffLoan?.totalMonths;
          }
          let totalPayments =
            +loanAmountWithUpfrontMip - +extraPayment + +totalInterestValue;
          // After the amortization loop, calculate the total out-of-pocket expenses
          totalResults.mortgagePayment = totalPayments;

          totalResults.propertyTax =
            propertyTaxesIncrease > 0
              ? totalPropertyTax
              : +monthlyPropertyTax * +objOfPaidOffLoan.totalMonths;
          totalResults.homeInsurance =
            homeInsuranceIncrease > 0
              ? +totalHomeInsurance
              : +monthlyHomeInsurance * +objOfPaidOffLoan.totalMonths;

          totalResults.hoaFees =
            hoaFeeIncrease > 0
              ? totalHoaFees
              : +monthlyHOA * +objOfPaidOffLoan.totalMonths;
          totalResults.otherCosts =
            otherCostsIncrease > 0
              ? totalOtherCost
              : +monthlyOtherCosts * +objOfPaidOffLoan.totalMonths;

          totalResults.annualMIP = +monthlyMIP * +objOfPaidOffLoan.totalMonths;

          totalResults.totalOutOfPocket =
            totalResults.hoaFees +
            totalResults.homeInsurance +
            totalResults.mortgagePayment +
            totalResults.otherCosts +
            +totalResults.propertyTax +
            +extraPayment;
          setLoanPaidOffTime(objOfPaidOffLoan);
          // Set the results to state once after all calculations
          if (annualFhaMipDuration === "No Annual MIP") {
            totalMonthlyPayment += 0;
            totalResults.totalOutOfPocket += 0;
          } else {
            totalMonthlyPayment += +monthlyMIP;
            totalResults.totalOutOfPocket += +monthlyMIP * +mipNumberOfPayments;
          }
          setResultDataTotal(totalResults);

          // Update state with calculated records
          setMonthlyRecords(records);
          setTotalInterest(totalInterestValue);
          setTotalOfMortgagePayments(
            +loanAmountWithUpfrontMipValue + +totalInterestValue - +extraPayment
          );
          setExtraPayment(+extraPayment);
        }
      };

      setHousePriceValue(homePrice);
      setLoanAmountWithUpfrontMip(loanAmountWithUpfrontMipValue);
      setDownPaymentValue(downPaymentValue);
      setUpfrontMip(upfrontMipValue);
      // setTotalOfMortgagePayments(totalPayments);
      // setTotalInterest(totalInterestValue);
      setMortgagePayoffDate(formattedMortgagePayoffDate);
      setAnnualMipPayoffDate(formattedAnnualMipPayoffDate);
      setBiWeeklyPayment(biWeeklyPaymentValue);
      setTotalInterestBiWeekly(biWeeklyTotalInterest);
      setPayoff(payoffYears.toFixed(2));
      generateMonthlyRecords(
        loanAmountWithUpfrontMipValue,
        monthlyPayment,
        monthlyInterestRate,
        startDate,
        loanTerm,
        extraMonthlyPay,
        extraYearlyPay,
        extraOnetimePay
      );

      setResultDataMonthly({
        mortgagePayment: monthlyPayment,
        extraPayment: +extraMonthlyPay + +extraYearlyPay + +extraOnetimePay,
        propertyTax: monthlyPropertyTax,
        homeInsurance: monthlyHomeInsurance,
        annualMIP: monthlyMIP,
        hoaFees: hoaFee / 12,
        otherCosts: monthlyOtherCosts,
        totalOutOfPocket: +totalMonthlyPayment,
      });
    };

    calculateLoanDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    homePrice,
    downPayment,
    loanTerm,
    interestRate,
    upfrontFhaMip,
    annualFhaMip,
    annualFhaMipDuration,
    propertyTaxes,
    homeInsurance,
    hoaFee,
    otherCosts,
    startDate,
    propertyTaxesIncrease,
    homeInsuranceIncrease,
    hoaFeeIncrease,
    otherCostsIncrease,
    extraMonthlyPay,
    extraYearlyPay,
    extraOnetimePay,
    OptionalCheckBox,
    biweeklyCheckBox,
    isFewerOptions,
  ]);

  const handleCheckboxChange = (newSelectedValues, flag) => {
    if (flag === "first") {
      if (newSelectedValues[0] === "Include Optionals Below") {
        setOptionalCheckBox(true);
      } else {
        setOptionalCheckBox(false);
      }
    } else {
      if (newSelectedValues[0] === "Show Biweekly Payback Results") {
        setBiweeklyCheckBox(true);
      } else {
        setBiweeklyCheckBox(false);
      }
    }
  };

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Home Price"
                value={homePrice}
                min={0}
                setState={setHomePrice}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Down Payment"
                value={downPayment}
                min={0}
                setState={setDownPayment}
                max={100}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Loan Term"
                value={loanTerm}
                min={0}
                setState={setLoanTerm}
                max={50}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Interest Rate"
                value={interestRate}
                min={0}
                setState={setInterestRate}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Upfront FHA MIP"
                value={upfrontFhaMip}
                min={0}
                setState={setUpfrontFhaMip}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Annual FHA MIP"
                value={annualFhaMip}
                min={0}
                setState={setAnnualFhaMip}
                max={999}
              />
              <SelectBoxComponent
                value={annualFhaMipDuration}
                setValue={setAnnualFhaMipDuration}
                data={[
                  "Loan Term",
                  "11 Years",
                  "5 Years",
                  "78% LTV",
                  "No Annual MIP",
                ]}
                label="Annual FHA MIP Duration"
              />
              <CheckboxGroup
                data={[
                  {
                    label: "Include Optionals Below",
                    value: "Include Optionals Below",
                  },
                ]}
                valueSetter={(e) => handleCheckboxChange(e, "first")}
                defaultValues={["Include Optionals Below"]}
                row={true}
              />
              {OptionalCheckBox && (
                <>
                  <InputComponent
                    isInput={true}
                    flag="percentage"
                    label="Property Taxes"
                    value={propertyTaxes}
                    min={0}
                    setState={setPropertyTaxes}
                    max={999}
                  />
                  <InputComponent
                    isInput={true}
                    flag="dollar"
                    label="Home Insurance"
                    value={homeInsurance}
                    min={0}
                    setState={setHomeInsurance}
                    max={10000000000}
                  />
                  <InputComponent
                    isInput={true}
                    flag="dollar"
                    label="HOA Fee"
                    value={hoaFee}
                    min={0}
                    setState={setHoaFee}
                    max={10000000000}
                  />
                  <InputComponent
                    isInput={true}
                    flag="dollar"
                    label="Other Costs"
                    value={otherCosts}
                    min={0}
                    setState={setOtherCosts}
                    max={10000000000}
                  />
                  <DatePickerCommon
                    label="Start Date"
                    value={startDate}
                    setValue={setStartDate}
                    enableFutureDate={true}
                  />
                  {isFewerOptions ? (
                    <Typography
                      className="common-sub-heading-calc center-align"
                      onClick={() => setIsFewerOptions(!isFewerOptions)}
                    >
                      - Fewer Options
                    </Typography>
                  ) : (
                    <Typography
                      className="common-sub-heading-calc center-align"
                      onClick={() => {
                        setIsFewerOptions(!isFewerOptions);
                        setBiweeklyCheckBox(false);
                      }}
                    >
                      + More Options
                    </Typography>
                  )}
                  {isFewerOptions && (
                    <>
                      <Typography className="common-sub-heading-calc">
                        Annual Tax & Cost Increase
                      </Typography>
                      <InputComponent
                        isInput={true}
                        flag="percentage"
                        label="Property Taxes Increase"
                        value={propertyTaxesIncrease}
                        min={0}
                        setState={setPropertyTaxesIncrease}
                        max={999}
                      />{" "}
                      <InputComponent
                        isInput={true}
                        flag="percentage"
                        label="Home Insurance Increase"
                        value={homeInsuranceIncrease}
                        min={0}
                        setState={setHomeInsuranceIncrease}
                        max={999}
                      />{" "}
                      <InputComponent
                        isInput={true}
                        flag="percentage"
                        label="HOA Fee Increase"
                        value={hoaFeeIncrease}
                        min={0}
                        setState={setHoaFeeIncrease}
                        max={999}
                      />{" "}
                      <InputComponent
                        isInput={true}
                        flag="percentage"
                        label="Other Costs Increase"
                        value={otherCostsIncrease}
                        min={0}
                        setState={setOtherCostsIncrease}
                        max={999}
                      />
                      <Typography className="common-sub-heading-calc">
                        Extra Payments
                      </Typography>
                      <InputComponent
                        isInput={true}
                        flag="dollar"
                        label="Extra Monthly Pay"
                        value={extraMonthlyPay}
                        min={0}
                        setState={setExtraMonthlyPay}
                        max={10000000000}
                      />{" "}
                      <InputComponent
                        isInput={true}
                        flag="dollar"
                        label="Extra Yearly Pay"
                        value={extraYearlyPay}
                        min={0}
                        setState={setExtraYearlyPay}
                        max={10000000000}
                      />{" "}
                      <InputComponent
                        isInput={true}
                        flag="dollar"
                        label="Extra One-time Pay"
                        value={extraOnetimePay}
                        min={0}
                        setState={setExtraOnetimePay}
                        max={10000000000}
                      />
                      <CheckboxGroup
                        data={[
                          {
                            label: "Show Biweekly Payback Results",
                            value: "Show Biweekly Payback Results",
                          },
                        ]}
                        valueSetter={handleCheckboxChange}
                        defaultValues={false}
                        row={true}
                      />
                    </>
                  )}
                </>
              )}
            </Grid>

            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              {(extraMonthlyPay > 0 ||
                extraYearlyPay > 0 ||
                extraOnetimePay > 0) && (
                <InputComponent
                  isInput={false}
                  value={`With the extra payment, the loan will be paid off in ${loanPaidOffTime?.years} years and ${loanPaidOffTime?.months} months.`}
                  date={true}
                />
              )}
              <Typography className="common-sub-heading-calc">
                House Price
              </Typography>
              <InputComponent
                label="House Price"
                isInput={false}
                flag="dollar"
                value={housePriceValue}
              />
              <InputComponent
                label="Loan Amount with Upfront MIP"
                isInput={false}
                flag="dollar"
                value={loanAmountWithUpfrontMip}
              />
              <InputComponent
                label="Down Payment"
                isInput={false}
                flag="dollar"
                value={downPaymentValue}
              />
              <InputComponent
                label="Upfront MIP"
                isInput={false}
                flag="dollar"
                value={upfrontMip}
              />
              <InputComponent
                label={`Total of ${loanPaidOffTime?.totalMonths} Mortgage Payments`}
                isInput={false}
                flag="dollar"
                value={totalOfMortgagePayments}
              />
              <InputComponent
                label="Total Interest"
                isInput={false}
                flag="dollar"
                value={totalInterest}
              />
              {OptionalCheckBox &&
                isFewerOptions &&
                (extraMonthlyPay > 0 ||
                  extraYearlyPay > 0 ||
                  extraOnetimePay > 0) && (
                  <InputComponent
                    label="Extra Payment"
                    isInput={false}
                    flag="dollar"
                    value={extraPayment}
                  />
                )}
              <InputComponent
                label="Mortgage Payoff Date"
                isInput={false}
                date={true}
                value={mortgagePayoffDate}
              />
              {annualFhaMipDuration !== "Loan Term" &&
                annualFhaMipDuration !== "No Annual MIP" && (
                  <InputComponent
                    label="Annual MIP Payoff Date (147 Total Payments)"
                    isInput={false}
                    date={true}
                    value={annualMipPayoffDate}
                  />
                )}
              {OptionalCheckBox && (
                <>
                  <Typography className="common-sub-heading-calc">
                    Monthly
                  </Typography>
                  <InputComponent
                    label="Mortgage Payment"
                    isInput={false}
                    flag="dollar"
                    value={resultDataMonthly?.mortgagePayment}
                  />
                  {isFewerOptions &&
                    (extraMonthlyPay > 0 ||
                      extraYearlyPay > 0 ||
                      extraOnetimePay > 0) && (
                      <InputComponent
                        label="Extra Payment"
                        isInput={false}
                        flag="dollar"
                        value={resultDataMonthly?.extraPayment}
                      />
                    )}
                  <InputComponent
                    label="Property Tax"
                    isInput={false}
                    flag="dollar"
                    value={resultDataMonthly?.propertyTax}
                  />
                  <InputComponent
                    label="Home Insurance"
                    isInput={false}
                    flag="dollar"
                    value={resultDataMonthly?.homeInsurance}
                  />
                  {annualFhaMipDuration !== "No Annual MIP" && (
                    <InputComponent
                      label="Annual MIP"
                      isInput={false}
                      flag="dollar"
                      value={resultDataMonthly?.annualMIP}
                    />
                  )}
                  {hoaFee > 0 && (
                    <InputComponent
                      label="HOA Fee"
                      isInput={false}
                      flag="dollar"
                      value={resultDataMonthly?.hoaFees}
                    />
                  )}
                  <InputComponent
                    label="Other Costs"
                    isInput={false}
                    flag="dollar"
                    value={resultDataMonthly?.otherCosts}
                  />
                  <InputComponent
                    label="Total Out-of-Pocket"
                    isInput={false}
                    flag="dollar"
                    value={resultDataMonthly?.totalOutOfPocket}
                  />
                  <Typography className="common-sub-heading-calc">
                    Total
                  </Typography>
                  <InputComponent
                    label="Mortgage Payment"
                    isInput={false}
                    flag="dollar"
                    value={resultDataTotal?.mortgagePayment}
                  />
                  {isFewerOptions &&
                    (extraMonthlyPay > 0 ||
                      extraYearlyPay > 0 ||
                      extraOnetimePay > 0) && (
                      <InputComponent
                        label="Extra Payment"
                        isInput={false}
                        flag="dollar"
                        value={extraPayment}
                      />
                    )}
                  <InputComponent
                    label="Property Tax"
                    isInput={false}
                    flag="dollar"
                    value={resultDataTotal?.propertyTax}
                  />
                  <InputComponent
                    label="Home Insurance"
                    isInput={false}
                    flag="dollar"
                    value={resultDataTotal?.homeInsurance}
                  />
                  {annualFhaMipDuration !== "No Annual MIP" && (
                    <InputComponent
                      label="Annual MIP"
                      isInput={false}
                      flag="dollar"
                      value={resultDataTotal?.annualMIP}
                    />
                  )}
                  {hoaFee > 0 && (
                    <InputComponent
                      label="HOA Fee"
                      isInput={false}
                      flag="dollar"
                      value={resultDataTotal?.hoaFees}
                    />
                  )}
                  <InputComponent
                    label="Other Costs"
                    isInput={false}
                    flag="dollar"
                    value={resultDataTotal?.otherCosts}
                  />
                  <InputComponent
                    label="Total Out-of-Pocket"
                    isInput={false}
                    flag="dollar"
                    value={resultDataTotal?.totalOutOfPocket}
                  />
                  {isFewerOptions ? (
                    biweeklyCheckBox && (
                      <>
                        <Typography className="common-sub-heading-calc">
                          If Payback Bi-weekly
                        </Typography>
                        <InputComponent
                          label="Bi-weekly Payment"
                          isInput={false}
                          flag="dollar"
                          value={biWeeklyPayment}
                        />
                        <InputComponent
                          label="Total Interest"
                          isInput={false}
                          flag="dollar"
                          value={totalInterestBiWeekly}
                        />
                        <InputComponent
                          label="Payoff"
                          isInput={false}
                          flag="year"
                          date={true}
                          value={`${payoff} years`}
                        />
                      </>
                    )
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              flag="usa-income"
              data={[
                {
                  id: 0,
                  value: +loanAmountWithUpfrontMip,
                  label: "Principal",
                },
                {
                  id: 1,
                  value: +totalInterest,
                  label: "Interest",
                },
                {
                  id: 2,
                  value: +resultDataTotal?.propertyTax,
                  label: "Property Taxes",
                },
                {
                  id: 3,
                  value: +resultDataTotal?.annualMIP,
                  label: "Annual MIP",
                },
                {
                  id: 4,
                  value: +resultDataTotal?.otherCosts,
                  label: "Other Cost",
                },
                {
                  id: 5,
                  value: +resultDataTotal?.homeInsurance,
                  label: "Home Insurance",
                },
                hoaFee > 0 && {
                  id: 6,
                  value: +resultDataTotal?.hoaFees,
                  label: "HOA Fee",
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid className="data-table-container">
          <Grid className="table-toggle-div">
            <Typography variant="h5">
              Your Amortization Details{" "}
              <span>
                (<u>Annually</u>)
              </span>
            </Typography>
            <Tooltip
              title={tableView ? "click to close table" : "click to open table"}
              placement="top"
              arrow
            >
              <span>
                <img
                  src={plusIcon}
                  alt="plusIcon"
                  onClick={() => setTableView(!tableView)}
                  style={{
                    transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                  className="add-icon-table"
                />
              </span>
            </Tooltip>
          </Grid>
          {tableView && (
            <Grid>
              <Grid>
                <TableComponent
                  rows={monthlyRecords}
                  headerData={[
                    "Year",
                    "Interest",
                    "Principal",
                    "PT",
                    "Home-INS",
                    "HOA",
                    "OTHER Tax",
                    "Ending Balance",
                  ]}
                  currency={"dollar"}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default FHALoanCalc;
