import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import InputComponent from "../../Common/InputComponent/InputComponent";
import SelectBoxModified from "../CalorieCalc/SelectBoxModified";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import {
  calculateBMR,
  GENDERS,
  ACTIVITY,
  CARBO_FORMULAS,
} from "../../../Utils/calorieHelper";
import TableComponent from "../../Common/TableComponent/TableComponent";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import UnitConversion from "../../Common/UnitConversion/UnitConversion";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

const ACTIVITY_NAMES = ACTIVITY?.slice(1)?.map((item) => ({
  name: item.name,
  value: item.value,
}));

export default function CarbohydrateCalc() {
  const [weight, setWeight] = useState({ unit: "kg", value: 70 });
  const [height, setHeight] = useState({ unit: "cm", value: 180 });
  const [unitConvert, setUnitConvert] = useState(false);

  const [age, setAge] = useState(25);
  const [gender, setGender] = useState({ name: "Male", value: "male" });
  const [fat, setFat] = useState(0.2);
  const [BMRFormula, setBMRFormula] = useState({
    name: "Mifflin-St Jeor",
    value: "MJ",
  });

  const [activity, setActivity] = useState({
    name: "Moderate: exercise 4-5 times/week",
    value: "moderate",
  });
  const [showSettings, setShowSettings] = useState(false);
  const [carbData, setCarbData] = useState([]);

  useEffect(() => {
    let weightInKG =
      weight.unit === "kg" ? weight.value : weight.value / 2.20462;
    let heigthInCM =
      height.unit === "cm"
        ? height.value
        : height.value.ft * 30.48 + height.value.in * 2.54;

    const bmrValue = calculateBMR(
      weightInKG,
      heigthInCM,
      age,
      fat,
      BMRFormula.value,
      gender.value
    );

    const activityMultiplier = ACTIVITY?.slice(1)?.find(
      (x) => x.value === activity.value
    )?.goals[0].multiplier;
    const tempTdee = bmrValue * activityMultiplier;
    let calorieGoals;
    if (gender.value === "male") {
      calorieGoals = [
        { goal: "Weight Maintenance", adjustment: 0 },
        { goal: "Lose 0.5 kg/week", adjustment: -500 },
        { goal: "Lose 1 kg/week", adjustment: -1000 },
        { goal: "Gain 0.5 kg/week", adjustment: 500 },
        { goal: "Gain 1 kg/week", adjustment: 1000 },
      ];
    } else {
      calorieGoals = [
        { goal: "Weight Maintenance", adjustment: 6 },
        { goal: "Lose 0.5 kg/week", adjustment: -494 },
        { goal: "Lose 1 kg/week", adjustment: -994 },
        { goal: "Gain 0.5 kg/week", adjustment: 506 },
        { goal: "Gain 1 kg/week", adjustment: 1006 },
      ];
    }

    const carbPercentages = [0.4, 0.55, 0.65, 0.75];
    const tableData = calorieGoals.map(({ goal, adjustment }) => {
      const adjustedCalories = tempTdee + adjustment;
      const carbs = carbPercentages.map((percentage) =>
        Math.round((adjustedCalories * percentage) / 3.75)
      );
      return {
        goal,
        adjustedCalories: Math.round(adjustedCalories),
        carbs40: carbs[0],
        carbs55: carbs[1],
        carbs65: carbs[2],
        carbs75: carbs[3],
      };
    });
    setCarbData(tableData);
  }, [weight, height, gender, age, fat, BMRFormula, activity]);

  const weightUnitSetter = (value) =>
    setWeight((prev) => ({ ...prev, unit: value }));
  const weightValueSetter = (event) => {
    const value = parseFloat(event.target.value);
    setWeight((prev) => ({ ...prev, value: Math.min(value, 500) }));
  };

  const heightUnitSetter = (value) => {
    if (value === "ft") {
      setHeight({ unit: value, value: { ft: 5, in: 8 } });
    } else if (value === "cm") {
      setHeight({ unit: value, value: 170 });
    }
  };

  const heightCMValueSetter = (value) =>
    setHeight((prev) => ({ ...prev, value: Math.min(value, 260) }));
  const heightFTValueSetter = (value) =>
    setHeight((prev) => ({
      ...prev,
      value: { ...prev.value, ft: Math.min(value, 8) },
    }));
  const heightINValueSetter = (value) =>
    setHeight((prev) => ({
      ...prev,
      value: { ...prev.value, in: Math.min(value, 12) },
    }));

  const fatSetter = (value) => setFat(() => Math.min(value / 100, 1));

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Grid item className="calculation-part">
              <Grid>
                <InputComponent
                  label="Age"
                  isInput={true}
                  value={age}
                  setState={setAge}
                  min={18}
                  max={80}
                />
                <RadioButtonComponent
                  label={"Gender"}
                  data={GENDERS}
                  defaultValue={"male"}
                  valueSetter={setGender}
                  row={false}
                />
                {height.unit === "cm" && (
                  <InputComponent
                    label="Height (cm)"
                    isInput={true}
                    value={height.value}
                    setState={heightCMValueSetter}
                    min={1}
                    max={200}
                  />
                )}

                {height.unit === "ft" && (
                  <>
                    <InputComponent
                      label="Height (ft)"
                      isInput={true}
                      value={height.value.ft}
                      setState={heightFTValueSetter}
                      min={1}
                      max={10}
                    />

                    <InputComponent
                      label="Height (in)"
                      isInput={true}
                      value={height.value.in}
                      setState={heightINValueSetter}
                      min={1}
                      max={20}
                    />
                  </>
                )}
                <SelectBoxComponent
                  label={"Height Unit"}
                  value={height.unit}
                  setValue={heightUnitSetter}
                  data={["cm", "ft"]}
                  placeholder="Select height unit"
                />

                <Grid>
                  <Grid className="concrete-calc-input pace">
                    <Typography className="input-label">Weight</Typography>
                    <Grid sx={{ display: "flex" }}>
                      <input
                        type="number"
                        value={weight.value}
                        onChange={weightValueSetter}
                        className="input-field"
                      />

                      <Grid className="select-box-wrap">
                        <SelectBoxComponent
                          value={weight.unit}
                          setValue={weightUnitSetter}
                          data={["kg", "lb"]}
                          placeholder="Select weight unit"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Typography
                variant="h6"
                sx={{ mx: 1, fontWeight: 500, fontSize: "18px" }}
              >
                Activity
              </Typography>

              <SelectBoxModified
                value={activity.value}
                valueSetter={setActivity}
                data={ACTIVITY_NAMES}
                placeholder="Select your activity"
              />

              <Grid
                id="settings"
                sx={{ ml: 1.5 }}
                className="data-table-container"
              >
                <Grid item>
                  <Grid className="table-toggle-div">
                    <Typography
                      variant="h6"
                      sx={{ color: "black", fontWeight: 600 }}
                    >
                      Settings
                    </Typography>
                    <Tooltip
                      title={
                        showSettings
                          ? "click to close setting"
                          : "click to open setting"
                      }
                      placement="top"
                      arrow
                    >
                      <span>
                        <img
                          src={plusIcon}
                          alt="plusIcon"
                          onClick={() => setShowSettings((prev) => !prev)}
                          style={{
                            transform: showSettings
                              ? "rotate(45deg)"
                              : "rotate(0deg)",
                          }}
                          className="add-icon-table"
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                  {showSettings && (
                    <>
                      <Grid item>
                        <RadioButtonComponent
                          label={"BMR estimation formula"}
                          data={CARBO_FORMULAS}
                          defaultValue={"MJ"}
                          valueSetter={setBMRFormula}
                          row={false}
                        />

                        {BMRFormula.value === "KM" && (
                          <InputComponent
                            label="Body Fat (%)"
                            isInput={true}
                            value={fat * 100}
                            setState={fatSetter}
                          />
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              {!unitConvert ? (
                <Grid sx={{ textAlign: "center" }}>
                  <Button
                    onClick={(e) => setUnitConvert(!unitConvert)}
                    variant="contained"
                  >
                    Unit Conversion
                  </Button>
                </Grid>
              ) : (
                <UnitConversion setUnitConvert={setUnitConvert} />
              )}
              <Grid className="result-label">
                <Typography>Result</Typography>
              </Grid>

              <Grid>
                <InputComponent
                  isInput={false}
                  value={
                    "It is recommended that carbohydrates comprise 40-75% of daily caloric intake."
                  }
                  date={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid item>
              <Grid className="data-table-container">
                <Grid className="table-toggle-div">
                  <Typography variant="h6" className="common-sub-heading-calc">
                    40-75% of daily caloric intake:
                  </Typography>
                </Grid>
                <Grid>
                  <Grid>
                    <TableComponent
                      headerData={[
                        "Goal",
                        "Daily Calorie Allowance",
                        "40%",
                        "55%",
                        "65%",
                        "75%",
                      ]}
                      rows={carbData.map((row) => ({
                        goal: row.goal,
                        adjustedCalories: `${row.adjustedCalories} Calories`,
                        carbs40: `${row.carbs40} grams`,
                        carbs55: `${row.carbs55} grams`,
                        carbs65: `${row.carbs65} grams`,
                        carbs75: `${row.carbs75} grams`,
                      }))}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
