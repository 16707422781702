import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import InputComponent from "../../Common/InputComponent/InputComponent";
import PieChartComponent from "../../Common/PieChart/PieChart";

function RDCalc() {
  const [monthlySipAmount, setMonthlySipAmount] = useState(50000);
  const [sipInterval, setSipInterval] = useState(3);
  const [expectInterest, setExpectInterest] = useState(6.5);
  const [investedAmount, setInvestedAmount] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [estimateReturns, setEstimateReturns] = useState(0);

  useEffect(() => {
    var quarterlyReturnRate = +expectInterest / 100 / 4;
    var totalMonths = +sipInterval * 12;

    var futureValue = 0;
    var totalInvestment = 0;

    for (let i = 1; i <= +totalMonths; i++) {
      let maturityValue =
        +monthlySipAmount * Math.pow(1 + +quarterlyReturnRate, (4 * i) / 12);
      futureValue += +maturityValue;
      totalInvestment += +monthlySipAmount;
    }

    setTotalValue(+futureValue?.toFixed(2));
    setInvestedAmount(+totalInvestment?.toFixed(2));
    setEstimateReturns((+futureValue - +totalInvestment)?.toFixed(2));
  }, [monthlySipAmount, sipInterval, expectInterest]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid>
          <Grid container>
            <Grid item xs={12} md={7} className="calculation-part">
              <Grid>
                <InputComponent
                  isInput={true}
                  flag="rupee"
                  label="Monthly investment"
                  value={monthlySipAmount}
                  min={1}
                  setState={setMonthlySipAmount}
                  max={10000000000}
                />
                <InputComponent
                  isInput={true}
                  flag="percentage"
                  label="Rate of interest (p.a)"
                  setState={setExpectInterest}
                  value={expectInterest}
                  min={0}
                  max={999}
                />
                <InputComponent
                  isInput={true}
                  flag="year"
                  label="Time Period"
                  setState={setSipInterval}
                  value={sipInterval}
                  min={0}
                  max={199}
                />
              </Grid>
              <Grid className="result-label">
                <Typography>Result</Typography>
              </Grid>
              <Grid className="result-common-div">
                <InputComponent
                  label="Invested Amount"
                  isInput={false}
                  value={investedAmount}
                />
                <InputComponent
                  label="Estimate Return"
                  isInput={false}
                  value={estimateReturns}
                />
                <InputComponent
                  label="Total Value"
                  isInput={false}
                  value={totalValue}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={5} sx={{ display: "flex" }}>
              <PieChartComponent
                data={[
                  { id: 0, value: +investedAmount, label: "Invested Amount" },
                  { id: 1, value: +estimateReturns, label: "Est. Returns" },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default RDCalc;
