import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import "./Checkbox.scss";

export default function CheckboxGroup({
  label,
  data,
  valueSetter,
  row,
  defaultValues,
  type,
}) {
  const [selectedValues, setSelectedValues] = useState(defaultValues || []);

  const handleChange = (item) => {
    const updatedValues = selectedValues.includes(item.value)
      ? selectedValues.filter((value) => value !== item.value)
      : [...selectedValues, item.value];

    setSelectedValues(updatedValues);
    valueSetter(updatedValues);
  };

  return (
    <>
      <Grid
        container
        sx={{ my: 1.5, mx: 1 }}
        justifyContent={"flex-start"}
        alignItems="center"
      >
        <Grid item xs={12} md={12}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "18px",
            }}
          >
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} sx={{ alignItems: "end" }}>
          <FormGroup row={Boolean(row)}>
            {data.map((item) => (
              <FormControlLabel
                key={item.value}

                control={
                  <Checkbox
                    checked={selectedValues.includes(item.value)}
                    onChange={() => handleChange(item)}
                  />
                }
                label={item.label}
                sx={{ width: "100%" ,fontWeight: 700,}}
              />
            ))}
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
}
