/* eslint-disable no-sparse-arrays */
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import dayjs from "dayjs";
import CheckboxGroup from "../../Common/Checkbox/Checkbox";
import DatePickerCommon from "../../Common/Datepicker/DatePickerCommon";
import PieChartComponent from "../../Common/PieChart/PieChart";
import plusIcon from "../../../Assets/Icon/plusIcon.png";

import {
  BOOLEANS,
  VAEligibility,
  VAFundingFee,
} from "../../../Utils/calorieHelper";
import TableComponent from "../../Common/TableComponent/TableComponent";

function VAMortgageCalc() {
  const [homePrice, setHomePrice] = useState(500000);
  const [downPayment, setDownPayment] = useState(0);
  const [loanTerm, setLoanTerm] = useState(30);
  const [tableView, setTableView] = useState(false);

  const [interestRate, setInterestRate] = useState(6.243);
  const [vaEligibility, setVaEligibility] = useState({
    label: "Active/Veteran",
    value: "activeV",
  });
  const [loanBefore, setLoanBefore] = useState({ label: "No", value: "no" });
  const [disability, setDisability] = useState({ label: "No", value: "no" });
  const [vaFunding, setVaFunding] = useState({
    label: "Financed Into Loan",
    value: "FinancedLoan",
  });
  const [propertyTaxes, setPropertyTaxes] = useState(1.2);
  const [homeInsurance, setHomeInsurance] = useState(2500);
  const [hoaFee, setHoaFee] = useState(0);
  const [otherCosts, setOtherCosts] = useState(5000);
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [isFewerOptions, setIsFewerOptions] = useState(false);
  const [propertyTaxesIncrease, setPropertyTaxesIncrease] = useState(0);
  const [homeInsuranceIncrease, setHomeInsuranceIncrease] = useState(0);
  const [hoaFeeIncrease, setHoaFeeIncrease] = useState(0);
  const [otherCostsIncrease, setOtherCostsIncrease] = useState(0);
  const [extraMonthlyPay, setExtraMonthlyPay] = useState(0);
  const [extraYearlyPay, setExtraYearlyPay] = useState(0);
  const [extraOnetimePay, setExtraOnetimePay] = useState(0);
  const [biweeklyCheckBox, setBiweeklyCheckBox] = useState(false);
  const [housePriceValue, setHousePriceValue] = useState(0);
  const [vaFundingFee, setVaFundingFee] = useState(0);
  const [downPaymentValue, setDownPaymentValue] = useState(0);
  const [mortgagePaymentMonth, setMortgagePaymentMonth] = useState(
    loanTerm * 12
  );
  const [extraTotalPayment, setExtraTotalPayment] = useState(0);
  const [loanAmount, setLoanAmount] = useState(0);
  const [totalOfMortgagePayments, setTotalOfMortgagePayments] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [mortgagePayoffDate, setMortgagePayoffDate] = useState(0);
  const [biWeeklyPayment, setBiWeeklyPayment] = useState(0);
  const [totalInterestBiWeekly, setTotalInterestBiWeekly] = useState(0);
  const [payoff, setPayoff] = useState(0);
  const [amortizationTable, setAmortizationTable] = useState([]);
  const [extraPaymentLine, setExtraPaymentLine] = useState("");
  const [resultDataMonthly, setResultDataMonthly] = useState({
    mortgagePayment: 0,
    extraPayment: +extraMonthlyPay + +extraOnetimePay + +extraYearlyPay,
    propertyTax: 0,
    homeInsurance: 0,
    hoaFees: 0,
    otherCosts: 0,
    totalOutOfPocket: 0,
  });
  const [resultDataTotal, setResultDataTotal] = useState({
    mortgagePayment: 0,
    propertyTax: 0,
    homeInsurance: 0,
    hoaFees: 0,
    otherCosts: 0,
    totalOutOfPocket: 0,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculateVaFundingFee = () => {
    let rate;
    const downPaymentPercentage = (+downPaymentValue / +homePrice) * 100;

    if (disability.value === "yes" || vaEligibility.value === "survivingS") {
      rate = 0;
    } else {
      if (downPaymentPercentage < 5) {
        rate = loanBefore.value === "yes" ? 0.033 : 0.0215;
      } else if (downPaymentPercentage >= 5 && downPaymentPercentage < 10) {
        rate = 0.015;
      } else {
        rate = 0.0125;
      }
    }

    const vaFundingFeeValue = (homePrice - downPaymentValue) * rate;

    return vaFundingFeeValue;
  };

  useEffect(() => {
    const calculateLoanDetails = () => {
      const downPaymentValue = (+homePrice * +downPayment) / 100;
      const totalResults = {
        mortgagePayment: 0,
        propertyTax: 0,
        homeInsurance: 0,
        hoaFees: 0,
        otherCosts: 0,
        totalOutOfPocket: 0,
      };
      const vaFundingFeeValue = calculateVaFundingFee();

      // Calculate loan amount based on whether the VA funding fee is financed
      const loanAmount =
        loanBefore.value === "yes" && vaFundingFeeValue === 0
          ? +homePrice - +downPaymentValue
          : +homePrice - +downPaymentValue + +vaFundingFeeValue;

      const monthlyInterestRate = +interestRate / 100 / 12;
      let numberOfPayments = +loanTerm * 12;
      const monthlyPayment =
        (+loanAmount *
          +monthlyInterestRate *
          Math.pow(1 + +monthlyInterestRate, +numberOfPayments)) /
        (Math.pow(1 + +monthlyInterestRate, +numberOfPayments) - 1);

      let totalInterestValue = 0;

      const monthlyPropertyTax = (+homePrice * +propertyTaxes) / 100 / 12;
      const monthlyHomeInsurance = +homeInsurance / 12;
      const monthlyHOA = +hoaFee / 12;
      const monthlyOtherCosts = +otherCosts / 12;
      let totalMonthlyPayment =
        +monthlyPayment +
        +monthlyPropertyTax +
        +monthlyHomeInsurance +
        +monthlyHOA +
        +monthlyOtherCosts +
        +extraMonthlyPay;

      // Initialize the amortization table
      let tableData = [];
      let currentLoanBalance = loanAmount;
      let extraPayment = 0;

      let totalPropertyTax = 0;
      let totalHomeInsurance = 0;
      let totalHoaFees = 0;
      let totalOtherCost = 0;
      let paidOffYearTotal = 0; // Store the year when the loan is paid off
      let paidOffMonthTotal = 0; // Store the month when the loan is paid off

      for (let year = 1; year <= loanTerm; year++) {
        let yearPrincipal = 0;
        let yearInterest = 0;
        let yearPropertyTax = monthlyPropertyTax;
        let yearHomeInsurance = 0;
        let yearHoaFees = 0;
        let yearOtherCosts = 0;

        // Apply yearly extra payment at the beginning of each year
        if (year === 1) {
          currentLoanBalance -= +extraOnetimePay;
          extraPayment += +extraOnetimePay;
          yearPrincipal += +extraOnetimePay; // Add one-time extra payment to yearPrincipal
        }
        totalResults.propertyTax += yearPropertyTax;
        totalResults.homeInsurance += yearHomeInsurance;
        totalResults.hoaFees += yearHoaFees;
        totalResults.otherCosts += yearOtherCosts;
        // Apply yearly extra payment
        currentLoanBalance -= +extraYearlyPay;
        extraPayment += +extraYearlyPay;
        yearPrincipal += +extraYearlyPay; // Add yearly extra payment to yearPrincipal

        for (let month = 1; month <= 12; month++) {
          // Apply monthly extra payment before interest calculation
          currentLoanBalance -= +extraMonthlyPay;
          extraPayment += +extraMonthlyPay;
          yearPrincipal += +extraMonthlyPay; // Add monthly extra payment to yearPrincipal

          // Ensure balance doesn't go negative
          if (currentLoanBalance < 0) currentLoanBalance = 0;

          let interestPaid =
            +currentLoanBalance.toFixed(6) * +monthlyInterestRate.toFixed(7);
          let principalPaid = +monthlyPayment - +interestPaid;

          // Reduce loan balance
          currentLoanBalance -= principalPaid;

          // Ensure balance doesn't go negative
          if (currentLoanBalance < 0 && month !== 12) {
            currentLoanBalance = 0;
            setMortgagePaymentMonth((year - 1) * 12 + month);
            extraPayment -= +extraMonthlyPay;
            paidOffYearTotal = year - 1;
            paidOffMonthTotal = month;
            numberOfPayments = (year - 1) * 12 + month;

            if (month === 12) {
              setExtraPaymentLine(
                `With the extra payment, the loan will be paid off in ${year} years and 0 months.`
              );
            } else {
              setExtraPaymentLine(
                `With the extra payment, the loan will be paid off in ${
                  year - 1
                } years and ${month} months.`
              );
            }

            // Accumulate property taxes, insurance, HOA fees, and other costs
            yearPropertyTax =
              (yearPropertyTax *
                12 *
                Math.pow(1 + propertyTaxesIncrease / 100, year - 1)) /
              (12 / month);
            yearHomeInsurance =
              (homeInsurance *
                Math.pow(1 + homeInsuranceIncrease / 100, year - 1)) /
              (12 / month);
            yearHoaFees = hoaFee * Math.pow(1 + hoaFeeIncrease / 100, year - 1);
            yearOtherCosts =
              (+otherCosts * Math.pow(1 + otherCostsIncrease / 100, year - 1)) /
              (12 / month);

            totalPropertyTax += yearPropertyTax;
            totalHomeInsurance += yearHomeInsurance;
            totalHoaFees += yearHoaFees;
            totalOtherCost += yearOtherCosts;

            totalInterestValue += +yearInterest.toFixed(2);
            isFewerOptions
              ? tableData.push({
                  year: year,
                  interestPaid: +yearInterest.toFixed(2),
                  principalPaid: +yearPrincipal.toFixed(2),
                  propertyTax: +yearPropertyTax.toFixed(2),
                  homeInsurance: +yearHomeInsurance.toFixed(2),
                  hoaFees: +yearHoaFees.toFixed(2),
                  otherCosts: +yearOtherCosts.toFixed(2),
                  endingBalance: 0.0,
                })
              : tableData.push({
                  year: year,
                  interestPaid: +yearInterest.toFixed(2),
                  principalPaid: +yearPrincipal.toFixed(2),
                  endingBalance: 0.0,
                });

            break;
          }

          yearPrincipal += principalPaid;
          yearInterest += interestPaid;
        }

        // Push yearly data if the loan balance is still greater than zero
        if (currentLoanBalance > 0) {
          yearPropertyTax =
            yearPropertyTax *
            12 *
            Math.pow(1 + propertyTaxesIncrease / 100, year - 1);
          yearHomeInsurance =
            homeInsurance * Math.pow(1 + homeInsuranceIncrease / 100, year - 1);
          yearHoaFees = hoaFee * Math.pow(1 + hoaFeeIncrease / 100, year - 1);
          yearOtherCosts =
            otherCosts * Math.pow(1 + otherCostsIncrease / 100, year - 1);
          totalPropertyTax += +yearPropertyTax;
          totalHomeInsurance += yearHomeInsurance;
          totalHoaFees += yearHoaFees;
          totalOtherCost += yearOtherCosts;

          totalInterestValue += +yearInterest.toFixed(2);
          paidOffYearTotal = year;
          paidOffMonthTotal = 0;
          isFewerOptions
            ? tableData.push({
                year: year,
                interestPaid: +yearInterest.toFixed(2),
                principalPaid: +yearPrincipal.toFixed(2),
                propertyTax: +yearPropertyTax.toFixed(2),
                homeInsurance: +yearHomeInsurance.toFixed(2),
                hoaFees: +yearHoaFees.toFixed(2),
                otherCosts: +yearOtherCosts.toFixed(2),
                endingBalance: +currentLoanBalance.toFixed(2),
              })
            : tableData.push({
                year: year,
                interestPaid: +yearInterest.toFixed(2),
                principalPaid: +yearPrincipal.toFixed(2),

                endingBalance: +currentLoanBalance.toFixed(2),
              });
        } else {
          // Loan has been paid off, break the outer loop as well

          break;
        }
      }
      let totalPayments = +loanAmount - +extraPayment + +totalInterestValue;
      // After the amortization loop, calculate the total out-of-pocket expenses
      totalResults.mortgagePayment = totalPayments;
      totalResults.propertyTax =
        propertyTaxesIncrease > 0
          ? totalPropertyTax
          : +monthlyPropertyTax * +numberOfPayments;
      totalResults.homeInsurance =
        homeInsuranceIncrease > 0
          ? totalHomeInsurance
          : +monthlyHomeInsurance * +numberOfPayments;
      totalResults.hoaFees =
        hoaFeeIncrease > 0 ? totalHoaFees : +monthlyHOA * +numberOfPayments;
      totalResults.otherCosts =
        otherCostsIncrease > 0
          ? totalOtherCost
          : +monthlyOtherCosts * +numberOfPayments;

      totalResults.totalOutOfPocket =
        totalResults.hoaFees +
        totalResults.homeInsurance +
        totalResults.mortgagePayment +
        totalResults.otherCosts +
        +totalResults.propertyTax +
        +extraPayment;

      // Set the results to state once after all calculations
      setResultDataTotal(totalResults);

      setExtraTotalPayment(+extraPayment.toFixed(2));

      // Add bi-weekly payment calculation
      const biweeklyInterestRate = +interestRate / 100 / 26;
      const biWeeklyPaymentValue = +monthlyPayment / 2;
      const numPayments =
        Math.log(
          +biWeeklyPaymentValue /
            (+biWeeklyPaymentValue - +loanAmount * +biweeklyInterestRate)
        ) / Math.log(1 + +biweeklyInterestRate);

      const payoffYears = Math.floor(numPayments / 26);
      const payoffMonths = Math.round((numPayments % 26) / 2.17);

      const totalPayment = +biWeeklyPaymentValue * +numPayments;
      const biWeeklyTotalInterest = +totalPayment - +loanAmount;

      // Calculate mortgage payoff date
      const mortgagePayoffDateValue = new Date(startDate);
      mortgagePayoffDateValue.setFullYear(
        mortgagePayoffDateValue.getFullYear() + paidOffYearTotal
      );
      mortgagePayoffDateValue.setMonth(
        mortgagePayoffDateValue.getMonth() + paidOffMonthTotal
      );

      const options = { year: "numeric", month: "short" };
      const formattedMortgagePayoffDate =
        mortgagePayoffDateValue.toLocaleDateString("en-US", options);

      // Set results to state
      setHousePriceValue(homePrice);
      setVaFundingFee(vaFundingFeeValue);
      setDownPaymentValue(downPaymentValue);
      setLoanAmount(loanAmount);
      setTotalOfMortgagePayments(totalPayments);
      setTotalInterest(totalInterestValue);
      setMortgagePayoffDate(formattedMortgagePayoffDate);

      setBiWeeklyPayment(biWeeklyPaymentValue);
      setTotalInterestBiWeekly(biWeeklyTotalInterest);
      setPayoff(`${payoffYears} years and ${payoffMonths} months`);

      setAmortizationTable(tableData);

      setResultDataMonthly({
        mortgagePayment: monthlyPayment,
        extraPayment: +extraMonthlyPay + +extraOnetimePay + +extraYearlyPay,
        propertyTax: monthlyPropertyTax,
        homeInsurance: monthlyHomeInsurance,
        hoaFees: monthlyHOA,
        otherCosts: monthlyOtherCosts,
        totalOutOfPocket: totalMonthlyPayment,
      });
    };

    calculateLoanDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    homePrice,
    downPayment,
    loanTerm,
    interestRate,
    propertyTaxes,
    homeInsurance,
    hoaFee,
    otherCosts,
    startDate,
    isFewerOptions,
    propertyTaxesIncrease,
    homeInsuranceIncrease,
    hoaFeeIncrease,
    otherCostsIncrease,
    extraMonthlyPay,
    extraYearlyPay,
    extraOnetimePay,
    biweeklyCheckBox,
    vaEligibility,
    loanBefore,
    disability,
    vaFunding,
    downPaymentValue,
  ]);

  const handleCheckboxChange = (newSelectedValues) => {
    if (newSelectedValues[0] === "Show Biweekly Payback Results") {
      setBiweeklyCheckBox(true);
    } else {
      setBiweeklyCheckBox(false);
    }
  };

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Home Price"
                value={homePrice}
                min={0}
                setState={setHomePrice}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Down Payment"
                value={downPayment}
                min={0}
                setState={setDownPayment}
                max={100}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Loan Term"
                value={loanTerm}
                min={0}
                setState={setLoanTerm}
                max={50}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Interest Rate"
                value={interestRate}
                min={0}
                setState={setInterestRate}
                max={999}
              />
              <RadioButtonComponent
                label={"VA Eligibility"}
                data={VAEligibility}
                defaultValue={"activeV"}
                valueSetter={setVaEligibility}
                row={true}
              />
              <RadioButtonComponent
                label={"Used VA Loan Before?"}
                data={BOOLEANS}
                defaultValue={"no"}
                valueSetter={setLoanBefore}
                row={true}
              />
              <RadioButtonComponent
                label={"Service-Related Disability (10+%)?"}
                data={BOOLEANS}
                defaultValue={"no"}
                valueSetter={setDisability}
                row={true}
              />
              <RadioButtonComponent
                label={"VA Funding Fee"}
                data={VAFundingFee}
                defaultValue={"FinancedLoan"}
                valueSetter={setVaFunding}
                row={true}
              />

              {isFewerOptions ? (
                <Typography
                  className="common-sub-heading-calc center-align"
                  onClick={() => setIsFewerOptions(!isFewerOptions)}
                >
                  - Less Options
                </Typography>
              ) : (
                <Typography
                  className="common-sub-heading-calc center-align"
                  onClick={() => {
                    setIsFewerOptions(!isFewerOptions);
                    setBiweeklyCheckBox(false);
                  }}
                >
                  + More Options
                </Typography>
              )}
              {isFewerOptions && (
                <>
                  <InputComponent
                    isInput={true}
                    flag="percentage"
                    label="Property Taxes"
                    value={propertyTaxes}
                    min={0}
                    setState={setPropertyTaxes}
                    max={999}
                  />
                  <InputComponent
                    isInput={true}
                    flag="dollar"
                    label="Home Insurance"
                    value={homeInsurance}
                    min={0}
                    setState={setHomeInsurance}
                    max={10000000000}
                  />
                  <InputComponent
                    isInput={true}
                    flag="dollar"
                    label="HOA Fee"
                    value={hoaFee}
                    min={0}
                    setState={setHoaFee}
                    max={10000000000}
                  />
                  <InputComponent
                    isInput={true}
                    flag="dollar"
                    label="Other Costs"
                    value={otherCosts}
                    min={0}
                    setState={setOtherCosts}
                    max={10000000000}
                  />
                  <DatePickerCommon
                    label="Start Date"
                    value={startDate}
                    setValue={setStartDate}
                    enableFutureDate={true}
                  />
                  <Typography className="common-sub-heading-calc">
                    Annual Tax & Cost Increase
                  </Typography>
                  <InputComponent
                    isInput={true}
                    flag="percentage"
                    label="Property Taxes Increase"
                    value={propertyTaxesIncrease}
                    min={0}
                    setState={setPropertyTaxesIncrease}
                    max={999}
                  />{" "}
                  <InputComponent
                    isInput={true}
                    flag="percentage"
                    label="Home Insurance Increase"
                    value={homeInsuranceIncrease}
                    min={0}
                    setState={setHomeInsuranceIncrease}
                    max={999}
                  />{" "}
                  <InputComponent
                    isInput={true}
                    flag="percentage"
                    label="HOA Fee Increase"
                    value={hoaFeeIncrease}
                    min={0}
                    setState={setHoaFeeIncrease}
                    max={999}
                  />{" "}
                  <InputComponent
                    isInput={true}
                    flag="percentage"
                    label="Other Costs Increase"
                    value={otherCostsIncrease}
                    min={0}
                    setState={setOtherCostsIncrease}
                    max={999}
                  />
                  <Typography className="common-sub-heading-calc">
                    Extra Payments
                  </Typography>
                  <InputComponent
                    isInput={true}
                    flag="dollar"
                    label="Extra Monthly Pay"
                    value={extraMonthlyPay}
                    min={0}
                    setState={setExtraMonthlyPay}
                    max={10000000000}
                  />{" "}
                  <InputComponent
                    isInput={true}
                    flag="dollar"
                    label="Extra Yearly Pay"
                    value={extraYearlyPay}
                    min={0}
                    setState={setExtraYearlyPay}
                    max={10000000000}
                  />{" "}
                  <InputComponent
                    isInput={true}
                    flag="dollar"
                    label="Extra One-time Pay"
                    value={extraOnetimePay}
                    min={0}
                    setState={setExtraOnetimePay}
                    max={10000000000}
                  />
                  <CheckboxGroup
                    data={[
                      {
                        label: "Show Biweekly Payback Results",
                        value: "Show Biweekly Payback Results",
                      },
                    ]}
                    valueSetter={handleCheckboxChange}
                    defaultValues={false}
                    row={true}
                  />
                </>
              )}
            </Grid>

            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              {(extraMonthlyPay > 0 ||
                extraOnetimePay > 0 ||
                extraYearlyPay > 0) && (
                <InputComponent
                  isInput={false}
                  date={true}
                  value={extraPaymentLine}
                />
              )}
              <Typography className="common-sub-heading-calc">
                House Price
              </Typography>
              <InputComponent
                label="House Price"
                isInput={false}
                flag="dollar"
                value={housePriceValue}
              />
              <InputComponent
                label={`VA Funding Fee ${
                  vaEligibility?.name === "Surviving Spouses" ||
                  disability?.name === "Yes"
                    ? "(0%)"
                    : loanBefore?.name === "Yes"
                    ? "(3.3%)"
                    : "(2.15%)"
                }`}
                isInput={false}
                flag="dollar"
                value={vaFundingFee}
              />
              <InputComponent
                label="Down Payment"
                isInput={false}
                flag="dollar"
                value={downPaymentValue}
              />
              <InputComponent
                label="Loan Amount"
                isInput={false}
                flag="dollar"
                value={loanAmount}
              />
              <InputComponent
                label={`Total of ${mortgagePaymentMonth} Mortgage Payments`}
                isInput={false}
                flag="dollar"
                value={totalOfMortgagePayments}
              />
              <InputComponent
                label="Total Interest"
                isInput={false}
                flag="dollar"
                value={totalInterest}
              />
              {isFewerOptions &&
                (extraMonthlyPay > 0 ||
                  extraYearlyPay > 0 ||
                  extraOnetimePay > 0) && (
                  <InputComponent
                    label="Extra Payment"
                    isInput={false}
                    flag="dollar"
                    value={extraTotalPayment}
                  />
                )}
              <InputComponent
                label="Mortgage Payoff Date"
                isInput={false}
                date={true}
                value={mortgagePayoffDate}
              />

              {isFewerOptions && (
                <>
                  <Typography className="common-sub-heading-calc">
                    Monthly
                  </Typography>
                  <InputComponent
                    label="Mortgage Payment"
                    isInput={false}
                    flag="dollar"
                    value={resultDataMonthly?.mortgagePayment}
                  />
                  {isFewerOptions &&
                    (extraMonthlyPay > 0 ||
                      extraYearlyPay > 0 ||
                      extraOnetimePay > 0) && (
                      <InputComponent
                        label="Extra Payment"
                        isInput={false}
                        flag="dollar"
                        value={resultDataMonthly?.extraPayment}
                      />
                    )}
                  <InputComponent
                    label="Property Tax"
                    isInput={false}
                    flag="dollar"
                    value={resultDataMonthly?.propertyTax}
                  />
                  <InputComponent
                    label="Home Insurance"
                    isInput={false}
                    flag="dollar"
                    value={resultDataMonthly?.homeInsurance}
                  />
                  {hoaFee > 0 && (
                    <InputComponent
                      label="HOA Fee"
                      isInput={false}
                      flag="dollar"
                      value={resultDataMonthly?.hoaFees}
                    />
                  )}
                  <InputComponent
                    label="Other Costs"
                    isInput={false}
                    flag="dollar"
                    value={resultDataMonthly?.otherCosts}
                  />
                  <InputComponent
                    label="Total Out-of-Pocket"
                    isInput={false}
                    flag="dollar"
                    value={resultDataMonthly?.totalOutOfPocket}
                  />
                  <Typography className="common-sub-heading-calc">
                    Total
                  </Typography>
                  <InputComponent
                    label="Mortgage Payment"
                    isInput={false}
                    flag="dollar"
                    value={resultDataTotal?.mortgagePayment}
                  />
                  {isFewerOptions &&
                    (extraMonthlyPay > 0 ||
                      extraYearlyPay > 0 ||
                      extraOnetimePay > 0) && (
                      <InputComponent
                        label="Extra Payment"
                        isInput={false}
                        flag="dollar"
                        value={extraTotalPayment}
                      />
                    )}
                  <InputComponent
                    label="Property Tax"
                    isInput={false}
                    flag="dollar"
                    value={resultDataTotal?.propertyTax}
                  />
                  <InputComponent
                    label="Home Insurance"
                    isInput={false}
                    flag="dollar"
                    value={resultDataTotal?.homeInsurance}
                  />
                  {hoaFee > 0 && (
                    <InputComponent
                      label="HOA Fee"
                      isInput={false}
                      flag="dollar"
                      value={resultDataTotal?.hoaFees}
                    />
                  )}
                  <InputComponent
                    label="Other Costs"
                    isInput={false}
                    flag="dollar"
                    value={resultDataTotal?.otherCosts}
                  />
                  <InputComponent
                    label="Total Out-of-Pocket"
                    isInput={false}
                    flag="dollar"
                    value={resultDataTotal?.totalOutOfPocket}
                  />
                  {biweeklyCheckBox && (
                    <>
                      <Typography className="common-sub-heading-calc">
                        If Payback Bi-weekly
                      </Typography>
                      <InputComponent
                        label="Bi-weekly Payment"
                        isInput={false}
                        flag="dollar"
                        value={biWeeklyPayment}
                      />
                      <InputComponent
                        label="Total Interest"
                        isInput={false}
                        flag="dollar"
                        value={totalInterestBiWeekly}
                      />
                      <InputComponent
                        label="Payoff"
                        isInput={false}
                        flag="year"
                        value={`${payoff}`}
                        date={true}
                      />
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              flag="invest"
              data={[
                {
                  id: 0,
                  value: +loanAmount,
                  label: "Principal",
                },
                {
                  id: 1,
                  value: +totalInterest,
                  label: "Interest",
                },
                {
                  id: 2,
                  value: +resultDataTotal?.propertyTax,
                  label: "Property Taxes",
                },

                {
                  id: 3,
                  value: +resultDataTotal?.otherCosts,
                  label: "Other Cost",
                },
                {
                  id: 4,
                  value: +resultDataTotal?.homeInsurance,
                  label: "Home Insurance",
                },
                hoaFee > 0 && {
                  id: 5,
                  value: +resultDataTotal?.hoaFees,
                  label: "HOA Fee",
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid className="data-table-container">
          <Grid className="table-toggle-div">
            <Typography variant="h5">
              Your Amortization Details{" "}
              <span>
                (<u>Annually</u>)
              </span>
            </Typography>
            <Tooltip
              title={tableView ? "click to close table" : "click to open table"}
              placement="top"
              arrow
            >
              <span>
                <img
                  src={plusIcon}
                  alt="plusIcon"
                  onClick={() => setTableView(!tableView)}
                  style={{
                    transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                  className="add-icon-table"
                />
              </span>
            </Tooltip>
          </Grid>
          {tableView && (
            <Grid>
              <Grid>
                <TableComponent
                  rows={amortizationTable}
                  headerData={
                    isFewerOptions
                      ? [
                          "Year",
                          "Interest",
                          "Principal",
                          "Property Tax",
                          "Home Insurance",
                          "HOA Fees",
                          "Other Costs",
                          "Ending Balance",
                        ]
                      : ["Year", "Interest", "Principal", "Ending Balance"]
                  }
                  currency="dollar"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default VAMortgageCalc;
