import { Grid, Typography } from "@mui/material";
import React from "react";
import "./inputComponent.scss";

function InputComponentContactUs(props) {
  const { label, value, setState, isTextArea = false } = props;

  const handleChange = (e) => {
    setState(e.target.value);
  };
  return (
    <>
      <Grid className="input-div">
        <Typography className="input-label">{label}</Typography>

        <Grid
          className="common-input-parent"
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          {!isTextArea ? (
            <input
              type="text"
              id={label}
              style={{ padding: "12px" }}
              name={label}
              onChange={handleChange}
              value={value}
            />
          ) : (
            <textarea
              id={label}
              name={label}
              style={{ padding: "12px" }}
              onChange={handleChange}
              value={value}
              rows={5}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default InputComponentContactUs;
