import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import ComponentHeader from "../Header/ComponentHeader";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function RetirementCalc() {
  const [currentAge, setCurrentAge] = useState(30);
  const [retirementAge, setRetirementAge] = useState(60);
  const [lifeExpectancy, setLifeExpectancy] = useState(80);
  const [monthlyIncome, setMonthlyIncome] = useState(10000);
  const [inflation, setInflation] = useState(6);
  const [expectedReturnOnPreRetirement, setExpectedReturnOnPreRetirement] =
    useState(15);
  const [expectedReturnOnPostRetirement, setExpectedReturnOnPostRetirement] =
    useState(6);
  const [existingRetirementFund, setExistingRetirementFund] = useState(65000);

  const [annualIncome, setAnnualIncome] = useState(0);
  const [additionalRetirementFund, setAdditionalRetirementFund] = useState(0);
  const [monthlySavings, setMonthlySavings] = useState(0);
  const [corpusReturn, setCorpusReturn] = useState(8);


  useEffect(() => {
    const yearsUntilRetirement = +retirementAge - +currentAge;
    const annualIncomeRequired =
      +monthlyIncome *
      12 *
      Math.pow(1 + +inflation / 100, +yearsUntilRetirement);

    setAnnualIncome(+annualIncomeRequired.toFixed(0));

    const yearsInRetirementInMonth = (+lifeExpectancy - +retirementAge) * 12;
    const inflationAdjustedRate = (
      (1 + +corpusReturn / 100) / (1 + +inflation / 100) -
      1
    ).toFixed(6);

    const inflationAdjustedRateForMonthly = (
      Math.pow(1 + +inflationAdjustedRate, 1 / 12) - 1
    ).toFixed(6);

    const inflationAdjustedMonthlyIncome =
      +annualIncomeRequired.toFixed(0) / 12;

    function calculateRequiredCorpus(
      inflationAdjustedRateForMonthly,
      yearsInRetirementInMonth,
      inflationAdjustedMonthlyIncome,
      type = 1
    ) {
      let monthlyRate = inflationAdjustedRateForMonthly;
      let numberOfPayments = yearsInRetirementInMonth;
      let requiredCorpus;

      if (monthlyRate === 0) {
        requiredCorpus = inflationAdjustedMonthlyIncome * numberOfPayments;
      } else {
        requiredCorpus =
          inflationAdjustedMonthlyIncome *
          ((1 - Math.pow(1 + monthlyRate, -numberOfPayments)) / monthlyRate);
      }

      return +requiredCorpus.toFixed(2);
    }
    const corpusAmount = calculateRequiredCorpus(
      +inflationAdjustedRateForMonthly,
      +yearsInRetirementInMonth,
      +inflationAdjustedMonthlyIncome,
      1
    );
    setAdditionalRetirementFund(corpusAmount.toFixed(2));

    function PMT(rate, periods, presentValue, futureValue, type) {
      if (!rate || rate === 0) return -(presentValue + futureValue) / periods;
      if (!type) type = 0;

      var pvif = Math.pow(1 + rate, periods);
      var pmt = (rate / (pvif - 1)) * -(presentValue * pvif + futureValue);
      if (type === 1) {
        pmt /= 1 + rate;
      }
      return pmt;
    }

    const monthlyAmount = PMT(
      +(expectedReturnOnPostRetirement / 12 / 100),
      +yearsInRetirementInMonth,
      0,
      -(+corpusAmount - +existingRetirementFund),
      1
    );
    setMonthlySavings(monthlyAmount.toFixed(2));
  }, [
    currentAge,
    retirementAge,
    lifeExpectancy,
    monthlyIncome,
    inflation,
    expectedReturnOnPreRetirement,
    expectedReturnOnPostRetirement,
    existingRetirementFund,
    corpusReturn,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <InputComponent
                isInput={true}
                flag="year"
                label="Current Age"
                value={currentAge}
                setState={setCurrentAge}
                min={0}
                max={199}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Retirement Age"
                setState={setRetirementAge}
                value={retirementAge}
                min={0}
                max={199}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Life Expectancy"
                setState={setLifeExpectancy}
                value={lifeExpectancy}
                max={100}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Your current pre-tax income (yr)"
                setState={setExistingRetirementFund}
                value={existingRetirementFund}
                min={0}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Monthly Income Required In Retirement Years"
                setState={setMonthlyIncome}
                value={monthlyIncome}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Expected Inflation Rate (%)"
                setState={setInflation}
                value={inflation}
                min={0}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Expected Return On Investment (Pre-retirement)"
                setState={setExpectedReturnOnPreRetirement}
                value={expectedReturnOnPreRetirement}
                min={0}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Expected Return On Investment (Post-retirement)"
                setState={setExpectedReturnOnPostRetirement}
                value={expectedReturnOnPostRetirement}
                min={0}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Corpus Return Rate"
                setState={setCorpusReturn}
                value={corpusReturn}
                min={0}
                max={999}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              <InputComponent
                label="Annual Income Required Immediately After Retirement"
                isInput={false}
                flag="dollar"
                value={annualIncome}
              />
              <InputComponent
                label="Additional Retirement Fund Which Needs To Be Accumulated"
                isInput={false}
                flag="dollar"
                value={additionalRetirementFund}
              />
              <InputComponent
                label="Monthly Savings Required To Accumulate The Fund Is"
                isInput={false}
                flag="dollar"
                value={monthlySavings}
              />
            </Grid>
          </Grid>
          {/* <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +loanAmountInput, label: "Principal Amount" },
                { id: 1, value: +totalInterest, label: "Interest Amount" },
              ]}
            />
          </Grid> */}
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default RetirementCalc;
