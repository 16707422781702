import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import CheckboxGroup from "../../Common/Checkbox/Checkbox";
import PieChartComponent from "../../Common/PieChart/PieChart";

const DeductionCalcRadio = [
  { label: "Standard deduction", value: "standard deduction" },
  { label: "Itemized deductions", value: "itemized deductions" },
];

const blindOptions = [
  {
    label: "I am legally blind.",
    value: "I am legally blind.",
  },
  {
    label: "My spouse is legally blind.",
    value: "My spouse is legally blind.",
  },
  {
    label: "My spouse is 65 or older.",
    value: "My spouse is 65 or older.",
  },
];

function IRSRefundCalc() {
  const [taxYear, setTaxYear] = useState("2024");
  const [filingStatus, setFilingStatus] = useState("Single");
  const [grossIncome, setGrossIncome] = useState(50000);
  const [age, setAge] = useState(35);
  const [deduction, setDeduction] = useState({
    label: "Standard deduction",
    value: "standard deduction",
  });
  const [standardDeductions, setStandardDeductions] = useState(0);
  const [itemizedDeductions, setItemizedDeductions] = useState(0);
  const [taxesWithheld, setTaxesWithheld] = useState(0);
  const [isFewerOptions, setIsFewerOptions] = useState(false);
  const [kContributions, setKContributions] = useState(0);
  const [iraContributions, setIraContributions] = useState(0);
  const [otherDeductions, setOtherDeductions] = useState(0);
  const [taxCredits, setTaxCredits] = useState(0);
  const [irsCheckBoxOption, setIrsCheckBoxOption] = useState();
  const [taxableIncome, setTaxableIncome] = useState(0);
  const [taxesBeforeAdjustments, setTaxesBeforeAdjustments] = useState(0);
  const [taxesOwed, setTaxesOwed] = useState(0);
  const [effectiveTaxRate, setEffectiveTaxRate] = useState(0);
  const [marginalTaxRate, setMarginalTaxRate] = useState(0);

  useEffect(() => {
    const calculateTaxableIncome = () => {
      let standardDeductions = {};
      let additionalDeduction = {};
      if (taxYear === "2024") {
        standardDeductions = {
          2024: {
            Single: 14600,
            "Married Filling Jointly": 29200,
            "Married Filling Separately": 14600,
            "Head of Household": 21900,
            "Surviving Spouse": 29200,
          },
        };

        additionalDeduction = {
          Single: { age65OrBlind: 1950 },
          "Head of Household": { age65OrBlind: 1950 },
          "Married Filling Jointly": { age65OrBlind: 1550 },
          "Married Filling Separately": { age65OrBlind: 1550 },
          "Surviving Spouse": { age65OrBlind: 1550 },
        };
      } else {
        standardDeductions = {
          2025: {
            Single: 15000,
            "Married Filling Jointly": 30000,
            "Married Filling Separately": 15000,
            "Head of Household": 22500,
            "Surviving Spouse": 30000,
          },
        };

        additionalDeduction = {
          Single: { age65OrBlind: 2000 },
          "Head of Household": { age65OrBlind: 2000 },
          "Married Filling Jointly": { age65OrBlind: 1600 },
          "Married Filling Separately": { age65OrBlind: 1600 },
          "Surviving Spouse": { age65OrBlind: 1600 },
        };
      }

      let totalDeduction =
        deduction.value === "standard deduction"
          ? standardDeductions[taxYear][filingStatus]
          : itemizedDeductions;

      let additionalAmount = 0;
      if (irsCheckBoxOption?.includes("I am legally blind.")) {
        additionalAmount += additionalDeduction[filingStatus].age65OrBlind;
      }
      if (
        filingStatus === "Married Filling Jointly" &&
        irsCheckBoxOption?.includes("My spouse is legally blind.")
      ) {
        additionalAmount += additionalDeduction[filingStatus].age65OrBlind;
      }
      if (age >= 65) {
        additionalAmount += additionalDeduction[filingStatus].age65OrBlind;
      }
      if (
        filingStatus === "Married Filling Jointly" &&
        irsCheckBoxOption?.includes("My spouse is 65 or older.")
      ) {
        additionalAmount += additionalDeduction[filingStatus].age65OrBlind;
      }

      totalDeduction += additionalAmount;
      setStandardDeductions(totalDeduction);

      const totalRetirementContributions = kContributions
        ? kContributions
        : iraContributions;

      const calculatedTaxableIncome = Math.max(
        0,
        grossIncome -
          (deduction?.value === "standard deduction"
            ? totalDeduction
            : itemizedDeductions) -
          totalRetirementContributions -
          otherDeductions
      );

      setTaxableIncome(calculatedTaxableIncome);
      return calculatedTaxableIncome;
    };

    const calculateTaxes = (taxableInc) => {
      const taxBrackets2024 = {
        Single: [
          { threshold: 11600, rate: 0.1 },
          { threshold: 47150, rate: 0.12 },
          { threshold: 100525, rate: 0.22 },
          { threshold: 191950, rate: 0.24 },
          { threshold: 243725, rate: 0.32 },
          { threshold: 609350, rate: 0.35 },
          { threshold: Infinity, rate: 0.37 },
        ],
        "Head of Household": [
          { threshold: 16550, rate: 0.1 },
          { threshold: 63100, rate: 0.12 },
          { threshold: 100500, rate: 0.22 },
          { threshold: 191950, rate: 0.24 },
          { threshold: 243700, rate: 0.32 },
          { threshold: 609350, rate: 0.35 },
          { threshold: Infinity, rate: 0.37 },
        ],
        "Married Filling Jointly": [
          { threshold: 23200, rate: 0.1 },
          { threshold: 94300, rate: 0.12 },
          { threshold: 201050, rate: 0.22 },
          { threshold: 383900, rate: 0.24 },
          { threshold: 487450, rate: 0.32 },
          { threshold: 731200, rate: 0.35 },
          { threshold: Infinity, rate: 0.37 },
        ],
        "Married Filling Separately": [
          { threshold: 11600, rate: 0.1 },
          { threshold: 47150, rate: 0.12 },
          { threshold: 100525, rate: 0.22 },
          { threshold: 191950, rate: 0.24 },
          { threshold: 243725, rate: 0.32 },
          { threshold: 365600, rate: 0.35 },
          { threshold: Infinity, rate: 0.37 },
        ],
      };

      const taxBrackets2025 = {
        Single: [
          { threshold: 11925, rate: 0.1 },
          { threshold: 48475, rate: 0.12 },
          { threshold: 103350, rate: 0.22 },
          { threshold: 197300, rate: 0.24 },
          { threshold: 250525, rate: 0.32 },
          { threshold: 626350, rate: 0.35 },
          { threshold: Infinity, rate: 0.37 },
        ],
        "Head of Household": [
          { threshold: 17000, rate: 0.1 },
          { threshold: 64850, rate: 0.12 },
          { threshold: 103350, rate: 0.22 },
          { threshold: 197300, rate: 0.24 },
          { threshold: 250500, rate: 0.32 },
          { threshold: 626350, rate: 0.35 },
          { threshold: Infinity, rate: 0.37 },
        ],
        "Married Filling Jointly": [
          { threshold: 23850, rate: 0.1 },
          { threshold: 96950, rate: 0.12 },
          { threshold: 206700, rate: 0.22 },
          { threshold: 394600, rate: 0.24 },
          { threshold: 501050, rate: 0.32 },
          { threshold: 751600, rate: 0.35 },
          { threshold: Infinity, rate: 0.37 },
        ],
        "Married Filling Separately": [
          { threshold: 11925, rate: 0.1 },
          { threshold: 48475, rate: 0.12 },
          { threshold: 103350, rate: 0.22 },
          { threshold: 197300, rate: 0.24 },
          { threshold: 250525, rate: 0.32 },
          { threshold: 375800, rate: 0.35 },
          { threshold: Infinity, rate: 0.37 },
        ],
      };

      const brackets =
        taxYear === "2024"
          ? taxBrackets2024[filingStatus]
          : taxBrackets2025[filingStatus];
      let tax = 0;
      let lastThreshold = 0;
      let marginalRate = 0;

      for (let i = 0; i < brackets?.length; i++) {
        const { threshold, rate } = brackets[i];
        if (taxableInc > lastThreshold) {
          const taxableAmount = Math.min(
            +taxableInc - +lastThreshold,
            +threshold - +lastThreshold
          );
          tax += taxableAmount * rate;
          if (taxableInc >= lastThreshold && taxableInc <= threshold) {
            marginalRate = +rate;
          }
        }
        lastThreshold = +threshold;
      }

      setTaxesBeforeAdjustments(Math.round(+tax));
      setMarginalTaxRate(+marginalRate * 100);
      return Math.round(+tax);
    };

    const finalCalculations = () => {
      const calculatedTaxableIncome = calculateTaxableIncome();
      const taxesBeforeCredits = calculateTaxes(calculatedTaxableIncome);

      const finalTaxesOwed =
        taxesBeforeCredits -
        Math.min(taxesBeforeAdjustments, taxCredits) -
        taxesWithheld;

      setTaxesOwed(Math.abs(finalTaxesOwed));

      const effectiveRate =
        (+taxesBeforeAdjustments / +calculatedTaxableIncome) * 100;
      setEffectiveTaxRate(+effectiveRate.toFixed(2) || 0);
    };

    if (grossIncome > 0) {
      finalCalculations();
    }
  }, [
    taxYear,
    filingStatus,
    grossIncome,
    age,
    deduction,
    itemizedDeductions,
    taxesWithheld,
    kContributions,
    iraContributions,
    otherDeductions,
    taxCredits,
    irsCheckBoxOption,
    taxesBeforeAdjustments,
  ]);

  const handleCheckboxChange = (newSelectedValues) => {
    setIrsCheckBoxOption(newSelectedValues);
  };

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <SelectBoxComponent
                label={"Tax year"}
                value={taxYear}
                setValue={setTaxYear}
                data={["2024", "2025"]}
                placeholder="Select tax year"
              />
              <SelectBoxComponent
                label={"Filing Status"}
                value={filingStatus}
                setValue={setFilingStatus}
                data={[
                  "Single",
                  "Married Filling Jointly",
                  "Married Filling Separately",
                  "Head of Household",
                ]}
                placeholder="Select filing status"
              />
              <InputComponent
                isInput={true}
                label="Annual gross income ($)"
                flag="dollar"
                min={100}
                max={1000000000}
                setState={setGrossIncome}
                value={grossIncome}
              />
              <InputComponent
                isInput={true}
                label="Age"
                value={age}
                setState={setAge}
                min={18}
                max={80}
              />
              <RadioButtonComponent
                label={"Your deduction"}
                data={DeductionCalcRadio}
                defaultValue={"standard deduction"}
                valueSetter={setDeduction}
                row={true}
              />
              {deduction?.value === "itemized deductions" && (
                <InputComponent
                  isInput={true}
                  label="Itemized deductions"
                  flag="dollar"
                  setState={setItemizedDeductions}
                  value={itemizedDeductions}
                  min={0}
                  max={100000000}
                />
              )}
              <InputComponent
                isInput={true}
                label="Taxes withheld ($)"
                flag="dollar"
                min={0}
                max={1000000000}
                setState={setTaxesWithheld}
                value={taxesWithheld}
              />
              {isFewerOptions ? (
                <Typography
                  className="common-sub-heading-calc center-align"
                  onClick={() => setIsFewerOptions(!isFewerOptions)}
                >
                  - Hide Advanced
                </Typography>
              ) : (
                <Typography
                  className="common-sub-heading-calc center-align"
                  onClick={() => {
                    setIsFewerOptions(!isFewerOptions);
                  }}
                >
                  + Show Advanced
                </Typography>
              )}
              {isFewerOptions && (
                <>
                  <InputComponent
                    isInput={true}
                    label="401(k) contributions"
                    flag="dollar"
                    min={0}
                    max={age < 50 ? 23000 : 30500}
                    setState={setKContributions}
                    value={kContributions}
                  />
                  <InputComponent
                    isInput={true}
                    label="IRA contributions"
                    flag="dollar"
                    min={0}
                    max={age < 50 ? 7000 : 8000}
                    setState={setIraContributions}
                    value={iraContributions}
                  />
                  <InputComponent
                    isInput={true}
                    label="Other deductions"
                    flag="dollar"
                    min={0}
                    max={5000000}
                    setState={setOtherDeductions}
                    value={otherDeductions}
                  />
                  <InputComponent
                    isInput={true}
                    label="Tax credits"
                    flag="dollar"
                    min={0}
                    max={5000000}
                    setState={setTaxCredits}
                    value={taxCredits}
                  />
                  <CheckboxGroup
                    label="Select Options"
                    data={
                      filingStatus === "Married Filling Jointly"
                        ? blindOptions
                        : [blindOptions[0]]
                    }
                    valueSetter={handleCheckboxChange}
                    row={true}
                    type="default"
                  />
                </>
              )}
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <Typography className="common-sub-heading-calc">
                Taxable income
              </Typography>
              <InputComponent
                label="Gross income"
                isInput={false}
                value={grossIncome}
                flag="dollar"
              />
              <InputComponent
                label={
                  deduction?.value === "standard deduction"
                    ? "Standard deduction"
                    : "Itemized deductions"
                }
                isInput={false}
                value={
                  deduction?.value === "standard deduction"
                    ? standardDeductions
                    : itemizedDeductions
                }
                flag="dollar"
              />
              <InputComponent
                label="Retirement contributions"
                isInput={false}
                value={kContributions ? kContributions : iraContributions}
                flag="dollar"
              />
              <InputComponent
                label="Other deductions"
                isInput={false}
                value={otherDeductions}
                flag="dollar"
              />
              <InputComponent
                label="Taxable income"
                isInput={false}
                value={taxableIncome}
                flag="dollar"
              />
              <Typography className="common-sub-heading-calc">
                Estimated federal taxes
              </Typography>
              <InputComponent
                label="Taxes before adjustments"
                isInput={false}
                value={taxesBeforeAdjustments}
                flag="dollar"
              />
              <InputComponent
                label="Federal taxes withheld"
                isInput={false}
                value={taxesWithheld}
                flag="dollar"
              />
              <InputComponent
                label={"Tax credits"}
                isInput={false}
                value={Math.min(taxesBeforeAdjustments, taxCredits)}
                flag="dollar"
              />
              <InputComponent
                label={
                  taxesBeforeAdjustments -
                    Math.min(taxesBeforeAdjustments, taxCredits) -
                    taxesWithheld <
                  0
                    ? "Taxes refunded"
                    : "Taxes owed"
                }
                isInput={false}
                value={taxesOwed}
                flag="dollar"
              />
              <Typography className="common-sub-heading-calc">
                Tax rate
              </Typography>
              <InputComponent
                label="Effective tax rate"
                isInput={false}
                value={effectiveTaxRate}
                flag="percentage"
              />
              <InputComponent
                label="Marginal tax rate"
                isInput={false}
                value={marginalTaxRate}
                flag="percentage"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +grossIncome, label: "Gross income" },
                { id: 1, value: +taxableIncome, label: "Taxable income" },
                {
                  id: 2,
                  value: +taxesOwed,
                  label:
                    taxesBeforeAdjustments -
                      Math.min(taxesBeforeAdjustments, taxCredits) -
                      taxesWithheld <
                    0
                      ? "Taxes refunded"
                      : "Taxes owed",
                },
              ]}
              flag="invest"
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default IRSRefundCalc;
