import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import PieChartComponent from "../../Common/PieChart/PieChart";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import {
  returnHelmetData
} from "../../../Utils/CommonFunction";
import TabbingComponent from "../../Common/TabbingRadio/TabbingComponent";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function FDCalc() {
  const [loanAmountInput, setLoanAmountInput] = useState(100000);
  const [interestValue, setInterestValue] = useState(6.5);
  const [yearOfLoan, setYearOfLoan] = useState(5);
  const [estReturn, setEstReturn] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [frequency, setFrequency] = useState("Years");
  const [cmpFrequency, setCmpFrequency] = useState("Quarterly");

  useEffect(() => {
    calculateFdAmount(
      loanAmountInput,
      interestValue,
      yearOfLoan,
      frequency,
      cmpFrequency
    );
  }, [loanAmountInput, interestValue, yearOfLoan, frequency, cmpFrequency]);
  const calculateFdAmount = (
    investmentAmount,
    rateOfInterest,
    timePeriod,
    timePeriodFlag,
    compoundingFrequency
  ) => {
    let estimateReturns = 0;
    let totalValue = 0;

    let n = 1;
    switch (compoundingFrequency?.toLowerCase()) {
      case "half yearly":
        n = 6;
        break;
      case "monthly":
        n = 12;
        break;
      case "yearly":
        n = 1;
        break;
      case "quarterly":
        n = 4;
        break;
      default:
        return "Invalid compounding frequency. Please use 'daily', 'monthly', 'quarterly', or 'yearly'.";
    }

    // Convert time period to years based on flag
    switch (timePeriodFlag?.toLowerCase()) {
      case "days":
        timePeriod /= 365;
        break;
      case "months":
        timePeriod /= 12;
        break;
      case "years":
        // No need for conversion
        break;
      default:
        return "Invalid time period flag. Please use 'days', 'months', or 'years'.";
    }

    // Calculate total value using compound interest formula
    totalValue =
      +investmentAmount *
      Math.pow(1 + +rateOfInterest / (100 * n), n * +timePeriod);

    // Calculate estimate returns
    estimateReturns = (+totalValue - +investmentAmount).toFixed(2);

    // Set the estimated returns and total amount
    setEstReturn(+estimateReturns);
    setTotalAmount(+totalValue.toFixed(2));
  };
  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ pY: "12px" }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <InputComponent
                isInput={true}
                flag="rupee"
                label="Total Investment"
                value={loanAmountInput}
                setState={setLoanAmountInput}
                min={1}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Rate of Interest (p.a.)"
                min={0}
                max={999}
                setState={setInterestValue}
                value={interestValue}
              />
              <Grid sx={{ textAlign: "right", pr: 1 }}>
                <TabbingComponent
                  data={["Days", "Months", "Years"]}
                  value={frequency}
                  setValue={setFrequency}
                />
              </Grid>
              <InputComponent
                isInput={true}
                flag={
                  frequency === "Years"
                    ? "year"
                    : frequency === "Months"
                    ? "month"
                    : "day"
                }
                label={`Time Period in ${frequency}`}
                setState={setYearOfLoan}
                value={yearOfLoan}
                min={0}
                max={199}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid sx={{ textAlign: "right", pr: 1, py: 1 }}>
              <TabbingComponent
                data={["Monthly", "Quarterly", "Half yearly", "Yearly"]}
                value={cmpFrequency}
                setValue={setCmpFrequency}
              />
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Compound Added Frequency"
                isInput={false}
                date={true}
                value={cmpFrequency}
              />
              <InputComponent
                label="Invested Amount"
                isInput={false}
                value={loanAmountInput}
              />
              <InputComponent
                label="Estimated Returns"
                isInput={false}
                value={estReturn}
              />
              <InputComponent
                label="Total Value"
                isInput={false}
                value={totalAmount}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +loanAmountInput, label: "Total Investment" },
                { id: 1, value: +estReturn, label: "Total Returns" },
              ]}
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default FDCalc;
