import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import averageCalc from "../../../Assets/ComponentImage/Average-Formula.jpg";

function AverageCalc() {
  const [numbersInput, setNumbersInput] = useState("");
  const [average, setAverage] = useState(0);
  const [sum, setSum] = useState(0);
  const [count, setCount] = useState(0);
  const [median, setMedian] = useState(0);
  const [geometricMean, setGeometricMean] = useState(0);
  const [largest, setLargest] = useState(0);
  const [smallest, setSmallest] = useState(0);
  const [range, setRange] = useState(0);
  const numberCommaRegex = /^[\d,\s]+$/;

  const handleNumbersInputChange = (event) => {
    const { value } = event.target;
    if (numberCommaRegex.test(value) || value === "") {
      setNumbersInput(value);
    }
  };

  useEffect(() => {
    // Convert the string into an array of numbers
    let numbers = numbersInput.split(",").map((num) => parseFloat(num.trim()));

    // Calculate the sum
    let sum = numbers.reduce((acc, num) => acc + num, 0);

    // Calculate the count
    let count = numbers.length;

    // Calculate the average
    let average = sum / count;

    // Sort the array for median and range calculation
    numbers.sort((a, b) => a - b);

    // Calculate the median
    let median =
      count % 2 === 0
        ? (numbers[count / 2 - 1] + numbers[count / 2]) / 2
        : numbers[Math.floor(count / 2)];

    // Calculate the geometric mean
    let geometricMean = Math.pow(
      numbers.reduce((acc, num) => acc * num, 1),
      1 / count
    );

    // Find the largest number
    let largest = Math.max(...numbers);

    // Find the smallest number
    let smallest = Math.min(...numbers);

    // Calculate the range
    let range = largest - smallest;

    setSum(sum || 0);
    setCount(numbersInput === "" ? 0 : count || 0);
    setAverage(isNaN(average.toFixed(6)) ? 0 : average.toFixed(6));
    setMedian(median || 0);
    setGeometricMean(
      isNaN(geometricMean.toFixed(6)) ? 0 : geometricMean.toFixed(6)
    );
    setLargest(largest || 0);
    setSmallest(smallest || 0);
    setRange(range || 0);
  }, [numbersInput]);
  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid
          container
          sx={{ p: { xs: "12px 0", md: "12px 30px" }, alignItems: "center" }}
        >
          <Grid item xs={12} md={7} className="calculation-part" sx={{ mt: 2 }}>
            <Grid>
              <Typography className="common-sub-heading-calc">
                Enter Values:
              </Typography>
              <textarea
                rows="4"
                value={numbersInput}
                onChange={handleNumbersInputChange}
              />
              <Typography className="value-enter">
                (Please provide numbers separated by a comma to calculate the
                average of the numbers. )
              </Typography>
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div">
              <InputComponent
                label="Average"
                isInput={false}
                value={average}
                date={true}
              />
              <InputComponent
                label="Sum"
                isInput={false}
                value={sum}
                date={true}
              />
              <InputComponent
                label="Count"
                isInput={false}
                value={count}
                date={true}
              />
              <InputComponent
                label="Median"
                isInput={false}
                value={median}
                date={true}
              />
              <InputComponent
                label="Geometric Mean"
                isInput={false}
                value={geometricMean}
                date={true}
              />
              <InputComponent
                label="Largest"
                isInput={false}
                value={largest}
                date={true}
              />
              <InputComponent
                label="Smallest"
                isInput={false}
                value={smallest}
                date={true}
              />
              <InputComponent
                label="Range"
                isInput={false}
                value={range}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={0} md={5}>
            <img
              src={averageCalc}
              alt="age-calculator"
              className="age-calc-assets"
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default AverageCalc;
