import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import CheckboxGroup from "../../Common/Checkbox/Checkbox";
import {
  cashBackCheckBox,
  stateListForCashBack,
  stateRates,
} from "../../../Utils/Constant";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import PieChartComponent from "../../Common/PieChart/PieChart";

function CashBackAndLowInterestCalc() {
  const [selectedValues, setSelectedValues] = useState(false);
  const [cashBackAmount, setCashBackAmount] = useState(1000);
  const [interestRateHigh, setInterestRateHigh] = useState(5);
  const [interestRateLow, setInterestRateLow] = useState(2);
  const [autoPrice, setAutoPrice] = useState(50000);
  const [loanTerm, setLoanTerm] = useState(5);
  const [downPayment, setDownPayment] = useState(10000);
  const [tradeInValue, setTradeInValue] = useState(0);
  const [stateValue, setStateValue] = useState("--Select--");
  const [saleTaxRate, setSaleTaxRate] = useState(7);
  const [otherFees, setOtherFees] = useState(2000);
  const [resultWithLowInterestRate, setResultWithLowInterestRate] = useState({
    totalLoanAmount: 0,
    saleTax: 0,
    upfrontPayment: 0,
    monthlyPay: 0,
    totalLoanPayments: 0,
    totalLoanInterest: 0,
    totalCost: 0,
  });
  const [resultWithCashBack, setResultWithCashBack] = useState({
    totalLoanAmount: 0,
    saleTax: 0,
    upfrontPayment: 0,
    monthlyPay: 0,
    totalLoanPayments: 0,
    totalLoanInterest: 0,
    totalCost: 0,
  });

  useEffect(() => {
    const stateTaxRate = +stateRates[stateValue] || 0; // Get state sales tax rate
    const appliedSaleTax = +saleTaxRate || +stateTaxRate; // Use user-input sale tax or state tax rate

    function calculateMonthlyPayment(principal, interestRate, months) {
      const monthlyRate = +interestRate / 12;
      return (
        (+principal * +monthlyRate) / (1 - Math.pow(1 + +monthlyRate, -months))
      );
    }
    // Calculate "With Cash Back Offer"
    const saleTaxWithCashBack = +autoPrice * (+appliedSaleTax / 100);
    // const loanAmountWithCashBack =
    //   +autoPrice - +downPayment - +tradeInValue - +cashBackAmount;

    const loanAmountWithCashBack = selectedValues
      ? +autoPrice -
        +downPayment -
        +tradeInValue -
        +cashBackAmount +
        +otherFees +
        +saleTaxWithCashBack
      : +autoPrice - +downPayment - +tradeInValue - +cashBackAmount;

    const monthlyPayWithCashBack = calculateMonthlyPayment(
      loanAmountWithCashBack,
      interestRateHigh / 100,
      loanTerm * 12
    );
    const totalLoanPaymentsWithCashBack =
      +monthlyPayWithCashBack * +loanTerm * 12;
    const totalLoanInterestWithCashBack = selectedValues
      ? +totalLoanPaymentsWithCashBack - +loanAmountWithCashBack
      : +totalLoanPaymentsWithCashBack - +loanAmountWithCashBack;
    const upfrontPaymentWithCashBack = selectedValues
      ? +downPayment
      : +downPayment + +otherFees + +saleTaxWithCashBack;

    const totalCostWithCashBack =
      +totalLoanPaymentsWithCashBack + +upfrontPaymentWithCashBack;

    setResultWithCashBack({
      totalLoanAmount: loanAmountWithCashBack.toFixed(2),
      saleTax: saleTaxWithCashBack.toFixed(2),
      upfrontPayment: upfrontPaymentWithCashBack.toFixed(2),
      monthlyPay: monthlyPayWithCashBack.toFixed(2),
      totalLoanPayments: totalLoanPaymentsWithCashBack.toFixed(2),
      totalLoanInterest: totalLoanInterestWithCashBack.toFixed(2),
      totalCost: totalCostWithCashBack.toFixed(2),
    });

    // Calculate "With Low Interest Rate Offer"
    const saleTaxWithLowInterest = +autoPrice * (+appliedSaleTax / 100);
    const loanAmountWithLowInterest = selectedValues
      ? +autoPrice -
        +downPayment -
        +tradeInValue +
        +otherFees +
        +saleTaxWithLowInterest
      : +autoPrice - +downPayment - +tradeInValue;

    const monthlyPayWithLowInterest = calculateMonthlyPayment(
      loanAmountWithLowInterest,
      interestRateLow / 100,
      loanTerm * 12
    );
    const totalLoanPaymentsWithLowInterest =
      +monthlyPayWithLowInterest * +loanTerm * 12;
    const totalLoanInterestWithLowInterest =
      +totalLoanPaymentsWithLowInterest - +loanAmountWithLowInterest;
    const upfrontPaymentWithLowInterest = selectedValues
      ? +downPayment
      : +downPayment + +otherFees + +saleTaxWithLowInterest;

    const totalCostWithLowInterest =
      +totalLoanPaymentsWithLowInterest + +upfrontPaymentWithLowInterest;

    setResultWithLowInterestRate({
      totalLoanAmount: loanAmountWithLowInterest.toFixed(2),
      saleTax: saleTaxWithLowInterest.toFixed(2),
      upfrontPayment: upfrontPaymentWithLowInterest.toFixed(2),
      monthlyPay: monthlyPayWithLowInterest.toFixed(2),
      totalLoanPayments: totalLoanPaymentsWithLowInterest.toFixed(2),
      totalLoanInterest: totalLoanInterestWithLowInterest.toFixed(2),
      totalCost: totalCostWithLowInterest.toFixed(2),
    });
  }, [
    autoPrice,
    cashBackAmount,
    interestRateHigh,
    interestRateLow,
    loanTerm,
    downPayment,
    tradeInValue,
    stateValue,
    saleTaxRate,
    otherFees,
    selectedValues,
  ]);
  const handleCheckboxChange = (newSelectedValues) => {
    if (newSelectedValues.length <= 0) {
      setSelectedValues(false);
    } else {
      setSelectedValues(true);
    }
  };
  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={10} className="calculation-part ">
            <Grid>
              <Typography className="common-sub-heading-calc">
                Cash Back Offer
              </Typography>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Cash Back Amount"
                value={cashBackAmount}
                min={0}
                setState={setCashBackAmount}
                max={10000000000}
              />

              <InputComponent
                isInput={true}
                flag="percentage"
                label="Interest Rate (High)"
                value={interestRateHigh}
                min={0}
                setState={setInterestRateHigh}
                max={999}
              />
              <Typography className="common-sub-heading-calc">
                Low Interest Rate Offer
              </Typography>
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Interest Rate (Low)"
                value={interestRateLow}
                min={0}
                setState={setInterestRateLow}
                max={999}
              />

              <Typography className="common-sub-heading-calc">
                Other Information
              </Typography>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Auto Price"
                value={autoPrice}
                min={0}
                setState={setAutoPrice}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Loan Term"
                value={loanTerm}
                min={0}
                setState={setLoanTerm}
                max={50}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Down Payment"
                value={downPayment}
                min={0}
                setState={setDownPayment}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Trade-in Value	"
                value={tradeInValue}
                min={0}
                setState={setTradeInValue}
                max={10000000000}
              />
              <SelectBoxComponent
                value={stateValue}
                setValue={setStateValue}
                data={stateListForCashBack}
                label="Your State"
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Sales Tax"
                value={saleTaxRate}
                min={0}
                setState={setSaleTaxRate}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Title, Registration and Other Fees"
                value={otherFees}
                min={0}
                setState={setOtherFees}
                max={10000000000}
              />
              <CheckboxGroup
                data={cashBackCheckBox}
                valueSetter={handleCheckboxChange}
                defaultValues={false}
                row={true}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="common-sub-heading-calc">
                The Low Interest Rate Offer is Better!
              </Typography>
              <InputComponent
                isInput={false}
                date={true}
                value={`The low rate will save you $${(
                  resultWithCashBack?.totalLoanInterest -
                  resultWithLowInterestRate?.totalLoanInterest
                ).toFixed(
                  2
                )} in interest, which is larger than the cash back of ${(
                  resultWithLowInterestRate?.totalLoanAmount -
                  resultWithCashBack?.totalLoanAmount
                ).toFixed(2)}.`}
              />
              <Typography className="common-sub-heading-calc">
                With Cash Back Offer
              </Typography>
              <InputComponent
                label="Total Loan Amount:"
                isInput={false}
                flag="dollar"
                value={resultWithCashBack?.totalLoanAmount}
              />
              <InputComponent
                label="Sale Tax:"
                isInput={false}
                flag="dollar"
                value={resultWithCashBack?.saleTax}
              />
              <InputComponent
                label="Upfront Payment:"
                isInput={false}
                flag="dollar"
                value={resultWithCashBack?.upfrontPayment}
              />
              <InputComponent
                label="Monthly Pay:"
                isInput={false}
                flag="dollar"
                value={resultWithCashBack?.monthlyPay}
              />
              <InputComponent
                label={`Total of ${loanTerm * 12} Loan Payments:`}
                isInput={false}
                flag="dollar"
                value={resultWithCashBack?.totalLoanPayments}
              />
              <InputComponent
                label="Total Loan Interest:"
                isInput={false}
                flag="dollar"
                value={resultWithCashBack?.totalLoanInterest}
              />
              <InputComponent
                label="Total Cost (price, interest, tax, fees):"
                isInput={false}
                flag="dollar"
                value={resultWithCashBack?.totalCost}
              />
              <Typography className="common-sub-heading-calc">
                With Low Interest Rate Offer
              </Typography>
              <InputComponent
                label="Total Loan Amount:"
                isInput={false}
                flag="dollar"
                value={resultWithLowInterestRate?.totalLoanAmount}
              />
              <InputComponent
                label="Sale Tax:"
                isInput={false}
                flag="dollar"
                value={resultWithLowInterestRate?.saleTax}
              />
              <InputComponent
                label="Upfront Payment:"
                isInput={false}
                flag="dollar"
                value={resultWithLowInterestRate?.upfrontPayment}
              />
              <InputComponent
                label="Monthly Pay:"
                isInput={false}
                flag="dollar"
                value={resultWithLowInterestRate?.monthlyPay}
              />
              <InputComponent
                label={`Total of ${loanTerm * 12} Loan Payments:`}
                isInput={false}
                flag="dollar"
                value={resultWithLowInterestRate?.totalLoanPayments}
              />
              <InputComponent
                label="Total Loan Interest:"
                isInput={false}
                flag="dollar"
                value={resultWithLowInterestRate?.totalLoanInterest}
              />
              <InputComponent
                label="Total Cost (price, interest, tax, fees):"
                isInput={false}
                flag="dollar"
                value={resultWithLowInterestRate?.totalCost}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={10} className="piechart-div" sx={{flexDirection:"row !important"}}>
            <PieChartComponent
              data={[
                {
                  id: 0,
                  value: +resultWithCashBack?.totalCost,
                  label: "Total Cost - With Cash Back Offer",
                },
                {
                  id: 1,
                  value: +resultWithCashBack?.totalLoanAmount,
                  label: "Total Loan Amount -With Cash Back Offer",
                },
                {
                  id: 2,
                  value: +resultWithCashBack?.saleTax,
                  label: "Sale Tax -With Cash Back Offer",
                },       {
                  id: 3,
                  value: +resultWithCashBack?.totalLoanInterest,
                  label: "Total Loan Interest -With Cash Back Offer",
                },            
              ]}
              flag="invest"
            />
            <PieChartComponent
              data={[
                {
                  id: 0,
                  value: +resultWithLowInterestRate?.totalCost,
                  label: "Total Cost - With Low Interest Rate Offer",
                },
                {
                  id: 1,
                  value: +resultWithLowInterestRate?.totalLoanAmount,
                  label: "Total Loan Amount -With Low Interest Rate Offer",
                },
                {
                  id: 2,
                  value: +resultWithLowInterestRate?.saleTax,
                  label: "Sale Tax -With Low Interest Rate Offer",
                },       {
                  id: 3,
                  value: +resultWithLowInterestRate?.totalLoanInterest,
                  label: "Total Loan Interest -With Low Interest Rate Offer",
                },            
              ]}
              flag="invest"
            />
           
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default CashBackAndLowInterestCalc;
