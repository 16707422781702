import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import InputComponent from "../../Common/InputComponent/InputComponent";
import { passwordCheckboxData } from "../../../Utils/Constant";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import CheckboxGroup from "../../Common/Checkbox/Checkbox";
import "./PasswordGeneratorCalc.scss";

export default function PasswordGeneratorCalc() {
  const [passwordLength, setPasswordLength] = useState(8);
  const [password, setPassword] = useState();
  const [selectedValues, setSelectedValues] = useState([
    "Include Symbols (!#$%&\"()'*+,-./:;<=>?@[]^_`{|}~)",
    "Include Lower Case (a-z)",
    "Include Numbers (0-9)",
  ]);
  const [count, setCount] = useState(false);
  const [copied, setCopied] = useState(false);


  const handleCopy = () => {
    navigator.clipboard.writeText(password);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  useEffect(() => {
    generatePassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (count) {
      generatePassword();
    } else {
      setCount(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordLength, selectedValues]);

  const generatePassword = () => {
    const lowerCase = "abcdefghijklmnopqrstuvwxyz";
    const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const symbols = "!#$%&\"()*+,-./:;<=>?@[]^_`{|}~'";
    const ambiguous = "iIl1L|oO0`\"-_':;.,";
    const brackets = "<>()[]{}";

    let charSet = "";

    if (selectedValues.includes("Include Lower Case (a-z)")) {
      charSet += lowerCase;
    }
    if (selectedValues.includes("Include Upper Case (A-Z)")) {
      charSet += upperCase;
    }
    if (selectedValues.includes("Include Numbers (0-9)")) {
      charSet += numbers;
    }
    if (
      selectedValues.includes(
        "Include Symbols (!#$%&\"()'*+,-./:;<=>?@[]^_`{|}~)"
      )
    ) {
      charSet += symbols;
    }

    if (
      selectedValues.includes(
        "Exclude Ambiguous Character (iIl1L|oO0`\"-_':;.,)"
      )
    ) {
      const ambiguousRegex = new RegExp(
        `[${ambiguous.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")}]`,
        "g"
      );
      charSet = charSet.replace(ambiguousRegex, "");
    }

    if (selectedValues.includes("Exclude Brackets (<>()[]{})")) {
      const bracketsRegex = new RegExp(
        `[${brackets.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")}]`,
        "g"
      );
      charSet = charSet.replace(bracketsRegex, "");
    }

    let generatedPassword = "";
    const charSetLength = charSet.length;

    for (let i = 0; i < +passwordLength; i++) {
      let randomChar;
      do {
        randomChar = charSet.charAt(Math.floor(Math.random() * charSetLength));
      } while (
        selectedValues.includes("No Repeated Characters") &&
        generatedPassword.includes(randomChar)
      );
      generatedPassword += randomChar;
    }

    setPassword(generatedPassword);
  };

  const handleCheckboxChange = (newSelectedValues) => {
    setSelectedValues(newSelectedValues);
  };

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={8} className="calculation-part ">
            <Grid>
              <InputComponent
                label="Password Length"
                isInput={true}
                value={passwordLength}
                min={4}
                max={50}
                setState={setPasswordLength}
              />
              <CheckboxGroup
                label="Select Options"
                data={passwordCheckboxData}
                valueSetter={handleCheckboxChange}
                row={true}
                defaultValues={[
                  "Include Symbols (!#$%&\"()'*+,-./:;<=>?@[]^_`{|}~)",
                  "Include Lower Case (a-z)",
                  "Include Numbers (0-9)",
                ]}
                type="default"
              />
            </Grid>

            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                className="notranslate"
                label="Password"
                isInput={false}
                value={password}
                date={true}
              />
              <Grid
                sx={{
                  textAlign: "center",
                  gap: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button onClick={() => generatePassword()} variant="contained">
                  Regenerate
                </Button>
                <Button
                  onClick={handleCopy}
                  variant="contained"
                  className={copied ? "green-copy-btn" : "default"}
                >
                  {copied ? "Copied!" : "Copy"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
