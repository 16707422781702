import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import InputComponent from "../../Common/InputComponent/InputComponent";
import Table from "./Table";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import "./Table.scss";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
const GRADE_POINTS = {
  "A+": 4.3,
  A: 4,
  "A-": 3.7,
  "B+": 3.3,
  B: 3,
  "B-": 2.7,
  "C+": 2.3,
  C: 2,
  "C-": 1.7,
  "D+": 1.3,
  D: 1,
  "D-": 0.7,
  F: 0,
  P: -1,
  NP: -1,
};

export default function GPACalc() {
  const [courses, setCourses] = useState({
    co0: "Maths",
    co1: "English",
    co2: "Physics",
  });
  const [credits, setCredits] = useState({ cr0: 5, cr1: 3, cr2: 3 });
  const [grades, setGrades] = useState({ gr0: "B+", gr1: "A+", gr2: "B" });

  const [GPA, setGPA] = useState(3.6);
  const [totalCredits, setTotalCredits] = useState(0);

  const [neededGPA, setNeededGPA] = useState(0);
  const [currentGPA2, setCurrentGPA2] = useState(2.8);
  const [targetGPA2, setTargetGPA2] = useState(3);
  const [currentCredits, setCurrentCredits] = useState(25);
  const [additionalCredits, setAdditionalCredits] = useState(15);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculateGPA = useCallback((credits, grades) => {
    let totalGradePoints = 0;
    let totalCredits = 0;

    // Calculate total grade points
    for (let i = 0; i < Object.keys(credits).length; i++) {
      if (GRADE_POINTS[grades[`gr${i}`]] < 0) continue; // Skip is grade is 'NP'
      totalGradePoints += credits[`cr${i}`] * GRADE_POINTS[grades[`gr${i}`]];
    }

    // Calculate total credits and set credits state
    for (const key in credits) {
      totalCredits += credits[key];
    }
    setTotalCredits(totalCredits);
    // GPA
    let GPA = (totalGradePoints / totalCredits).toFixed(2);

    return GPA;
  });

  useEffect(() => {
    setGPA(calculateGPA(credits, grades));
  }, [credits, grades, calculateGPA]);

  useEffect(() => {
    setCurrentGPA2(GPA);
    setCurrentCredits(totalCredits);
  }, [GPA, totalCredits]);

  useEffect(() => {
    setNeededGPA(
      calculateGPA(
        +targetGPA2,
        +currentGPA2,
        +currentCredits,
        +additionalCredits
      )
    );
    function calculateGPA(
      targetGPA,
      currentGPA,
      currentCredits,
      additionalCredits
    ) {
      let totalCurrentGradePoints = +currentGPA * +currentCredits;

      let totalCredits = +currentCredits + +additionalCredits;

      let totalTargetGradePoints = +targetGPA * +totalCredits;

      let requiredGPA =
        (+totalTargetGradePoints - +totalCurrentGradePoints) /
        +additionalCredits;

      let comparison = +requiredGPA >= +currentGPA ? "higher" : "lower";

      return (
        <span>
          To achieve a target GPA of <strong>{targetGPA}</strong>, the GPA for
          the next <strong>{additionalCredits}</strong> credits needs to be{" "}
          <strong>{isNaN(requiredGPA) ? 0 : requiredGPA.toFixed(2)}</strong> or{" "}
          <strong>{comparison}</strong>.
        </span>
      );
    }
  }, [targetGPA2, currentGPA2, currentCredits, additionalCredits]);

  return (
    <>
            {returnHelmetData()}


      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container spacing={3}>
          <Grid item xs={12} md={5.9}>
            <Grid item className="calculation-part ">
              <Grid item>
                <Typography className="common-sub-heading-desc">
                  1. Use this calculator to calculate grade point average (GPA)
                  and generate a GPA report. If you use percentage grades, have
                  grades on a different scale or in high school with AP/IB
                  classes, Also use the settings to group courses into semesters
                  or to include past GPA.
                </Typography>
              </Grid>
              <Grid item>
                <Table
                  numOfRows={Object.keys(courses).length}
                  headerData={["Course", "Credits", "Grade", "Grade Points"]}
                  courses={{ data: courses, setter: setCourses }}
                  credits={{ data: credits, setter: setCredits }}
                  grades={{ data: grades, setter: setGrades }}
                />
                <Button
                  sx={{ my: 2 }}
                  variant="contained"
                  onClick={() => {
                    // Adds a new course: first copies existing courses, generates the new course id, sets a default value
                    setCourses((prev) => ({
                      ...prev,
                      [`co${[Object.keys(courses).length]}`]: "",
                    }));
                    setCredits((prev) => ({
                      ...prev,
                      [`cr${[Object.keys(courses).length]}`]: 0,
                    }));
                    setGrades((prev) => ({
                      ...prev,
                      [`gr${[Object.keys(courses).length]}`]: "A+",
                    }));
                  }}
                >
                  Add a course
                </Button>
              </Grid>
              <Grid className="result-label">
                <Typography>Result</Typography>
              </Grid>{" "}
              <Grid className="result-common-div">
                <InputComponent
                  label="Your current GPA is"
                  isInput={false}
                  value={GPA || "0"}
                  date={true}
                />
                <InputComponent
                  label="Total Credits"
                  isInput={false}
                  value={totalCredits || "0"}
                  date={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.9}>
            <Grid item className="calculation-part ">
              <Grid item>
                <Typography className="common-sub-heading-desc">
                  2. GPA Planning Calculator
                </Typography>
              </Grid>
              <Grid item>
                <InputComponent
                  isInput={true}
                  label="Current GPA"
                  value={currentGPA2}
                  max={10}
                  setState={setCurrentGPA2}
                />
                <InputComponent
                  isInput={true}
                  label="Target GPA"
                  value={targetGPA2}
                  max={10}
                  setState={setTargetGPA2}
                />
                <InputComponent
                  isInput={true}
                  label="Current Credits"
                  value={currentCredits}
                  max={999}
                  setState={setCurrentCredits}
                />
                <InputComponent
                  isInput={true}
                  label="Additional Credits"
                  value={additionalCredits}
                  max={999}
                  setState={setAdditionalCredits}
                />
              </Grid>
              <Grid className="result-label">
                <Typography>Result</Typography>
              </Grid>{" "}
              <Grid className="result-common-div">
                <Typography className="gpa-final-result">
                  {neededGPA}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
