import React from "react";
import "./InputField.scss";

function InputField(props) {
  const { value, setState, label, type = "text", isDisabled, min } = props;

  const handleChange = (e) => {
    const { value } = e.target;
    setState(value);
  };
  return (
    <>
      <input
        type={type}
        id={label}
        name={label}
        value={value}
        defaultValue={value}
        onChange={(e) => handleChange(e)}
        className="input-field"
        disabled={isDisabled}
        min={min}
      />
    </>
  );
}

export default InputField;
