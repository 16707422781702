import * as React from "react";
import "./Slider.scss";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";

export default function InputSlider({
  label,
  value,
  valueSetter,
  min,
  max,
  steps,
  sx,
}) {
  const handleSliderChange = (event, newValue) => {
    valueSetter(newValue);
  };

  const handleInputChange = (event) => {
    if (min < event.target.value || max > event.target.value) {
      valueSetter(event.target.value);
    }
  };

  const handleBlur = (e) => {
    if (e.target.value < min) {
      valueSetter(min);
    } else if (e.target.value > max) {
      valueSetter(max);
    }
  };

  return (
    <Grid item sx={{ ...sx, marginX: 1 }}>
      <Typography id="label" gutterBottom>
        {label}
      </Typography>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="space-between"
      >
        <Grid item xs={12} md={10}>
          <Slider
            value={typeof value === "number" ? value : 0}
            min={min}
            max={max}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            step={steps}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            className="input"
            value={value}
            type="number"
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            sx={{
              marginTop: -2.5,
              input: { color: "white" },
              "& fieldset": { border: "none" },
            }}
            inputProps={{
              step: 1,
              min: { min },
              max: { max },
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
