import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import "./AutoLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import PieChartComponent from "../../Common/PieChart/PieChart";
import TabbingComponent from "../../Common/TabbingRadio/TabbingComponent";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import {
  cashBackCheckBox,
  stateListForCashBack,
} from "../../../Utils/Constant";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import CheckboxGroup from "../../Common/Checkbox/Checkbox";
import TableComponent from "../../Common/TableComponent/TableComponent";

function AutoLoanCalc() {
  const [autoPrice, setAutoPrice] = useState(50000);
  const [monthlyPay, setMonthlyPay] = useState(750);
  const [initialPayType, setInitialPayType] = useState("Total Price");
  const [loanTerm, setLoanTerm] = useState(5);
  const [interestRate, setInterestRate] = useState(5);
  const [cashIncentives, setCashIncentives] = useState(5000);
  const [downPayment, setDownPayment] = useState(10000);
  const [tradeInValue, setTradeInValue] = useState(1000);
  const [owedTradeInValue, setOwedTradeInValue] = useState(2000);
  const [yourState, setYourState] = useState("Indiana");
  const [saleTax, setSaleTax] = useState(7);
  const [fees, setFees] = useState(2000);
  const [selectedValues, setSelectedValues] = useState(false);

  const [monthlyLoanPayment, setMonthlyLoanPayment] = useState(0);
  const [vehicleCost, setVehicleCost] = useState(0);
  const [totalLoanAmount, setTotalLoanAmount] = useState(0);
  const [saleTaxValue, setSaleTaxValue] = useState(0);
  const [upfrontPayment, setUpfrontPayment] = useState(0);
  const [totalLoanPayments, setTotalLoanPayments] = useState(0);
  const [totalLoanInterest, setTotalLoanInterest] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [tableView, setTableView] = useState(false);
  const [monthlyRecords, setMonthlyRecords] = useState([]);

  useEffect(() => {
    if (initialPayType === "Total Price") {
      const saleTaxValueCalc = (+autoPrice - +tradeInValue) * (+saleTax / 100);
      let loanAmountCalc = 0;
      let upfrontPaymentCalc = 0;
      if (selectedValues) {
        loanAmountCalc +=
          +autoPrice -
          +cashIncentives -
          +downPayment -
          +tradeInValue +
          +owedTradeInValue +
          +saleTaxValueCalc +
          +fees;
        upfrontPaymentCalc = +downPayment;
      } else {
        loanAmountCalc +=
          +autoPrice -
          +cashIncentives -
          +downPayment -
          +tradeInValue +
          +owedTradeInValue;
        upfrontPaymentCalc = +downPayment + +fees + +saleTaxValueCalc;
      }
      const totalLoanMonths = +loanTerm * 12;
      const interestPerMonth = +interestRate / 100 / 12;
      const monthlyPayment =
        (+loanAmountCalc *
          +interestPerMonth *
          (1 + +interestPerMonth) ** +totalLoanMonths) /
        ((1 + +interestPerMonth) ** +totalLoanMonths - 1);

      const totalInterestGenerated =
        +monthlyPayment * +totalLoanMonths - +loanAmountCalc;
      const loanPaymentCalc = +totalInterestGenerated + +loanAmountCalc;

      const totalCost = !selectedValues
        ? +loanPaymentCalc + +saleTaxValueCalc + +downPayment + +tradeInValue
        : +loanPaymentCalc +
          +(+downPayment) +
          (+tradeInValue - +owedTradeInValue);

      const generateMonthlyRecords = (
        monthlyPayment,
        monthlyInterestRate,
        numPayments
      ) => {
        setMonthlyRecords([]);
        if (monthlyPayment > 0 && monthlyInterestRate > 0 && numPayments > 1) {
          const records = [];
          let remainingPrincipal = loanAmountCalc;
          for (let i = 1; i <= numPayments; i++) {
            const interestPayment = remainingPrincipal * monthlyInterestRate;
            const principalPayment = monthlyPayment - interestPayment;
            remainingPrincipal -= principalPayment;
            records.push({
              month: i,
              principalPayment: principalPayment.toFixed(2),
              interestPayment: interestPayment.toFixed(2),
              totalPaid: (+principalPayment + +interestPayment).toFixed(2),
              balance: Math.abs(remainingPrincipal.toFixed(2)),
            });
          }
          setMonthlyRecords(records);
        }
      };

      setMonthlyLoanPayment(monthlyPayment);
      setTotalLoanAmount(loanAmountCalc);
      setSaleTaxValue(saleTaxValueCalc);
      setUpfrontPayment(upfrontPaymentCalc);
      setTotalLoanPayments(loanPaymentCalc);
      setTotalLoanInterest(totalInterestGenerated);
      setTotalCost(totalCost);
      generateMonthlyRecords(monthlyPayment, interestPerMonth, totalLoanMonths);
    } else {
      const totalLoanMonths = +loanTerm * 12;
      const interestPerMonth = +interestRate / 100 / 12;

      // Use monthly payment to calculate loan amount
      const loanAmountCalc =
        (+monthlyPay * ((1 + interestPerMonth) ** totalLoanMonths - 1)) /
        (interestPerMonth * (1 + interestPerMonth) ** totalLoanMonths);

      // Calculate vehicle price from loan amount
      const vehiclePriceCalc = !selectedValues
        ? +loanAmountCalc +
          +downPayment +
          +tradeInValue -
          +owedTradeInValue +
          +cashIncentives
        : (+loanAmountCalc +
            +downPayment +
            cashIncentives +
            +tradeInValue -
            +owedTradeInValue -
            +fees +
            (tradeInValue * saleTax) / 100) /
          (1 + saleTax / 100);

      const saleTaxValueCalc =
        (+vehiclePriceCalc - +tradeInValue) * (+saleTax / 100);

      const upfrontPaymentCalc = !selectedValues
        ? +downPayment + +fees + +saleTaxValueCalc
        : +downPayment;

      const totalInterestGenerated =
        +monthlyPay * +totalLoanMonths - +loanAmountCalc;
      const loanPaymentCalc = +totalInterestGenerated + +loanAmountCalc;

      const totalCost =
        loanPaymentCalc +
        upfrontPaymentCalc -
        (+owedTradeInValue - +tradeInValue);

      const generateMonthlyRecords = (
        monthlyPayment,
        monthlyInterestRate,
        numPayments
      ) => {
        setMonthlyRecords([]);
        if (monthlyPayment > 0 && monthlyInterestRate > 0 && numPayments > 1) {
          const records = [];
          let remainingPrincipal = loanAmountCalc;
          for (let i = 1; i <= numPayments; i++) {
            const interestPayment = remainingPrincipal * monthlyInterestRate;
            const principalPayment = monthlyPayment - interestPayment;
            remainingPrincipal -= principalPayment;
            records.push({
              month: i,
              principalPayment: principalPayment.toFixed(2),
              interestPayment: interestPayment.toFixed(2),
              totalPaid: (+principalPayment + +interestPayment).toFixed(2),
              balance: Math.abs(remainingPrincipal.toFixed(2)),
            });
          }
          setMonthlyRecords(records);
        }
      };

      setTotalLoanAmount(loanAmountCalc);
      setVehicleCost(vehiclePriceCalc);
      setSaleTaxValue(saleTaxValueCalc);
      setUpfrontPayment(upfrontPaymentCalc);
      setTotalLoanPayments(loanPaymentCalc);
      setTotalLoanInterest(totalInterestGenerated);
      setTotalCost(totalCost);

      generateMonthlyRecords(monthlyPay, interestPerMonth, totalLoanMonths);
    }
  }, [
    autoPrice,
    monthlyPay,
    initialPayType,
    loanTerm,
    interestRate,
    cashIncentives,
    downPayment,
    tradeInValue,
    owedTradeInValue,
    yourState,
    saleTax,
    fees,
    selectedValues,
  ]);

  const handleCheckboxChange = (newSelectedValues) => {
    if (newSelectedValues.length <= 0) {
      setSelectedValues(false);
    } else {
      setSelectedValues(true);
    }
  };

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <TabbingComponent
                data={["Total Price", "Monthly Payment"]}
                value={initialPayType}
                setValue={setInitialPayType}
              />
              {initialPayType === "Total Price" ? (
                <InputComponent
                  isInput={true}
                  flag="dollar"
                  label="Auto Price"
                  value={autoPrice}
                  min={1}
                  setState={setAutoPrice}
                  max={10000000000}
                />
              ) : (
                <InputComponent
                  isInput={true}
                  flag="dollar"
                  label="Monthly Payment"
                  value={monthlyPay}
                  min={1}
                  setState={setMonthlyPay}
                  max={10000000000}
                />
              )}
              <InputComponent
                isInput={true}
                flag="year"
                label="Loan Term "
                setState={setLoanTerm}
                value={loanTerm}
                min={0}
                max={199}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Interest Rate "
                value={interestRate}
                min={0}
                setState={setInterestRate}
                max={99}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Cash Incentives	"
                value={cashIncentives}
                min={0}
                setState={setCashIncentives}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Down Payment	"
                value={downPayment}
                min={0}
                setState={setDownPayment}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Trade-in Value "
                value={tradeInValue}
                min={0}
                setState={setTradeInValue}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Amount Owed on Trade-in	"
                value={owedTradeInValue}
                min={0}
                setState={setOwedTradeInValue}
                max={10000000000}
              />
              <SelectBoxComponent
                value={yourState}
                setValue={setYourState}
                data={stateListForCashBack}
                label="Your State"
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Sales Tax "
                setState={setSaleTax}
                value={saleTax}
                min={0}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Title, Registration and Other Fees "
                value={fees}
                min={0}
                setState={setFees}
                max={10000000000}
              />
              <CheckboxGroup
                data={cashBackCheckBox}
                valueSetter={handleCheckboxChange}
                defaultValues={false}
                row={true}
              />
            </Grid>

            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              {initialPayType === "Total Price" ? (
                <InputComponent
                  label="Monthly Loan Payment"
                  flag="dollar"
                  isInput={false}
                  value={monthlyLoanPayment}
                />
              ) : (
                <InputComponent
                  label="Vehicle Cost"
                  flag="dollar"
                  isInput={false}
                  value={vehicleCost}
                />
              )}
              <InputComponent
                label="Total Loan Amount"
                flag="dollar"
                isInput={false}
                value={totalLoanAmount}
              />
              <InputComponent
                label="Sale Tax"
                flag="dollar"
                isInput={false}
                value={saleTaxValue}
              />
              <InputComponent
                label="Upfront Payment"
                flag="dollar"
                isInput={false}
                value={upfrontPayment}
              />
              <InputComponent
                label={`Total of ${loanTerm * 12} Loan Payments`}
                flag="dollar"
                isInput={false}
                value={totalLoanPayments}
              />
              <InputComponent
                label="Total Loan Interest"
                flag="dollar"
                isInput={false}
                value={totalLoanInterest}
              />
              <InputComponent
                label="Total Cost (price, interest, tax, fees)"
                flag="dollar"
                isInput={false}
                value={totalCost}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                {
                  id: 0,
                  value: +totalLoanAmount,
                  label: "Total Loan Amount",
                },
                {
                  id: 1,
                  value: +totalLoanInterest,
                  label: "Total Loan Interest",
                },
              ]}
              flag="invest"
            />
          </Grid>
        </Grid>
        <Grid className="data-table-container">
          <Grid className="table-toggle-div">
            <Typography variant="h5">
              Your Amortization Details{" "}
              <span>
                (<u>Monthly</u>)
              </span>
            </Typography>
            <Tooltip
              title={tableView ? "click to close table" : "click to open table"}
              placement="top"
              arrow
            >
              <span>
                <img
                  src={plusIcon}
                  alt="plusIcon"
                  onClick={() => setTableView(!tableView)}
                  style={{
                    transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                  className="add-icon-table"
                />
              </span>
            </Tooltip>
          </Grid>
          {tableView && (
            <Grid>
              <Grid>
                <TableComponent
                  rows={monthlyRecords}
                  headerData={[
                    "Month",
                    "Principal Paid",
                    "Interest Charged",
                    "Total Payment",
                    "Balance",
                  ]}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default AutoLoanCalc;
