import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import Table from "./Table";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import {
  gradeLetterColor,
  returnHelmetData
} from "../../../Utils/CommonFunction";
import InputComponent from "../../Common/InputComponent/InputComponent";
import "./GradeCalc.scss";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

export default function GradeCalc() {
  const [courses, setCourses] = useState({
    co0: "Maths",
    co1: "English",
    co2: "Physics",
  });
  const [weight, setWeight] = useState({ w0: 30, w1: 20, w2: 20 });
  const [grades, setGrades] = useState({ gr0: 50, gr1: 70, gr2: 80 });
  const [averageGrade, setAverageGrade] = useState(0);
  const [letterGrade, setLetterGrade] = useState("F");
  

  const calculateAverageGrade = useCallback((grades, weight) => {
    if (!grades || !weight || grades.length !== weight.length) {
      return { averageGrade: 0, letterGrade: "F" };
    }

    let totalWeight = 0;
    let weightedGradeSum = 0;

    for (let i = 0; i < Object.keys(grades).length; i++) {
      const numericGrade = parseFloat(grades[`gr${i}`]);
      const numericWeight = parseFloat(weight[`w${i}`]);

      if (!isNaN(numericGrade) && !isNaN(numericWeight)) {
        totalWeight += numericWeight;
        weightedGradeSum += numericGrade * numericWeight;
      }
    }

    if (totalWeight === 0) {
      return { averageGrade: 0, letterGrade: "F" }; // Return 0 if total weight is 0
    }

    const average = weightedGradeSum / totalWeight;

    let letterGrade;
    if (average >= 97) {
      letterGrade = "A+";
    } else if (average >= 93) {
      letterGrade = "A";
    } else if (average >= 90) {
      letterGrade = "A-";
    } else if (average >= 87) {
      letterGrade = "B+";
    } else if (average >= 83) {
      letterGrade = "B";
    } else if (average >= 80) {
      letterGrade = "B-";
    } else if (average >= 77) {
      letterGrade = "C+";
    } else if (average >= 73) {
      letterGrade = "C";
    } else if (average >= 70) {
      letterGrade = "C-";
    } else if (average >= 67) {
      letterGrade = "D+";
    } else if (average >= 63) {
      letterGrade = "D";
    } else if (average >= 60) {
      letterGrade = "D-";
    } else {
      letterGrade = "F";
    }

    return { averageGrade: average.toFixed(2), letterGrade };
  }, []);

  useEffect(() => {
    const averageGrade = calculateAverageGrade(grades, weight);
    setAverageGrade(averageGrade.averageGrade);
    setLetterGrade(averageGrade.letterGrade);
  }, [grades, weight, calculateAverageGrade]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Grid item className="calculation-part ">
              <Grid item>
                <Typography className="common-sub-heading-desc">
                  Use this calculator to calculate average grade based on the
                  scores they achieved in various courses, assignments, tests,
                  quizzes, or other assessments. It typically allows users to
                  input their scores and corresponding weights or percentages
                  for each item, then calculates the overall grade according to
                  a predefined grading scale or formula.
                </Typography>
              </Grid>
              <Grid item>
                <Table
                  numOfRows={Object.keys(courses).length}
                  headerData={["Course", "Grade %", "Weight"]}
                  courses={{ data: courses, setter: setCourses }}
                  grades={{ data: grades, setter: setGrades }}
                  weight={{ data: weight, setter: setWeight }}
                />
                <Button
                  sx={{ my: 2 }}
                  variant="contained"
                  onClick={() => {
                    setCourses((prev) => ({
                      ...prev,
                      [`co${[Object.keys(courses).length]}`]: "",
                    }));
                    setGrades((prev) => ({
                      ...prev,
                      [`gr${[Object.keys(courses).length]}`]: 0,
                    }));
                    setWeight((prev) => ({
                      ...prev,
                      [`w${[Object.keys(courses).length]}`]: 0,
                    }));
                  }}
                >
                  Add a course
                </Button>
              </Grid>
              <Grid className="result-label">
                <Typography>Result</Typography>
              </Grid>{" "}
              <Grid item sx={{ ml: 1 }}>
                <Typography className="grade-show-text">
                  Your Grade is:
                  <span
                    style={{
                      color: gradeLetterColor(letterGrade),
                    }}
                  >
                    {" "}
                    {letterGrade}
                  </span>
                </Typography>
                <Grid className="result-common-div">
                  <InputComponent
                    label="Your Percentage is"
                    isInput={false}
                    value={`${averageGrade}%` || "0%"}
                    date={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
