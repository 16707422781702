import * as React from "react";
import { Grid, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function RowRadioButtonsGroup({
  label,
  data,
  valueSetter,
  row,
  defaultValue,
  type,
}) {
  return (
    <>
      {row && (
        <Grid
          container
          sx={{ my: 1.5, mx: 1 , wordBreak:"break-word"}}
          justifyContent={type === "yes-no" ? "space-between" : "flex-start"}
          alignItems="center"
        >
          <Grid item xs={6} md={type === "yes-no" ? 9 : 5}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "18px",
              }}
            >
              {" "}
              {label}{" "}
            </Typography>
          </Grid>
          {type !== "yes-no" && <Grid item xs={0} md={2}></Grid>}
          <Grid
            item
            xs={6}
            md={type === "yes-no" ? 2.9 : 5}
            sx={{ alignItems: "end" }}
          >
            <RadioGroup
              row={Boolean(row)}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue={defaultValue}
            >
              {data.map((item) => {
                return (
                  <FormControlLabel
                    key={item.value}
                    onChange={(e) => {
                      valueSetter({ name: item.label, value: item.value });
                    }}
                    value={item.value}
                    control={<Radio />}
                    label={item.label}
                  />
                );
              })}
            </RadioGroup>
          </Grid>
        </Grid>
      )}

      {!row && (
        <>
          <Grid container sx={{ my: 2, mx: 1.5, wordBreak:"break-word" }}>
            <Grid item xs={12} sm={4} sx={{ mt: 0.7 }}>
              <Typography
                inline="true"
                align="left"
                sx={{
                  fontWeight: 500,
                  fontSize: "18px",
                }}
              >
                {" "}
                {label}{" "}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              sx={{ textAlign: { xs: "start", sm: "end" } }}
            >
              <RadioGroup
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue={defaultValue}
                sx={{ display: "contents" }}
              >
                {data.map((item) => {
                  return (
                    <FormControlLabel
                      key={item.value}
                      onChange={(e) => {
                        valueSetter({ name: item.label, value: item.value });
                      }}
                      value={item.value}
                      control={<Radio />}
                      label={item.label}
                    />
                  );
                })}
              </RadioGroup>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
