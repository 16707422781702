import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import "./RentalPropertyCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import { BOOLEANS } from "../../../Utils/calorieHelper";
import PieChartComponent from "../../Common/PieChart/PieChart";
import TableComponent from "../../Common/TableComponent/TableComponent";

function RentalPropertyCalc() {
  const [isUseLoan, setIsUseLoan] = useState({ label: "Yes", value: "yes" });
  const [needRepair, setNeedRepair] = useState({ label: "No", value: "no" });
  const [isSellPrice, setIsSellPrice] = useState({ label: "No", value: "no" });
  const [purchasePrice, setPurchasePrice] = useState(200000);
  const [downPayment, setDownPayment] = useState(20);
  const [repairCost, setRepairCost] = useState(20000);
  const [interestRate, setInterestRate] = useState(6);
  const [loanTerms, setLoanTerms] = useState(30);
  const [closingCost, setClosingCost] = useState(6000);
  const [propertyTax, setPropertyTax] = useState(3000);
  const [propertyTaxIncrement, setPropertyTaxIncrement] = useState(3);
  const [tableView, setTableView] = useState(false);
  const [totalIns, setTotalIns] = useState(1200);
  const [totalInsIncrement, setTotalInsIncrement] = useState(3);

  const [HOA, setHOA] = useState(0);
  const [HOAIncrement, setHOAIncrement] = useState(3);
  const [maintenance, setMaintenance] = useState(2000);
  const [maintenanceIncrement, setMaintenanceIncrement] = useState(3);

  const [otherCost, setOtherCost] = useState(500);
  const [otherCostIncrement, setOtherCostIncrement] = useState(3);

  const [monthlyRent, setMonthlyRent] = useState(2000);
  const [monthlyRentIncrement, setMonthlyRentIncrement] = useState(3);

  const [otherMonthlyIncome, setOtherMonthlyIncome] = useState(0);
  const [otherMonthlyIncomeIncrement, setOtherMonthlyIncomeIncrement] =
    useState(3);
  const [vacancyRate, setVacancyRate] = useState(5);
  const [managementFee, setManagementFee] = useState(0);
  const [holdingLength, setHoldingLength] = useState(20);
  const [costToSell, setCostToSell] = useState(8);

  const [valueAppreciation, setValueAppreciation] = useState(3);
  const [sellPrice, setSellPrice] = useState(0);

  const [holdingRecords, setHoldingRecords] = useState([]);

  useEffect(() => {
    function calculateAnnualIncome(year) {
      let rentIncrementFactor = 1 + monthlyRentIncrement / 100;
      let incomeIncrementFactor = 1 + otherMonthlyIncomeIncrement / 100;
      let vacancyAdjustment = 1 - vacancyRate / 100;

      // Calculate compounded rent and other income for the specific year
      let tempMonthlyRent =
        monthlyRent * Math.pow(rentIncrementFactor, year - 1);
      let tempOtherMonthlyIncome =
        otherMonthlyIncome * Math.pow(incomeIncrementFactor, year - 1);

      // Calculate income for the entire year at adjusted rates
      let annualRent = 12 * tempMonthlyRent;
      let annualOtherIncome = 12 * tempOtherMonthlyIncome;

      // Apply vacancy rate
      let yearlyIncome = (annualRent + annualOtherIncome) * vacancyAdjustment;

      return yearlyIncome.toFixed(2);
    }
    function calculateMortgagePayment() {
      // Convert interest rate into decimal and monthly rate
      const monthlyInterestRate = interestRate / 100 / 12;

      // Convert down payment percentage to actual down payment value
      const downPaymentValue = (+downPayment / 100) * purchasePrice;

      // Calculate the loan amount (purchase price minus down payment)
      const loanAmount = purchasePrice - downPaymentValue;

      // Calculate the total number of monthly payments (loan term in years times 12 months)
      const totalPayments = loanTerms * 12;

      // Calculate the monthly mortgage payment using the formula:
      const monthlyPayment =
        (loanAmount *
          monthlyInterestRate *
          Math.pow(1 + monthlyInterestRate, totalPayments)) /
        (Math.pow(1 + monthlyInterestRate, totalPayments) - 1);

      return (monthlyPayment * 12).toFixed(2);
    }
    const calculateFutureExpenses = (years) => {
      // Calculate future expenses assuming the first year is year 1
      const futurePropertyTax =
        +propertyTax * Math.pow(1 + +propertyTaxIncrement / 100, years - 1);
      const futureTotalIns =
        +totalIns * Math.pow(1 + +totalInsIncrement / 100, years - 1);
      const futureHOA = +HOA * Math.pow(1 + +HOAIncrement / 100, years - 1);
      const futureMaintenance =
        +maintenance * Math.pow(1 + +maintenanceIncrement / 100, years - 1);
      const futureOtherCost =
        +otherCost * Math.pow(1 + +otherCostIncrement / 100, years - 1);

      const totalFutureExpenses =
        futurePropertyTax +
        futureTotalIns +
        futureHOA +
        futureMaintenance +
        futureOtherCost;

      return totalFutureExpenses.toFixed(2);
    };

    const calculateBeginCashFlow = () => {
      let intialPrice =
        isUseLoan.value === "yes"
          ? +purchasePrice * (+downPayment / 100)
          : +purchasePrice;
      let extraRepairPrice = needRepair.value === "yes" ? repairCost : 0;
      return (+intialPrice + +extraRepairPrice + +closingCost).toFixed(2);
    };

    const calculateEquity = (years) => {
      const downPaymentValue = purchasePrice * (downPayment / 100);

      // Step 3: Value after repairs
      let valueAfterRepairs =
        needRepair.value === "yes" ? purchasePrice + repairCost : purchasePrice; // If no repairs, just use purchase price

      // Step 4: Calculate loan amount
      const loanAmount = valueAfterRepairs - downPaymentValue;

      // Step 5: Monthly interest rate calculation
      const monthlyInterestRate = interestRate / 1200;

      // Step 6: Total number of payments
      const numberOfPayments = loanTerms * 12;

      // Step 7: Future property value calculation
      const futurePropertyValue =
        isSellPrice.value === "no"
          ? +purchasePrice * Math.pow(1 + +valueAppreciation / 100, years)
          : sellPrice;

      // Step 8: Remaining loan balance calculation
      const remainingLoanBalance =
        (loanAmount *
          (Math.pow(1 + monthlyInterestRate, numberOfPayments) -
            Math.pow(1 + monthlyInterestRate, years * 12))) /
        (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

      // Step 9: Calculate accumulated equity
      const accumulatedEquity = futurePropertyValue - remainingLoanBalance;

      return accumulatedEquity.toFixed(2);
    };

    const cashToReceiveCalculate = (years) => {
      const futurePropertyValue =
        isSellPrice.value === "no"
          ? +purchasePrice * Math.pow(1 + +valueAppreciation / 100, years)
          : sellPrice;
      let incrementedValue = (
        futurePropertyValue *
        (+costToSell / 100)
      ).toFixed(2);
      let equityValue = calculateEquity(years);
      return (equityValue - incrementedValue).toFixed(2);
    };

    function calculateIRR(cashFlows) {
      let guess = 0.1; // Starting guess for IRR
      const maxIterations = 1000;
      const accuracy = 1.0e-6;
      let x0 = guess;

      for (let i = 0; i < maxIterations; i++) {
        let x1 = x0 - f(cashFlows, x0) / df(cashFlows, x0);

        // Check for convergence
        if (Math.abs(x1 - x0) <= accuracy) {
          return x1; // Found the IRR
        }

        // Prepare for the next iteration
        x0 = x1;

        // Avoid going to extreme values that may cause problems
        if (Math.abs(x0) > 1) {
          x0 = 0.1; // Reset guess if it diverges
        }
      }
      return null; // Return null if no solution is found
    }

    function f(cashFlows, rate) {
      let total = 0;
      for (let i = 0; i < cashFlows.length; i++) {
        total += cashFlows[i] / Math.pow(1 + rate, i);
      }
      return total;
    }

    function df(cashFlows, rate) {
      let total = 0;
      for (let i = 1; i < cashFlows.length; i++) {
        total -= (i * cashFlows[i]) / Math.pow(1 + rate, i + 1);
      }
      return total;
    }

    let annualIncomeIterate = 0;
    let mortgageIterate = calculateMortgagePayment();
    let expensesIterate = 0;
    let cashFlowIterate = 0;
    let cashOnCashReturnIterate = 0;
    let annualIncomeTotal = 0;
    let mortgageTotal = (mortgageIterate * holdingLength).toFixed(2);
    let expensesTotal = 0;
    let cashFlowTotal = 0;
    let cashOnCashReturnTotal = 0;
    let equityIterate = 0;
    let cashToReceiveIterate = 0;
    let IRRiterate = 0;
    let cashFlows = [-calculateBeginCashFlow()];
    let records = [
      {
        year: "Begin",
        annualIncome: "",
        mortgage: "",
        expenses: "",
        cashFlow: -calculateBeginCashFlow(),
        cashOnCashReturn: "",
        equity: "",
        cashToReceive: "",
        IRR: "",
      },
    ];
    for (let year = 1; year <= holdingLength; year++) {
      annualIncomeIterate = calculateAnnualIncome(year);
      expensesIterate = calculateFutureExpenses(year);
      cashFlowIterate = annualIncomeIterate - mortgageIterate - expensesIterate;
      cashOnCashReturnIterate = (
        (cashFlowIterate / calculateBeginCashFlow()) *
        100
      ).toFixed(2);

      equityIterate = calculateEquity(year);
      cashToReceiveIterate = cashToReceiveCalculate(year);
      cashFlows.push(+cashToReceiveIterate);
      IRRiterate = `${(calculateIRR(cashFlows) * 100).toFixed(2)}%`;

      records.push({
        year: year,
        annualIncome: annualIncomeIterate,
        mortgage: mortgageIterate,
        expenses: expensesIterate,
        cashFlow: cashFlowIterate,
        cashOnCashReturn: `${cashOnCashReturnIterate}%`,
        equity: equityIterate,
        cashToReceive: cashToReceiveIterate,
        IRR: IRRiterate,
      });
      annualIncomeTotal += +annualIncomeIterate;
      expensesTotal += +expensesIterate;
      cashFlowTotal += +cashFlowIterate;
      cashOnCashReturnTotal += +cashOnCashReturnIterate;
    }
    records.push({
      year: "Total",
      annualIncome: annualIncomeTotal,
      mortgage: mortgageTotal,
      expenses: expensesTotal,
      cashFlow: cashFlowTotal,
      cashOnCashReturn: `${cashOnCashReturnTotal.toFixed(2)}%`,
      equity: "",
      cashToReceive: "",
      IRR: "",
    });
    setHoldingRecords(records);
  }, [
    isUseLoan,
    needRepair,
    isSellPrice,
    purchasePrice,
    downPayment,
    interestRate,
    loanTerms,
    closingCost,
    propertyTax,
    propertyTaxIncrement,
    totalIns,
    totalInsIncrement,
    HOA,
    HOAIncrement,
    maintenance,
    maintenanceIncrement,
    otherCost,
    otherCostIncrement,
    monthlyRent,
    monthlyRentIncrement,
    otherMonthlyIncome,
    otherMonthlyIncomeIncrement,
    vacancyRate,
    managementFee,
    holdingLength,
    costToSell,
    valueAppreciation,
    sellPrice,
    repairCost,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={8} className="calculation-part">
            <Grid>
              <Typography className="common-sub-heading-calc">
                Purchase
              </Typography>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Purchase Price"
                value={purchasePrice}
                min={1}
                setState={setPurchasePrice}
                max={10000000000}
              />
              <RadioButtonComponent
                label={"Use Loan?"}
                data={BOOLEANS}
                defaultValue={"yes"}
                valueSetter={setIsUseLoan}
                row={false}
              />
              {isUseLoan.value === "yes" && (
                <div className="inside-input">
                  <InputComponent
                    isInput={true}
                    flag="percentage"
                    label="Down Payment"
                    value={downPayment}
                    min={0}
                    setState={setDownPayment}
                    max={100}
                  />
                  <InputComponent
                    isInput={true}
                    flag="percentage"
                    label="Interest Rate"
                    value={interestRate}
                    min={0}
                    setState={setInterestRate}
                    max={999}
                  />
                  <InputComponent
                    isInput={true}
                    flag="year"
                    label="Loan Term"
                    value={loanTerms}
                    min={0}
                    setState={setLoanTerms}
                    max={50}
                  />
                </div>
              )}
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Closing Cost"
                value={closingCost}
                min={0}
                setState={setClosingCost}
                max={10000000}
              />
              <RadioButtonComponent
                label={"Need Repairs?"}
                data={BOOLEANS}
                defaultValue={"no"}
                valueSetter={setNeedRepair}
                row={false}
              />
              {needRepair.value === "yes" && (
                <>
                  <InputComponent
                    isInput={true}
                    flag="dollar"
                    label="Repair Cost"
                    value={repairCost}
                    min={0}
                    setState={setRepairCost}
                    max={10000000}
                  />
                  <InputComponent
                    isInput={true}
                    flag="dollar"
                    label="After Repair Value"
                    value={+repairCost + +purchasePrice + +closingCost}
                    min={0}
                    dis={true}
                    max={10000000}
                  />
                </>
              )}
            </Grid>
            <Grid>
              <Typography className="common-sub-heading-calc">
                Recurring Operating Expenses [ Annual - Annual Increase]
              </Typography>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Property Tax"
                value={propertyTax}
                min={1}
                setState={setPropertyTax}
                max={10000000000}
                isCombo={true}
                otherValue={propertyTaxIncrement}
                setOtherState={setPropertyTaxIncrement}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Total Insurance"
                value={totalIns}
                min={1}
                setState={setTotalIns}
                max={10000000000}
                isCombo={true}
                otherValue={totalInsIncrement}
                setOtherState={setTotalInsIncrement}
              />{" "}
              <InputComponent
                isInput={true}
                flag="dollar"
                label="HOA Fee"
                value={HOA}
                min={1}
                setState={setHOA}
                max={10000000000}
                isCombo={true}
                otherValue={HOAIncrement}
                setOtherState={setHOAIncrement}
              />{" "}
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Maintenance"
                value={maintenance}
                min={1}
                setState={setMaintenance}
                max={10000000000}
                isCombo={true}
                otherValue={maintenanceIncrement}
                setOtherState={setMaintenanceIncrement}
              />{" "}
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Other Costs"
                value={otherCost}
                min={1}
                setState={setOtherCost}
                max={10000000000}
                isCombo={true}
                otherValue={otherCostIncrement}
                setOtherState={setOtherCostIncrement}
              />
            </Grid>
            <Grid>
              <Typography className="common-sub-heading-calc">
                Income
              </Typography>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Monthly Rent"
                value={monthlyRent}
                min={1}
                setState={setMonthlyRent}
                max={10000000000}
                isCombo={true}
                otherValue={monthlyRentIncrement}
                setOtherState={setMonthlyRentIncrement}
              />{" "}
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Other Monthly Income"
                value={otherMonthlyIncome}
                min={1}
                setState={setOtherMonthlyIncome}
                max={10000000000}
                isCombo={true}
                otherValue={otherMonthlyIncomeIncrement}
                setOtherState={setOtherMonthlyIncomeIncrement}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Vacancy Rate"
                value={vacancyRate}
                min={0}
                setState={setVacancyRate}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Management Fee"
                value={managementFee}
                min={0}
                setState={setManagementFee}
                max={10000000}
              />
            </Grid>
            <Grid>
              <Typography className="common-sub-heading-calc">Sell</Typography>
              <RadioButtonComponent
                label={"Do You Know the Sell Price?"}
                data={BOOLEANS}
                defaultValue={"no"}
                valueSetter={setIsSellPrice}
                row={false}
              />

              {isSellPrice.value === "yes" ? (
                <InputComponent
                  isInput={true}
                  flag="dollar"
                  value={sellPrice}
                  min={0}
                  setState={setSellPrice}
                  label="Sell Price"
                  max={10000000000}
                />
              ) : (
                <InputComponent
                  isInput={true}
                  flag="percentage"
                  label="Value Appreciation (p.a.)"
                  value={valueAppreciation}
                  min={0}
                  setState={setValueAppreciation}
                  max={10000000000}
                />
              )}

              <InputComponent
                isInput={true}
                flag="year"
                label="Holding Length"
                value={holdingLength}
                min={0}
                setState={setHoldingLength}
                max={100}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Cost to Sell"
                value={costToSell}
                min={0}
                setState={setCostToSell}
                max={10000000000}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              <Typography className="common-sub-heading-calc">
                For the {holdingLength} Years Invested
              </Typography>
              <InputComponent
                label="Return (IRR):"
                isInput={false}
                value={holdingRecords[+holdingLength]?.IRR || 0}
                date={true}
              />
              <InputComponent
                label="Total Profit when Sold:"
                isInput={false}
                flag="dollar"
                value={holdingRecords[+holdingLength + 1]?.cashFlow || 0}
              />
              <InputComponent
                label="Cash on Cash Return:	"
                isInput={false}
                date={true}
                value={
                  holdingRecords[+holdingLength + 1]?.cashOnCashReturn || 0
                }
              />
              <InputComponent
                label="Capitalization Rate:"
                isInput={false}
                flag="percentage"
                value={(
                  ((+holdingRecords[1]?.mortgage +
                    +holdingRecords[1]?.cashFlow) /
                    +purchasePrice) *
                  100
                ).toFixed(2)}
              />
              <InputComponent
                label="Total Rental Income:"
                isInput={false}
                flag="dollar"
                value={holdingRecords[+holdingLength + 1]?.annualIncome || 0}
              />{" "}
              <InputComponent
                label="Total Mortgage Payments:"
                isInput={false}
                flag="dollar"
                value={holdingRecords[+holdingLength + 1]?.mortgage || 0}
              />{" "}
              <InputComponent
                label="Total Expenses:"
                isInput={false}
                flag="dollar"
                value={holdingRecords[+holdingLength + 1]?.expenses || 0}
              />{" "}
              <InputComponent
                label="Total Net Operating Income:"
                isInput={false}
                flag="dollar"
                value={
                  holdingRecords[+holdingLength + 1]?.annualIncome -
                    holdingRecords[+holdingLength + 1]?.expenses || 0
                }
              />
              <Typography className="common-sub-heading-calc">
                First Year Income and Expense (Monthly)
              </Typography>
              <InputComponent
                label="Income:"
                isInput={false}
                flag="dollar"
                value={monthlyRent}
              />
              <InputComponent
                label="Mortgage Pay:"
                isInput={false}
                flag="dollar"
                value={holdingRecords[2]?.mortgage / 12}
              />
              <InputComponent
                label={`Vacancy (${vacancyRate}%):`}
                isInput={false}
                flag="dollar"
                value={+monthlyRent * (+vacancyRate / 100)}
              />
              <InputComponent
                label="Property Tax:"
                isInput={false}
                flag="dollar"
                value={+propertyTax / 12}
              />
              <InputComponent
                label="Total Insurance:"
                isInput={false}
                flag="dollar"
                value={totalIns / 12}
              />
              <InputComponent
                label="Maintenance Cost:"
                isInput={false}
                flag="dollar"
                value={maintenance / 12}
              />
              <InputComponent
                label="Other Cost:"
                isInput={false}
                flag="dollar"
                value={otherCost / 12}
              />
              <InputComponent
                label="Cash Flow:"
                isInput={false}
                flag="dollar"
                value={holdingRecords[1]?.cashFlow / 12}
              />
              <InputComponent
                label="Net Operating Income (NOI):"
                isInput={false}
                flag="dollar"
                value={
                  holdingRecords[1]?.mortgage / 12 +
                    holdingRecords[1]?.cashFlow / 12 || 0
                }
              />
              <Typography className="common-sub-heading-calc">
                First Year Income and Expense (Annual)
              </Typography>
              <InputComponent
                label="Income:"
                isInput={false}
                flag="dollar"
                value={monthlyRent * 12}
              />
              <InputComponent
                label="Mortgage Pay:"
                isInput={false}
                flag="dollar"
                value={holdingRecords[2]?.mortgage}
              />
              <InputComponent
                label={`Vacancy (${vacancyRate}%):`}
                isInput={false}
                flag="dollar"
                value={+monthlyRent * (+vacancyRate / 100) * 12}
              />
              <InputComponent
                label="Property Tax:"
                isInput={false}
                flag="dollar"
                value={+propertyTax}
              />
              <InputComponent
                label="Total Insurance:"
                isInput={false}
                flag="dollar"
                value={totalIns}
              />
              <InputComponent
                label="Maintenance Cost:"
                isInput={false}
                flag="dollar"
                value={maintenance}
              />
              <InputComponent
                label="Other Cost:"
                isInput={false}
                flag="dollar"
                value={otherCost}
              />
              <InputComponent
                label="Cash Flow:"
                isInput={false}
                flag="dollar"
                value={holdingRecords[1]?.cashFlow}
              />
              <InputComponent
                label="Net Operating Income (NOI):"
                isInput={false}
                flag="dollar"
                value={
                  +holdingRecords[1]?.mortgage + +holdingRecords[1]?.cashFlow ||
                  0
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} className="piechart-div">
            <PieChartComponent
              data={[
                {
                  id: 0,
                  value: +holdingRecords[1]?.mortgage || 0,
                  label: "Mortgage",
                },
                {
                  id: 1,
                  value: +monthlyRent * (+vacancyRate / 100) * 12,
                  label: "Vacancy",
                },
                { id: 2, value: +propertyTax, label: "Property Tax" },
                { id: 3, value: +totalIns, label: "Total Insurance" },
                { id: 4, value: +maintenance, label: "Maintenance Cost" },
                { id: 5, value: +otherCost, label: "Other Cost" },
              ]}
              flag="invest"
              isTax={true}
            />
          </Grid>
        </Grid>
        <Grid className="data-table-container">
          <Grid className="table-toggle-div">
            <Typography variant="h5">
              Your Amortization Details{" "}
              <span>
                (<u>Yearly</u>)
              </span>
            </Typography>
            <Tooltip
              title={tableView ? "click to close table" : "click to open table"}
              placement="top"
              arrow
            >
              <span>
                <img
                  src={plusIcon}
                  alt="plusIcon"
                  onClick={() => setTableView(!tableView)}
                  style={{
                    transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                  className="add-icon-table"
                />
              </span>
            </Tooltip>
          </Grid>
          {tableView && (
            <Grid>
              <Grid>
                <TableComponent
                  rows={holdingRecords}
                  currency="dollar"
                  headerData={[
                    "Year",
                    "Annual Income",
                    "Mortgage",
                    "Expenses",
                    "Cash Flow",
                    "Cash on Cash Return",
                    "Equity Accumulated",
                    "Cash to Receive",
                    "Return (IRR)",
                  ]}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default RentalPropertyCalc;
