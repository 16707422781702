import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import PieChartComponent from "../../Common/PieChart/PieChart";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import TableComponent from "../../Common/TableComponent/TableComponent";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import { AnnuityCalcRadio } from "../../../Utils/calorieHelper";
import BarChartComponent from "../../Common/BarChart/BarCahrt";

function AnnuityCalc() {
  const [startPrincipal, setStartPrincipal] = useState(20000);
  const [annualAddition, setAnnualAddition] = useState(10000);
  const [monthlyAddition, setMonthlyAddition] = useState(0);

  const [tableView, setTableView] = useState(false);
  const [monthlyRecords, setMonthlyRecords] = useState([]);
  const [interestRateType, setInterestRateType] = useState({
    label: "beginning (annuity due)",
    value: "began",
  });
  const [yearlySummary, setYearlySummary] = useState([
    {
      year: 0,
      principal: +startPrincipal,
      interest: 0,
    },
  ]);
  const [annualGrowthRate, setAnnualGrowthRate] = useState(6);
  const [years, setYears] = useState(10);
  const [endBalance, setEndBalance] = useState(0);
  const [totalAddition, setTotalAddition] = useState(0);
  const [totalReturn, setTotalReturn] = useState(0);

  useEffect(() => {
    let yearlySummaryData = [];
    let monthlyRecordsData = [];
    let totalInterest = 0;
    let totalAdditions = 0;
    let currentBalance = +startPrincipal;

    // Calculating monthly rate based on annual growth rate
    let monthlyRate = (+annualGrowthRate / 12).toFixed(3) / 100;

    // Loop for each month up to the total months (years * 12)
    for (let month = 1; month <= +years * 12; month++) {
      let principalPaid = 0;

      if (month === 1) {
        principalPaid += +startPrincipal;
      }
      // Add monthly contributions depending on addAt (beginning/end of the period)
      if (interestRateType.value === "began" && month % 12 === 1) {
        currentBalance += +annualAddition + +monthlyAddition;
        totalAdditions += +annualAddition + +monthlyAddition;        
        principalPaid += +annualAddition + +monthlyAddition;        
      }

      let interestCharged = currentBalance * monthlyRate; // Monthly interest
      totalInterest += interestCharged;
      currentBalance += interestCharged; // Add interest to balance


      
      if (interestRateType.value === "end" && month % 12 === 0) {
        currentBalance += +annualAddition + +monthlyAddition;
        totalAdditions += +annualAddition + +monthlyAddition; 
        principalPaid += +annualAddition + +monthlyAddition;            
      }

      // Push monthly data
      monthlyRecordsData.push({
        month: month,
        principalPaid: principalPaid.toFixed(2),
        interestCharged: interestCharged.toFixed(2),
        balance: currentBalance.toFixed(2),
      });

      // Every 12 months (end of year), push yearly summary
      if (month % 12 === 0) {
        let year = month / 12;
        yearlySummaryData.push({
          year: year,
          principal: +startPrincipal,
          interest: totalInterest.toFixed(2),
          total: totalAdditions.toFixed(2),
        });

       
      }
    }

    let endBalance = currentBalance.toFixed(2);
    let totalReturn = (endBalance - startPrincipal - totalAdditions).toFixed(2);

    // Set state variables
    setEndBalance(+endBalance);
    setTotalAddition(+totalAdditions.toFixed(2));
    setTotalReturn(+totalReturn);
    setYearlySummary(yearlySummaryData);
    setMonthlyRecords(monthlyRecordsData);
  }, [startPrincipal, annualAddition, annualGrowthRate, years, interestRateType, monthlyAddition]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Starting principal"
                value={startPrincipal}
                setState={setStartPrincipal}
                min={1}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Annual addition"
                value={annualAddition}
                setState={setAnnualAddition}
                min={0}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Monthly addition"
                value={monthlyAddition}
                setState={setMonthlyAddition}
                min={0}
                max={10000000000}
              />
              <Grid>
                <RadioButtonComponent
                  label={"Add at each period's"}
                  data={AnnuityCalcRadio}
                  defaultValue={"began"}
                  valueSetter={setInterestRateType}
                  row={true}
                />
              </Grid>
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Annual growth rate"
                setState={setAnnualGrowthRate}
                value={annualGrowthRate}
                min={0}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="After"
                setState={setYears}
                value={years}
                min={0}
                max={199}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                label="End balance"
                isInput={false}
                value={endBalance}
              />
              <InputComponent
                label="Starting principal"
                isInput={false}
                value={startPrincipal}
              />
              <InputComponent
                label="Total additions"
                isInput={false}
                value={totalAddition}
              />
              <InputComponent
                label="Total return/interest earned"
                isInput={false}
                value={totalReturn}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +startPrincipal, label: "Starting principal" },
                { id: 1, value: +totalAddition, label: "Additions" },
                { id: 2, value: +totalReturn, label: "Return/interest" },
              ]}
              isTax={true}
            />
          </Grid>
        </Grid>
        <Grid className="data-table-container">
          <Grid className="table-toggle-div">
            <Typography variant="h5">
              Your Amortization Details{" "}
              <span>
                (<u>Monthly</u>)
              </span>
            </Typography>
            <Tooltip
              title={tableView ? "click to close table" : "click to open table"}
              placement="top"
              arrow
            >
              <span>
                <img
                  src={plusIcon}
                  alt="plusIcon"
                  onClick={() => setTableView(!tableView)}
                  style={{
                    transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                  className="add-icon-table"
                />
              </span>
            </Tooltip>
          </Grid>
          {tableView && (
            <Grid>
              <Grid>
                <TableComponent
                  rows={monthlyRecords}
                  headerData={[
                    "Month",
                    "Addition",
                    "Return",
                    "Ending balance"
                  ]}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={5} className="piechart-div">
          <BarChartComponent
            data={[
              {
                data: yearlySummary
                  ?.sort((a, b) => (a.year > b.year ? 1 : -1))
                  ?.map((x) => x?.principal),
                stack: "A",
                label: "Principal Amount",
              },
              {
                data: yearlySummary
                  ?.sort((a, b) => (a.year > b.year ? 1 : -1))
                  ?.map((x) => x?.total),
                stack: "A",
                label: "Total Addition",
              },
              {
                data: yearlySummary
                  ?.sort((a, b) => (a.year > b.year ? 1 : -1))
                  ?.map((x) => x?.interest),
                stack: "A",
                label: "Interest Value",
              },
            ]}
          />
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default AnnuityCalc;
