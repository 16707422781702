import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import "../GPACalc/Table.scss";

export default function DenseTable({
  numOfRows,
  headerData,
  courses,
  grades,
  weight,
}) {
  const rowHeaderCellStyling = {
    align: "left",
    backgroundColor: "#295fa6",
    fontWeight: "bold",
    color: "white",
    paddingRight: "5px",
  };

  return (
    <TableContainer className="tableBorder" sx={{}} component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow sx={{ border: "2px solid #295fa6" }}>
            {headerData?.map((item, index) => (
              <TableCell
                align="left"
                sx={{ ...rowHeaderCellStyling }}
                key={index}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(numOfRows)].map((value, i) => (
            <TableRow sx={{ border: "2px solid #295fa6" }}>
              <TableCell sx={{ p: 2 }}>
                <TextField
                  sx={{ width: "100%" }}
                  id={`co${i}`}
                  name={`co${i}`}
                  label="Subject"
                  size="small"
                  variant="outlined"
                  value={courses.data[`co${i}`]}
                  onChange={(event) => {
                    courses.setter((prev) => ({
                      ...prev,
                      [event.target.name]: event.target.value,
                    }));
                  }}
                />
              </TableCell>
              <TableCell sx={{ p: 2 }}>
                <TextField
                  sx={{ width: "100%" }}
                  id={`gr${i}`}
                  name={`gr${i}`}
                  label="Grade"
                  size="small"
                  variant="outlined"
                  value={grades.data[`gr${i}`]}
                  inputProps={{ type: "number" }}
                  onChange={(event) => {
                    grades.setter((prev) => ({
                      ...prev,
                      [event.target.name]: Math.max(
                        parseInt(event.target.value),
                        0
                      ),
                    }));
                  }}
                />
              </TableCell>
              <TableCell sx={{ p: 2 }}>
                <TextField
                  sx={{ width: "100%" }}
                  id={`w${i}`}
                  name={`w${i}`}
                  label="weight"
                  size="small"
                  variant="outlined"
                  value={weight.data[`w${i}`]}
                  inputProps={{ type: "number" }}
                  onChange={(event) => {
                    weight.setter((prev) => ({
                      ...prev,
                      [event.target.name]: Math.max(
                        parseInt(event.target.value),
                        0
                      ),
                    }));
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
