import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";

function GravelCalc() {
  const [coverArea, setCoverArea] = useState({
    label: "Use total area",
    value: "Use total area",
  });
  const [totalArea, setTotalArea] = useState({
    value: 200,
    unit: "square meters",
  });
  const [rectangleLength, setRectangleLength] = useState({
    value: 10,
    unit: "meters",
  });
  const [rectangleWidth, setRectangleWidth] = useState({
    value: 15,
    unit: "meters",
  });
  const [diameter, setDiameter] = useState({ value: 10, unit: "meters" });
  const [gravelDepth, setGravelDepth] = useState({
    value: 3,
    unit: "cm",
  });
  const [gravelDensity, setGravelDensity] = useState(
    "Gravel(0.2-2'' or 0.5-5 cm)"
  );
  const [density, setDensity] = useState({
    value: 1600,
    unit: "kg/m³",
  });
  const [priceValue, setPriceValue] = useState(0);
  const [priceUnit, setPriceUnit] = useState("per kg");
  const [volumeInCubicFeet, setVolumeInCubicFeet] = useState(0);
  const [volumeInLiters, setVolumeInLiters] = useState(0);
  const [weightInKG, setWeightInKG] = useState(0);
  const [weightInLbs, setWeightInLbs] = useState(0);
  const [resultPrice, setResultPrice] = useState(0);

  const AREA_COVER = [
    {
      label: "Use total area",
      value: "Use total area",
    },
    { label: "Rectangle", value: "rectangle" },
    { label: "Circle", value: "circle" },
  ];

  useEffect(() => {
    const densityValue = (() => {
      let rawDensity = density.value;
      let densityInKgPerM3;

      if (gravelDensity === "Use your own density") {
        switch (density.unit) {
          case "kg/m³":
            densityInKgPerM3 = rawDensity;
            break;
          case "g/cm³":
            densityInKgPerM3 = rawDensity * 1000;
            break;
          case "lbs/ft³":
            densityInKgPerM3 = rawDensity * 16.0185;
            break;
          default:
            densityInKgPerM3 = rawDensity;
        }
      } else {
        const densityMapping = {
          "Gravel(0.2-2'' or 0.5-5 cm)": 1680,
          "Gravel with sand": 1920,
          "Dry sand": 1500,
          "Wet sand": 1900,
        };

        densityInKgPerM3 = densityMapping[gravelDensity] || 1680;
      }

      return densityInKgPerM3;
    })();

    let areaInMeters = 0;

    if (coverArea?.value === "Use total area") {
      switch (totalArea.unit) {
        case "square meters":
          areaInMeters = totalArea.value;
          break;
        case "square feet":
          areaInMeters = totalArea.value * 0.092903;
          break;
        case "square yards":
          areaInMeters = totalArea.value * 0.836127;
          break;
        case "acres":
          areaInMeters = totalArea.value * 4046.86;
          break;
        default:
          areaInMeters = totalArea.value;
          break;
      }
    } else if (coverArea?.value === "rectangle") {
      const lengthInMeters = (() => {
        switch (rectangleLength.unit) {
          case "meters":
            return rectangleLength.value;
          case "feet":
            return rectangleLength.value * 0.3048;
          case "inches":
            return rectangleLength.value * 0.0254;
          case "cm":
            return rectangleLength.value / 100;
          case "yards":
            return rectangleLength.value * 0.9144;
          default:
            return rectangleLength.value;
        }
      })();

      const widthInMeters = (() => {
        switch (rectangleWidth.unit) {
          case "meters":
            return rectangleWidth.value;
          case "feet":
            return rectangleWidth.value * 0.3048;
          case "inches":
            return rectangleWidth.value * 0.0254;
          case "cm":
            return rectangleWidth.value / 100;
          case "yards":
            return rectangleWidth.value * 0.9144;
          default:
            return rectangleWidth.value;
        }
      })();

      areaInMeters = lengthInMeters * widthInMeters;
    } else if (coverArea?.value === "circle") {
      const radiusInMeters = (() => {
        switch (diameter.unit) {
          case "meters":
            return diameter.value / 2;
          case "feet":
            return (diameter.value * 0.3048) / 2;
          case "inches":
            return (diameter.value * 0.0254) / 2;
          case "cm":
            return diameter.value / 100 / 2;
          case "yards":
            return (diameter.value * 0.9144) / 2;
          default:
            return diameter.value / 2;
        }
      })();

      areaInMeters = Math.PI * radiusInMeters ** 2;
    }

    const depthInMeters = (() => {
      switch (gravelDepth.unit) {
        case "meters":
          return gravelDepth.value;
        case "cm":
          return gravelDepth.value / 100;
        case "feet":
          return gravelDepth.value * 0.3048;
        case "inches":
          return gravelDepth.value * 0.0254;
        default:
          return gravelDepth.value;
      }
    })();

    const calculatedVolume = areaInMeters * depthInMeters;
    const volumeInCubicFeet = calculatedVolume * 35.3147;
    setVolumeInLiters(Math.round(calculatedVolume * 1000 * 100) / 100);
    setVolumeInCubicFeet(Math.round(volumeInCubicFeet * 100) / 100);

    const calculatedWeight = calculatedVolume * densityValue;
    const weightInLbs = calculatedWeight * 2.20462;
    setWeightInKG(Math.round(calculatedWeight * 100) / 100);
    setWeightInLbs(Math.round(weightInLbs * 100) / 100);

    const pricePerCubicMeter = (() => {
      switch (priceUnit) {
        case "per cubic meter":
          return priceValue;
        case "per cubic foot":
          return priceValue * 35.3147;
        case "per cubic yard":
          return priceValue * 1.30795;
        case "per liter":
          return priceValue * 1000;
        case "per kg":
          return priceValue * densityValue;
        case "per pound":
          return priceValue * 2.20462 * densityValue;
        case "per short ton":
          return (priceValue / 907.18474) * densityValue;
        case "per metric ton":
          return (priceValue / 1000) * densityValue;
        default:
          return priceValue;
      }
    })();

    const calculatedCost = calculatedVolume * pricePerCubicMeter;
    setResultPrice(calculatedCost.toFixed(2));
  }, [
    coverArea,
    totalArea,
    rectangleLength,
    rectangleWidth,
    diameter,
    gravelDepth,
    gravelDensity,
    density,
    priceValue,
    priceUnit,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <RadioButtonComponent
                label={"Area to Cover"}
                data={AREA_COVER}
                defaultValue={"Use total area"}
                valueSetter={setCoverArea}
                row={false}
              />
              {coverArea?.value === "Use total area" ? (
                <Grid>
                  <Grid className="concrete-calc-input pace">
                    <Typography className="input-label">Total Area</Typography>
                    <Grid display={"flex"}>
                      <input
                        type="number"
                        value={totalArea?.value}
                        onChange={(e) =>
                          setTotalArea((prev) => ({
                            ...prev,
                            value:
                              e.target.value < 0
                                ? 0
                                : e.target.value > 1000000000000
                                ? 1000000000000
                                : e.target.value,
                          }))
                        }
                        className="input-field"
                      />
                      <Grid className="select-box-wrap">
                        <SelectBoxComponent
                          value={totalArea?.unit}
                          setValue={(newUnit) =>
                            setTotalArea((prev) => ({
                              ...prev,
                              unit: newUnit,
                            }))
                          }
                          data={[
                            "square feet",
                            "square meters",
                            "square yards",
                            "acres",
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : coverArea?.value === "rectangle" ? (
                <Grid>
                  <Grid className="concrete-calc-input pace">
                    <Typography className="input-label">Length</Typography>
                    <Grid display={"flex"}>
                      <input
                        type="number"
                        value={rectangleLength?.value}
                        onChange={(e) =>
                          setRectangleLength((prev) => ({
                            ...prev,
                            value:
                              e.target.value < 0
                                ? 0
                                : e.target.value > 1000000000000
                                ? 1000000000000
                                : e.target.value,
                          }))
                        }
                        className="input-field"
                      />
                      <Grid className="select-box-wrap">
                        <SelectBoxComponent
                          value={rectangleLength?.unit}
                          setValue={(newUnit) =>
                            setRectangleLength((prev) => ({
                              ...prev,
                              unit: newUnit,
                            }))
                          }
                          data={["feet", "meters", "inches", "cm", "yards"]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className="concrete-calc-input pace">
                    <Typography className="input-label">Width</Typography>
                    <Grid display={"flex"}>
                      <input
                        type="number"
                        value={rectangleWidth?.value}
                        onChange={(e) =>
                          setRectangleWidth((prev) => ({
                            ...prev,
                            value:
                              e.target.value < 0
                                ? 0
                                : e.target.value > 1000000000000
                                ? 1000000000000
                                : e.target.value,
                          }))
                        }
                        className="input-field"
                      />
                      <Grid className="select-box-wrap">
                        <SelectBoxComponent
                          value={rectangleWidth?.unit}
                          setValue={(newUnit) =>
                            setRectangleWidth((prev) => ({
                              ...prev,
                              unit: newUnit,
                            }))
                          }
                          data={["feet", "meters", "inches", "cm", "yards"]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid>
                  <Grid className="concrete-calc-input pace">
                    <Typography className="input-label">Diameter</Typography>
                    <Grid display={"flex"}>
                      <input
                        type="number"
                        value={diameter?.value}
                        onChange={(e) =>
                          setDiameter((prev) => ({
                            ...prev,
                            value:
                              e.target.value < 0
                                ? 0
                                : e.target.value > 1000000000000
                                ? 1000000000000
                                : e.target.value,
                          }))
                        }
                        className="input-field"
                      />
                      <Grid className="select-box-wrap">
                        <SelectBoxComponent
                          value={diameter?.unit}
                          setValue={(newUnit) =>
                            setDiameter((prev) => ({
                              ...prev,
                              unit: newUnit,
                            }))
                          }
                          data={["feet", "meters", "inches", "cm", "yards"]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid>
                <Grid className="concrete-calc-input pace">
                  <Typography className="input-label">
                    Depth of Gravel
                  </Typography>
                  <Grid display={"flex"}>
                    <input
                      type="number"
                      value={gravelDepth?.value}
                      onChange={(e) =>
                        setGravelDepth((prev) => ({
                          ...prev,
                          value:
                            e.target.value < 0
                              ? 0
                              : e.target.value > 1000000000000
                              ? 1000000000000
                              : e.target.value,
                        }))
                      }
                      className="input-field"
                    />
                    <Grid className="select-box-wrap">
                      <SelectBoxComponent
                        value={gravelDepth?.unit}
                        setValue={(newUnit) =>
                          setGravelDepth((prev) => ({
                            ...prev,
                            unit: newUnit,
                          }))
                        }
                        data={["feet", "meters", "inches", "cm", "yards"]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <SelectBoxComponent
                label={"Gravel Density"}
                value={gravelDensity}
                setValue={setGravelDensity}
                data={[
                  "Gravel(0.2-2'' or 0.5-5 cm)",
                  "Gravel with sand",
                  "Dry sand",
                  "Wet sand",
                  "Use your own density",
                ]}
              />
              {gravelDensity === "Use your own density" && (
                <Grid>
                  <Grid className="concrete-calc-input pace">
                    <Typography className="input-label">Density</Typography>
                    <Grid display={"flex"}>
                      <input
                        type="number"
                        value={density?.value}
                        onChange={(e) =>
                          setDensity((prev) => ({
                            ...prev,
                            value:
                              e.target.value < 0
                                ? 0
                                : e.target.value > 1000000000000
                                ? 1000000000000
                                : e.target.value,
                          }))
                        }
                        className="input-field"
                      />
                      <Grid className="select-box-wrap">
                        <SelectBoxComponent
                          value={density?.unit}
                          setValue={(newUnit) =>
                            setDensity((prev) => ({
                              ...prev,
                              unit: newUnit,
                            }))
                          }
                          data={["kg/m³", "g/cm³", "lbs/ft³"]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid>
                <Grid className="concrete-calc-input pace">
                  <Typography className="input-label">Price</Typography>
                  <Grid display={"flex"}>
                    <input
                      type="number"
                      value={priceValue}
                      onChange={(e) =>
                        setPriceValue(
                          e.target.value < 0
                            ? 0
                            : e.target.value > 1000000000000
                            ? 1000000000000
                            : e.target.value
                        )
                      }
                      className="input-field"
                    />
                    <Grid className="select-box-wrap">
                      <SelectBoxComponent
                        value={priceUnit}
                        setValue={(newUnit) => setPriceUnit(newUnit)}
                        data={[
                          "per cubic foot",
                          "per cubic yard",
                          "per cubic meter",
                          "per liter",
                          "per pound",
                          "per short ton",
                          "per kg",
                          "per metric ton",
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div">
              <InputComponent
                label="Volume(liters)"
                isInput={false}
                value={`${volumeInLiters} liters`}
                date={true}
              />
              <InputComponent
                label="Volume(cubic feet)"
                isInput={false}
                value={`${volumeInCubicFeet} cubic feet`}
                date={true}
              />
              <InputComponent
                label={"Weight(kg)"}
                isInput={false}
                value={`${weightInKG} kg`}
                date={true}
              />
              <InputComponent
                label={"Weight(lbs)"}
                isInput={false}
                value={`${weightInLbs} lbs`}
                date={true}
              />
              {priceValue > 0 && (
                <InputComponent
                  label={"Total material cost "}
                  isInput={false}
                  value={resultPrice}
                  flag={"dollar"}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default GravelCalc;
