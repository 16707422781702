import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import PieChartComponent from "../../Common/PieChart/PieChart";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function SalaryCalc() {
  const [ctc, setCtc] = useState(600000);

  const [bonus, setBonus] = useState(20000);
  const [tax, setTax] = useState(200);
  const [employerPF, setEmployerPF] = useState(1800);
  const [employeePF, setEmployeePF] = useState(1800);
  const [deduction, setDeduction] = useState(0);
  const [monthlyDeduction, setMonthlyDeduction] = useState(0);
  const [annualDeduction, setAnnualDeduction] = useState(0);
  const [monthlySalary, setMonthlySalary] = useState(0);
  const [annualSalary, setAnnualSalary] = useState(0);

  useEffect(() => {
    // Calculate monthly deduction
    const monthlyDeductionTotal =
      Number(bonus) / 12 +
      Number(tax) +
      Number(employerPF) +
      Number(employeePF) +
      Number(deduction);
    setMonthlyDeduction(monthlyDeductionTotal.toFixed(0));

    // Calculate annual deduction
    const annualDeductionTotal = monthlyDeductionTotal * 12;
    setAnnualDeduction(annualDeductionTotal.toFixed(0));

    // Calculate monthly salary
    const monthlySalaryTotal = Number(ctc) / 12 - monthlyDeductionTotal;
    setMonthlySalary(monthlySalaryTotal.toFixed(0));

    // Calculate annual salary
    const annualSalaryTotal = Number(ctc) - annualDeductionTotal;
    setAnnualSalary(annualSalaryTotal.toFixed(0));
  }, [ctc, bonus, tax, employerPF, employeePF, deduction]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: "10px 24px" }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <InputComponent
                isInput={true}
                flag="rupee"
                label="Cost to Company (CTC)"
                value={ctc}
                setState={setCtc}
                max={10000000000}
              />

              <InputComponent
                isInput={true}
                flag="rupee"
                label="Bonus Included in CTC"
                setState={setBonus}
                value={bonus}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="rupee"
                label="Monthly Professional Tax"
                setState={setTax}
                value={tax}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="rupee"
                label="Monthly Employer PF"
                value={employerPF}
                setState={setEmployerPF}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="rupee"
                label="Monthly Employee PF"
                setState={setEmployeePF}
                value={employeePF}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="rupee"
                label="Monthly Additional Deduction"
                setState={setDeduction}
                value={deduction}
                max={10000000000}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                label="Total Monthly Deductions"
                isInput={false}
                value={monthlyDeduction}
              />
              <InputComponent
                label="Total Annual Deductions"
                isInput={false}
                value={annualDeduction}
              />
              <InputComponent
                label="Take Home Monthly Salary"
                isInput={false}
                value={monthlySalary}
              />
              <InputComponent
                label="Take Home Annual Salary"
                isInput={false}
                value={annualSalary}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              flag="invest"
              data={[
                {
                  id: 0,
                  value: +monthlyDeduction,
                  label: "Total Monthly Deductions",
                },
                {
                  id: 1,
                  value: +annualDeduction,
                  label: "Total Annual Deductions",
                },
                {
                  id: 2,
                  value: +monthlySalary,
                  label: "Take Home Monthly Salary",
                },
                {
                  id: 3,
                  value: +annualSalary,
                  label: "Take Home Annual Salary",
                },
              ]}
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default SalaryCalc;
