import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

export default function CalenderView({ date, valueSetter, ...props }) {
  return (
    <div className="calender-width-set">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar value={date} onChange={valueSetter} />
      </LocalizationProvider>
    </div>
  );
}
