import React, { useContext, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { COMPONENTDATA } from "../../../Utils/Constant";
import { DataContext } from "../../../Context/DataContext";
import "./SideBarMenu.scss";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getValueBySlugOrTitle } from "../../../Utils/CommonFunction";

const SideBarMenu = () => {
  const { tabIndex, setTabIndex } = useContext(DataContext);
  useEffect(() => {
    if (window.location.pathname) {
      setTabIndex(
        getValueBySlugOrTitle("slug", window.location.pathname.substring(1))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setTabIndex(event.target.getAttribute("aria-label"));

    navigate(`/${event.target.getAttribute("aria-labelledby")}`);
    window.scrollTo(0, 0);
  };
  return (
    <Box className="vertical-tabs-container">
      <Typography sx={{ color: "#61B0C1", fontSize: "24px", mb: 2 }}>
        Other Calculator
      </Typography>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabIndex}
        onChange={handleChange}
        className="vertical-tabs-div"
      >
        {COMPONENTDATA.map((item, index) => (
          <Tab
            label={item?.title}
            aria-labelledby={item?.slug}
            aria-label={item?.title}
            value={item?.title}
            className="vertical-tab-label"
            key={index}
            sx={{
              borderLeft:
                item?.title === tabIndex
                  ? "2px solid #61B0C1"
                  : "2px solid transparent",
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};
export default SideBarMenu;
